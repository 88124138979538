/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Container,
  Pagination,
  Box,
  Button,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import TableHorizontal from "../../../components/TableHorizontal";
import api from "../../../services/api";
//import { inputsName } from "../../../config/languageConst";
import React from "react";
import { useLocation } from "react-router-dom";
import { LogTrackUser } from "../../../types/log-track-user/log-track-user";
import { AsyncPaginate } from "react-select-async-paginate";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
//import { Language } from "@mui/icons-material";

export default function Products() {
  const [pageState, setPageState] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////
  const [products, setProducts] = useState<any[]>([]);
  const columns = [
    "ID",
    "Product Type",
    "SKU",
    "Name",
    "Image",
    "SEO URL",
    "Status",
    "Actions",
    "Delete",
  ];
  const keys = [
    "id",
    "product_type_text",
    "sku",
    "name",
    "image",
    "seo_url",
    "status_text",
    "action",
    "delete",
  ];
  const fetchData = async (
    page = 1,
    language = 1,
    numberItemsPerPage = 250
  ) => {
    await api
      .get(
        `/products-custom?page=${page}&limit=${numberItemsPerPage}&language_id=${language}&search_sku=${skuFilter}&search_product_name=${productNameFilter}&search_barcode=${barcodeFilter}&search_seo_url=${SEOURLFilter}`
      )
      .then((res) => {
        setProducts(res.data.items);
        setTotalPages(res.data.total_pages);
      });
  };
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageState(value);
    fetchData(value, Number(language), Number(numberItemsPerPage));
  };

  const [numberItemsPerPage, setNumberItemsPerPage] = useState(250);
  const handleNumberItemsPerPage = (event: SelectChangeEvent) => {
    setNumberItemsPerPage(Number(event.target.value));
    fetchData(1, Number(language), Number(event.target.value));
  };

  const [language, setLanguage] = useState(0);
  const handleChangeLanguage = (event: SelectChangeEvent) => {
    setLanguage(Number(event.target.value));
    fetchData(1, Number(event.target.value), Number(numberItemsPerPage));
  };

  const [languages, setLanguages] = React.useState<
    { id: number; name: string }[]
  >([]);
  const fetchLanguages = async () => {
    const response = await api.get(`languages`);
    setLanguages(response.data.items);
    setLanguage(1);
  };

  const [skuFilter, setSKUFilter] = useState("");

  const [productNameFilter, setProductNameFilter] = useState("");

  const [barcodeFilter, setBarcodeFilter] = useState("");

  const [SEOURLFilter, setSEOURLFilter] = useState("");
  const handleFilterButtonClick = () => {
    fetchData(1, Number(language));
  };

  const [productIDBegin, setProductIDBegin] = useState("1");
  const [productIDEnd, setProductIDEnd] = useState("10");

  const [productImagesUpdate, setProductImagesUpdate] = React.useState<
    string[]
  >([]);
  const fetchProductImagesUpdate = async () => {
    //Get products ID array based on the productIDBegin and productIDEnd
    const results_product_ids = await api.get(
      `/products-custom/productIDsRange/1?IDBegin=${productIDBegin}&IDEnd=${productIDEnd}`
    );

    setProductImagesUpdate([]);
    productImagesUpdate.length = 0;
    for (var i = 0; i < results_product_ids.data.length; i++) {
      let product_id = results_product_ids.data[i].product_id;
      //Call Function that will update the images for the product_id and return the status to show in the screen
      const result_update_images = await api.put(
        `/products-custom/imagesfromS3/${product_id}`
      );
      productImagesUpdate.push(result_update_images.data);
      setProductImagesUpdate(productImagesUpdate);
      if (i > 0 && i % 5 === 0) {
        fetchData();
      } //Only updates every 5 products
    }
    fetchData();
  };

  const [productTitleDescriptionsCheck, setProductTitleDescriptionsCheck] =
    React.useState<string[]>([]);
  const fetchProductTitleDescriptionsCheck = async () => {
    //Get products ID array based on the productIDBegin and productIDEnd
    const results_product_ids = await api.get(
      `/products-custom/productIDsRange/1?IDBegin=${productIDBegin}&IDEnd=${productIDEnd}`
    );

    setProductTitleDescriptionsCheck([]);
    productTitleDescriptionsCheck.length = 0;
    for (var i = 0; i < results_product_ids.data.length; i++) {
      let product_id = results_product_ids.data[i].product_id;
      const result_check_titledescriptions = await api.get(
        `/products-custom/checkTitleDescriptions/${product_id}`
      );
      productTitleDescriptionsCheck.push(result_check_titledescriptions.data);
      if (i > 0 && i % 10 === 0) {
        fetchData();
      } //Only updates every 10 products
    }
    fetchData();
  };

  const [productSEOLinksCreate, setProductSEOLinksCreate] = React.useState<
    string[]
  >([]);
  const fetchProductSEOLinksCreate = async () => {
    //Get products ID array based on the productIDBegin and productIDEnd
    const results_product_ids = await api.get(
      `/products-custom/productIDsRange/1?IDBegin=${productIDBegin}&IDEnd=${productIDEnd}`
    );
    setProductSEOLinksCreate([]);
    productSEOLinksCreate.length = 0;
    for (var i = 0; i < results_product_ids.data.length; i++) {
      let product_id = results_product_ids.data[i].product_id;
      const result_create_SEOLinks = await api.put(
        `/products-custom/createSEOLinks/${product_id}`
      );
      productSEOLinksCreate.push(result_create_SEOLinks.data);
      setProductSEOLinksCreate(productSEOLinksCreate);
      if (i > 0 && i % 10 === 0) {
        fetchData();
      } //Only updates every 10 products
    }
    fetchData();
  };

  const [productCategoriesCheck, setProductCategoriesCheck] = React.useState<
    string[]
  >([]);
  const fetchProductCategoriesCheck = async () => {
    //Get products ID array based on the productIDBegin and productIDEnd
    const results_product_ids = await api.get(
      `/products-custom/productIDsRange/1?IDBegin=${productIDBegin}&IDEnd=${productIDEnd}`
    );

    setProductCategoriesCheck([]);
    productCategoriesCheck.length = 0;
    for (var i = 0; i < results_product_ids.data.length; i++) {
      let product_id = results_product_ids.data[i].product_id;
      const result_check_categories = await api.get(
        `/products-custom/checkCategories/${product_id}`
      );
      productCategoriesCheck.push(result_check_categories.data);
      setProductCategoriesCheck(productCategoriesCheck);
      if (i > 0 && i % 10 === 0) {
        fetchData();
      } //Only updates every 10 products
    }
    fetchData();
  };

  const [productMeasurementsCheck, setProductMeasurementsCheck] =
    React.useState<string[]>([]);
  const fetchProductMeasurementsCheck = async () => {
    //Get products ID array based on the productIDBegin and productIDEnd
    const results_product_ids = await api.get(
      `/products-custom/productIDsRange/1?IDBegin=${productIDBegin}&IDEnd=${productIDEnd}`
    );

    setProductMeasurementsCheck([]);
    productMeasurementsCheck.length = 0;
    for (var i = 0; i < results_product_ids.data.length; i++) {
      let product_id = results_product_ids.data[i].product_id;
      const result_check_measurements = await api.get(
        `/products-custom/checkMeasurements/${product_id}`
      );
      productMeasurementsCheck.push(result_check_measurements.data);
      setProductMeasurementsCheck(productMeasurementsCheck);
      if (i > 0 && i % 10 === 0) {
        fetchData();
      } //Only updates every 10 products
    }
    fetchData();
  };

  const [productAttributesCheck, setProductAttributesCheck] = React.useState<
    string[]
  >([]);
  const fetchProductAttributesCheck = async () => {
    //Get products ID array based on the productIDBegin and productIDEnd
    const results_product_ids = await api.get(
      `/products-custom/productIDsRange/1?IDBegin=${productIDBegin}&IDEnd=${productIDEnd}`
    );

    setProductAttributesCheck([]);
    productAttributesCheck.length = 0;
    for (var i = 0; i < results_product_ids.data.length; i++) {
      let product_id = results_product_ids.data[i].product_id;
      const result_check_attributes = await api.get(
        `/products-custom/checkAttributes/${product_id}`
      );
      productAttributesCheck.push(result_check_attributes.data);
      setProductAttributesCheck(productAttributesCheck);
      if (i > 0 && i % 10 === 0) {
        fetchData();
      } //Only updates every 10 products
    }
    fetchData();
  };

  const [productSpecificationsCheck, setProductSpecificationsCheck] =
    React.useState<string[]>([]);
  const fetchProductSpecificationsCheck = async () => {
    //Get products ID array based on the productIDBegin and productIDEnd
    const results_product_ids = await api.get(
      `/products-custom/productIDsRange/1?IDBegin=${productIDBegin}&IDEnd=${productIDEnd}`
    );

    setProductSpecificationsCheck([]);
    productSpecificationsCheck.length = 0;
    for (var i = 0; i < results_product_ids.data.length; i++) {
      let product_id = results_product_ids.data[i].product_id;
      const result_check_specifications = await api.get(
        `/products-custom/checkSpecifications/${product_id}`
      );
      productSpecificationsCheck.push(result_check_specifications.data);
      setProductSpecificationsCheck(productSpecificationsCheck);
      if (i > 0 && i % 10 === 0) {
        fetchData();
      } //Only updates every 10 products
    }
    fetchData();
  };

  //Clone Product Section
  const defaultAdditional = {
    page: 1,
  };

  const [productClone, setProductClone] = useState<{
    id: number;
    sku: string;
  } | null>(null);

  const [cloneDestinationProductSKU, setCloneDestinationProductSKU] =
    useState("");

  const loadOptionsItemProducts = async (search: any, page: any) => {
    const results = await api.get(
      `/products-custom?page=${page}&limit=${numberItemsPerPage}&language_id=${language}&search=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsItemProducts = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsItemProducts(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const handleCloneProductButtonClick = async () => {
    //Clone Product
    api
      .get(
        `/products-custom/clone?productOrigin_id=${productClone?.id}&productDestination_sku=${cloneDestinationProductSKU}`
      )
      .then((res) => {
        Swal.fire({
          title: "Product Cloned!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        fetchData(1, Number(language));
      });

    //Refresh Table
  };

  /////////

  // Export Product to Ebay Section
  const [productExport, setProductExport] = useState<{
    id: number;
    sku: string;
  } | null>(null);

  const handleExportProductEbayButtonClick = async () => {
    if (productExport?.id) {
      // Load Page on new tab
      window.open(`products/export-ebay/${productExport.id}`);
    }
  };

  ////

  useEffect(() => {
    fetchData();
    fetchLanguages();
  }, []);

  return (
    <Container component="main" maxWidth="xl">
      <Box sx={{ paddingTop: 5 }}>
        Image Update Section<br></br>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mb: "16px",
            width: "400px",
            marginLeft: "32px",
            mt: "16px",
          }}
        >
          <Button
            variant="contained"
            href=""
            onClick={fetchProductImagesUpdate}
          >
            Update Product Images Database
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mb: "16px",
            width: "400px",
            marginLeft: "32px",
          }}
        >
          <Button
            variant="contained"
            href=""
            onClick={fetchProductTitleDescriptionsCheck}
          >
            Check Title & Descriptions
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mb: "16px",
            width: "400px",
            marginLeft: "32px",
          }}
        >
          <Button
            variant="contained"
            href=""
            onClick={fetchProductSEOLinksCreate}
          >
            Generate SEO URL for Products
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mb: "16px",
            width: "400px",
            marginLeft: "32px",
          }}
        >
          <Button
            variant="contained"
            href=""
            onClick={fetchProductCategoriesCheck}
          >
            Check Products with NO Categories
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mb: "16px",
            width: "400px",
            marginLeft: "32px",
          }}
        >
          <Button
            variant="contained"
            href=""
            onClick={fetchProductMeasurementsCheck}
          >
            Check Products without Measurements
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mb: "16px",
            width: "400px",
            marginLeft: "32px",
          }}
        >
          <Button
            variant="contained"
            href=""
            onClick={fetchProductAttributesCheck}
          >
            Check Products with NO Attributes
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mb: "16px",
            width: "400px",
            ml: "32px",
          }}
        >
          <Button
            variant="contained"
            href=""
            onClick={fetchProductSpecificationsCheck}
          >
            Check Products with NO Attributes
          </Button>
        </Box>
        <br></br>
        <TextField
          sx={{ minWidth: 1 / 4, mt: "16px", marginLeft: "32px" }}
          //fullWidth
          id={"Product-ID-Begin"}
          type="text"
          label="Product ID Begin"
          //value={productIDBegin}
          defaultValue={productIDBegin}
          //onChange={(e) => setProductIDBegin(e.target.value)}
          onBlur={(e) => setProductIDBegin(e.target.value)}
        />
        <TextField
          sx={{ minWidth: 1 / 4, ml: "8px" }}
          margin="normal"
          //fullWidth
          id={"Product-ID-End"}
          type="text"
          label="Product ID End"
          //value={productIDEnd}
          defaultValue={productIDEnd}
          //onChange={(e) => setProductIDEnd(e.target.value)}
          onBlur={(e) => setProductIDEnd(e.target.value)}
        />
        {productImagesUpdate.map((input, index) => {
          return <p key={index}> {input}</p>;
        })}
        {productTitleDescriptionsCheck.map((input, index) => {
          return <p key={index}> {input}</p>;
        })}
        {productSEOLinksCreate.map((input, index) => {
          return <p key={index}> {input}</p>;
        })}
        {productCategoriesCheck.map((input, index) => {
          return <p key={index}> {input}</p>;
        })}
        {productMeasurementsCheck.map((input, index) => {
          return <p key={index}> {input}</p>;
        })}
        {productAttributesCheck.map((input, index) => {
          return <p key={index}> {input}</p>;
        })}
        {productSpecificationsCheck.map((input, index) => {
          return <p key={index}> {input}</p>;
        })}
      </Box>
      <Container maxWidth="xl" sx={{ mt: 2 }}>
        <Button
          variant="contained"
          href="/products/new"
          sx={{ marginLeft: "8px" }}
        >
          Create Product
        </Button>
        <Box style={{ display: "flex", flexDirection: "row" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: "8px",
              marginLeft: 1,
              width: "40%",
            }}
          >
            <InputLabel id="clone-origin-product-sku-label" sx={{ mt: "8px" }}>
              Clone Origin Product (SKU)
            </InputLabel>
            <AsyncPaginate
              placeholder="Select Clone Origin Product"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              additional={defaultAdditional}
              key={"product_id"}
              id="product"
              name="product"
              getOptionValue={(option: any) => option?.id}
              getOptionLabel={(option: any) =>
                option?.sku + " - " + option?.name
              }
              loadOptions={loadPageOptionsItemProducts}
              value={productClone}
              onChange={setProductClone}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: "8px",
              marginLeft: 1,
              width: "40%",
            }}
          >
            <InputLabel
              id="clone-destination-product-sku-label"
              sx={{ mt: "8px" }}
            >
              Clone Destination Product SKU
            </InputLabel>
            <TextField
              id={"clone-desination-product-SKU"}
              type="text"
              label="Clone Destination Product SKU "
              //value={cloneDestinationProductSKU}
              defaultValue={cloneDestinationProductSKU}
              //onChange={(e) => setSKUFilter(e.target.value)}
              onBlur={(e) => setCloneDestinationProductSKU(e.target.value)}
            />
          </Box>
          {/* `/products-custom?page=${page}&limit=${numberItemsPerPage}&language_id=${language}&search_sku=${search}` */}
          <Button
            variant="contained"
            onClick={handleCloneProductButtonClick}
            sx={{ marginLeft: "8px", marginTop: "10px" }}
          >
            Clone Product Product
          </Button>
        </Box>

        <Box style={{ display: "flex", flexDirection: "row" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: "8px",
              marginLeft: 1,
              width: "40%",
            }}
          >
            <InputLabel id="export-product-ebay-sku-label" sx={{ mt: "8px" }}>
              Product to Generate Template to be Exported to Ebay (SKU)
            </InputLabel>
            <AsyncPaginate
              placeholder="Select Product to Generate Template"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              additional={defaultAdditional}
              key={"product_id"}
              id="product"
              name="product"
              getOptionValue={(option: any) => option?.id}
              getOptionLabel={(option: any) =>
                option?.sku + " - " + option?.name
              }
              loadOptions={loadPageOptionsItemProducts}
              value={productExport}
              onChange={setProductExport}
            />
          </Box>
          <Button
            variant="contained"
            onClick={handleExportProductEbayButtonClick}
            sx={{ marginLeft: "8px", marginTop: "10px" }}
          >
            Generate Template to Export Product to Ebay
          </Button>
        </Box>

        <Box style={{ display: "flex", flexDirection: "row" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: "8px",
              marginLeft: 1,
            }}
          >
            <InputLabel id="number-items-page-label" sx={{ mt: "8px" }}>
              Number of Items per Page
            </InputLabel>
            <Select
              labelId="number-items-page-select-label"
              id="number-items-page"
              value={String(numberItemsPerPage)}
              label="Number of Items per Page"
              onChange={handleNumberItemsPerPage}
            >
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={250}>250</MenuItem>
              <MenuItem value={500}>500</MenuItem>
              <MenuItem value={750}>750</MenuItem>
              <MenuItem value={1000}>1000</MenuItem>
            </Select>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <InputLabel id="language-label" sx={{ mt: "8px" }}>
              Language
            </InputLabel>
            <Select
              labelId="language-select-label"
              id="language"
              value={String(language)}
              label="Language"
              onChange={handleChangeLanguage}
            >
              <MenuItem value={0}>None</MenuItem>
              {languages.map((language, index) => (
                <MenuItem value={language.id} key={index}>
                  {language.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <TextField
          //sx={{ mt: 1, minWidth: 3/4 }}
          sx={{ minWidth: 1 / 8, mt: "8px", marginLeft: "8px" }}
          //fullWidth
          id={"SKU-filter"}
          type="text"
          label="SKU Filter"
          //value={skuFilter}
          defaultValue={skuFilter}
          //onChange={(e) => setSKUFilter(e.target.value)}
          onBlur={(e) => setSKUFilter(e.target.value)}
        />
        <TextField
          //sx={{ mt: 1, minWidth: 3/4 }}
          sx={{ minWidth: 1 / 8, mt: "8px", mx: "8px" }}
          margin="normal"
          //fullWidth
          id={"Product-name-filter"}
          type="text"
          label="Product Name Filter"
          //value={productNameFilter}
          defaultValue={productNameFilter}
          //onChange={(e) => setProductNameFilter(e.target.value)}
          onBlur={(e) => setProductNameFilter(e.target.value)}
        />
        <TextField
          sx={{ minWidth: 1 / 8, mt: "8px", mx: "8px" }}
          margin="normal"
          //fullWidth
          id={"barcode-filter"}
          type="text"
          label="Barcode Filter"
          //value={barcodeFilter}
          defaultValue={barcodeFilter}
          //onChange={(e) => setBarcodeFilter(e.target.value)}
          onBlur={(e) => setBarcodeFilter(e.target.value)}
        />
        <TextField
          sx={{ minWidth: 1 / 8, mt: "8px", mx: "8px" }}
          margin="normal"
          //fullWidth
          id={"seo-url-filter"}
          type="text"
          label="SEO URL Filter"
          //value={barcodeFilter}
          defaultValue={SEOURLFilter}
          //onChange={(e) => setBarcodeFilter(e.target.value)}
          onBlur={(e) => setSEOURLFilter(e.target.value)}
        />
        <Button
          variant="contained"
          href=""
          onClick={handleFilterButtonClick}
          sx={{ mx: "8px", mt: "8px" }}
        >
          Filter
        </Button>
        <TableHorizontal
          screen={"products"}
          columns={columns}
          data={products}
          objectKey={keys}
        />
        <Box
          display="flex"
          width={"100%"}
          height={80}
          alignItems="center"
          justifyContent="center"
        >
          <Pagination
            count={totalPages}
            page={pageState}
            onChange={handleChange}
          />
        </Box>
      </Container>
    </Container>
  );
}
