import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import api from "../../services/api";
import { green } from "@mui/material/colors";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";

function NewLanguages() {
  const [circularLoading, setCircularLoading] = React.useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (language: any) => {
    setCircularLoading(true);

    api
      .post("languages", language)
      .then((response) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Language created!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "" + error.response.data.message,
          footer: '<a href="">Why do I have this issue?</a>',
        });
        setCircularLoading(false);
        setRequestError(true);
        setRequestErrorData(error.response.data.message);
      });
  };

  const [type, setType] = React.useState("");
  const handleChange = (event: SelectChangeEvent) => {
    setType(event.target.value);
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{
            fontSize: "32px",
            marginBottom: "10px",
            alignItems: "center",
            color: "#556cd6",
          }}
        >
          New Language
        </Typography>
        {requestError && (
          <List component="nav" aria-label="erros">
            {requestErrorData.map((error) => (
              <ListItem key={error}>
                <ListItemText
                  sx={{
                    color: "red",
                  }}
                  primary={error}
                />
              </ListItem>
            ))}
          </List>
        )}
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            minWidth: 3 / 4,
            marginBottom: "30px",
            width: "100%",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <InputLabel id="status-select">Status</InputLabel>
          <Select
            labelId="status-select-label"
            required
            id="status-select"
            value={type}
            label="Type"
            {...register("status", {})}
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={1}>Enabled</MenuItem>
            <MenuItem value={0}>Disabled</MenuItem>
          </Select>
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.status?.toString()}
          </p>

          <TextField
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
            fullWidth
            id="name"
            type="text"
            label="Language Name (In the language of the country)"
            {...register("name", {})}
            autoFocus
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.name?.toString()}
          </p>

          <TextField
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
            fullWidth
            id="code"
            type="text"
            label="Language Code"
            {...register("code", {})}
            autoFocus
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.code?.toString()}
          </p>

          <TextField
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
            fullWidth
            id="locale"
            type="text"
            label="Locale Parameters (; separated)"
            {...register("locale")}
            autoFocus
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.locale?.toString()}
          </p>

          <TextField
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
            fullWidth
            id="image"
            type="text"
            label="Image Address"
            {...register("image")}
            autoFocus
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.image?.toString()}
          </p>

          <Stack
            direction="row"
            spacing={2}
            sx={{
              mt: 2,
            }}
          >
            <Button
              onClick={() => reset()}
              variant="outlined"
              startIcon={<DeleteIcon />}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              size="large"
              endIcon={<SendIcon />}
              disabled={circularLoading}
              type="submit"
            >
              Send
              {circularLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
}
export default NewLanguages;
