import { useEffect, useState } from "react";
import { Container, Pagination, Box } from "@mui/material";
import TableHorizontal from "../../components/TableHorizontal";
import api from "../../services/api";
function ListCashiers() {
  const [pageState, setPageState] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [users, setUsers] = useState([]);
  const columns = ["User ID", "User Name", "Name", "Code", "Action", "Delete"];
  const keys = ["user_id", "user_username", "name", "code", "action", "delete"];
  const fetchData = async (page = 1) => {
    await api.get(`/cashiers?page=${page}&limit=200`).then((res) => {
      setUsers(res.data.items);
      setTotalPages(res.data.total_pages);
    });
  };
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageState(value);
    fetchData(value);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
      <TableHorizontal
        screen={"cashiers"}
        columns={columns}
        data={users}
        objectKey={keys}
      />
      <Box
        display="flex"
        width={"100%"}
        height={80}
        alignItems="center"
        justifyContent="center"
      >
        <Pagination
          count={totalPages}
          page={pageState}
          onChange={handleChange}
        />
      </Box>
    </Container>
  );
}
export default ListCashiers;
