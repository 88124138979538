/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  TextField,
  Stack,
  List,
  ListItem,
  ListItemText,
  Container,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import Swal from "sweetalert2";
import { inputsName } from "../../../config/languageConst";

function NewSpecificationGroup() {
  const [circularLoading, setCircularLoading] = useState(false);

  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);
  const {
    register,
    reset,
    handleSubmit,
    // formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    setCircularLoading(true);

    for (let index = 0; index < data.descriptions.length; index++) {
      data.descriptions[index].language_id = Number(
        data.descriptions[index].language_id
      );
    }

    api
      .post("specification-groups", data)
      .then((res) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Specification Group created!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          footer: '<a href="">Why do I have this issue?</a>',
        });
        setCircularLoading(false);
        setRequestError(true);
        setRequestErrorData(error.response.data.message);
      });
  };
  useEffect(() => {}, []);
  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{ fontSize: "30px", marginBottom: "15px", color: "#556cd6" }}
        >
          New Specification Group
        </Typography>
        {requestError && (
          <List component="nav" aria-label="BackEnd Return">
            {requestErrorData.map((error) => (
              <ListItem key={error}>
                <ListItemText
                  sx={{
                    color: "red",
                  }}
                  primary={error}
                />
              </ListItem>
            ))}
          </List>
        )}
        <Box
          component="form"
          sx={{ mt: 1, minWidth: 3 / 4, width: "100%" }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div style={{ marginTop: "30px" }}>
            {inputsName.map((input, index) => (
              <div className="flags-container" key={index}>
                <input
                  hidden
                  {...register(`descriptions[${index}].language_id`, {
                    required: `descriptions[${index}].language_id is required`,
                  })}
                  value={input.language_id}
                />
                <span className="fa-flags">
                  <img
                    src={`/assets/language/${input.language_id}.png`}
                    alt=""
                  />
                </span>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  required
                  id={"#descriptions[" + index + "].name"}
                  type="text"
                  label="Name"
                  {...register(`descriptions[${index}].name`, {
                    required: `descriptions[${index}].name is required`,
                  })}
                />
              </div>
            ))}
          </div>

          <Stack
            direction="row"
            spacing={2}
            sx={{
              mt: 2,
            }}
          >
            <Button
              onClick={() => reset()}
              variant="outlined"
              startIcon={<DeleteIcon />}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              size="large"
              endIcon={<SendIcon />}
              disabled={circularLoading}
              type="submit"
            >
              Send
              {circularLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
}
export default NewSpecificationGroup;
