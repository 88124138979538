import api from "../../services/api";
import { login, logout } from "../ducks/auth";
import { UserLogin } from "../../types/user";
import Swal from "sweetalert2";
import { AnyAction, Dispatch } from "redux";
import { Token } from "../../types/login";
import Cookies from "js-cookie";
import { useCookies } from "react-cookie";

export const loginRequest = (user: UserLogin) => {
  Swal.fire({
    html: "<img src='/loader.svg' />",
    showConfirmButton: false,
  });
  return (dispatch: Dispatch) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [cookies, setCookie] = useCookies([
      "roles",
      "token",
      "username",
      "user_id",
    ]);

    api.get("/csrf-cookie").then((response) => {
      api
        .post<Token>("auth/login", user)
        .then((res) => {
          setCookie("token", response.data.access_token, {
            path: "/",
            maxAge: 60 * 60 * 24 * 30, // 30 days
          });
          setCookie("roles", response.data.roles, {
            path: "/",
            maxAge: 60 * 60 * 24 * 30, // 30 days
          });
          setCookie("username", response.data.username, {
            path: "/",
            maxAge: 60 * 60 * 24 * 30, // 30 days
          });
          setCookie("user_id", response.data.user_id, {
            path: "/",
            maxAge: 60 * 60 * 24 * 30, // 30 days
          });
          localStorage.setItem("token", res.data.access_token);
          localStorage.setItem("role", String(res.data.roles));
          localStorage.setItem("username", String(res.data.username));
          localStorage.setItem("user_id", String(res.data.user_id));

          dispatch(
            login({
              type: "LOGIN",
              payload: {
                isAuthenticated: true,
                role: res.data.roles,
                username: res.data.username,
              },
            })
          );
          return (window.location.href = "/");
        })
        .catch((error) => {
          Swal.fire({
            title: error.response.data.message,
            text: "Do you want to continue?",
            icon: "error",
            confirmButtonText: "Yes",
          });
        });
    });
  };
};
export const logoutRequest = () => {
  return (dispatch: Dispatch<AnyAction>) => {
    Swal.fire({
      title: "Want to exit?",
      showDenyButton: true,
      showCancelButton: false,
      icon: "warning",
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("token");
        localStorage.removeItem("roles");
        localStorage.removeItem("username");
        localStorage.removeItem("user_id");
        Cookies.remove("token");
        Cookies.remove("roles");
        Cookies.remove("username");
        Cookies.remove("user_id");
        dispatch(
          logout({
            type: "LOGOUT",
            payload: {
              isAuthenticated: false,
              role: 0,
              username: "",
            },
          })
        );
        return (window.location.href = "/login");
      }
    });
  };
};

export const logoutRequestNoConfirmation = () => {
  return (dispatch: Dispatch<AnyAction>) => {
    localStorage.removeItem("token");
    localStorage.removeItem("roles");
    localStorage.removeItem("username");
    localStorage.removeItem("user_id");
    Cookies.remove("token");
    Cookies.remove("roles");
    Cookies.remove("username");
    Cookies.remove("user_id");
    dispatch(
      logout({
        type: "LOGOUT",
        payload: {
          isAuthenticated: false,
          role: 0,
          username: "",
        },
      })
    );
    return (window.location.href = "/login");
  };
};
