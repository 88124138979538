/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Container,
  Pagination,
  Box,
  SelectChangeEvent,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import TableHorizontal from "../TableHorizontal";
import api from "../../services/api";

function ListStores() {
  const [pageState, setPagepageState] = useState(1);
  const [total_pages, setTotalPages] = useState(0);
  const [stores, setStores] = useState([]);
  const columns = [
    "ID",
    "Name",
    "URL",
    "E-mail",
    "Image",
    "Country",
    "Main Currency",
    "Warehouse",
    "Wholesale PDV",
    "W. Online Store",
    "Last Change by",
    "Actions",
  ];
  const keys = [
    "id",
    "name",
    "url",
    "email",
    "image",
    "country",
    "currency",
    "warehouse",
    "wholesale_pdv",
    "wholesale_store",
    "last_change_username",
    "action",
  ];
  const fetchData = async (
    page = 1,
    numberItemsPerPage = 500,
    language = 1,
  ) => {
    await api
      .get(
        `/stores?page=${page}&limit=${numberItemsPerPage}&language_id=${language}`
      )
      .then((res) => {
        setStores(res.data.items);
        setTotalPages(res.data.total_pages);
      });
  };
  const [numberItemsPerPage, setNumberItemsPerPage] = useState("500");
  const handleNumberItemsPerPage = (event: SelectChangeEvent) => {
    setNumberItemsPerPage(event.target.value);
    fetchData(1, Number(event.target.value), Number(language));
  };
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPagepageState(value);
    fetchData(value, Number(numberItemsPerPage), Number(numberItemsPerPage));
  };

  const [language, setLanguage] = useState('');
  const handleChangeLanguage = (event: SelectChangeEvent) => {
    setLanguage(event.target.value);
    fetchData(pageState, Number(numberItemsPerPage), Number(event.target.value));
  };

  const [languages, setLanguages] = useState<{ id: number; name: string }[]>(
    []
  );
  const fetchLanguages = async () => {
    const response = await api.get(`languages`);
    setLanguages(response.data.items);
    setLanguage('1');
    fetchData(pageState, Number(numberItemsPerPage), 1);
  };

  useEffect(() => {
    //fetchData();
    fetchLanguages();
  }, []);
  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
      <Box style={{ display: "flex", flexDirection: "row" }}>
      <Box 
      sx={{
        display: "flex",
        flexDirection: "column",
        marginRight: "8px",
        marginLeft: 1,
      }}
      >
      <InputLabel id="number-items-page-label">
        Number of Items per Page
      </InputLabel>
      <Select
        labelId="number-items-page-select-label"
        id="number-items-page"
        value={numberItemsPerPage}
        label="Number of Items per Page"
        onChange={handleNumberItemsPerPage}
      >
        <MenuItem value={100}>100</MenuItem>
        <MenuItem value={250}>250</MenuItem>
        <MenuItem value={500}>500</MenuItem>
        <MenuItem value={750}>750</MenuItem>
        <MenuItem value={1000}>1000</MenuItem>
      </Select>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          
        }}
      >
      <InputLabel id="language-select-label">Language</InputLabel>
      <Select
        labelId="language-select-label"
        id="language"
        value={language}
        label="Language"
        onChange={handleChangeLanguage}
      >
        
        {languages.map((language, index) => (
          <MenuItem value={language.id} key={index}>
            {language.name}
          </MenuItem>
        ))}
      </Select>
      </Box>
      </Box>
      <TableHorizontal
        screen={"stores"}
        columns={columns}
        data={stores}
        objectKey={keys}
      />
      <Box
        display="flex"
        width={"100%"}
        height={80}
        alignItems="center"
        justifyContent="center"
      >
        <Pagination
          count={total_pages}
          page={pageState}
          onChange={handleChange}
        />
      </Box>
    </Container>
  );
}
export default ListStores;
