/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItem,
  ListItemText,
  List,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import api from "../../services/api";
import Swal from "sweetalert2";

function NewMarketplace() {
  const [circularLoading, setCircularLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (marketplace: any) => {
    setCircularLoading(true);
    if (marketplace.currency_id !== "") {
      marketplace.currency_id = Number(marketplace.currency_id);
    } else {
      delete marketplace.currency_id;
    }

    api
      .post("marketplaces", marketplace)
      .then((response) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Marketplace created!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "" + error.response.data.message,
        });
        setCircularLoading(false);
        setRequestError(true);
        setRequestErrorData(error.response.data.message);
      });
  };

  const [currency, setCurrency] = useState(Number(null));

  const [currencies, setCurrencies] = React.useState<
    { id: number; code: string }[]
  >([]);
  const fetchCurrencies = async () => {
    const response = await api.get(`currencies`);
    setCurrencies(response.data.items);
    setCurrency(0);
  };

  useEffect(() => {
    fetchCurrencies();
  }, []);

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{
            fontSize: "32px",
            marginBottom: "10px",
            alignItems: "center",
            color: "#556cd6",
          }}
        >
          New Marketplace
        </Typography>
        {requestError && (
          <List component="nav" aria-label="erros">
            {requestErrorData.map((error) => (
              <ListItem key={error}>
                <ListItemText
                  sx={{
                    color: "red",
                  }}
                  primary={error}
                />
              </ListItem>
            ))}
          </List>
        )}
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            minWidth: 3 / 4,
            marginBottom: "30px",
            width: "100%",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="code"
            type="text"
            label="Code ID - Identification that the Marketplace account will be identified with"
            autoComplete="marketplace_code"
            {...register("code", {})}
            autoFocus
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.code?.toString()}
          </p>

          <TextField
            margin="normal"
            fullWidth
            id="name"
            type="text"
            label="Marketplace Name"
            autoComplete="marketplace_name"
            {...register("name")}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.name?.toString()}
          </p>

          <TextField
            margin="normal"
            fullWidth
            id="website"
            type="text"
            label="Marketplace Account Store Website / Link"
            autoComplete="website"
            {...register("website")}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.website?.toString()}
          </p>

          <TextField
            slotProps={{ inputLabel: { shrink: true } }}
            margin="normal"
            fullWidth
            id="logo"
            type="text"
            label="Marketplace Account Logo Link"
            autoComplete="logo"
            {...register("logo")}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.logo?.toString()}
          </p>

          <TextField
            slotProps={{ inputLabel: { shrink: true } }}
            margin="normal"
            fullWidth
            id="banner"
            type="text"
            label="Marketplace Account Banner Link"
            autoComplete="banner"
            {...register("banner")}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.banner?.toString()}
          </p>

          <TextField
            margin="normal"
            fullWidth
            id="username"
            type="text"
            label="Marketplace Account Username"
            autoComplete="marketplace_username"
            {...register("username")}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.username?.toString()}
          </p>

          <TextField
            margin="normal"
            fullWidth
            id="store_name"
            type="text"
            label="Store Name at the Marketplace"
            autoComplete="marketplace_store_name"
            {...register("store_name")}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.store_name?.toString()}
          </p>

          <TextField
            slotProps={{ inputLabel: { shrink: true } }}
            fullWidth
            multiline
            margin="normal"
            id="comments"
            type="text"
            label="Intro Text"
            rows={6}
            {...register("intro_text")}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.intro_text?.toString()}
          </p>

          <TextField
            fullWidth
            multiline
            margin="normal"
            id="comments"
            type="text"
            label="Comments"
            rows={6}
            {...register("comments")}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.comments?.toString()}
          </p>

          <div style={{ marginTop: "30px" }}></div>
          <FormControl fullWidth>
            <InputLabel id="currency-label">Currency</InputLabel>
            <Select
              labelId="currency-select-label"
              id="currency"
              value={currency}
              {...register("currency_id")}
              label="Currency"
              onChange={(e) => setCurrency(Number(e.target.value))}
            >
              <MenuItem value={0}>
                <em>None</em>
              </MenuItem>
              {currencies.map((currency, index) => (
                <MenuItem value={currency.id} key={index}>
                  {currency.code}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.currency_id?.toString()}
          </p>

          <Stack
            direction="row"
            spacing={2}
            sx={{
              mt: 2,
            }}
          >
            <Button
              onClick={() => reset()}
              variant="outlined"
              startIcon={<DeleteIcon />}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              size="large"
              endIcon={<SendIcon />}
              disabled={circularLoading}
              type="submit"
            >
              Send
              {circularLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>
          </Stack>
        </Box>
        <div style={{ marginTop: "100px" }}></div>
      </Box>
    </Container>
  );
}
export default NewMarketplace;
