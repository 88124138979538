/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Stack,
  List,
  ListItemText,
  ListItem,
  CardMedia,
  IconButton,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import api from "../../../../services/api";
import Swal from "sweetalert2";
import { useLocation, useParams } from "react-router-dom";
import { LogTrackUser } from "../../../../types/log-track-user/log-track-user";
import moment from "moment";
import { MultiValue } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Cookies from "js-cookie";

export default function EditCart() {
  const [circularLoading, setCircularLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);
  const { id } = useParams<{ id: string }>();

  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [imageBaseURL, setimageBaseURL] = useState(
    process.env.REACT_APP_IMG_CATALOG_URL
  );

  const [store, setStore] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const [customer, setCustomer] = useState<{
    id: number;
    first_name: string;
    last_name: string;
    email: string;
  } | null>(null);

  const [updatedAt, setUpdatedAt] = useState<Date | null>(null);

  const fetchImageFolder = async () => {
    if (imageBaseURL?.substring(imageBaseURL?.length - 1) !== "/") {
      setimageBaseURL(imageBaseURL + "/");
    }
  };

  // const [productsQuantity, setProductsQuantity] = useState<Number[]>([]);

  const [products, setProducts] = useState<
    { product_id: number; image: string; sku: string; quantity: number }[]
  >([]);
  const onChangeProducts = async (
    newValue: MultiValue<{
      product_id: number;
      image: string;
      sku: string;
      quantity: number;
    }>
  ) => {
    if (newValue.length) newValue[newValue.length - 1].quantity = 0;
    setProducts([...newValue]);
  };

  const defaultAdditional = {
    page: 1,
  };

  const loadOptionsProductsStore = async (search: any, page: any) => {
    const results = await api.get(
      `/product-stores/notparentforcart?page=${page}&store_id=${store?.id}&product_sku=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsProductsStore = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsProductsStore(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const deleteProductFromCart = (product_id: number) => {
    //Remove from the product list the product
    setProducts(products.filter((row) => row.product_id !== product_id));
  };

  const changeProductQuantity = (product_id: number, quantity: number) => {
    products.filter((row) => {
      if (row.product_id === product_id) {
        return (row.quantity = quantity);
      } else {
        return null;
      }
    });
    setProducts(products);
  };

  /*   const setProductQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    products[Number(e.target.id)].quantity = Number(e.target.value);
    // productsQuantity[Number(e.target.id)] = Number(e.target.value);
    setProductsQuantity(productsQuantity);
    setProducts(products);
  }; */

  const onSubmit = (cart: any) => {
    setCircularLoading(true);
    cart.id = id;

    cart.store_id = Number(store?.id);
    if (!store) return;

    cart.customer_id = Number(customer?.id);
    if (!customer) return;

    if (!products) return;
    for (let index = 0; index < products.length; index++) {
      cart.products[index].product_id = Number(products[index].product_id);
      cart.products[index].quantity = Number(products[index].quantity);
    }
    cart.products.length = products.length;

    api
      .put(`carts/${id}`, cart)
      .then((response) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Cart updated!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        //reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "" + error.response.data.message,
          // footer: '<a href="">Why do I have this issue?</a>',
        });
        setCircularLoading(false);
        setRequestError(true);
        setRequestErrorData(error.response.data.message);
      });
  };

  const fetchCart = async () => {
    const response = await api.get(`carts/${id}`);
    reset(response.data);
    setStore(response.data.store);
    setCustomer(response.data.customer);
    setUpdatedAt(response.data.updated_at);
    // Map response.data.products to be able to insert at setProducts
    const add_product_array: any = response.data.products.map(
      (product_mapped: any) =>
        //  product_id: number; image: string; sku: string
        ({
          product_id: product_mapped.product_id,
          image: product_mapped.product.image,
          sku: product_mapped.product.sku,
          quantity: product_mapped.quantity,
        })
      // add_product_array.push(add_product);
    );
    setProducts(add_product_array);

    reset(response.data);
  };

  useEffect(() => {
    fetchCart();
    fetchImageFolder();
  }, []);

  return (
    <Container component="main" maxWidth="xl">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{ fontSize: "40px", marginBottom: "10px" }}
        >
          Edit Cart {id}
        </Typography>
        {requestError && (
          <List component="nav" aria-label="BackEnd Return">
            {requestErrorData.map((error) => (
              <ListItem key={error}>
                <ListItemText
                  sx={{
                    color: "red",
                  }}
                  primary={error}
                />
              </ListItem>
            ))}
          </List>
        )}
        <Box
          component="form"
          sx={{ mt: 1, minWidth: 3 / 4, width: "100%" }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <p>
            Store: <b>{store?.id}</b> / <b>{store?.name}</b>
          </p>
          <p>
            Customer: <b>{customer?.id}</b> / <b>{customer?.email}</b> /{" "}
            <b>
              {customer?.first_name} {customer?.last_name}
            </b>{" "}
          </p>
          <p>
            Create At: <b>{moment(updatedAt).format("DD/MM/YYYY hh:mm:ss")}</b>
          </p>

          <Box sx={{ width: "100%", typography: "body1" }}>
            Cart Products
            <AsyncPaginate
              isMulti
              closeMenuOnSelect={false}
              placeholder="Select Products"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              additional={defaultAdditional}
              key={"product_ids"}
              id="products"
              name="products"
              getOptionValue={(option: any) => option?.id}
              getOptionLabel={(option: any) => option?.sku}
              loadOptions={loadPageOptionsProductsStore}
              value={products}
              onChange={onChangeProducts}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.products?.toString()}
            </p>
            {products.map((input, index) => {
              return (
                <p key={index}>
                  {" "}
                  Product ID / SKU: {input.product_id} / {input.sku}
                </p>
              );
            })}
            <div style={{ marginTop: "50px", width: "100%" }}>
              {products.map((product, indexProduct) => {
                return (
                  <Box
                    key={indexProduct}
                    sx={{
                      width: "100%",
                      marginBottom: 2,
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <input
                      hidden
                      {...register(`products[${indexProduct}].product_id`)}
                      value={product.product_id}
                    />
                    <div>
                      Product ID / SKU: {product.product_id} / {product.sku}
                      <br></br>
                      <CardMedia
                        component="img"
                        sx={{ width: 151, display: "inline" }}
                        image={`${imageBaseURL}${product.image}`}
                        alt={"image"}
                      />
                    </div>
                    <TextField
                      // ref={textFieldQtyRef[indexProduct]}
                      sx={{
                        mt: 1,
                        marginTop: 10,
                        minWidth: 1 / 4,
                        width: "20%",
                      }}
                      InputLabelProps={{ shrink: true }}
                      margin="normal"
                      required
                      label="Quantity"
                      id={`#products[${indexProduct}].quantity`}
                      type="number"
                      {...register(`products[${indexProduct}].quantity`, {
                        required: `products[${indexProduct}].quantity is required`,
                      })}
                      // value={products[indexProduct].quantity}
                      // value={productsQuantity[indexProduct] || 0}
                      /*                       onChange={(e) =>
                        changeProductQuantity(
                          product.product_id,
                          Number(e.target.value)
                        )
                      } */
                      onChange={(e) =>
                        changeProductQuantity(
                          product.product_id,
                          Number(e.target.value)
                        )
                      }
                    />
                    <IconButton
                      color="primary"
                      aria-label="delete product from cart"
                      component="label"
                      size="large"
                      // className={classes.delete}
                      onClick={() => {
                        deleteProductFromCart(product.product_id);
                      }}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </Box>
                );
              })}
            </div>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              sx={{
                mt: 2,
              }}
            >
              <Button
                onClick={() => reset()}
                variant="outlined"
                startIcon={<DeleteIcon />}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                size="large"
                endIcon={<SendIcon />}
                disabled={circularLoading}
                type="submit"
              >
                Send
                {circularLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
