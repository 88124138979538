/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Container, Box, Typography, Tab } from "@mui/material";
import React from "react";
import { TabContext, TabList, useTabContext } from "@mui/lab";
import NewPaymentMethods from "../../components/PaymentMethod/NewPaymentMethod";
import ListPaymentMethods from "../../components/PaymentMethod/ListPaymentMethods";
import { useLocation } from "react-router-dom";
import { LogTrackUser } from "../../types/log-track-user/log-track-user";
import Cookies from "js-cookie";
function TabPanel(props: any) {
  const { children, className, value, index, ...other } = props;

  const context = useTabContext();

  if (context === null) {
    throw new TypeError("No TabContext provided");
  }
  const tabId = context.value;

  return (
    <Typography
      component="div"
      role="tabpanel"
      padding="24px"
      hidden={value !== tabId}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      //visibility={value === tabId ? "visible" : "hidden"}
      {...other}
    >
      {children}
    </Typography>
  );
}

export default function PaymentMethods() {
  const [tabValue, setTabValue] = useState("1");
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };
  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////
  return (
    <Container
      component="main"
      maxWidth="xl"
      sx={{
        marginTop: 4,
      }}
    >
      <div>
        <TabContext value={tabValue}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              maxWidth: "xl",
              minWidth: 3 / 4,
            }}
          >
            <TabList onChange={handleChangeTab} centered aria-label="Tabs">
              <Tab label="List Payment Methods" value="1" />
              <Tab label="New Payment Method" value="2" />
            </TabList>
          </Box>
          <TabPanel
            value="1"
            sx={{
              width: "100%",
              typography: "body1",
              maxWidth: "xl",
              minWidth: 3 / 4,
              alignItems: "center",
            }}
          >
            <ListPaymentMethods />
          </TabPanel>
          <TabPanel
            value="2"
            sx={{
              width: "100%",
              typography: "body1",
              maxWidth: "xl",
              minWidth: 3 / 4,
              alignItems: "center",
            }}
          >
            <NewPaymentMethods />
          </TabPanel>
        </TabContext>
      </div>
    </Container>
  );
}
