import React, { useState } from "react";
// import Dropzone from "react-dropzone";
import { DropContainer } from "./styles";
import { useDropzone } from "react-dropzone";
import { FiUpload } from "react-icons/fi";
import { FileUpload } from "@mui/icons-material";

//import {Container} from './styles';
interface Props {
  onUpload: (file: File) => void;
}
const Upload: React.FC<Props> = ({ onUpload }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [uploadedFiles, setUploadedFiles] = useState([]);
  // const [file, setFile] = useState<any>();
  /* const PngToJpg = (file: any) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.src = URL.createObjectURL(file[0]);
    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx?.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL("image/jpeg");
      dataURLtoFile(dataURL, file[0].name);
      // return dataURL;
    };
  }; */
  // mime = arr[0].match(/:(.*?);/)![1],
  //Agora converter URL para FILE
  /* const dataURLtoFile = (dataurl: any, filename: any) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)![1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    var name = filename.split(".");
    const mario = new File([u8arr], name[0] + ".jpg", { type: mime });
    setFile(mario);
    return new File([u8arr], name[0] + ".jpg", { type: mime });
  }; */

  const onDrop = async (acceptedFiles: any) => {
    // PngToJpg(acceptedFiles);
    // dataURLtoFile(test, acceptedFiles[0].name);

    // setTest(file);
    setUploadedFiles(acceptedFiles);

    onUpload(acceptedFiles);
  };
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    isDragActive,
  } = useDropzone({
    onDrop,
    accept: { "image/*": [".jpg", ".jpeg", ".png", ".gif", ".webp"] },
  });
  // imagem pra aceitar png
  // useDropzone({ onDrop, accept: { "image/*": [".jpeg", ".png"] } });
  // const { onUpload } = acceptedFiles;
  return (
    <div className="container">
      <DropContainer
        {...getRootProps({
          isFocused,
          isDragAccept,
          isDragReject,
          isDragActive,
        })}
      >
        {/* descomentar aqui */}
        {/* <h1>Aqui comecou o teste muy loko</h1>
        <h2>{test}</h2>
        <img src={test} alt="teste"></img> */}
        {/* //// */}

        <input {...getInputProps()} />
        {isDragAccept && (
          <p style={{ color: "#78e5d5" }}>
            <FiUpload />
            All files will be accepted
          </p>
        )}
        {isDragReject && (
          <p style={{ color: "#e57878" }}>
            <FileUpload />
            Some files will be rejected
          </p>
        )}
        {!isDragActive && (
          <p style={{ color: "#556cd6" }}>
            <FileUpload></FileUpload>Drop some files here ...
          </p>
        )}
      </DropContainer>
    </div>
  );
};
export default Upload;
