import { useEffect, useState } from "react";
import {
  Container,
  Pagination,
  Box,
  SelectChangeEvent,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
} from "@mui/material";
import TableHorizontal from "../TableHorizontal";
import api from "../../services/api";

export function ListProductSuppliers() {
  const [pageState, setPagepageState] = useState(1);
  const [total_pages, setTotalPages] = useState(0);
  const [productSuppliers, setProductsSuppliers] = useState([]);
  const columns = [
    "Product",
    "Supplier",
    "Currency",
    "Last price",
    "Comments",
    "Action",
  ];
  const keys = [
    "sku",
    "name",
    "currency_name",
    "last_price",
    "comments",
    "action",
  ];
  const fetchData = async (
    page = 1,

    numberItemsPerPage = 500
  ) => {
    await api
      .get(
        `/product-suppliers?page=${page}&limit=${numberItemsPerPage}&search=${search}`
      )
      .then((res) => {
        setProductsSuppliers(res.data.items);
        setTotalPages(res.data.total_pages);
      });
  };
  const [numberItemsPerPage, setNumberItemsPerPage] = useState("500");
  const handleNumberItemsPerPage = (event: SelectChangeEvent) => {
    setNumberItemsPerPage(event.target.value);
    fetchData(1, Number(event.target.value));
  };
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPagepageState(value);
    fetchData(value, Number(numberItemsPerPage));
  };
  const [search, setSearch] = useState("");
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const handleSearchClick = () => {
    fetchData(1);
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="number-items-page-label">
            Number of Items per Page
          </InputLabel>
          <Select
            labelId="number-items-page-select-label"
            id="number-items-page"
            value={numberItemsPerPage}
            label="Number of Items per Page"
            onChange={handleNumberItemsPerPage}
          >
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={250}>250</MenuItem>
            <MenuItem value={500}>500</MenuItem>
            <MenuItem value={750}>750</MenuItem>
            <MenuItem value={1000}>1000</MenuItem>
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <InputLabel id="table-name-filter-label">
            Table name filter
          </InputLabel>
          <TextField
            //sx={{ mt: 1, minWidth: 3/4 }}
            sx={{ minWidth: 1 / 4 }}
            //fullWidth
            id={"Search"}
            type="text"
            label="Type here..."
            value={search}
            onChange={handleChangeSearch}
          />
        </Box>

        <Button
          variant="contained"
          sx={{ m: 3 }}
          href=""
          onClick={handleSearchClick}
        >
          Filter
        </Button>
      </Box>
      <TableHorizontal
        screen={"product-suppliers"}
        columns={columns}
        data={productSuppliers}
        objectKey={keys}
      />
      <Box
        display="flex"
        width={"100%"}
        height={80}
        alignItems="center"
        justifyContent="center"
      >
        <Pagination
          count={total_pages}
          page={pageState}
          onChange={handleChange}
        />
      </Box>
    </Container>
  );
}
