/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  TextField,
  Stack,
  List,
  ListItem,
  ListItemText,
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  IconButton,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import Swal from "sweetalert2";
import { AsyncPaginate } from "react-select-async-paginate";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function NewCustomer() {
  const [circularLoading, setCircularLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [status, setStatus] = useState(1);

  /*   const [valueCustomerGroup, setValueCustomerGroup] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const loadOptionsCustomerGroups = async (search: any, page: any) => {
    const results = await api.get(
      `/customer-groups?page=${page}&name=${search}`
    );

    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsCustomerGroups = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsCustomerGroups(q, page);

    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  }; */

  const [newsletter, setNewsletter] = useState(true);

  // Add these variables to your component to track the state
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [password, setPassword] = useState("");

  const [valueLanguage, setValueLanguage] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const loadOptionsLanguages = async (search: any, page: any) => {
    const results = await api.get(`/languages?page=${page}&search=${search}`);

    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsLanguages = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsLanguages(q, page);

    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  };

  const [valueCurrency, setValueCurrency] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const loadOptionsCurrencies = async (search: any, page: any) => {
    const results = await api.get(
      `/currencies?page=${page}&search_name=${search}`
    );

    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsCurrencies = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsCurrencies(q, page);

    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  };

  const [isCompany, setIsCompany] = useState(false);

  const onSubmit = (customer: any) => {
    setCircularLoading(true);

    customer.status = Number(customer.status);

    if (!customer.email_2) {
      delete customer.email_2;
    }

    if (!customer.phone_number) {
      delete customer.phone_number;
    }

    if (!customer.phone_number_2) {
      delete customer.phone_number_2;
    }

    if (!customer.phone_number_3) {
      delete customer.phone_number_3;
    }

    if (!customer.birth_date) {
      delete customer.birth_date;
    }

    customer.newsletter = newsletter;

    if (!customer.profile_photo) {
      delete customer.profile_photo;
    }

    if (valueLanguage) {
      customer.language_id = valueLanguage.id;
    }

    if (valueCurrency) {
      customer.currency_id = valueCurrency.id;
    }

    if (!customer.person_id) {
      delete customer.person_id;
    }

    if (!customer.person_tax_id) {
      delete customer.person_tax_id;
    }

    customer.is_company = isCompany;

    if (!customer.tax_id) {
      delete customer.tax_id;
    }

    if (!customer.tax_id_2) {
      delete customer.tax_id_2;
    }

    //After a New Customer is Created, it goes to the Edit Page, where addresses can be edit or added
    api
      .post("customers", customer)
      .then((res) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Customer created!",
          icon: "success",
          confirmButtonText: "Yes",
        }).then(() => {
          //Load Customer Edit Page after Creating a User - Test it Later
          window.location.href = "/customers/" + res.data.id;
        });
        //reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "" + error.response.data.message,
          footer: error.response,
        });
        setCircularLoading(false);
        setRequestError(true);
        setRequestErrorData(error.response.data.message);
      });
  };

  const defaultAdditional = {
    page: 1,
  };

  useEffect(() => {}, []);

  return (
    <Container component="main" maxWidth="xl">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{ fontSize: "32px", marginBottom: "10px" }}
        >
          New Customer
        </Typography>
        {requestError && (
          <List component="nav" aria-label="BackEnd Return">
            {requestErrorData.map((error) => (
              <ListItem key={error}>
                <ListItemText
                  sx={{
                    color: "red",
                  }}
                  primary={error}
                />
              </ListItem>
            ))}
          </List>
        )}
        <Box
          component="form"
          sx={{ mt: 1, minWidth: 3 / 4, width: "100%" }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormControl fullWidth>
            <InputLabel id="status-select">Status</InputLabel>
            <Select
              labelId="status-select-label"
              id="status"
              value={status}
              {...register("status")}
              label="Status"
              onChange={(e) => setStatus(Number(e.target.value))}
            >
              <MenuItem value={1}>Enabled</MenuItem>
              <MenuItem value={0}>Disabled</MenuItem>
            </Select>
          </FormControl>
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.status?.toString()}
          </p>
          <br></br>
          {/* <InputLabel id="customer-group-select">Customer Group</InputLabel>
          <AsyncPaginate
            placeholder="Select Customer Group"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"customer_group_id"}
            id="customer_group"
            name="customer_group"
            value={valueCustomerGroup}
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.name} //.customer_group_descriptions[0]
            loadOptions={loadPageOptionsCustomerGroups}
            onChange={setValueCustomerGroup}
          /> */}
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.customer_group_id?.toString()}
          </p>
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <TextField
              margin="normal"
              required
              sx={{ mt: 1, minWidth: 2 / 4, width: "50%" }}
              id="first_name"
              type="text"
              label="First Name"
              {...register("first_name")}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.first_name?.toString()}
            </p>

            <TextField
              margin="normal"
              required
              sx={{ mt: 1, minWidth: 2 / 4, width: "50%" }}
              id="last_name"
              type="text"
              label="Last Name"
              {...register("last_name")}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.last_name?.toString()}
            </p>
          </Box>
          <TextField
            margin="normal"
            required
            sx={{ mt: 1, minWidth: 2 / 4, width: "100%" }}
            id="email"
            type="text"
            label="Main E-mail - Must be an unique email address (not Registered in the database before)"
            {...register("email")}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.email?.toString()}
          </p>

          <TextField
            margin="normal"
            sx={{ mt: 1, minWidth: 2 / 4, width: "100%" }}
            id="email_2"
            type="text"
            label="Secondary E-mail Address"
            {...register("email_2")}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.email_2?.toString()}
          </p>
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <TextField
              margin="normal"
              sx={{ mt: 1, minWidth: 1 / 3, width: "33%" }}
              id="phone_number"
              type="text"
              label="Main Phone Number (with Country Code. Ex.: +55)"
              {...register("phone_number")}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.phone_number?.toString()}
            </p>

            <TextField
              margin="normal"
              sx={{ mt: 1, minWidth: 1 / 3, width: "33%" }}
              id="phone_number_2"
              type="text"
              label="Secondary Phone Number (with Country Code. Ex.: +55)"
              {...register("phone_number_2")}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.phone_number_2?.toString()}
            </p>

            <TextField
              margin="normal"
              sx={{ mt: 1, minWidth: 1 / 3, width: "33%" }}
              id="phone_number_3"
              type="text"
              label="Additional Phone Number (with Country Code. Ex.: +55)"
              {...register("phone_number_3")}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.phone_number_3?.toString()}
            </p>
          </Box>

          <TextField
            margin="normal"
            sx={{ mt: 1, minWidth: 1 / 2, width: "50%" }}
            id="birth_date"
            type="date"
            label="Birth Date"
            InputLabelProps={{
              shrink: true,
            }}
            {...register("birth_date")}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.birth_date?.toString()}
          </p>

          <TextField
            margin="normal"
            sx={{ mt: 1, minWidth: 1 / 2, width: "100%" }}
            id="profile_photo"
            type="text"
            label="Profile Photo Address"
            {...register("profile_photo")}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.profile_photo?.toString()}
          </p>

          <FormControlLabel
            label="Newsletter Subscription?"
            control={
              <Checkbox
                checked={newsletter || false}
                onChange={(e) => setNewsletter(e.target.checked)}
                inputProps={{ "aria-label": "Newsletter Subscription?" }}
                // {...register("newsletter")}
              />
            }
          />

          <TextField
            margin="normal"
            fullWidth
            required
            label="Password"
            //type="password"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="new-password"
            {...register("password")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.password?.toString()}
          </p>

          <InputLabel id="customer-group-select">
            Website / Communication Preferred Language
          </InputLabel>
          <AsyncPaginate
            placeholder="Select Preferred Language"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"language_id"}
            id="language"
            name="language_id"
            value={valueLanguage}
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.name} //.customer_group_descriptions[0]
            loadOptions={loadPageOptionsLanguages}
            onChange={setValueLanguage}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.language_id?.toString()}
          </p>

          <InputLabel id="customer-group-select">
            Website / Payment Preferred Currency
          </InputLabel>
          <AsyncPaginate
            placeholder="Select Preferred Currency"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"currency_id"}
            id="currency"
            name="currency_id"
            value={valueCurrency}
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.name}
            loadOptions={loadPageOptionsCurrencies}
            onChange={setValueCurrency}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.currenncy_id?.toString()}
          </p>

          <Box style={{ display: "flex", flexDirection: "row" }}>
            <TextField
              margin="normal"
              sx={{ mt: 1, minWidth: 1 / 2, width: "50%" }}
              id="person_id"
              type="text"
              label="Person ID Number"
              {...register("person_id")}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.person_id?.toString()}
            </p>

            <TextField
              margin="normal"
              sx={{ mt: 1, minWidth: 1 / 2, width: "50%" }}
              id="person_tax_id"
              type="text"
              label="Person Tax ID Number"
              {...register("person_tax_id")}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.person_tax_id?.toString()}
            </p>
          </Box>

          <FormControlLabel
            label="Is Company?"
            control={
              <Checkbox
                checked={isCompany || false}
                onChange={(e) => setIsCompany(e.target.checked)}
                inputProps={{ "aria-label": "Is Company?" }}
                // {...register("newsletter")}
              />
            }
          />

          <Box style={{ display: "flex", flexDirection: "row" }}>
            <TextField
              margin="normal"
              sx={{ mt: 1, minWidth: 1 / 2, width: "50%" }}
              id="tax_id"
              type="text"
              label="Tax ID Number"
              {...register("tax_id")}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.tax_id?.toString()}
            </p>

            <TextField
              margin="normal"
              sx={{ mt: 1, minWidth: 1 / 2, width: "50%" }}
              id="tax_id_2"
              type="text"
              label="Tax ID Number 2"
              {...register("tax_id_2")}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.tax_id_2?.toString()}
            </p>
          </Box>

          <Stack
            direction="row"
            spacing={2}
            sx={{
              mt: 2,
            }}
          >
            <Button
              onClick={() => reset()}
              variant="outlined"
              startIcon={<DeleteIcon />}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              size="large"
              endIcon={<SendIcon />}
              disabled={circularLoading}
              type="submit"
            >
              Send
              {circularLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>
          </Stack>
        </Box>
        <div style={{ marginTop: "100px" }}></div>
      </Box>
    </Container>
  );
}
