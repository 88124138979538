/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  TextField,
  Stack,
  List,
  ListItem,
  ListItemText,
  Typography,
  Container,
  InputLabel,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import api from "../../services/api";
import Swal from "sweetalert2";
import { inputsName } from "../../config/languageConst";
import { AsyncPaginate } from "react-select-async-paginate";

function NewPaymentMethod() {
  useEffect(() => {}, []);
  const [circularLoading, setCircularLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [dependentShippingMethod, setDependentShippingMethod] = useState<{
    id: number;
    code: string;
    name: string;
  } | null>(null);

  const defaultAdditional = {
    page: 1,
  };

  const loadOptionsShippingMethod = async (search: any, page: any) => {
    const results = await api.get(
      `/shipping-methods?page=${page}&search=${search}`
    );

    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsShippingMethod = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsShippingMethod(q, page);
    if (options.length > 0) {
      options.unshift({ id: 0, code: "None" });
    }
    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  };

  const onSubmit = (payment_method: any) => {
    setCircularLoading(true);
    if (dependentShippingMethod) {
      if (dependentShippingMethod?.id > 0) {
        payment_method.dependent_shipping_method_id =
          dependentShippingMethod?.id;
      }
    }

    for (let index = 0; index < payment_method.descriptions.length; index++) {
      payment_method.descriptions[index].language_id = Number(
        payment_method.descriptions[index].language_id
      );
    }
    api
      .post("payment-methods", payment_method)
      .then((res) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Payment Method created!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        //reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "" + error.response.data.message,
          //footer: '<a href="">Why do I have this issue?</a>',
        });
        setCircularLoading(false);
        setRequestError(false);
        setRequestErrorData(error.response.data.message);
      });
  };

  useEffect(() => {}, []);

  return (
    <Container component="main" maxWidth="xl">
      <Typography
        component="h1"
        variant="h5"
        sx={{ fontSize: "32px", marginBottom: "15px", color: "#556cd6" }}
      >
        New Payment Method
      </Typography>
      {requestError && (
        <List component="nav" aria-label="Errors">
          {requestErrorData.map((error) => (
            <ListItem key={error}>
              <ListItemText
                sx={{
                  color: "red",
                }}
                primary={error}
              />
            </ListItem>
          ))}
        </List>
      )}
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          minWidth: 3 / 4,
          marginBottom: "30px",
          width: "100%",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextField
          margin="normal"
          // InputLabelProps={{ shrink: true }}
          required
          fullWidth
          id="code"
          type="text"
          label="Code"
          {...register("code")}
        />
        <p style={{ color: "red", textAlign: "center" }}>
          {errors?.code?.toString()}
        </p>

        <TextField
          margin="normal"
          // InputLabelProps={{ shrink: true }}
          required
          fullWidth
          id="name"
          type="text"
          label="Name"
          {...register("name")}
        />
        <p style={{ color: "red", textAlign: "center" }}>
          {errors?.name?.toString()}
        </p>

        <TextField
          margin="normal"
          // InputLabelProps={{ shrink: true }}
          fullWidth
          id="payment_company_name"
          type="text"
          label="Payment Company Name"
          {...register("payment_company_name")}
        />
        <p style={{ color: "red", textAlign: "center" }}>
          {errors?.payment_company_name?.toString()}
        </p>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="payment-method-filter-select">
            Dependent Shipping Method (To activate this Payment Method, the
            following Shipping Method must be chosen before)
          </InputLabel>
          <AsyncPaginate
            placeholder="Select Shipping Method"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"shipping_method"}
            id="shipping_method"
            name="shipping_method"
            value={dependentShippingMethod}
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) =>
              option?.code + " / " + option?.name
            }
            loadOptions={loadPageOptionsShippingMethod}
            onChange={setDependentShippingMethod}
          />
        </Box>
        <p style={{ color: "red", textAlign: "center" }}>
          {errors?.dependent_shipping_method_id?.toString()}
        </p>

        <TextField
          margin="normal"
          // InputLabelProps={{ shrink: true }}
          fullWidth
          id="additional_info"
          type="text"
          multiline
          rows={5}
          label="Additional Info"
          {...register("additional_info")}
        />
        <p style={{ color: "red", textAlign: "center" }}>
          {errors?.additional_info?.toString()}
        </p>

        <Box sx={{ width: "100%", typography: "body1" }}>
          <div style={{ marginTop: "30px" }}>
            {inputsName.map((input, index) => (
              <div
                key={index}
                className="flags-container"
                style={{ display: "block", marginTop: "50px" }}
              >
                <div className="fa-flags">
                  <img
                    src={`/assets/language/${input.language_id}.png`}
                    alt=""
                  />
                  &nbsp;-&nbsp;{input.language_name}
                </div>

                <input
                  hidden
                  {...register(`descriptions[${index}].language_id`, {
                    required: `descriptions[${index}].language_id is required`,
                  })}
                  value={input.language_id}
                />

                <TextField
                  sx={{ mt: 1, minWidth: 3 / 4 }}
                  // InputLabelProps={{ shrink: true }}
                  margin="normal"
                  required
                  fullWidth
                  id={"#name" + index.toString()}
                  type="text"
                  label="Name"
                  {...register(`descriptions[${index}].name`)}
                />

                <TextField
                  sx={{ mt: 1, minWidth: 3 / 4 }}
                  // InputLabelProps={{ shrink: true }}
                  margin="normal"
                  fullWidth
                  multiline
                  rows={5}
                  id={"#description" + index.toString()}
                  type="text"
                  label="Name"
                  {...register(`descriptions[${index}].description`)}
                />
              </div>
            ))}
          </div>
        </Box>

        <p style={{ color: "red", textAlign: "center" }}>
          {errors?.descriptions?.toString()}
        </p>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            mt: 2,
          }}
        >
          <Button
            onClick={() => reset()}
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Clear
          </Button>
          <Button
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            size="large"
            endIcon={<SendIcon />}
            disabled={circularLoading}
            type="submit"
          >
            Send
            {circularLoading && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Button>
        </Stack>
        <div style={{ marginTop: "100px" }}></div>
      </Box>
    </Container>
  );
}
export default NewPaymentMethod;
