/* eslint-disable react-hooks/exhaustive-deps */

//TO DO:
/* ADiconar Editor HTML no campo Description

Adicionar seletor de imagens no campo imagem

Adicionar preenchimento automatio sugerido de SEO URL

 */
import {
  Box,
  Button,
  TextField,
  Stack,
  Grid,
  Tab,
  List,
  ListItem,
  ListItemText,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  // CardMedia,
  Modal,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import api from "../../../../services/api";
import Swal from "sweetalert2";
import { AsyncPaginate } from "react-select-async-paginate";
import { inputsName } from "../../../../config/languageConst";
import { TabContext, TabList, useTabContext } from "@mui/lab";
import { MultiValue } from "react-select";
import { useLocation } from "react-router-dom";
import { LogTrackUser } from "../../../../types/log-track-user/log-track-user";
import { Content } from "../../../../components/Upload/styles";
import Upload from "../../../../components/Upload/index";
import FileList from "../../../../components/FileList";
import { uniqueId } from "lodash";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Cookies from "js-cookie";

//I HAD to declare TabPanel like this to prevent Lazy Loading on the TabPanels.
//DUe to Lazy loading, the data form the DB was not being filled into the fields in the other tabs
function TabPanel(props: any) {
  const { children, className, value, index, ...other } = props;

  const context = useTabContext();

  if (context === null) {
    throw new TypeError("No TabContext provided");
  }
  const tabId = context.value;

  return (
    <Typography
      component="div"
      role="tabpanel"
      padding="24px"
      hidden={value !== tabId}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      //visibility={value === tabId ? "visible" : "hidden"}
      {...other}
    >
      {children}
    </Typography>
  );
}

export default function NewProduct() {
  const [circularLoading, setCircularLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);
  const [imageBaseURL, setimageBaseURL] = useState(
    process.env.REACT_APP_IMG_CATALOG_URL
  );
  // Upload Image Section //////////////////////////////////////////
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const handleUpload = (files: any) => {
    const uploadedFile = files.map((file: any, index: number) => ({
      file,
      name: file.name,
      id: uniqueId(),
      readableSize: file.size,
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
      order: index,
    }));

    setUploadedFiles(uploadedFile.concat(uploadedFiles));
    uploadedFile.forEach(processUpload);
  };
  const updateFile = (id: any, data: any) => {
    setUploadedFiles((state) =>
      state.map((uploadedFile) => {
        return id === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile;
      })
    );
  };

  const processUpload = (uploadedFile: any, index: number) => {
    const data = new FormData();
    data.append("image", uploadedFile.file, uploadedFile.name);

    data.append("sort_order", (uploadedFiles.length + index).toString());

    api
      .post(`products-custom/upload-new/${inputSKU}`, data, {
        onUploadProgress: (e) => {
          const progress = e.total
            ? parseInt(Math.round((e.loaded * 100) / e.total).toFixed(2))
            : 0;
          updateFile(uploadedFile.id, {
            progress,
          });
        },
      })
      .then((response) => {
        updateFile(uploadedFile.id, {
          uploaded: true,
          url: response.data.Location,
        });
        componentDidMount(inputSKU);
      })
      .catch((error) => {
        updateFile(uploadedFile.id, {
          error: true,
        });
        console.error(error.response.data.message);
      });
  };
  const handleDelete = async (id: any, data: any) => {
    await api.delete(`products-custom/upload/${inputSKU}/${data.name}`);
    setUploadedFiles((state) => state.filter((file) => file.id !== id));
  };

  const handleChangeOrder = async (previous_id: number, next_id: number) => {
    // develop
  };

  //This function gets the files that already exists at S3 given an Input SKU
  //Input SKU always will have the structure of Main Folder (3 letters)/ Sub Folder
  const componentDidMount = async (inputSKU: string) => {
    await api
      .get(`products-custom/imagesfromS3/${inputSKU}`)
      .then((response) => {
        const uploadedFile: any = response.data.map((file: any) => ({
          name: file.filename,
          id: uniqueId(),
          readableSize: file.size,
          preview: `${imageBaseURL}${file.name}`,
          progress: 100,
          uploaded: true,
          error: false,
          url: `${imageBaseURL}${file.name}`,
        }));
        setUploadedFiles(uploadedFile);
        //Also Updates the Main Image Path, Secondary Image Path and Additional Images
        //TO DO
        const images_temp = [];
        for (var i = 0; i < response.data.length; i++) {
          images_temp.push(response.data[i].name);
        }
        setImagesConst(images_temp);
      })
      .catch((error: any) => {
        console.error(error.response.data.message);
      });
  };
  ///////////////////////////////////////////////////////////
  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [tabValue, setTabValue] = useState("1");
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const [inputSKU, setinputSKU] = useState("");
  const handleChangeSKU = (event: any) => {
    //setMessage(event.target.value);
    setinputSKU(event.target.value);
    componentDidMount(event.target.value);
  };

  //=IF(LEN(C2)>9,LEFT(C2,9)&ROUND(RAND()*1000,0),C2&ROUND(RAND()*10^(12-LEN(C2)),0))
  const [YSIN, setYSIN] = useState("");
  const generateYSIN = () => {
    if (inputSKU) {
      if (inputSKU.length > 9) {
        setYSIN(inputSKU.substring(0, 9) + Math.round(Math.random() * 1000));
      } else {
        setYSIN(
          inputSKU +
            Math.round(Math.random() * Math.pow(10, 12 - inputSKU.length))
        );
      }
    }
    //fetchData(1,Number(language));
  };
  const handleChangeYSIN = (event: any) => {
    setYSIN(event.target.value);
  };

  const [product_type, setProduct_type] = useState("");
  const handleChangeProduct_type = (event: SelectChangeEvent) => {
    setProduct_type(event.target.value);
  };

  const [barcode_type, setBarcode_type] = useState("");
  const handleChangeBarcode_type = (event: SelectChangeEvent) => {
    setBarcode_type(event.target.value);
  };

  const [status, setStatus] = useState("");
  const handleChangeStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  const loadOptionsBrands = async (search: any, page: any) => {
    const results = await api.get(
      `/brands?page=${page}&name=${search}&language_id=1`
    );

    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsBrands = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsBrands(q, page);

    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  };

  const [valueBrand, setValueBrand] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const [value, setValue] = useState<{ id: number; name: string }[]>([]);

  //(cat_chosen) => setValue([...cat_chosen])
  const onChangeCategories = async (
    newValue: MultiValue<{ id: number; name: string }>
  ) => {
    //when Adding, do the code below
    if (newValue.length > value.length) {
      //check to see if the added Category parents have been already added, if not, then add then too
      let last_inserted_category = newValue.slice(-1)[0];
      value.push(last_inserted_category);
      /* let results = await api.get(`/categories-custom/${last_inserted_category.id}/parents-categories`).then(response => {
      
      }); */
      const results = await api.get(
        `/categories-custom/${last_inserted_category.id}/parents-categories`
      );
      const parents_categories = results.data;
      parents_categories.forEach((category: { id: number; name: string }) => {
        let cat_search = newValue.filter((obj) => {
          return obj.id === category.id;
        })[0];
        if (cat_search === undefined) {
          const insert = { id: category.id, name: category.name };
          value.push(insert);
        }
      });
      setValue(Array.from(new Set(value)));
    } else {
      setValue([...newValue]);
    }
  };

  const defaultAdditional = {
    page: 1,
  };

  const loadOptionsCategories = async (search: any, page: any) => {
    const results = await api.get(`/categories?page=${page}&search=${search}`);

    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsCategories = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsCategories(q, page);

    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  };

  const TitleToURL = function (str: string) {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from =
      "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;";
    var to =
      "AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa------";
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes

    return str;
  };

  const [inputTitle, setInputTitle] = useState<string[]>([]);
  const [inputSEOURL, setInputSEOURL] = useState<string[]>([]);

  inputTitle.length = inputsName.length;
  inputSEOURL.length = inputsName.length;

  const handleChangeInputTitle = (event: any) => {
    inputTitle[event.target.id] = event.target.value;
    setInputTitle(inputTitle);
  };

  const handleChangeInputSEOURL = (event: any) => {
    inputSEOURL[event.target.id] = event.target.value;
    setInputSEOURL(inputSEOURL);
  };

  const GenerateSEOURL = async () => {
    //setMessage(event.target.value);
    const seourl_temp = [];
    for (var i = 0; i < inputTitle.length; i++) {
      if (inputTitle[i]) {
        let language_code: string;
        switch (i) {
          case 0:
            language_code = "-en";
            break;
          case 1:
            language_code = "-pt";
            break;
          case 2:
            language_code = "-es";
            break;
          case 3:
            language_code = "-jp";
            inputTitle[i] = inputTitle[0];
            break;
          default:
            language_code = "";
          // code block
        }
        inputSEOURL[i] = TitleToURL(inputTitle[i] + language_code);
        setInputSEOURL(inputSEOURL);
        seourl_temp.push(inputSEOURL[i]);
      }
    }
    setInputSEOURL(seourl_temp);
  };

  const [imagesConst, setImagesConst] = useState<string[]>([]);

  /*   const updateImagesList = async () => {
    const results = await api.get(`/products-custom/imagesfromS3/${inputSKU}`);
    const images_temp = [];
    for (var i = 0; i < results.data.length; i++) {
      images_temp.push(results.data[i].name);
    }
    setImagesConst(images_temp);
  }; */

  /*   const onImageTextInputChange = (newInputValue: object) => {
    //uodate the imagesConst according to the number of TextField

    //const { id, name, value } = newInputValue.target
    const newArray = [...imagesConst]
    //newArray[id][name] = value

  } */

  const loadOptionsAttributes = async (search: any, page: any) => {
    const results = await api.get(`/attributes?page=${page}&search=${search}`);

    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsAttributes = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsAttributes(q, page);

    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  };

  const [valueAttributes, setValueAttributes] = useState<
    { id: number; name: string }[]
  >([]);

  const onChangeAttributes = async (
    newValue: MultiValue<{ id: number; name: string }>
  ) => {
    setValueAttributes([...newValue]);

    //setValueAttributesExtraInfo([...newValue]);
  };
  //css do modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  //css modal image
  const [openI, setOpenI] = useState(false);
  const handleOpenImage = () => setOpenI(true);
  const handleCloseImage = () => setOpenI(false);

  const loadOptionsSpecifications = async (search: any, page: any) => {
    const results = await api.get(
      `/specifications?page=${page}&search=${search}`
    );

    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsSpecifications = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsSpecifications(q, page);

    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  };

  const [valueSpecifications, setValueSpecifications] = useState<
    { id: number; name: string }[]
  >([]);
  //const [valueSpecificationsExtraInfo, setValueSpecificationsExtraInfo] = useState<{id: number, name: string, group_id: number, group_name: string}[]>([]);

  const onChangeSpecifications = async (
    newValue: MultiValue<{ id: number; name: string }>
  ) => {
    setValueSpecifications([...newValue]);

    //setValueSpecificationsExtraInfo([...newValue]);
  };

  //Inventory Sell Attributes
  const [inventoryUnit, setInventoryUnit] = useState("UN");
  const handleChangeInventoryUnit = (event: SelectChangeEvent) => {
    setInventoryUnit(event.target.value);
  };

  const [sellUnit, setSellUnit] = useState("UN");
  const handleChangeSellUnit = (event: SelectChangeEvent) => {
    setSellUnit(event.target.value);
  };

  const onSubmit = async (product: any) => {
    setCircularLoading(true);

    if (!product.ysin) {
      product.ysin = YSIN;
    }
    product.product_type = Number(product.product_type);

    if (product.status) {
      product.status = Number(product.status);
    } else {
      product.status = 1;
    }
    product.brand_id = valueBrand ? valueBrand.id : null;

    if (product.barcode === "") {
      product.barcode = null;
    }
    if (product.barcode_type === "") {
      product.barcode_type = null;
    }

    //Remove ID Duplicates
    //Array.from(new Set(a));
    //let uniq_categories = value => [...new Set(value)];
    let uniq_categories = value;
    let uniq_categories_ids = [];

    for (let index = 0; index < uniq_categories.length; index++) {
      uniq_categories_ids.push(Number(uniq_categories[index].id));
    }
    product.category_ids = uniq_categories_ids;

    for (let index = 0; index < product.descriptions.length; index++) {
      product.descriptions[index].language_id = Number(
        product.descriptions[index].language_id
      );
      if (product.descriptions[index].seo_url === "") {
        product.descriptions[index].seo_url = null;
      }
    }

    //SEO URLS
    GenerateSEOURL();
    //If the SEO URLs are empty, then it will automatically add it based on the product title (if they are not empty)
    for (var i = 0; i < product.descriptions.length; i++) {
      //Update the variable with current SEO URL on the field - Automatic SEO URL Definition Implementation
      product.descriptions[i].seo_url = inputSEOURL[i];
      if (
        product.descriptions[i].name !== "" &&
        product.descriptions[i].seo_url === ""
      ) {
        let language_code;
        switch (i) {
          case 0:
            language_code = "-en";
            break;
          case 1:
            language_code = "-pt";
            break;
          case 2:
            language_code = "-es";
            break;
          case 3:
            language_code = "-jp";
            break;
          default:
            language_code = "";
          // code block
        }
        inputSEOURL[i] = TitleToURL(
          product.descriptions[i].name + language_code
        );
        setInputSEOURL(inputSEOURL);
        product.descriptions[i].seo_url = inputSEOURL[i];
      }
    }

    if (!product.image) product.image = null;
    if (!product.image2) product.image2 = null;
    if (imagesConst) {
      //Images
      for (let index = 0; index < imagesConst.length; index++) {
        if (!product.image && index === 0) {
          product.image = imagesConst[0];
        }
        if (!product.image2 && index === 1) {
          product.image2 = imagesConst[1];
        }
        if (index > 1) {
          product.images[index - 2].sort_order = 0;
        }
      }
    }

    //Measurements
    product.product_measurement.width = Number(
      product.product_measurement.width
    );
    product.product_measurement.height = Number(
      product.product_measurement.height
    );
    product.product_measurement.length = Number(
      product.product_measurement.length
    );
    product.product_measurement.weight = Number(
      product.product_measurement.weight
    );

    //Attributes/Filters
    let uniq_attributes = valueAttributes;
    let uniq_attributes_ids = [];
    for (let index = 0; index < uniq_attributes.length; index++) {
      uniq_attributes_ids.push(Number(uniq_attributes[index].id));
    }
    product.attribute_ids = uniq_attributes_ids;

    for (
      let index = 0;
      index < inputsName.length * valueSpecifications.length;
      index++
    ) {
      product.specifications[index].specification_id = Number(
        product.specifications[index].specification_id
      );
      product.specifications[index].language_id = Number(
        product.specifications[index].language_id
      );
    }

    //Product Inventory Sell Attributes
    if (!product.product_inventory_sell_attribute.inventory_unit) {
      product.product_inventory_sell_attribute.inventory_unit = "UN";
    }
    if (!product.product_inventory_sell_attribute.sell_unit) {
      product.product_inventory_sell_attribute.sell_unit = "UN";
    }
    if (
      !product.product_inventory_sell_attribute
        .price_sell_unit_x_inventory_unit_multiplier
    ) {
      product.product_inventory_sell_attribute.price_sell_unit_x_inventory_unit_multiplier = 1;
    }
    product.product_inventory_sell_attribute.price_sell_unit_x_inventory_unit_multiplier =
      Number(
        product.product_inventory_sell_attribute
          .price_sell_unit_x_inventory_unit_multiplier
      );
    if (!product.product_inventory_sell_attribute.minimum_sell_unit) {
      product.product_inventory_sell_attribute.minimum_sell_unit = 1;
    }
    product.product_inventory_sell_attribute.minimum_sell_unit = Number(
      product.product_inventory_sell_attribute.minimum_sell_unit
    );
    if (!product.product_inventory_sell_attribute.sell_quantity_multiple) {
      product.product_inventory_sell_attribute.sell_quantity_multiple = 1;
    }
    product.product_inventory_sell_attribute.sell_quantity_multiple = Number(
      product.product_inventory_sell_attribute.sell_quantity_multiple
    );

    //SKU Validation
    //Verify if the new value of SKU already exists in another product
    const responseSKU = await api.get(`products-custom/SKU/${product.sku}`);
    if (responseSKU.data) {
      setCircularLoading(false);
      setRequestError(true);
      Swal.fire({
        title: "Duplicated SKU!",
        icon: "error",
        confirmButtonText: "NO",
      });
      return;
    }

    api
      .post(`products-custom/`, product)
      .then((res) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Product Created!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        //reset();
        //window.location.href = `../products`;
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
        setCircularLoading(false);
        setRequestError(false);
        setRequestErrorData(error.response.data.message);
      });
  };

  useEffect(() => {
    if (imageBaseURL?.substring(imageBaseURL?.length - 1) !== "/") {
      setimageBaseURL(imageBaseURL + "/");
    }
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <Typography
            component="h1"
            variant="h5"
            sx={{
              paddingTop: "40px",
              fontWeight: "bold",
              fontSize: "32px",
              color: "#556cd6",
              paddingLeft: "20px",
            }}
          >
            New Product
          </Typography>
          {requestError && (
            <List key="1" component="nav" aria-label="error label">
              {requestErrorData.map((error) => (
                <ListItem key={error}>
                  <ListItemText
                    sx={{
                      color: "red",
                    }}
                    primary={error}
                  />
                </ListItem>
              ))}
            </List>
          )}
          <TabContext value={tabValue}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                maxWidth: "xl",
                minWidth: 3 / 4,
              }}
            >
              <TabList
                onChange={handleChangeTab}
                centered
                aria-label="lab API tabs example"
              >
                <Tab label="Essential Info" value="1" />
                <Tab label="Categories" value="2" />
                <Tab label="Title/Description" value="3" />
                <Tab label="Images" value="4" />
                <Tab label="Measurements" value="5" />
                <Tab label="Inventory & Sell Attributes" value="6" />
                <Tab label="Attributes/Filters" value="7" />
                <Tab label="Specifications" value="8" />
              </TabList>
            </Box>
            <Box
              component="form"
              sx={{
                mt: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onSubmit={handleSubmit(onSubmit)}
            >
              {/* Essential Info */}
              <TabPanel
                value="1"
                sx={{
                  width: "100%",
                  typography: "body1",
                  maxWidth: "xl",
                  minWidth: 3 / 4,
                  alignItems: "center",
                }}
              >
                <Box>
                  <FormControl sx={{ width: "180px" }}>
                    <InputLabel id="product_type-select-label">
                      Product Type
                    </InputLabel>

                    <Select
                      labelId="product_type-select-label"
                      id="product_type"
                      required
                      value={product_type}
                      {...register("product_type", {
                        required: "Product Type is required!",
                      })}
                      label="Product Type"
                      onChange={handleChangeProduct_type}
                    >
                      <MenuItem value={1}>Unique Product</MenuItem>
                      <MenuItem value={2}>Parent Product</MenuItem>
                      <MenuItem value={3}>Child Product</MenuItem>
                      <MenuItem value={4}>Combination Product</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    required
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: "70%", marginLeft: "8px" }}
                    id="sku"
                    type="text"
                    label="SKU (Product Code - 3 first letters is the manufacturer code. The rest is the product code) (Images are loaded based on it)"
                    {...register("sku")}
                    onBlur={handleChangeSKU}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.barcode?.toString()}
                  </p>
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.sku?.toString()}
                  </p>
                </Box>
                <Button onClick={handleOpen}>products types help</Button>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      sx={{ color: "#556cd6" }}
                    >
                      <b>Products Type</b>
                    </Typography>
                    <Typography id="modal-modal-description">
                      <b> product_type = 1</b> - Produtos Únicos (Unique
                      Products) (Buyable and Shown on Catalog) - Most Basic and
                      Default Type of Product. It can be part of the Combination
                      Product, and of Parent Products.
                    </Typography>
                    <Typography id="modal-modal-description">
                      <b>product_type = 2</b> - Produtos Pai (Parent Products)
                      (Not Buyable and Shown on Catalog) - It cannot be part of
                      the Combination Product. It can contains Child Products
                      and Unique Products. Parent Products will can be Multi
                      Level Products. Example. Shirt: Level 1, Color; Level 2,
                      Size.
                    </Typography>
                    <Typography id="modal-modal-description">
                      <b>product_type = 3</b> - Produtos Filhos (Child Products)
                      (Buyable and not Shown on Catalog) - It is allowed to have
                      MULTIPLE PARENTS. It can also be part of a Combination
                      Product.
                    </Typography>
                    <Typography id="modal-modal-description">
                      <b>product_type = 4</b> - Produtos Combinação (Combination
                      Products) (Buyable and Shown on Catalog) - Composed by
                      Unique Products and/or Child Products Later, draw diagrams
                      and more detailed info to explain the product type.
                    </Typography>
                  </Box>
                </Modal>
                {/* Adicionar Explicação do tipo de Produto */}
                <br />
                <Box>
                  <TextField
                    margin="normal"
                    required
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: "70%" }}
                    id="ysin"
                    type="text"
                    label="YSIN - Y&Stevo Standard Identification Number (12 digits) (Click in the button to generate a new one - SKU MUST be filled!)"
                    {...register("ysin")}
                    value={YSIN || ""}
                    onChange={handleChangeYSIN}
                  />
                  <Button
                    sx={{
                      marginLeft: "8px",
                      marginTop: "16px",
                      padding: "15px",
                    }}
                    variant="contained"
                    href=""
                    onClick={generateYSIN}
                  >
                    Generate a YSIN
                  </Button>
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.ysin?.toString()}
                  </p>
                </Box>
                <Box sx={{ marginTop: "10px" }}>
                  <TextField
                    sx={{ width: "50%" }}
                    InputLabelProps={{ shrink: true }}
                    id="barcode"
                    type="text"
                    label="Barcode Number"
                    {...register(
                      "barcode"
                    )} /* Different Products can have the same barcode. It is not common, but it can happen. In the store, if this happens, it will open a window to choose the correct product */
                  />
                  <FormControl sx={{ width: "20%", marginLeft: "8px" }}>
                    <InputLabel id="barcode_type-select-label">
                      Barcode Type
                    </InputLabel>
                    <Select
                      labelId="barcode_type-select-label"
                      id="barcode_type"
                      value={barcode_type}
                      {...register("barcode_type")}
                      label="Barcode Type"
                      onChange={handleChangeBarcode_type}
                    >
                      <MenuItem value={""}>None</MenuItem>
                      <MenuItem value={"EAN"}>EAN</MenuItem>
                      <MenuItem value={"UPC"}>UPC</MenuItem>
                      <MenuItem value={"ISBN"}>ISBN</MenuItem>
                      <MenuItem value={"GCID"}>GCID</MenuItem>
                      <MenuItem value={"GTIN"}>GTIN</MenuItem>
                    </Select>
                  </FormControl>
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.barcode?.toString()}
                  </p>
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.barcode_type?.toString()}
                  </p>
                </Box>
                {/* Brand */}
                <InputLabel id="brand-select" sx={{ marginTop: "8px" }}>
                  Brand
                </InputLabel>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box sx={{ minWidth: "20%", maxWidth: "22%" }}>
                    <AsyncPaginate
                      placeholder="Select Brand"
                      styles={{
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 9999,
                        }),
                        input: (provided) => ({
                          ...provided,
                          zIndex: 9999,
                          marginTop: "20px",
                        }),
                      }}
                      additional={defaultAdditional}
                      key={"brand_id"}
                      id="brand"
                      name="brand"
                      getOptionValue={(option: any) => option?.id}
                      getOptionLabel={(option: any) => option?.name}
                      loadOptions={loadPageOptionsBrands}
                      value={valueBrand}
                      onChange={setValueBrand}
                    />
                  </Box>
                  {/* Status Produto */}

                  <FormControl sx={{ width: "10%", marginLeft: "8px" }}>
                    <InputLabel id="status-select">Status</InputLabel>
                    <Select
                      required
                      labelId="status-select-label"
                      id="status"
                      value={status}
                      {...register("status", {
                        required: "Status is required",
                      })}
                      label="Status"
                      onChange={handleChangeStatus}
                    >
                      <MenuItem value={1}>Enabled</MenuItem>
                      <MenuItem value={0}>Disabled</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors?.status?.toString()}
                </p>
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors?.status?.toString()}
                </p>
              </TabPanel>

              <TabPanel
                value="2"
                sx={{
                  width: "100%",
                  typography: "body1",
                  maxWidth: "xl",
                  minWidth: 3 / 4,
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <AsyncPaginate
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Select Categories"
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    additional={defaultAdditional}
                    key={"category_ids"}
                    id="category"
                    name="category"
                    getOptionValue={(option: any) => option?.id}
                    getOptionLabel={(option: any) => option?.name}
                    loadOptions={loadPageOptionsCategories}
                    value={value}
                    onChange={onChangeCategories}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.category_ids?.toString()}
                  </p>

                  {value.map((input, index) => {
                    return (
                      <p key={index}>
                        {" "}
                        Category ID: {input.id} / {input.name}
                      </p>
                    );
                  })}
                </Box>
              </TabPanel>

              <TabPanel
                value="3"
                sx={{
                  width: "100%",
                  typography: "body1",
                  maxWidth: "xl",
                  minWidth: 3 / 4,
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <Button variant="contained" href="" onClick={GenerateSEOURL}>
                    Generate SEO URLs
                  </Button>

                  {inputsName.map((input, index) => (
                    <Box
                      key={index}
                      className="flags-container"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <div
                          className="fa-flags"
                          style={{
                            height: "50px",
                            width: "200px",
                            marginTop: "10px",
                          }}
                        >
                          <img
                            src={`/assets/language/${input.language_id}.png`}
                            alt=""
                          />
                          &nbsp;-&nbsp;{input.language_name}
                        </div>
                        <input
                          hidden
                          {...register(`descriptions[${index}].language_id`, {
                            required: `descriptions[${index}].language_id is required`,
                          })}
                          value={input.language_id}
                        />

                        <TextField
                          sx={{ mt: 1, minWidth: 3 / 4, marginLeft: "8px" }}
                          InputLabelProps={{ shrink: true }}
                          margin="normal"
                          required
                          fullWidth
                          id={index.toString()}
                          type="text"
                          label="Product Title"
                          {...register(`descriptions[${index}].name`)}
                          onChange={handleChangeInputTitle}
                        />
                        <p style={{ color: "red", textAlign: "center" }}>
                          {errors?.descriptions?.toString()}
                        </p>
                      </Box>

                      {/*                       {inputSEOURL[index] && (
                        <p>
                          Suggested New SEO URL: (Copy and Paste in the box
                          below to change the SEO URL)<br></br>
                          {inputSEOURL[index]}
                        </p>
                      )} */}
                      <p>
                        SEO URL are generated automatically based on the Product
                        Title. Japanese Language will be the same as the English
                        Language, only the end will be different
                      </p>
                      <Box
                        sx={{
                          width: "100%",
                        }}
                      >
                        <TextField
                          sx={{ mt: 1, minWidth: 3 / 4 }}
                          InputLabelProps={{ shrink: true }}
                          margin="normal"
                          fullWidth
                          required
                          id={index.toString()}
                          type="text"
                          value={inputSEOURL[index] || ""}
                          label="SEO URL (Friendly URL) - Nor Spaces or Special Characters are allowed"
                          {...register(`descriptions[${index}].seo_url`)}
                          onChange={handleChangeInputSEOURL}
                        />
                        <p style={{ color: "red", textAlign: "center" }}>
                          {errors?.descriptions?.toString()}
                        </p>

                        <TextField
                          InputLabelProps={{ shrink: true }}
                          margin="normal"
                          fullWidth
                          id={"#descriptions[" + index + "].description"}
                          type="text"
                          label="Description"
                          rows={10}
                          multiline
                          {...register(`descriptions[${index}].description`)}
                        />
                        <p style={{ color: "red", textAlign: "center" }}>
                          {errors?.descriptions?.toString()}
                        </p>
                        <br></br>
                      </Box>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        margin="normal"
                        fullWidth
                        id={"#descriptions[" + index + "].seo_keyword"}
                        type="text"
                        label="Meta keyword (Keywords for SEO - Write Everything related to the Product. Max of 2000 Characters)"
                        rows={3}
                        multiline
                        {...register(`descriptions[${index}].seo_keyword`)}
                      />
                      <p
                        style={{
                          color: "red",
                          textAlign: "center",
                          marginBottom: "30px",
                        }}
                      >
                        {errors?.descriptions?.toString()}
                      </p>
                    </Box>
                  ))}
                </Box>
              </TabPanel>

              <TabPanel
                value="4"
                sx={{
                  width: "100%",
                  typography: "body1",
                  maxWidth: "xl",
                  minWidth: 3 / 4,
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <p>
                    To organize the order of the images, click and hold on the
                    images and drop click over on one of the blue bars shown.
                  </p>
                  <Content>
                    <Upload onUpload={handleUpload} />
                    {!!uploadedFiles.length && (
                      <FileList
                        files={uploadedFiles}
                        onDelete={handleDelete}
                        changeOrder={handleChangeOrder}
                      />
                    )}
                  </Content>
                  {/* This section below shows what is registered in the database:{" "}
                  <br></br>
                  After Uploading Files into the Online Image Serve, always
                  Click the Send Button Below to Update the Database.
                  <Button variant="contained" onClick={updateImagesList}>
                    Update Images - Read From Image Repository
                  </Button> */}
                  <Button onClick={handleOpenImage}>update images help</Button>
                  <Modal
                    open={openI}
                    onClose={handleCloseImage}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ color: "#556cd6" }}
                      >
                        <b>Update Images</b>
                      </Typography>
                      <Typography id="modal-modal-description">
                        All image Paths are filled when clicking the button
                        above based on the imagens that have in the respective
                        folder accoding to SKU.
                      </Typography>
                      <Typography id="modal-modal-description">
                        Example: Product with SKU: ABCProd1 - Images will be
                        obtained from the URL: [Image Repository]/ABD/Prod1/
                      </Typography>
                      <Typography id="modal-modal-description">
                        The first 3 letters will always be from the first
                        folder, which represents the manufacturer
                      </Typography>
                    </Box>
                  </Modal>
                  <Box>
                    <TextField
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      sx={{ width: "70%" }}
                      id="image"
                      type="text"
                      label="Main Image Path"
                      {...register("image")}
                      value={imagesConst[0] || ""}
                      //onChange={onImageTextInputChange}
                    />
                    {/* {(() => {
                      if (
                        imagesConst[0] !== undefined &&
                        imagesConst[0] !== "" &&
                        imagesConst[0] !== null
                      ) {
                        return (
                          <div>
                            <CardMedia
                              component="img"
                              sx={{ width: 151, display: "inline" }}
                              image={`${imageBaseURL}${imagesConst[0]}`}
                              alt={"image"}
                            />
                            <p style={{ color: "red", textAlign: "center" }}>
                              {errors?.image?.toString() }
                            </p>
                          </div>
                        );
                      }
                    })()} */}
                    <TextField
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      sx={{ width: "70%" }}
                      id="image2"
                      type="text"
                      label="Secondary Image Path"
                      {...register("image2")}
                      value={imagesConst[1] || ""}
                    />
                    {/* {(() => {
                      if (
                        imagesConst[0] !== undefined &&
                        imagesConst[0] !== "" &&
                        imagesConst[0] !== null
                      ) {
                        return (
                          <div>
                            <CardMedia
                              component="img"
                              sx={{ width: 151, display: "inline" }}
                              image={`${imageBaseURL}${imagesConst[1]}`}
                              alt={"image"}
                            />
                            <p style={{ color: "red", textAlign: "center" }}>
                              {errors?.image?.toString()2 && errors?.image?.toString()2.message}
                            </p>
                          </div>
                        );
                      }
                    })()} */}
                    <div>
                      {imagesConst.map((image, index) => {
                        if (index > 1) {
                          return (
                            <div key={index}>
                              <TextField
                                margin="normal"
                                InputLabelProps={{ shrink: true }}
                                sx={{ width: "70%" }}
                                id={"#images[" + (index - 2) + "].name"}
                                type="text"
                                label="Aditional Image Path"
                                {...register(`images[${index - 2}].image`)}
                                //onChange={onImageTextInputChange}
                                value={imagesConst[index] || ""}
                              />
                              {/* <CardMedia
                                component="img"
                                id={"#images[" + (index - 2) + "].image"}
                                sx={{ width: 151, display: "inline" }}
                                image={`${imageBaseURL}${image}`}
                                //defaultValue={{imageBaseURL}+imagesConst[index]}
                                alt={"image"}
                              /> */}
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>
                    <p style={{ color: "red", textAlign: "center" }}>
                      {errors?.images?.toString()}
                    </p>
                  </Box>
                </Box>
              </TabPanel>

              <TabPanel
                value="5"
                sx={{
                  width: "100%",
                  typography: "body1",
                  maxWidth: "xl",
                  minWidth: 3 / 4,
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TextField
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    sx={{ mt: 1, minWidth: 3 / 4 }}
                    id="#product_measurement.width"
                    type="text"
                    label="Width (in mm)"
                    {...register(`product_measurement.width`)}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.product_measurement?.toString()}
                  </p>

                  <TextField
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    sx={{ mt: 1, minWidth: 3 / 4 }}
                    id="#product_measurement.height"
                    type="text"
                    label="Height (in mm)"
                    {...register(`product_measurement.height`)}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.product_measurement?.toString()}
                  </p>

                  <TextField
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    sx={{ mt: 1, minWidth: 3 / 4 }}
                    id="#product_measurement.length"
                    type="text"
                    label="Length (in mm)"
                    {...register(`product_measurement.length`)}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.product_measurement?.toString()}
                  </p>

                  <TextField
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    sx={{ mt: 1, minWidth: 3 / 4 }}
                    id="#product_measurement.weight"
                    type="text"
                    label="Weight (in Kg)"
                    {...register(`product_measurement.weight`)}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.product_measurement?.toString()}
                  </p>
                </Box>
              </TabPanel>
              <TabPanel
                value="6"
                sx={{
                  width: "100%",
                  typography: "body1",
                  maxWidth: "xl",
                  minWidth: 3 / 4,
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <Box sx={{ mt: 1, minWidth: 3 / 4, width: "100%" }}>
                    <FormControl sx={{ mt: 1, minWidth: 3 / 4 }}>
                      <InputLabel id="inventory_unit">
                        Inventory Quantity Unit / The Most Basic Unit in Which
                        the Product is stored at the Inventory Center
                      </InputLabel>
                      <Select
                        labelId="inventory_unit"
                        id="product_inventory_sell_attribute.inventory_unit"
                        required
                        value={inventoryUnit}
                        {...register(
                          "product_inventory_sell_attribute.inventory_unit"
                        )}
                        label="Inventory Quantity Unit / The Most Basic Unit in Which the Product is stored at the Inventory Center"
                        onChange={handleChangeInventoryUnit}
                      >
                        <MenuItem value="UN">UN</MenuItem>
                        <MenuItem value="PC">Piece</MenuItem>
                        <MenuItem value="KG">Kg - Kilogram</MenuItem>
                        <MenuItem value="G">gram</MenuItem>
                        <MenuItem value="LB">Pound</MenuItem>
                        <MenuItem value="OZ">Ounce</MenuItem>
                        <MenuItem value="M">Meter</MenuItem>
                        <MenuItem value="TON">Ton - (1000 Kgs)</MenuItem>
                        <MenuItem value="PACK">Pack</MenuItem>
                        <MenuItem value="SET">Set</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  <Box sx={{ mt: 1, minWidth: 3 / 4, width: "100%" }}>
                    <FormControl sx={{ mt: 1, minWidth: 3 / 4 }}>
                      <InputLabel id="inventory_unit">
                        Sell Quantity Unit / The Most Basic Unit in Which the
                        Product can be sold at any Store
                      </InputLabel>
                      <Select
                        labelId="sell_unit"
                        id="product_inventory_sell_attribute.sell_unit"
                        required
                        value={sellUnit}
                        {...register(
                          "product_inventory_sell_attribute.sell_unit"
                        )}
                        label="Sell Quantity Unit / The Most Basic Unit in Which the Product can be sold at any Store"
                        onChange={handleChangeSellUnit}
                      >
                        <MenuItem value="UN">UN</MenuItem>
                        <MenuItem value="PC">Piece</MenuItem>
                        <MenuItem value="KG">Kg - Kilogram</MenuItem>
                        <MenuItem value="G">gram</MenuItem>
                        <MenuItem value="LB">Pound</MenuItem>
                        <MenuItem value="OZ">Ounce</MenuItem>
                        <MenuItem value="M">Meter</MenuItem>
                        <MenuItem value="TON">Ton - (1000 Kgs)</MenuItem>
                        <MenuItem value="PACK">Pack</MenuItem>
                        <MenuItem value="SET">Set</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  <TextField
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    sx={{ mt: 1, minWidth: 3 / 4 }}
                    id="product_inventory_sell_attribute.price_sell_unit_x_inventory_unit_multiplier"
                    type="number"
                    inputProps={{ step: "1", lang: "en-US", min: 1 }}
                    label="Price Sell Unit x Inventory Unit Multiplier - Quantity in which the sell price is defined when compared to the quantity store in the inventory"
                    {...register(
                      `product_inventory_sell_attribute.price_sell_unit_x_inventory_unit_multiplier`
                    )}
                  />

                  <TextField
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    sx={{ mt: 1, minWidth: 3 / 4 }}
                    id="product_inventory_sell_attribute.minimum_sell_unit"
                    type="number"
                    inputProps={{ step: "1", lang: "en-US", min: 1 }}
                    label="Minimum Sell Unit - Smallest quantity that can be sold of the product."
                    {...register(
                      `product_inventory_sell_attribute.minimum_sell_unit`
                    )}
                  />

                  <TextField
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    sx={{ mt: 1, minWidth: 3 / 4 }}
                    id="product_inventory_sell_attribute.sell_quantity_multiple"
                    type="number"
                    inputProps={{ step: "1", lang: "en-US", min: 1 }}
                    label="Sell Quantity Multiple - Incremental Multiplier of how many items at once to be sold when the same makes the order on the online store."
                    {...register(
                      `product_inventory_sell_attribute.sell_quantity_multiple`
                    )}
                  />

                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.product_inventory_sell_attribute?.toString()}
                  </p>
                </Box>
              </TabPanel>
              <TabPanel
                value="7"
                sx={{
                  width: "100%",
                  typography: "body1",
                  maxWidth: "xl",
                  minWidth: 3 / 4,
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <AsyncPaginate
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Select Attributes/Filters"
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    additional={defaultAdditional}
                    key={"attributes_ids"}
                    id="attribute"
                    name="attribute"
                    getOptionValue={(option: any) => option?.id}
                    getOptionLabel={(option: any) => option?.name}
                    loadOptions={loadPageOptionsAttributes}
                    value={valueAttributes}
                    onChange={onChangeAttributes}
                  />

                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.attribute_ids?.toString()}
                  </p>

                  {valueAttributes.map((input, index) => {
                    return (
                      <p key={index}>
                        {" "}
                        Attribute ID: {input.id} / {input.name}
                      </p>
                    );
                  })}
                </Box>
              </TabPanel>

              <TabPanel
                value="8"
                sx={{
                  width: "100%",
                  typography: "body1",
                  maxWidth: "xl",
                  minWidth: 3 / 4,
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <AsyncPaginate
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Select Specifications"
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    additional={defaultAdditional}
                    key={"specifications"}
                    id="specification"
                    name="specification"
                    getOptionValue={(option: any) => option?.id}
                    getOptionLabel={(option: any) => option?.name}
                    loadOptions={loadPageOptionsSpecifications}
                    value={valueSpecifications}
                    onChange={onChangeSpecifications}
                  />
                  <div style={{ marginTop: "30px" }}>
                    {valueSpecifications.map((input, index) => {
                      return (
                        <p key={index}>
                          {" "}
                          Specification ID: {input.id} / {input.name}
                        </p>
                      );
                    })}
                  </div>

                  <div style={{ marginTop: "50px", width: "100%" }}>
                    {valueSpecifications.map((inputSpec, indexSpec) => {
                      return (
                        <div
                          style={{ marginBottom: "30px", width: "100%" }}
                          key={indexSpec}
                        >
                          <p>Specification {inputSpec.name}</p>
                          {inputsName.map((inputLanguage, indexLanguage) => {
                            return (
                              <div
                                className="flags-container"
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  marginTop: 0,
                                }}
                              >
                                <input
                                  hidden
                                  {...register(
                                    `specifications[${
                                      indexSpec * inputsName.length +
                                      indexLanguage
                                    }].specification_id`,
                                    {
                                      required: `specifications[${
                                        indexSpec * inputsName.length +
                                        indexLanguage
                                      }].specification_id is required`,
                                    }
                                  )}
                                  value={inputSpec.id}
                                />
                                <input
                                  hidden
                                  {...register(
                                    `specifications[${
                                      indexSpec * inputsName.length +
                                      indexLanguage
                                    }].language_id`,
                                    {
                                      required: `specifications[${
                                        indexSpec * inputsName.length +
                                        indexLanguage
                                      }].language_id is required`,
                                    }
                                  )}
                                  value={inputLanguage.language_id}
                                />
                                <span className="fa-flags">
                                  <img
                                    src={`/assets/language/${inputLanguage.language_id}.png`}
                                    alt=""
                                  />
                                </span>
                                <Box sx={{ width: "100%" }}>
                                  <TextField
                                    fullWidth
                                    sx={{
                                      mt: 1,
                                      minWidth: 3 / 4,
                                      width: "100%",
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    margin="normal"
                                    required
                                    label="Specification Name"
                                    id={
                                      "#specifications[" +
                                      (indexSpec * inputsName.length +
                                        indexLanguage) +
                                      "].name"
                                    }
                                    type="text"
                                    {...register(
                                      `specifications[${
                                        indexSpec * inputsName.length +
                                        indexLanguage
                                      }].name`,
                                      {
                                        required: `specifications[${
                                          indexSpec * inputsName.length +
                                          indexLanguage
                                        }].name is required`,
                                      }
                                    )}
                                  />
                                  <p
                                    style={{
                                      color: "red",
                                      textAlign: "center",
                                    }}
                                  >
                                    {errors?.specifications?.toString()}
                                  </p>
                                </Box>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </Box>
              </TabPanel>

              <Stack
                direction="row"
                spacing={2}
                sx={{
                  mt: 2,
                }}
              >
                <Button
                  href="/products"
                  variant="outlined"
                  startIcon={<ArrowBackIosNewIcon />}
                >
                  Go Back
                </Button>
                <Button
                  onClick={() => reset()}
                  variant="outlined"
                  startIcon={<DeleteIcon />}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  size="large"
                  endIcon={<SendIcon />}
                  disabled={circularLoading}
                  type="submit"
                >
                  Send
                  {circularLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: green[500],
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Button>
              </Stack>
              <div style={{ marginTop: "100px" }}></div>
            </Box>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );
}
