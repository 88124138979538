/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Container,
  Pagination,
  Box,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import TableHorizontal from "../../components/TableHorizontal";
import api from "../../services/api";
function ListBrands() {
  const [pageState, setPageState] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [brands, setBrands] = useState([]);
  const columns = ["ID", "Name", "Image", "Action", "Delete"];
  const keys = ["id", "name", "image", "action", "delete"];

  const fetchData = async (
    page = 1,
    language = 1,
    numberItemsPerPage = 500
  ) => {
    await api
      .get(
        `/brands?page=${page}&limit=${numberItemsPerPage}&language_id=${language}`
      )
      .then((res) => {
        setBrands(res.data.items);
        setTotalPages(res.data.total_pages);
      });
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageState(value);
    fetchData(value, Number(language), Number(numberItemsPerPage));
  };
  const [numberItemsPerPage, setNumberItemsPerPage] = useState("500");
  const handleNumberItemsPerPage = (event: SelectChangeEvent) => {
    setNumberItemsPerPage(event.target.value);
    fetchData(1, Number(language), Number(event.target.value));
  };

  const [language, setLanguage] = useState("0");
  const handleChangeLanguage = (event: SelectChangeEvent) => {
    setLanguage(event.target.value);
    fetchData(1, Number(event.target.value), Number(numberItemsPerPage));
  };

  const [languages, setLanguages] = useState<{ id: number; name: string }[]>(
    []
  );
  const fetchLanguages = async () => {
    const response = await api.get(`languages`);
    setLanguages(response.data.items);
    setLanguage("1");
  };

  useEffect(() => {
    fetchData();
    fetchLanguages();
  }, []);
  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box 
        sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
          >
      <InputLabel id="number-items-page-label">
        Number of Items per Page
      </InputLabel>
      <Select
        labelId="number-items-page-select-label"
        id="number-items-page"
        value={numberItemsPerPage}
        label="Number of Items per Page"
        onChange={handleNumberItemsPerPage}
      >
        <MenuItem value={100}>100</MenuItem>
        <MenuItem value={250}>250</MenuItem>
        <MenuItem value={500}>500</MenuItem>
        <MenuItem value={750}>750</MenuItem>
        <MenuItem value={1000}>1000</MenuItem>
      </Select>
      </Box>
      <Box>
      <InputLabel id="language-label">Language</InputLabel>
      <Select
        labelId="language-select-label"
        id="language"
        value={language}
        label="Language"
        onChange={handleChangeLanguage}
      >
        <MenuItem value={0}>None</MenuItem>
        {languages.map((language, index) => (
          <MenuItem value={language.id} key={index}>
            {language.name}
          </MenuItem>
        ))}
      </Select>
      </Box>
      </Box>
      <TableHorizontal
        screen={"brands"}
        columns={columns}
        data={brands}
        objectKey={keys}
      />
      <Box
        display="flex"
        width={"100%"}
        height={80}
        alignItems="center"
        justifyContent="center"
      >
        <Pagination
          count={totalPages}
          page={pageState}
          onChange={handleChange}
        />
      </Box>
    </Container>
  );
}
export default ListBrands;
