import { useEffect, useState } from "react";
import { Container, Pagination, Box } from "@mui/material";
import TableHorizontal from "../../components/TableHorizontal";
import api from "../../services/api";
function ListLanguages() {
  const [pageState, setPagepageState] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [users, setUsers] = useState([]);
  const columns = [
    "ID",
    "Name",
    "Code",
    "Locale",
    "Image",
    "Status",
    "Actions",
    "Delete",
  ];
  const keys = [
    "id",
    "name",
    "code",
    "locale",
    "image",
    "status_text",
    "action",
    "delete",
  ];
  const fetchData = async (page = 1) => {
    await api.get(`/languages?page=${page}&limit=200`).then((res) => {
      setUsers(res.data.items);
      setTotalPages(res.data.total_pages);
    });
  };
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPagepageState(value);
    fetchData(value);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Container maxWidth="xl" sx={{ mt: 2, mb: 4 }}>
      <TableHorizontal
        screen={"languages"}
        columns={columns}
        data={users}
        objectKey={keys}
      />
      <Box
        display="flex"
        width={"100%"}
        height={80}
        alignItems="center"
        justifyContent="center"
      >
        <Pagination
          count={totalPages}
          page={pageState}
          onChange={handleChange}
        />
      </Box>
    </Container>
  );
}
export default ListLanguages;
