/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import api from "../../../../services/api";
import { green } from "@mui/material/colors";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import { LogTrackUser } from "../../../../types/log-track-user/log-track-user";
import Cookies from "js-cookie";

export default function EditSuppliers() {
  const [circularLoading, setCircularLoading] = React.useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);
  const { register, reset, handleSubmit } = useForm();
  const { id } = useParams<{ id: string }>();
  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////

  // const [supplier, setSupplier] = useState("");
  const onSubmit = (supplier: any) => {
    setCircularLoading(true);
    supplier.quantity = Number(supplier.quantity);

    api
      .put(`suppliers/${id}`, supplier)
      .then((response) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Suppliers updated!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        // reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "" + error.response.data.message,
          footer: '<a href="">Why do I have this issue?</a>',
        });
        setCircularLoading(false);
        setRequestError(true); //trocado por false
        setRequestErrorData(error.response.data.message);
      });
  };

  // const handleChangeSupplier = (event: SelectChangeEvent) => {
  //   setSupplier(event.target.value as string);
  // };
  //esse fetch pega o valor do id do country_id para chamar no select
  const fetchSupplier = async () => {
    const response = await api.get(`suppliers/${id}`);
    setCountry(response.data.country_id);
    reset(response.data);
  };
  const [country, setCountry] = useState("");
  const handleChangeCountry = (event: SelectChangeEvent) => {
    setCountry(event.target.value as string);
  };

  const [countries, setCountries] = React.useState<
    { id: number; name: string }[]
  >([]);
  const fetchCountry = async () => {
    const response = await api.get("countries");
    setCountries(response.data.items);
  };

  useEffect(() => {
    fetchCountry();
    fetchSupplier();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Container component="main" maxWidth="lg">
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginLeft: "20px",
          }}
        >
          {" "}
          <Typography
            component="h1"
            variant="h5"
            sx={{ fontSize: "32px", marginBottom: "15px", color: "#556cd6" }}
          >
            Edit Suppliers {id}
          </Typography>
          {requestError && (
            <List component="nav" aria-label="erros">
              {requestErrorData.map((error, index) => (
                <ListItem key={index}>
                  <ListItemText
                    sx={{
                      color: "red",
                    }}
                    primary={error}
                  />
                </ListItem>
              ))}
            </List>
          )}
          <FormControl
            sx={{ m: 5, minWidth: 3 / 4 }} // equivalent to width: '75%' - usar fracao para %
            size="small"
          >
            <TextField
              margin="normal"
              InputLabelProps={{ shrink: true }}
              required
              fullWidth
              id="name"
              type="string"
              label="Name"
              {...register("name", {})}
              autoFocus
            />
            <TextField
              margin="normal"
              InputLabelProps={{ shrink: true }}
              required
              fullWidth
              id="website"
              type="string"
              label="website"
              {...register("website", {})}
              autoFocus
            />
            <TextField
              margin="normal"
              InputLabelProps={{ shrink: true }}
              required
              fullWidth
              id="image"
              type="string"
              label="image"
              {...register("image", {})}
              autoFocus
            />
            <TextField
              margin="normal"
              InputLabelProps={{ shrink: true }}
              required
              fullWidth
              id="phone_number"
              type="string"
              label="phone_number"
              {...register("phone_number", {})}
              autoFocus
            />
            <TextField
              margin="normal"
              InputLabelProps={{ shrink: true }}
              required
              fullWidth
              id="email"
              type="string"
              label="email"
              {...register("email", {})}
              autoFocus
            />
            <TextField
              margin="normal"
              InputLabelProps={{ shrink: true }}
              required
              fullWidth
              id="responsible_salesperson"
              type="string"
              label="responsible_salesperson"
              {...register("responsible_salesperson", {})}
              autoFocus
            />

            <Box>
              <FormControl fullWidth>
                <InputLabel id="country-label">Country</InputLabel>
                <Select
                  labelId="country-select-label"
                  id="country"
                  value={country}
                  {...register("country_id")}
                  label="Country"
                  onChange={handleChangeCountry}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {countries.map((country, index) => (
                    <MenuItem value={country.id} key={index}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                minWidth: 3 / 4,
                marginBottom: "30px",
              }}
            >
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  mt: 2,
                }}
              >
                <Button
                  sx={{ marginLeft: "40px" }}
                  onClick={() => reset()}
                  variant="outlined"
                  startIcon={<DeleteIcon />}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  size="large"
                  endIcon={<SendIcon />}
                  disabled={circularLoading}
                  type="submit"
                >
                  Send
                  {circularLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: green[500],
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Button>
              </Stack>
            </Box>
          </FormControl>
        </Box>
      </Container>
    </div>
  );
}
