/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Container,
  Typography,
  Stack,
  List,
  ListItemText,
  ListItem,
  InputLabel,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import api from "../../../../../services/api";
import Swal from "sweetalert2";
import { useLocation, useParams } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import { LogTrackUser } from "../../../../../types/log-track-user/log-track-user";
import Cookies from "js-cookie";

export default function EditProductParentChild() {
  const [circularLoading, setCircularLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);
  const { id } = useParams<{ id: string }>();
  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////
  const {
    //register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const defaultAdditional = {
    page: 1,
  };

  const loadOptionsParentProducts = async (search: any, page: any) => {
    const results = await api.get(
      `/products-custom/parent-products?page=${page}&search_sku=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsParentProducts = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsParentProducts(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadOptionsChildProducts = async (search: any, page: any) => {
    const results = await api.get(
      `/products-custom/notparent-products?page=${page}&search_sku=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsChildProducts = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsChildProducts(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const [initialParentProductID, setInitialParentProductID] = useState("");
  const [initialChildProductID, setInitialChildProductID] = useState("");
  const [parentProduct, setParentProduct] = useState<{
    id: number;
    sku: string;
  } | null>(null);
  const [childProduct, setChildProduct] = useState<{
    id: number;
    sku: string;
  } | null>(null);

  const loadOptionsParentChildProductsOptions = async (
    search: any,
    page: any
  ) => {
    const results = await api.get(
      `/product-parent-child-options?page=${page}&search_option_name=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: [{ id: 0, name: "None" }, ...results.data.items],
      //options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsParentChildProductsOptions = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsParentChildProductsOptions(
      q,
      page
    );
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const [level1Option, setLevel1Option] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [level2Option, setLevel2Option] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [level3Option, setLevel3Option] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const onSubmit = async (data: any) => {
    setCircularLoading(true);

    data.parent_product_id = Number(parentProduct?.id);
    data.child_product_id = Number(childProduct?.id);

    if (level1Option?.id !== 0) {
      data.level1_option_id = Number(level1Option?.id);
    } else {
      data.level1_option_id = null;
    }
    if (level2Option?.id !== 0) {
      data.level2_option_id = Number(level2Option?.id);
    } else {
      data.level2_option_id = null;
    }
    if (level3Option?.id !== 0) {
      data.level3_option_id = Number(level3Option?.id);
    } else {
      data.level3_option_id = null;
    }

    //IT CANNOT HAVE SAME Parent Product ID and Child Product ID. Child Product ID MUST BE different
    //IN OTHER WORDS, Child Product ID MUSTBE UNIQUE for the specific Parent Product ID
    //Check if exists current parent_product_id and child_product_id at the product_parent_child table
    let results;
    try {
      results = await api.get(
        `/product-parent-childs/checkExists?parent_product_id=${parentProduct?.id}&child_product_id=${childProduct?.id}`
      );
    } catch (error: any) {
      //throw new NotFoundException(error.message)
      return;
    }

    //First, Checks if its different than the initial
    if (
      initialParentProductID !== data.parent_product_id ||
      initialChildProductID !== data.child_product_id
    ) {
      // THen after, if it already exists at the table
      if (results.data) {
        setCircularLoading(false);
        setRequestError(true);
        Swal.fire({
          title:
            "Parent Product and Child Product Relation ALREADY exists at the Product Parent Child Table! It cannot be inserted AGAIN",
          icon: "error",
          confirmButtonText: "NO",
        });
        return;
      }
    }

    data.updated_at = new Date().toISOString();

    api
      .put(`product-parent-childs/${id}`, data)
      .then((response) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Product Parent Child Updated!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        //reset();
      })
      .catch((error) => {
        Swal.fire({
          title: "Oops...",
          text: error.response.data.message,
          confirmButtonText: "Yes",
        });
        setCircularLoading(false);
        setRequestError(true);
        setRequestErrorData(error.response.data.message);
      });
  };

  const fetchProductParentChild = async () => {
    const response = await api.get(`product-parent-childs/${id}`);
    reset(response.data);

    //ParentProduct
    setParentProduct({
      id: response.data.parent_product.id,
      sku: response.data.parent_product.sku,
    });
    setInitialParentProductID(response.data.parent_product.id);
    //ChildProduct
    setChildProduct({
      id: response.data.child_product.id,
      sku: response.data.child_product.sku,
    });
    setInitialChildProductID(response.data.child_product.id);
    //Level1Option
    if (response.data.level1_option_id) {
      setLevel1Option({
        id: response.data.level1_option.id,
        name: response.data.level1_option.descriptions[0].name,
      });
    }
    //Level2Option
    if (response.data.level2_option_id) {
      setLevel2Option({
        id: response.data.level2_option.id,
        name: response.data.level2_option.descriptions[0].name,
      });
    }
    //Level3Option
    if (response.data.level3_option_id) {
      setLevel3Option({
        id: response.data.level3_option.id,
        name: response.data.level3_option.descriptions[0].name,
      });
    }
  };

  useEffect(() => {
    fetchProductParentChild();
  }, []);

  return (
    <Container component="main" maxWidth="xl">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{ fontSize: "40px", marginBottom: "10px" }}
        >
          Edit Product Parent Child {id}
        </Typography>
        {requestError && (
          <List component="nav" aria-label="BackEnd Return">
            {requestErrorData.map((error) => (
              <ListItem>
                <ListItemText
                  sx={{
                    color: "red",
                  }}
                  primary={error}
                />
              </ListItem>
            ))}
          </List>
        )}
        <Box
          component="form"
          sx={{ mt: 1, minWidth: 3 / 4, width: "100%" }}
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* Parent Product */}
          <div style={{ width: "75%" }}>
            <InputLabel id="parent-product-select">
              Select Parent Product (Only Products with product_type = 2 -
              Parent Products)
            </InputLabel>
            <AsyncPaginate
              placeholder="Select Parent Product (Only Products with product_type = 2 - Parent Products)"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              additional={defaultAdditional}
              key={"parent_product_id"}
              id="parent_product"
              name="parent_product"
              getOptionValue={(option: any) => option?.id}
              getOptionLabel={(option: any) => option?.sku}
              loadOptions={loadPageOptionsParentProducts}
              value={parentProduct}
              onChange={setParentProduct}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.parent_product_id?.toString()}
            </p>
          </div>

          {/* Child Product */}
          <div style={{ width: "75%" }}>
            <InputLabel id="child-product-select">
              Select Child Product (Only Products with product_type different
              than 2 - Child Able Products)
            </InputLabel>
            <AsyncPaginate
              placeholder="Select Child Product"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              additional={defaultAdditional}
              key={"child_product_id"}
              id="child_product"
              name="child_product"
              getOptionValue={(option: any) => option?.id}
              getOptionLabel={(option: any) => option?.sku}
              loadOptions={loadPageOptionsChildProducts}
              value={childProduct}
              onChange={setChildProduct}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.child_product_id?.toString()}
            </p>
          </div>

          {/* level1_option */}
          <div style={{ width: "75%" }}>
            <InputLabel id="level1-option-select">Level 1 Option</InputLabel>
            <AsyncPaginate
              placeholder="Select Level 1 Option"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              additional={defaultAdditional}
              key={"level1_option_id"}
              id="level1_option"
              name="level1_option"
              getOptionValue={(option: any) => option?.id}
              getOptionLabel={(option: any) => option?.name}
              loadOptions={loadPageOptionsParentChildProductsOptions}
              value={level1Option}
              onChange={setLevel1Option}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.level1_option_id?.toString()}
            </p>
          </div>

          {/* level2_option */}
          <div style={{ width: "75%" }}>
            <InputLabel id="level2-option-select">Level 2 Option</InputLabel>
            <AsyncPaginate
              placeholder="Select Level 2 Option"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              additional={defaultAdditional}
              key={"level2_option_id"}
              id="level2_option"
              name="level2_option"
              getOptionValue={(option: any) => option?.id}
              getOptionLabel={(option: any) => option?.name}
              loadOptions={loadPageOptionsParentChildProductsOptions}
              value={level2Option}
              onChange={setLevel2Option}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.level2_option_id?.toString()}
            </p>
          </div>

          {/* level3_option */}
          <div style={{ width: "75%" }}>
            <InputLabel id="level3-option-select">Level 3 Option</InputLabel>
            <AsyncPaginate
              placeholder="Select Level 3 Option"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              additional={defaultAdditional}
              key={"level3_option_id"}
              id="level3_option"
              name="level3_option"
              getOptionValue={(option: any) => option?.id}
              getOptionLabel={(option: any) => option?.name}
              loadOptions={loadPageOptionsParentChildProductsOptions}
              value={level3Option}
              onChange={setLevel3Option}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.level3_option_id?.toString()}
            </p>
          </div>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              sx={{
                mt: 2,
              }}
            >
              <Button
                href="/products/product-parent-childs"
                variant="outlined"
                startIcon={<ArrowBackIosNewIcon />}
              >
                Go Back
              </Button>

              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                size="large"
                endIcon={<SendIcon />}
                disabled={circularLoading}
                type="submit"
              >
                Send
                {circularLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
