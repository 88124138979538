/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Stack,
  ListItem,
  ListItemText,
  List,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import api from "../../../../../services/api";
import Swal from "sweetalert2";
import { inputsName } from "../../../../../config/languageConst";
import { useLocation, useParams } from "react-router-dom";
import { LogTrackUser } from "../../../../../types/log-track-user/log-track-user";
import Cookies from "js-cookie";
export default function Editdata() {
  const { id } = useParams<{ id: string }>();

  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////
  const [circularLoading, setCircularLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data: any) => {
    setCircularLoading(true);
    for (let index = 0; index < data.descriptions.length; index++) {
      data.descriptions[index].category_id = Number(id);
      data.descriptions[index].language_id = Number(
        data.descriptions[index].language_id
      );
      data.descriptions[index].updated_at = new Date().toISOString();
    }
    data.decimal_place = Number(data.decimal_place);
    data.updated_at = new Date().toISOString();
    //{data.status === 1 ? status_text = 'Enabled' : data.status = 'Disabled'}
    //data.image = "";
    //user.role_id = Number(user.role_id);
    api
      .put(`currencies/${id}`, data)
      .then(() => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Currencies updated!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        //reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "" + error.response.data.message,
          footer: '<a href="">Why do I have this issue?</a>',
        });
        setCircularLoading(false);
        setRequestError(true);
        setRequestErrorData(error.response.data.message);
      });
  };

  const fetchdata = async () => {
    const response = await api.get(`currencies/${id}`);
    reset(response.data);
  };

  useEffect(() => {
    fetchdata();
  }, []);
  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          color: "#556cd6",
        }}
      >
        <Typography component="h1" variant="h5">
          Edit Currency {id}
        </Typography>
        {requestError && (
          <List component="nav" aria-label="errors">
            {requestErrorData.map((error) => (
              <ListItem key={error}>
                <ListItemText
                  sx={{
                    color: "red",
                  }}
                  primary={error}
                />
              </ListItem>
            ))}
          </List>
        )}
        {/* name code symbol_ left symbol symbol_right": "",
			"decimal_symbol": ".",
			"decimal_place*/}
        <Box
          component="form"
          sx={{ mt: 1, minWidth: 3 / 4 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
            fullWidth
            id="code"
            type="text"
            label="Currency Code"
            {...register("code")}
            autoFocus
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.code?.toString()}
          </p>
          <TextField
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
            fullWidth
            id="symbol_left"
            type="text"
            label="Symbol left"
            {...register("symbol_left")}
            autoFocus
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.symbol_left?.toString()}
          </p>

          <TextField
            margin="normal"
            InputLabelProps={{ shrink: true }}
            fullWidth
            id="symbol_right"
            type="text"
            label="Symbol right"
            {...register("symbol_right")}
            autoFocus
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.symbol_right?.toString()}
          </p>

          <TextField
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
            fullWidth
            id="decimal_symbol"
            type="text"
            label="Decimal symbol"
            {...register("decimal_symbol")}
            autoFocus
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.decimal_symbol?.toString()}
          </p>
          <TextField
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
            fullWidth
            id="decimal_place"
            type="number"
            label="Decimal Place"
            {...register("decimal_place")}
            autoFocus
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.decimal_place?.toString()}
          </p>

          <div style={{ marginTop: "30px" }}>
            {inputsName.map((input, index) => (
              <div
                className="flags-container"
                style={{ display: "block", marginTop: "50px" }}
                key={index}
              >
                <div className="fa-flags">
                  <img
                    src={`/assets/language/${input.language_id}.png`}
                    alt=""
                  />
                  &nbsp;-&nbsp;{input.language_name}
                </div>
                <input
                  hidden
                  {...register(`descriptions[${index}].language_id`, {})}
                  value={input.language_id}
                />
                <TextField
                  sx={{ mt: 1, minWidth: 3 / 4 }}
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  required
                  fullWidth
                  id={"#descriptions[" + index + "].name"}
                  type="text"
                  label="Name"
                  {...register(`descriptions[${index}].name`, {})}
                />
                <p style={{ color: "green", textAlign: "center" }}>
                  {errors?.name?.toString()}
                </p>
              </div>
            ))}
          </div>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              mt: 2,
            }}
          >
            <Button
              size="large"
              sx={{ marginTop: "10px", marginBottom: "10px" }}
              href="/currencies"
            >
              Back
            </Button>
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              size="large"
              endIcon={<SendIcon />}
              disabled={circularLoading}
              type="submit"
            >
              Send
              {circularLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>
          </Stack>
          <div style={{ marginTop: "100px" }}></div>
        </Box>
      </Box>
    </Container>
  );
}
