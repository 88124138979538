/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  TextField,
  Stack,
  List,
  ListItem,
  ListItemText,
  Container,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import Swal from "sweetalert2";
import { AsyncPaginate } from "react-select-async-paginate";
import { inputsName } from "../../../config/languageConst";

function NewAttribute() {
  const [circularLoading, setCircularLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [valueAttributeGroup, setValueAttributeGroup] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const loadOptionsAttributeGroups = async (search: any, page: any) => {
    const results = await api.get(
      `/attribute-groups?page=${page}&search=${search}`
    );

    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsAttributeGroups = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsAttributeGroups(q, page);

    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  };

  const onSubmit = (attribute: any) => {
    setCircularLoading(true);

    attribute.attribute_group_id = valueAttributeGroup?.id; // IT MUST BE DEFINED

    for (let index = 0; index < attribute.descriptions.length; index++) {
      attribute.descriptions[index].language_id = Number(
        attribute.descriptions[index].language_id
      );
    }

    api
      .post("attributes", attribute)
      .then((res) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Attribute created!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        //reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "" + error.response.data.message,
          footer: error.response,
        });
        setCircularLoading(false);
        setRequestError(true);
        setRequestErrorData(error.response.data.message);
      });
  };

  const defaultAdditional = {
    page: 1,
  };

  useEffect(() => {}, []);

  return (
    <Container component="main" maxWidth="xl">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{ fontSize: "32px", marginBottom: "10px" }}
        >
          New Attribute
        </Typography>
        {requestError && (
          <List component="nav" aria-label="BackEnd Return">
            {requestErrorData.map((error) => (
              <ListItem key={error}>
                <ListItemText
                  sx={{
                    color: "red",
                  }}
                  primary={error}
                />
              </ListItem>
            ))}
          </List>
        )}
        <Box
          component="form"
          sx={{ mt: 1, minWidth: 3 / 4, width: "100%" }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <AsyncPaginate
            placeholder="Select Attribute Group"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"attribute_group_id"}
            id="attribute_group"
            name="attribute_group"
            value={valueAttributeGroup}
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.name} //.attribute_group_descriptions[0]
            loadOptions={loadPageOptionsAttributeGroups}
            onChange={setValueAttributeGroup}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.defaultAdditional?.toString()}
          </p>
          <div style={{ marginTop: "15px" }}>
            {inputsName.map((input, index) => (
              <div key={index} className="flags-container">
                <input
                  hidden
                  {...register(`descriptions[${index}].language_id`)}
                  value={input.language_id}
                />
                <span className="fa-flags">
                  <img
                    src={`/assets/language/${input.language_id}.png`}
                    alt=""
                  />
                </span>
                <TextField
                  fullWidth
                  label="Name"
                  {...register(`descriptions[${index}].name`)}
                />
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors?.descriptions?.toString()}
                </p>
              </div>
            ))}
          </div>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              mt: 2,
            }}
          >
            <Button
              onClick={() => reset()}
              variant="outlined"
              startIcon={<DeleteIcon />}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              size="large"
              endIcon={<SendIcon />}
              disabled={circularLoading}
              type="submit"
            >
              Send
              {circularLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
}
export default NewAttribute;
