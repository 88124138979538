/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Pagination,
  SelectChangeEvent,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { LogTrackUser } from "../../../types/log-track-user/log-track-user";
import api from "../../../services/api";
import TableHorizontal from "../../../components/TableHorizontal";
import { AsyncPaginate } from "react-select-async-paginate";
import Cookies from "js-cookie";

export default function Orders() {
  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////

  const [pageState, setPageState] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [orders, setOrders] = useState([]);
  const columns = [
    "Edit Order",
    "Update Status",
    "ID",
    "Store",
    "Currency",
    "Customer",
    "Total",
    "Order Status",
    "Shiping Method",
    "Tracking",
    "Last Updated",
    "Subtotal",
    "Discount",
    "Shipping Cost",
    "Disc. Payment Method",
    "Customer Email",
    "Payment Method",
    "Payment Date",
    "Dest. Country",
    "Est. Delivery",

    "Cashier",
    "Seller",
  ];
  const keys = [
    "action",
    "update_order_status",
    "id",
    "store",
    "currency",
    "customer_name",
    "total",
    "order_status",
    "shipping_method",
    "shipping_info_tracking_code",
    "when",
    "subtotal",
    "discount",
    "shipping_cost",
    "discount_payment_method",
    "customer_email",
    "payment_method",
    "payment_info_date",
    "shipping_address_country",
    "shipping_info_delivery_time",

    "cashier",
    "seller",
  ];
  const fetchData = async (page = 1, numberItemsPerPage = 200) => {
    await api
      .get(
        `/orders?page=${page}&limit=${numberItemsPerPage}&store_id=${
          storeFilter?.id || ""
        }`
      )
      .then((res) => {
        setOrders(res.data.items);
        setTotalPages(res.data.total_pages);
      });
  };
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageState(value);
    fetchData(value, Number(numberItemsPerPage));
  };

  const [numberItemsPerPage, setNumberItemsPerPage] = useState("500");
  const handleNumberItemsPerPage = (event: SelectChangeEvent) => {
    setNumberItemsPerPage(event.target.value);
    fetchData(1, Number(event.target.value));
  };

  const defaultAdditional = {
    page: 1,
  };

  const loadOptionsStores = async (search: any, page: any) => {
    const results = await api.get(`/stores?page=${page}&search=${search}`);
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsStores = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsStores(q, page);
    if (options.length > 0) {
      options.unshift({ id: 0, name: "None" });
    }
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadOptionsCurrencies = async (search: any, page: any) => {
    const results = await api.get(
      `/currencies?page=${page}&search_name=${search}`
    );

    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsCurrencies = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsCurrencies(q, page);
    if (options.length > 0) {
      options.unshift({ id: 0, name: "None" });
    }
    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  };

  const loadOptionsCustomers = async (search: any, page: any) => {
    const results = await api.get(`/customers?page=${page}&search=${search}`);

    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsCustomers = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsCustomers(q, page);
    if (options.length > 0) {
      options.unshift({ id: 0, email: "None", first_name: "", last_name: "" });
    }
    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  };

  const loadOptionsOrderStatus = async (search: any, page: any) => {
    const results = await api.get(
      `/order-status?page=${page}&search=${search}`
    );

    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsOrderStatus = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsOrderStatus(q, page);
    if (options.length > 0) {
      options.unshift({ id: 0, code: "None" });
    }
    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  };

  const loadOptionsCashier = async (search: any, page: any) => {
    const results = await api.get(`/cashiers?page=${page}&search=${search}`);

    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsCashier = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsCashier(q, page);
    if (options.length > 0) {
      options.unshift({ id: 0, code: "None" });
    }
    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  };

  const loadOptionsSeller = async (search: any, page: any) => {
    const results = await api.get(`/sellers?page=${page}&search=${search}`);

    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsSeller = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsSeller(q, page);
    if (options.length > 0) {
      options.unshift({ id: 0, code: "None" });
    }
    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  };

  const loadOptionsPaymentMethod = async (search: any, page: any) => {
    const results = await api.get(
      `/payment-methods?page=${page}&search=${search}`
    );

    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsPaymentMethod = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsPaymentMethod(q, page);
    if (options.length > 0) {
      options.unshift({ id: 0, code: "None" });
    }
    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  };

  const loadOptionsShippingMethod = async (search: any, page: any) => {
    const results = await api.get(
      `/shipping-methods?page=${page}&search=${search}`
    );

    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsShippingMethod = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsShippingMethod(q, page);
    if (options.length > 0) {
      options.unshift({ id: 0, code: "None" });
    }
    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  };

  const loadOptionsCountries = async (search: any, page: any) => {
    const results = await api.get(
      `/countries?page=${page}&limit=0&search=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsCountries = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsCountries(q, page);
    if (options.length > 0) {
      options.unshift({ id: 0, name: "None" });
    }
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const [orderFilter, setOrderFilter] = useState("");
  const [storeFilter, setStoreFilter] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [currencyFilter, setCurrencyFilter] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [customerIDFilter, setCustomerIDFilter] = useState<{
    id: number;
    email: string;
    first_name: string;
    last_name: string;
  } | null>(null);
  const [customerFilter, setCustomerFilter] = useState("");
  const [orderStatusFilter, setOrderStatusFilter] = useState<{
    id: number;
    code: string;
  } | null>(null);
  const [cashierFilter, setCashierFilter] = useState<{
    id: number;
    code: string;
  } | null>(null);
  const [sellerFilter, setSellerFilter] = useState<{
    id: number;
    code: string;
  } | null>(null);
  const [paymentMethodFilter, setPaymentMethodFilter] = useState<{
    id: number;
    code: string;
  } | null>(null);
  const [shippingMethodFilter, setShippingMethodFilter] = useState<{
    id: number;
    code: string;
  } | null>(null);
  const [subtotalMinFilter, setSubtotalMinFilter] = useState(0);
  const [subtotalMaxFilter, setSubtotalMaxFilter] = useState(0);
  const [discountMinFilter, setDiscountMinFilter] = useState(0);
  const [discountlMaxFilter, setDiscountMaxFilter] = useState(0);
  const [shippingCostMinFilter, setShippingCostMinFilter] = useState(0);
  const [shippingCostMaxFilter, setShippingCostMaxFilter] = useState(0);
  const [totalMinFilter, setTotalMinFilter] = useState(0);
  const [totalMaxFilter, setTotalMaxFilter] = useState(0);
  // const [dateMinFilter, setDateMinFilter] = useState<Date>(); // Date Filter will not be used for now
  // Backend Error when null
  // const [dateMaxFilter, setDateMaxFilter] = useState<Date>(); // Date Filter will not be used for now

  const [shippingCountryFilter, setShippingCountryFilter] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [shippingStateFilter, setShippingStateFilter] = useState("");
  const [shippingCityFilter, setShippingCityFilter] = useState("");

  // const [shippedDateMinFilter, setShippedDateMinFilter] = useState<Date>(); // Date Filter will not be used for now
  // const [shippedDateMaxFilter, setShippedDateMaxFilter] = useState<Date>(); // Date Filter will not be used for now

  // const [search, setSearch] = useState("");

  const handleSearchClick = () => {
    fetchData(1);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container
      component="main"
      maxWidth="xl"
      sx={{
        marginTop: 4,
      }}
    >
      <Typography
        component="h1"
        variant="h5"
        sx={{ fontSize: "32px", marginBottom: "10px" }}
      >
        Orders
      </Typography>
      {/*       All Filters available on the BackEnd
      order_id?: string
      store_id?: number
      currency_id?: number
      customer_id?: number
      customer?: string
      order_status_id?: number
      cashier_id?: number
      seller_id?: number
      payment_method_id?: number
      shipping_method_id?: number
      subtotal_min?: number
      subtotal_max?: number
      discount_min?: number
      discount_max?: number
      shipping_cost_min?: number
      shipping_cost_max?: number
      total_min?: number
      total_max?: number
      date_min?: Date
      date_max?: Date

      shipping_country_id?: number
      shipping_state?: string
      shipping_city?: string

      shipped_date_min?: Date
      shipped_date_max?: Date

      search?: string */}
      <InputLabel id="filters">Filters:</InputLabel>
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="number-items-page-label">
            Number of Items per Page
          </InputLabel>
          <Select
            labelId="number-items-page-select-label"
            id="number-items-page"
            value={numberItemsPerPage}
            label="Number of Items per Page"
            onChange={handleNumberItemsPerPage}
          >
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={250}>250</MenuItem>
            <MenuItem value={500}>500</MenuItem>
            <MenuItem value={750}>750</MenuItem>
            <MenuItem value={1000}>1000</MenuItem>
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <InputLabel id="order-id-filter-label">Order ID</InputLabel>
          <TextField
            // sx={{ minWidth: 1 / 4, marginLeft: "8px" }}
            id={"order-id-filter-input"}
            type="text"
            label="Order ID"
            defaultValue={orderFilter}
            onBlur={(e) => setOrderFilter(e.target.value)}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="store-filter-label">Store</InputLabel>
          <AsyncPaginate
            placeholder="Select Store"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"productstore_store"}
            id="productstore_store"
            name="productstore_store"
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.name}
            loadOptions={loadPageOptionsStores}
            value={storeFilter}
            onChange={setStoreFilter}
          />
          {/*           <Select
            labelId="store-select-label"
            id="store_id"
            value={storeFilter}
            label="Store"
            onChange={(e) => setStoreFilter(Number(e.target.value))}
          >
            <MenuItem key={0} value={0}>
              None
            </MenuItem>
            {stores.map((store, index) => (
              <MenuItem key={index} value={store.id}>
                {store.name}
              </MenuItem>
            ))}
          </Select> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="currency-filter-select">Currency</InputLabel>
          <AsyncPaginate
            placeholder="Select Preferred Currency"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"currency_id"}
            id="currency"
            name="currency_id"
            value={currencyFilter}
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.name}
            loadOptions={loadPageOptionsCurrencies}
            onChange={setCurrencyFilter}
          />
        </Box>
        <Button
          variant="contained"
          sx={{ m: 3 }}
          href=""
          onClick={handleSearchClick}
        >
          Filter
        </Button>
      </Box>
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="customer-id-filter-select">Customer</InputLabel>
          <AsyncPaginate
            placeholder="Select Customer"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"customer_id"}
            id="customer"
            name="customer_id"
            value={customerIDFilter}
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) =>
              option?.email +
              " / " +
              option?.first_name +
              " " +
              option?.last_name
            } //.customer_group_descriptions[0]
            loadOptions={loadPageOptionsCustomers}
            onChange={setCustomerIDFilter}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <InputLabel id="customer-filter-label">
            Customer Name / E-mail:
          </InputLabel>
          <TextField
            // sx={{ minWidth: 1 / 4, marginLeft: "8px" }}
            id={"customer-filter-input"}
            type="text"
            label="Customer Name / E-mail"
            defaultValue={customerFilter}
            onBlur={(e) => setCustomerFilter(e.target.value)}
          />
        </Box>
      </Box>
      <Box style={{ display: "flex", flexDirection: "row", marginBottom: 8 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="order-status-filter-select">Order Status</InputLabel>
          <AsyncPaginate
            placeholder="Select Order Status"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"order_status"}
            id="order_status"
            name="order_status"
            value={orderStatusFilter}
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.code}
            loadOptions={loadPageOptionsOrderStatus}
            onChange={setOrderStatusFilter}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="cashier-filter-select">Cashier</InputLabel>
          <AsyncPaginate
            placeholder="Select Cashier"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"cashier"}
            id="cashier"
            name="cashier"
            value={cashierFilter}
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.code}
            loadOptions={loadPageOptionsCashier}
            onChange={setCashierFilter}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="seller-filter-select">Seller</InputLabel>
          <AsyncPaginate
            placeholder="Select Seller"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"seller"}
            id="seller"
            name="seller"
            value={sellerFilter}
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.code}
            loadOptions={loadPageOptionsSeller}
            onChange={setSellerFilter}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="payment-method-filter-select">
            Payment Method
          </InputLabel>
          <AsyncPaginate
            placeholder="Select Payment Method"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"payment_method"}
            id="payment_method"
            name="payment_method"
            value={paymentMethodFilter}
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.code}
            loadOptions={loadPageOptionsPaymentMethod}
            onChange={setPaymentMethodFilter}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="shipping-method-filter-select">
            Shipping Method
          </InputLabel>
          <AsyncPaginate
            placeholder="Select Shipping Method"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"shipping_method"}
            id="shipping_method"
            name="shipping_method"
            value={shippingMethodFilter}
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.code}
            loadOptions={loadPageOptionsShippingMethod}
            onChange={setShippingMethodFilter}
          />
        </Box>
      </Box>
      <Box style={{ display: "flex", flexDirection: "row", marginBottom: 8 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <TextField
            // sx={{ minWidth: 1 / 4, marginLeft: "8px" }}
            id={"subTotal-min-filter-input"}
            type="number"
            label="SubTotal Min. (0=Deactivated)"
            defaultValue={subtotalMinFilter}
            onBlur={(e) => setSubtotalMinFilter(Number(e.target.value))}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <TextField
            // sx={{ minWidth: 1 / 4, marginLeft: "8px" }}
            id={"subTotal-max-filter-input"}
            type="number"
            label="SubTotal Max. (0=Deactivated)"
            defaultValue={subtotalMaxFilter}
            onBlur={(e) => setSubtotalMaxFilter(Number(e.target.value))}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <TextField
            // sx={{ minWidth: 1 / 4, marginLeft: "8px" }}
            id={"discount-min-filter-input"}
            type="number"
            label="Discount Min. (0=Deactivated)"
            defaultValue={discountMinFilter}
            onBlur={(e) => setDiscountMinFilter(Number(e.target.value))}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <TextField
            // sx={{ minWidth: 1 / 4, marginLeft: "8px" }}
            id={"discount-max-filter-input"}
            type="number"
            label="Discount Max. (0=Deactivated)"
            defaultValue={discountlMaxFilter}
            onBlur={(e) => setDiscountMaxFilter(Number(e.target.value))}
          />
        </Box>
      </Box>
      <Box style={{ display: "flex", flexDirection: "row", marginBottom: 8 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <TextField
            // sx={{ minWidth: 1 / 4, marginLeft: "8px" }}
            id={"shipping-cost-min-filter-input"}
            type="number"
            label="Shipping Cost Min. (0=Deactivated)"
            defaultValue={shippingCostMinFilter}
            onBlur={(e) => setShippingCostMinFilter(Number(e.target.value))}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <TextField
            // sx={{ minWidth: 1 / 4, marginLeft: "8px" }}
            id={"shipping-cost-max-filter-input"}
            type="number"
            label="Shipping Cost Max. (0=Deactivated)"
            defaultValue={shippingCostMaxFilter}
            onBlur={(e) => setShippingCostMaxFilter(Number(e.target.value))}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <TextField
            // sx={{ minWidth: 1 / 4, marginLeft: "8px" }}
            id={"total-min-filter-input"}
            type="number"
            label="Total Min. (0=Deactivated)"
            defaultValue={totalMinFilter}
            onBlur={(e) => setTotalMinFilter(Number(e.target.value))}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <TextField
            // sx={{ minWidth: 1 / 4, marginLeft: "8px" }}
            id={"total-max-filter-input"}
            type="number"
            label="Total Max. (0=Deactivated)"
            defaultValue={totalMaxFilter}
            onBlur={(e) => setTotalMaxFilter(Number(e.target.value))}
          />
        </Box>
      </Box>
      <Box style={{ display: "flex", flexDirection: "row", marginBottom: 8 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="shipping-country-filter-select">
            Shipping Destination Country
          </InputLabel>
          <AsyncPaginate
            placeholder="Select Shipping Destination Country"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"shipping_country"}
            id="shipping_country"
            name="shipping_country"
            value={shippingCountryFilter}
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.name}
            loadOptions={loadPageOptionsCountries}
            onChange={setShippingCountryFilter}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <TextField
            // sx={{ minWidth: 1 / 4, marginLeft: "8px" }}
            id={"shipping-state-filter-input"}
            type="text"
            label="Shipping Destination State"
            defaultValue={shippingStateFilter}
            onBlur={(e) => setShippingStateFilter(e.target.value)}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <TextField
            // sx={{ minWidth: 1 / 4, marginLeft: "8px" }}
            id={"shipping-city-filter-input"}
            type="text"
            label="Shipping Destination City"
            defaultValue={shippingCityFilter}
            onBlur={(e) => setShippingCityFilter(e.target.value)}
          />
        </Box>
      </Box>
      <Button variant="contained" sx={{ m: 3 }} href="/orders/new">
        New Order
      </Button>
      <TableHorizontal
        screen={"orders"}
        columns={columns}
        data={orders}
        objectKey={keys}
      />
      <Box
        display="flex"
        width={"100%"}
        height={80}
        alignItems="center"
        justifyContent="center"
      >
        <Pagination
          count={totalPages}
          page={pageState}
          onChange={handleChange}
        />
      </Box>
    </Container>
  );
}
