/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Stack,
  List,
  ListItemText,
  ListItem,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import api from "../../../../../services/api";
import Swal from "sweetalert2";
import { inputsName } from "../../../../../config/languageConst";
import { useLocation, useParams } from "react-router-dom";
import { LogTrackUser } from "../../../../../types/log-track-user/log-track-user";
import Cookies from "js-cookie";

export default function EditCustomerGroup() {
  const { id } = useParams<{ id: string }>();
  const [circularLoading, setCircularLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////
  const [requestErrorData, setRequestErrorData] = useState([]);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [status, setStatus] = useState(1);

  const onSubmit = (data: any) => {
    setCircularLoading(true);

    data.default_points_multiplier = Number(data.default_points_multiplier);
    data.default_orders_total_multiplier = Number(
      data.default_orders_total_multiplier
    );
    data.status = Number(data.status);

    for (let index = 0; index < data.descriptions.length; index++) {
      data.descriptions[index].language_id = Number(
        data.descriptions[index].language_id
      );
    }

    api
      .put(`customer-groups/${id}`, data)
      .then((response) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Customer Group Updated!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        //reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "" + error.response.data.message,
          // footer: '<a href="">Why do I have this issue?</a>',
        });
        setCircularLoading(false);
        setRequestError(true);
        setRequestErrorData(error.response.data.message);
      });
  };

  const fetchCustomerGroup = async () => {
    const response = await api.get(`customer-groups/${id}`);
    setStatus(response.data.status);
    reset(response.data);
  };

  useEffect(() => {
    fetchCustomerGroup();
  }, []);

  return (
    <Container component="main" maxWidth="xl">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{ fontSize: "40px", marginBottom: "10px" }}
        >
          Edit Customer Group {id}
        </Typography>
        {requestError && (
          <List component="nav" aria-label="BackEnd Return">
            {requestErrorData.map((error) => (
              <ListItem key={error}>
                <ListItemText
                  sx={{
                    color: "red",
                  }}
                  primary={error}
                />
              </ListItem>
            ))}
          </List>
        )}
        <Box
          component="form"
          sx={{ mt: 1, minWidth: 3 / 4, width: "100%" }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormControl fullWidth>
            <InputLabel id="status-select">Status</InputLabel>
            <Select
              labelId="status-select-label"
              id="status"
              value={status}
              {...register("status")}
              label="Status"
              onChange={(e) => setStatus(Number(e.target.value))}
            >
              <MenuItem value={1}>Enabled</MenuItem>
              <MenuItem value={0}>Disabled</MenuItem>
            </Select>
          </FormControl>
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.status?.toString()}
          </p>
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <TextField
              margin="normal"
              required
              sx={{ mt: 1, minWidth: 2 / 4, width: "50%" }}
              id="discount"
              type="number"
              label="Default Points Multiplier"
              defaultValue={1}
              {...register("default_points_multiplier", {})}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.default_points_multiplier?.toString()}
            </p>

            <TextField
              margin="normal"
              required
              sx={{ mt: 1, minWidth: 2 / 4, width: "50%" }}
              id="discount"
              type="number"
              label="Default Orders Total Multiplier"
              defaultValue={1}
              {...register("default_orders_total_multiplier", {})}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.default_orders_total_multiplier?.toString()}
            </p>
          </Box>

          <div style={{ marginTop: "15px" }}>
            <Box sx={{ width: "100%", typography: "body1" }}>
              {inputsName.map((input, index) => (
                <Box
                  key={index}
                  className="flags-container"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <div
                      className="fa-flags"
                      style={{
                        height: "50px",
                        width: "200px",
                        marginTop: "10px",
                      }}
                    >
                      <img
                        src={`/assets/language/${input.language_id}.png`}
                        alt=""
                      />
                      &nbsp;-&nbsp;{input.language_name}
                    </div>
                    <input
                      hidden
                      {...register(`descriptions[${index}].language_id`, {
                        required: `descriptions[${index}].language_id is required`,
                      })}
                      value={input.language_id}
                    />

                    <TextField
                      sx={{ mt: 1, minWidth: 3 / 4, marginLeft: "8px" }}
                      InputLabelProps={{ shrink: true }}
                      margin="normal"
                      required
                      fullWidth
                      id={index.toString()}
                      type="text"
                      label="Customer Group Title"
                      {...register(`descriptions[${index}].name`)}
                    />
                    <p style={{ color: "red", textAlign: "center" }}>
                      {errors?.descriptions?.toString()}
                    </p>
                  </Box>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    margin="normal"
                    fullWidth
                    id={"#descriptions[" + index + "].description"}
                    type="text"
                    label="Description - Shown at the Website at the Customer Info Page"
                    rows={5}
                    multiline
                    {...register(`descriptions[${index}].description`)}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.descriptions?.toString()}
                  </p>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    margin="normal"
                    fullWidth
                    id={"#descriptions[" + index + "].comments"}
                    type="text"
                    label="Comments - Internal Comments Section"
                    rows={5}
                    multiline
                    {...register(`descriptions[${index}].comments`)}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.descriptions?.toString()}
                  </p>
                </Box>
              ))}
            </Box>
          </div>

          <Stack
            direction="row"
            spacing={2}
            sx={{
              mt: 2,
            }}
          >
            <Button
              onClick={() => reset()}
              variant="outlined"
              startIcon={<DeleteIcon />}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              size="large"
              endIcon={<SendIcon />}
              disabled={circularLoading}
              type="submit"
            >
              Send
              {circularLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
}
