/* eslint-disable react-hooks/exhaustive-deps */

import { Box, TextField, Grid, Typography, CardMedia } from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../../../services/api";
import { useLocation, useParams } from "react-router-dom";
import { LogTrackUser } from "../../../../../types/log-track-user/log-track-user";
import Cookies from "js-cookie";

type ProductDescription = {
  product_id: number;
  language_id: number;
  name: string;
  description?: string;
  seo_keyword?: string;
  seo_url?: string;
  updated_at?: Date;
  created_at?: Date;
};

type ProductMeasurement = {
  product_id: number;
  weight: string;
  width: string;
  height: string;
  length: string;
  updated_at?: Date;
  created_at?: Date;
};

type Images = {
  product_id: number;
  image: string;
  sort_order?: number;
  updated_at?: Date;
  created_at?: Date;
};

type ProductCategoriesDescription = {
  category_id: number;
  language_id: number;
  name: string;
  meta_keyword: string;
  image: string;
  content_html: string;
  seo_url: string;
  created_at: Date;
  updated_at: Date;
};

type ProductCategories = {
  id: number;
  parent_id?: number;
  created_at: Date;
  updated_at: Date;
  descriptions: ProductCategoriesDescription[];
};

type ProductBrandDescriptions = {
  brand_id: number;
  language_id: number;
  name: string;
  content_html: string;
  meta_keyword: string;
  seo_url: string;
  created_at: Date;
  updated_at: Date;
};

type ProductBrand = {
  id: number;
  image: string;
  updated_at?: Date;
  created_at?: Date;
  descriptions?: ProductBrandDescriptions[];
};

type Product = {
  id: number;
  ysin: string;
  sku: string;
  barcode?: string;
  barcode_type?: string;
  image?: string;
  image2?: string;
  brand_id?: number;
  descriptions: ProductDescription[];
  product_measurement: ProductMeasurement;
  images?: Images[];
  categories?: ProductCategories[];
  brand?: ProductBrand;
  updated_at?: Date;
  created_at?: Date;
};

export default function ExportProductEbay() {
  const { id } = useParams<{ id: string }>();
  const [imageBaseURL, setimageBaseURL] = useState(
    process.env.REACT_APP_IMG_CATALOG_URL
  );

  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////

  const [bannerImageLink] = useState<string>(
    "https://s3.us-east-2.amazonaws.com/static.ystevo.com/assets/brvintages/images/LogoFinalBoth800pxHeight.png"
  );

  const [exportText, setExportText] = useState<string>("");

  const [product, setProduct] = useState<Product | null>(null);

  const [imagesConst, setImagesConst] = useState<string[]>([]);

  const [valueAttributes, setValueAttributes] = useState<
    { id: number; name: string }[]
  >([]);
  const [valueAttributesExtraInfo, setValueAttributesExtraInfo] = useState<
    {
      id: number;
      name: string;
      attribute_group_id: number;
      attribute_group_name: string;
    }[]
  >([]);

  const setAttributes = async (attributes: any) => {
    attributes.map((input: any, index: number) => {
      const insert = { id: input.id, name: input.descriptions[0].name };
      const insertExtra = {
        id: input.id,
        name: input.descriptions[0].name,
        attribute_group_id: input.attribute_group_id,
        attribute_group_name: input.attribute_group.descriptions[0].name,
      };
      valueAttributes.push(insert);
      setValueAttributes(Array.from(new Set(valueAttributes)));

      valueAttributesExtraInfo.push(insertExtra);
      setValueAttributesExtraInfo(
        Array.from(new Set(valueAttributesExtraInfo))
      );

      //setValueAttributes(valueAttributes);
      return setValueAttributes(Array.from(new Set(valueAttributes)));
    });
  };

  const fetchProduct = async () => {
    //setimageBaseURL(imageBaseURL);
    if (imageBaseURL?.substring(imageBaseURL?.length - 1) !== "/") {
      setimageBaseURL(imageBaseURL + "/");
    }

    const response = await api.get(`products-custom/${id}`);
    /*     if (response.data.product_inventory_sell_attribute) {
      response.data.product_inventory_sell_attribute.price_sell_unit_x_inventory_unit_multiplier = String(response.data.product_inventory_sell_attribute.price_sell_unit_x_inventory_unit_multiplier);
      response.data.product_inventory_sell_attribute.minimum_sell_unit = String(response.data.product_inventory_sell_attribute.minimum_sell_unit);
      response.data.product_inventory_sell_attribute.sell_quantity_multiple = String(response.data.product_inventory_sell_attribute.sell_quantity_multiple);
    } */

    setProduct(response.data);

    const images_temp = [];
    images_temp.push(response.data.image);
    images_temp.push(response.data.image2);
    for (var j = 0; j < response.data.images.length; j++) {
      images_temp.push(response.data.images[j].image);
    }

    setImagesConst(images_temp);

    setAttributes(response.data.attributes);

    // console.log(response.data);
  };

  const createEbayTemplate = async () => {
    let text = `<img src="${bannerImageLink}" "="" width="100%" border="0">\n`;
    text += "<br><br>\n";
    text +=
      "<p>Welcome to BR Vintages, your premier destination for classic car parts and vehicles, specializing in iconic Volkswagen (VW) models. Immerse yourself in the world of automotive nostalgia with our curated collection. What sets BR Vintages apart is our dedication to providing unique VW parts at the best prices in the market. Whether you're restoring a vintage gem or adding to your collection, we offer a wide range of meticulously sourced parts, components and iconic vehicles. Our team of experts is committed to providing a personalized and customer-centric experience. Explore the elegance, charm, and craftsmanship of classic cars with BR Vintages—where the past meets the present, and driving becomes a celebration of history, style, and Classic Cars excellence.</p>";
    text += "<br>\n";
    text += `<table id="outer-table">
    <tbody><tr style="vertical-align:top;">
    <td>`;
    text += `<img src="${imageBaseURL}${product?.image}" width="500px">\n`;
    text += `</td>
    <td>
    <table id="inner-table">
    <tbody><tr id="desc-row">
    <td id="desc-box" colspan="3" style="width:50px">\n`;
    text += `<div style="font-size:1.7em; font-weight:bold; margin-bottom:.5em;">${product?.descriptions[0].name}</div>\n`;
    text += `<div>Sku # ${product?.sku}</div>\n`;
    text += `</td>
    </tr>
    <tr id="detail-row" style="background:#FF0000; color:white;">
    <td id="condition-cell" style="padding:.5em;font-size:.8em">
    <div style="font-weight:bold">Condition</div>
    <div>New</div>
    </td>
    <td id="brand-cell" style="padding:.5em;font-size:.8em">
    <div style="font-weight:bold">Brand</div>
    <div>BR Vintages</div>
    </td>
    <td id="uom-cell" style="padding:.5em;font-size:.8em">
    <div style="font-weight:bold">Size</div>`;
    text += `<div>${Number(product?.product_measurement?.length) / 10}cm x ${
      Number(product?.product_measurement?.width) / 10
    }cm (${Math.round(
      Number(product?.product_measurement?.length) / 25.2
    )}in x ${Math.round(
      Number(product?.product_measurement?.width) / 25.2
    )}in)</div>\n`;
    text += `</td>
    </tr>\n`;
    for (var i = 0; i < imagesConst.length; i++) {
      if (i % 3 === 0) {
        if (i > 0) {
          text += `</tr>\n`;
        }
        text += `<tr id="image-row-1" class="image-row">\n`;
      }
      text += `<td style="width:150px;height:150px;padding:0px">\n`;
      text += `<img src="${imageBaseURL}${imagesConst[i]}" width="150px">\n`;
      text += `</td>\n`;
    }
    text += `</tr>\n`;
    text += `</tbody></table> <!-- end inner table -->
    </td>
    </tr>
    </tbody></table> <!-- end outer table -->
    <br>
    <br><br>\n`;
    text += `<p>${String(product?.descriptions[0]?.description)
      .replace(/ \\ r \\ n/g, "<br>")
      .replace(/\\r\\n/g, "<br>")
      .replace(/(?:\r\n|\r|\n)/g, "<br>")}</p>/n`;
    text += `<br>
    <br>
    <br>
    <img src="${bannerImageLink}" alt="Footer" width="100%" border="0">\n`;
    text += `<br><br><br>
    <p><b>CONDITIONS:</b></p>
    <p><b>Shipping Policy:</b></p>
    <p>In order to have faster service please pay for all items at the same time of purchase. If shipping rates do not combine properly, contact us or send a buyer requested total. You must contact us before making payment to combine your items. Failure to do so will result in separate shipping fees for each individual item. I am sorry but cannot combine your order if you have already paid.</p>
    <br>
    <p><b>Returns:</b></p>
    <p>We want you to be satisfied with your purchase. If within 60 days after you receive your shipment and you are not satisfied, you may return the item for a refund or an exchange. Please note all exchanged or returned merchandise must be in original factory condition with no modifications or alterations. You must ship orders back prepaid. We do not accept COD packages.  All exchanges need to have reshipping charges included. Please note there is no returns on special order, custom made, or closeout merchandise</p>
    <br>
    <p><b>Warranty:</b></p>
    <p>For warranty information please contact the product manufacturer directly. In some cases stock photos may not be an exact representation of the item. Please read all product info, descriptions and terms before purchasing.</p>
    <br>
    <p><b>Duties &amp; Taxes:</b></p>
    <p>You are responsible for all duties and taxes for your local area. This includes but not limited to international shipments. We can not quote you these fees, so you need to contact your local tax authority to confirm the amounts if any in your area.</p>
    <br>
    <img src="${bannerImageLink}" alt="Footer" width="100%" border="0">`;

    setExportText(text);
  };

  useEffect(() => {
    // fetchSpecifications(); //It must be loaded first the specifications because it is made a loop based on the valueSpecifications first
    //fetchBrand();
    //fetchCategories();
    //fetchAttributes();
    fetchProduct();
  }, []);

  useEffect(() => {
    createEbayTemplate();
  }, [product]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <Typography
            component="h1"
            variant="h5"
            sx={{
              paddingTop: "40px",
              fontWeight: "bold",
              fontSize: "32px",
              color: "#556cd6",
              paddingLeft: "20px",
            }}
          >
            Export Product {id} for Ebay
          </Typography>
          <Box
            sx={{
              width: "100%",
              typography: "body1",
              maxWidth: "xl",
              minWidth: 3 / 4,
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "100%", typography: "body1" }}>
              <p>SKU: {product?.sku}</p>
              <p>Product: {product?.descriptions[0].name}</p>
              <CardMedia
                component="img"
                sx={{ width: 100, display: "inline" }}
                image={`${imageBaseURL}${product?.image}`}
                alt={"image"}
              />
              <p>Banner Image Link: {bannerImageLink}</p>
              <p>Preview:</p>
            </Box>

            <Box sx={{ width: "100%", typography: "body1" }}>
              <p>HTML Code:</p>
              <TextField
                sx={{ mt: 1, minWidth: 3 / 4, marginLeft: "8px" }}
                InputLabelProps={{ shrink: true }}
                margin="normal"
                fullWidth
                id="HTMLCode"
                type="text"
                label="HTML Code"
                multiline
                rows={30}
                value={exportText}
              />
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
