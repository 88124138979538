/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Container, Pagination, Box, Button, TextField } from "@mui/material";
import TableHorizontal from "../../components/TableHorizontal";
import api from "../../services/api";
function ListCompetitors() {
  const [pageState, setPageState] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [users, setUsers] = useState([]);
  const columns = [
    "ID",
    "Image",
    "Name",
    "Email",
    "Phone",
    "Responsible",
    "General Comments",
    "Actions",
    "Delete",
  ];
  const keys = [
    "id",
    "image",
    "name",
    "email",
    "phone_number",
    "responsible_salesperson",
    "general_comments",
    "action",
    "delete",
  ];
  const fetchData = async (page = 1, language = 1, numberItemsPerPage = 50) => {
    await api
      .get(
        `/competitors?page=${page}&limit=${numberItemsPerPage}&name=${namefilter}`
      )
      .then((res) => {
        setUsers(res.data.items);
        setTotalPages(res.data.total_pages);
      });
  };

  const [language] = useState("1");
  //eu tirei o setLangue pois nao estava usando kazuo
  const [namefilter, setNameFilter] = useState("");
  const handleChangeNamefilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameFilter(e.target.value);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageState(value);
    fetchData(value);
  };

  const handleFilterButtonClick = () => {
    fetchData(1, Number(language));
  };


  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 4, }}>
      <Box
      sx={{
        marginLeft: "8px",
      }}
      >
      <TextField
        //sx={{ mt: 1, minWidth: 3/4 }}
        margin="normal"
        //fullWidth
        id={"name"}
        type="text"
        label="Name Filter"
        value={namefilter}
        onChange={handleChangeNamefilter}
      />

      <Button
        variant="contained"
        sx={{ m: 3 }}
        href=""
        onClick={handleFilterButtonClick}
      >
        Filter
      </Button>
      </Box>
      <TableHorizontal
        screen={"competitors"}
        columns={columns}
        data={users}
        objectKey={keys}
      />
      <Box
        display="flex"
        width={"100%"}
        height={80}
        alignItems="center"
        justifyContent="center"
      >
        <Pagination
          count={totalPages}
          page={pageState}
          onChange={handleChange}
        />
      </Box>
    </Container>
  );
}
export default ListCompetitors;
