/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Container,
  Typography,
  Stack,
  List,
  ListItemText,
  ListItem,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import Swal from "sweetalert2";
import { AsyncPaginate } from "react-select-async-paginate";

export default function EditProductCombination() {
  const [circularLoading, setCircularLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const defaultAdditional = {
    page: 1,
  };

  const loadOptionsCombinationProducts = async (search: any, page: any) => {
    const results = await api.get(
      `/products-custom/combination-products?page=${page}&search_sku=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsCombinationProducts = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsCombinationProducts(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadOptionsItemProducts = async (search: any, page: any) => {
    const results = await api.get(
      `/products-custom/notparent-notcombination-products?page=${page}&search_sku=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsItemProducts = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsItemProducts(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const [combinationProduct, setCombinationProduct] = useState<{
    id: number;
    sku: string;
  } | null>(null);
  const [itemProduct, setItemProduct] = useState<{
    id: number;
    sku: string;
  } | null>(null);

  const [showCatalog, setShowCatalog] = useState(false);
  const handleChangeShowCatalog = (event: SelectChangeEvent) => {
    setShowCatalog(event.target.value as any);
  };

  const onSubmit = async (data: any) => {
    setCircularLoading(true);

    data.combination_product_id = Number(combinationProduct?.id);
    data.item_product_id = Number(itemProduct?.id);
    data.qty_in_combination = Number(data.qty_in_combination);
    data.show_catalog = Boolean(data.show_catalog);

    data.updated_at = new Date().toISOString();

    api
      .post(`product-combinations/`, data)
      .then((response) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Product Combination Created!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        //reset();
      })
      .catch((error) => {
        Swal.fire({
          title: "Ops...",
          icon: "error",
          text: error.response.data,
          confirmButtonText: "Yes",
        });
        setCircularLoading(false);
        setRequestError(true);
        setRequestErrorData(error.response.data);
      });
  };

  useEffect(() => {}, []);

  return (
    <Container component="main" maxWidth="xl">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{ fontSize: "32px", marginBottom: "15px", color: "#556cd6" }}
        >
          New Combination Product
        </Typography>
        {requestError && (
          <List component="nav" aria-label="BackEnd Return">
            {requestErrorData.map((error) => (
              <ListItem>
                <ListItemText
                  sx={{
                    color: "red",
                  }}
                  primary={error}
                />
              </ListItem>
            ))}
          </List>
        )}
        <Box
          component="form"
          sx={{ mt: 1, minWidth: 3 / 4, width: "100%" }}
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* Combination Product */}
          <div style={{ width: "75%" }}>
            <AsyncPaginate
              placeholder="Select Combination Product"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              additional={defaultAdditional}
              key={"combination_product_id"}
              id="combination_product"
              name="combination_product"
              getOptionValue={(option: any) => option?.id}
              getOptionLabel={(option: any) => option?.sku}
              loadOptions={loadPageOptionsCombinationProducts}
              value={combinationProduct}
              onChange={setCombinationProduct}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.combination_product_id?.toString()}
            </p>
          </div>
          <InputLabel
            id="combination-product-select"
            sx={{ marginLeft: "2px" }}
          >
            Only Products with product type = 4 - Combination Products
          </InputLabel>

          {/* Item Product */}
          <div style={{ width: "75%", marginTop: "16px" }}>
            <AsyncPaginate
              placeholder="Select item Product"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              additional={defaultAdditional}
              key={"item_product_id"}
              id="item_product"
              name="item_product"
              getOptionValue={(option: any) => option?.id}
              getOptionLabel={(option: any) => option?.sku}
              loadOptions={loadPageOptionsItemProducts}
              value={itemProduct}
              onChange={setItemProduct}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.item_product_id?.toString()}
            </p>
          </div>
          <InputLabel id="item-product-select" sx={{ marginLeft: "2px" }}>
            Only Products with product type different than 2 and 4 - Item Able
            Products
          </InputLabel>
          <div style={{ marginTop: "16px", width: "100%" }}></div>
          <TextField
            InputLabelProps={{ shrink: true }}
            required
            id={"qty_in_combination"}
            type="text"
            label="Quantity of Item in Combination"
            {...register(`qty_in_combination`)}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.qty_in_combination?.toString()}
          </p>

          {/* <Box
              sx={{
                minWidth: 1 / 4
              }}
              > */}
          <FormControl>
            <InputLabel id="show_catalog-select" sx={{ mt: "16px" }}>
              Show Catalog
            </InputLabel>
            <Select
              sx={{ marginTop: "20px" }}
              labelId="show_catalog-select-label"
              required
              id="show_image"
              value={showCatalog as any}
              {...register("show_catalog")}
              onChange={handleChangeShowCatalog}
            >
              <MenuItem value={true as any}>Enabled</MenuItem>
              <MenuItem value={false as any}>Disabled</MenuItem>
            </Select>
          </FormControl>
          {/* </Box> */}
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.showCatalog?.toString()}
          </p>

          <Stack
            direction="row"
            spacing={2}
            sx={{
              mt: 2,
            }}
          >
            <Button
              onClick={() => reset()}
              variant="outlined"
              startIcon={<DeleteIcon />}
            >
              Clear
            </Button>

            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              size="large"
              endIcon={<SendIcon />}
              disabled={circularLoading}
              type="submit"
            >
              Send
              {circularLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>
          </Stack>
          <div style={{ marginTop: "100px", width: "100%" }}></div>
        </Box>
      </Box>
    </Container>
  );
}
