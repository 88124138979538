/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SendIcon from "@mui/icons-material/Send";
import Swal from "sweetalert2";
import { inputsName } from "../../../config/languageConst";
import api from "../../../services/api";
import { green } from "@mui/material/colors";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import { LogTrackUser } from "../../../types/log-track-user/log-track-user";
import Cookies from "js-cookie";

export default function EditCoupons() {
  const { id } = useParams<{ id: string }>();
  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////
  const [circularLoading, setCircularLoading] = React.useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (coupon: any) => {
    setCircularLoading(true);
    for (let index = 0; index < coupon.descriptions.length; index++) {
      coupon.descriptions[index].language_id = Number(
        coupon.descriptions[index].language_id
      );
      coupon.descriptions[index].updated_at = new Date().toISOString();
    }
    coupon.quantity = Number(coupon.quantity);
    coupon.quantity_client = Number(coupon.quantity_client);
    coupon.discount_type = Number(coupon.discount_type);

    api
      .put(`coupons/${id}`, coupon)
      .then((response) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Coupon updated!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          footer: '<a href="">Why do I have this issue?</a>',
        });

        setCircularLoading(false);
        setRequestError(true);
        setRequestErrorData(error.response.data.message);
      });
  };

  const [type, setType] = React.useState("");
  const handleChange = (event: SelectChangeEvent) => {
    setType(event.target.value);
  };
  const [store, setStore] = useState("");
  const handleChangeStore = (event: SelectChangeEvent) => {
    setStore(event.target.value);
  };

  const [stores, setStores] = React.useState<{ id: number; name: string }[]>(
    []
  );
  const fetchStores = async () => {
    const response = await api.get("stores");
    setStores(response.data.items);
  };

  const fetchData = async () => {
    const response = await api.get(`coupons/${id}`);
    reset(response.data);
    setStore(response.data.store_id);
    setType(response.data.discount_type);
  };

  useEffect(() => {
    fetchData();
    fetchStores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Container component="main" maxWidth="lg">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          <Box
            sx={{
              lexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              sx={{ fontSize: "30px", marginBottom: "15px", color: "#556cd6" }}
            >
              Edit Coupon ({id})
            </Typography>
          </Box>
          {requestError && (
            <List component="nav" aria-label="erros">
              {requestErrorData.map((error) => (
                <ListItem key={error}>
                  <ListItemText
                    sx={{
                      color: "red",
                    }}
                    primary={error}
                  />
                </ListItem>
              ))}
            </List>
          )}
          <Box
            component="form"
            sx={{ mt: 1, minWidth: 3 / 4, width: "100%" }}
            onSubmit={handleSubmit(onSubmit)}
          ></Box>
          <FormControl
            sx={{ m: 5, minWidth: 3 / 4 }} // equivalent to width: '75%' - usar fracao para %
            size="small"
          >
            <InputLabel id="discount_type-select">Tipo de desconto</InputLabel>
            <Select
              sx={{ marginBottom: "30px" }}
              labelId="discount_type-select-label"
              required
              id="discount_type"
              value={type}
              label="Type"
              {...register("discount_type", {
                required: "discount_type is required",
              })}
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={0}>Dinheiro</MenuItem>
              <MenuItem value={1}>Porcentagem</MenuItem>
            </Select>

            <FormControl fullWidth>
              <InputLabel id="store-label">Store</InputLabel>
              <Select
                labelId="store-select-label"
                id="stores"
                value={store}
                {...register("store_id")}
                label="Store"
                onChange={handleChangeStore}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {stores.map((store, index) => (
                  <MenuItem value={store.id} key={index}>
                    {store.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              InputLabelProps={{ shrink: true }}
              margin="normal"
              required
              fullWidth
              id="coupon_code"
              type="text"
              label="Coupon code"
              {...register("coupon_code", {
                required: "coupon_code is required",
              })}
              autoFocus
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.coupon_code?.toString()}
            </p>

            <TextField
              InputLabelProps={{ shrink: true }}
              margin="normal"
              required
              fullWidth
              id="discount"
              type="text"
              label="Discount($)"
              {...register("discount", {
                required: "discount is required",
              })}
              autoFocus
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.discount?.toString()}
            </p>
            <TextField
              InputLabelProps={{ shrink: true }}
              margin="normal"
              required
              fullWidth
              id="quantity"
              type="number"
              label="Quantity Total"
              {...register("quantity", {
                required: "quantity is required",
              })}
              autoFocus
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.quantity?.toString()}
            </p>
            <TextField
              InputLabelProps={{ shrink: true }}
              margin="normal"
              required
              fullWidth
              id="quantity"
              type="number"
              label="Quantity Client"
              {...register("quantity_client", {
                required: "quantity_client is required",
              })}
              autoFocus
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.quantity_client?.toString()}
            </p>
            <Typography
              sx={{ fontSize: 18 }}
              color="text.secondary"
              align="center"
              gutterBottom
            ></Typography>
            <div style={{ width: "100%" }}>
              {inputsName.map((input, index) => (
                <div className="flags-container" key={index}>
                  <input
                    hidden
                    {...register(`descriptions[${index}].language_id`, {
                      required: `descriptions[${index}].language_id is required`,
                    })}
                    value={input.language_id}
                  />
                  <span className="fa-flags">
                    <img
                      src={`/assets/language/${input.language_id}.png`}
                      alt=""
                    />
                  </span>
                  <Box sx={{ width: "100%" }}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      sx={{ width: "100%" }}
                      label={input.language_name + " (Name)"}
                      {...register(`descriptions[${index}].name`)}
                    />
                  </Box>
                </div>
              ))}
            </div>
            <Typography
              sx={{ fontSize: 18, mt: 3 }}
              color="text.secondary"
              align="center"
              gutterBottom
            ></Typography>
            <Box>
              {inputsName.map((input, index) => (
                <div className="flags-container" key={index}>
                  <span className="fa-flags" style={{ height: 264 }}>
                    <img
                      src={`/assets/language/${input.language_id}.png`}
                      alt=""
                      style={{ paddingTop: 100 }}
                    />
                  </span>
                  <TextField
                    id="filled-multiline-static"
                    InputLabelProps={{ shrink: true }}
                    label={input.language_name + " (Description)"}
                    {...register(`descriptions[${index}].description`)}
                    multiline
                    rows={10}
                    variant="filled"
                    fullWidth
                  />
                </div>
              ))}
            </Box>
            <Box
              component="form"
              sx={{ mt: 1, minWidth: 3 / 4, width: "100%" }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  mt: 2,
                }}
              >
                <Button
                  size="large"
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                  href="/coupons"
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  size="large"
                  endIcon={<SendIcon />}
                  disabled={circularLoading}
                  type="submit"
                >
                  Send
                  {circularLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: green[500],
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Button>
              </Stack>
            </Box>
          </FormControl>
        </Box>
      </Container>
    </div>
  );
}
