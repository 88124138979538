import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Stack,
  List,
  ListItemText,
  ListItem,
  CardMedia,
  Tab,
  InputLabel,
  Select,
  MenuItem,
  AlertProps,
  Snackbar,
  Alert,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import api from "../../../../services/api";
import Swal from "sweetalert2";
import { useLocation, useParams } from "react-router-dom";
import { LogTrackUser } from "../../../../types/log-track-user/log-track-user";
import { TabContext, TabList, useTabContext } from "@mui/lab";
import { inputsName } from "../../../../config/languageConst";
import moment from "moment";
import getAvailableOrderStatus from "../../../../config/avaliableOrderStatus";
import { OrderStatusEnum } from "../../../../enums/order-status.enum";
import getOrderStatusName from "../../../../config/getOrderStatusName";
import Cookies from "js-cookie";

type PropsOrderInfo = {
  id: number;
  store_id: number;
  currency_id: number;
  customer_id: number;
  order_status_id: number;
  seller_id: number;
  cart_id: string;
  payment_method_id: number;
  shipping_method_id: number;
  subtotal: number;
  discount: number;
  taxes: number;
  shipping_cost: number;
  discount_payment_method: number;
  total: number;
  is_paid: boolean;
  created_at: Date;
  updated_at: Date;
  last_change_username: string;
};

type PropsProductDescription = {
  product_id: number;
  language_id: number;
  name: string;
  description: string;
  seo_keyword: string;
  seo_url: string;
};

type PropsProduct = {
  id: number;
  ysin: string;
  sku: string;
  barcode: string;
  barcode_type: string;
  image: string;
  iamge2: string;
  product_type: number;
  status: number;
  brand_id: number;
  descriptions: PropsProductDescription[];
};

type PropsProductCart = {
  id: number;
  product_id: number;
  image: string;
  sku: string;
  ysin: string;
  product_type: number;
  quantity: number;
  name: string;
  seo_url: string;
  store_id: number;
  store_name: string;
  store: string;
  store_url: string;
  currency_code: string;
  product_store_price: number;
  product_store_status: number;
  product_store_is_sale: boolean;
  product_store_price_sale: number;
  product_pdv_store_price: number;
  product_pdv_store_status: number;
  product_pdv_store_is_sale: boolean;
  product_pdv_store_price_sale: number;
  product_wholesale_store_price: number;
  product_wholesale_store_status: number;
  product_wholesale_store_is_sale: boolean;
  product_wholesale_store_price_sale: number;
  product_wholesale_pdv_store_price: number;
  product_wholesale_pdv_store_status: number;
  product_wholesale_pdv_store_is_sale: boolean;
  product_wholesale_pdv_store_price_sale: number;
  sell_unit: string;
  language_id: number;
  price_unit_sold: number;
  weight: number;
  height: number;
  width: number;
  length: number;
  product: PropsProduct;
};

type PropsStore = {
  id: number;
  name: string;
  currency_id: number;
  currency_code: string;
  store_country_id: number;
  store_warehouse_id: number;
  url: string;
  ssl: string;
  title: string;
  description: string;
  keywords: string;
  email: string;
  phone_number: string;
  phone_number2: string;
  image: string;
  schedule: string;
  logo: string;
  icon: string;
  wholesale_pdv_enabled: boolean;
  wholesale_pdv_minimum_total: number;
  wholesale_pdv_minimum_quantity_item: number;
  wholesale_store_enabled: boolean;
  wholesale_store_minimum_total: number;
  wholesale_store_minimum_quantity_item: number;
  country: string;
  currency: string;
  warehouse: string;
  latitude: number;
  longitude: number;
};

type PropsCustomer = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
};

type PropShippingPaymentAddress = {
  id: number;
  customer_id: number;
  country_id: number;
  country_code: string;
  name: string;
  phone_number: string;
  postal_code: string;
  address: string;
  number: string;
  address_2: string;
  district: string;
  city: string;
  state: string;
  additional_info: string;
};

export type PropsShippingVolume = {
  weight: number; // in Kgs
  height: number; // in mm (same as DB)
  width: number; // in mm (same as DB)
  length: number; // in mm (same as DB)
  value: number; // in store default currency
  quantity: number; // Quantity of products inside Shipping Volume
};

export type PropsShippingCosts = {
  shipping_method_id: number;
  code: string;
  language_id: number;
  deliverable_time: number;
  delivery_unit: string;
  cost: number;
  description_name: string;
  description: string;
  weight: number;
  height: number;
  width: number;
  length: number;
  value: number;
  tracking_code?: string;
  link_tracking?: string;
};

type PropsCurrency = {
  currency_id: number;
  code: string;
  value: number;
};

type PropsOrderStatusDescription = {
  order_status_id: number;
  language_id: number;
  name: string;
  description: string;
};

type PropsOrderStatus = {
  id: number;
  code: string;
  descriptions?: PropsOrderStatusDescription[];
};

type PropsOrderStatusHistory = {
  order_status_id: number;
  notify: boolean;
  comments_client: string;
  comments: string;
  order_status: PropsOrderStatus;
  created_at: Date;
  username: string;
};

//I HAD to declare TabPanel like this to prevent Lazy Loading on the TabPanels.
//DUe to Lazy loading, the data form the DB was not being filled into the fields in the other tabs
function TabPanel(props: any) {
  const { children, className, value, index, ...other } = props;

  const context = useTabContext();

  if (context === null) {
    throw new TypeError("No TabContext provided");
  }
  const tabId = context.value;

  return (
    <Typography
      component="div"
      role="tabpanel"
      padding="24px"
      hidden={value !== tabId}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      //visibility={value === tabId ? "visible" : "hidden"}
      {...other}
    >
      {children}
    </Typography>
  );
}

export default function NewOrderStatusHistory(): JSX.Element {
  const [circularLoading, setCircularLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);

  const [tabValue, setTabValue] = useState("1");
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { id } = useParams<{ id: string }>();

  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const [imageBaseURL, setimageBaseURL] = useState(
    process.env.REACT_APP_IMG_CATALOG_URL
  );

  const fetchImageFolder = async () => {
    if (imageBaseURL?.substring(imageBaseURL?.length - 1) !== "/") {
      setimageBaseURL(imageBaseURL + "/");
    }
  };

  const [store, setStore] = useState<PropsStore | null>(null);

  const [customer, setCustomer] = useState<PropsCustomer | null>(null);

  const [cashier, setCashier] = useState<{
    id: number;
    code: string;
    name: string;
    username: string;
  } | null>(null);

  const [seller, setSeller] = useState<{
    id: number;
    code: string;
    name: string;
    username: string;
  } | null>(null);

  const [cartSubTotal, setCartSubTotal] = useState(0);
  const [cartItensTotal, setItensTotal] = useState(0);
  const [products, setProducts] = useState<PropsProductCart[]>([]);

  const updateSubTotalProducts = async (products: PropsProductCart[]) => {
    if (products.length > 0) {
      var totalPerItem = products.map(
        (product) => product.quantity * product.price_unit_sold
      );
      var total = totalPerItem.reduce((a, b) => a + b);
      var totalItem = products.map((product) => product.quantity);
      var totalItens = totalItem.reduce((a, b) => a + b);
      setCartSubTotal(total);
      setItensTotal(totalItens);
      updateOrderTotal(total);

      //
    } else {
      setCartSubTotal(0);
      setItensTotal(0);
      updateOrderTotal(0);
    }
  };

  const [discountTotal, setDiscountTotal] = useState(0);

  const [taxesTotal, setTaxesTotal] = useState(0);

  const [shippingAddress, setShippingAddress] =
    useState<PropShippingPaymentAddress | null>(null);
  const [shippingInfo, setShippingInfo] = useState<PropsShippingCosts>();
  const [shippingCost, setShippingCost] = useState(0);

  /*   const [paymentAddress, setPaymentAddress] =
    useState<PropShippingPaymentAddress | null>(null);

  const [paymentMethod, setPaymentMethod] = useState(""); */
  const [discountPaymentTotal, setDiscountPaymentTotal] = useState(0);

  const [orderTotal, setOrderTotal] = useState(0);

  const [orderInfo, setOrderInfo] = useState<PropsOrderInfo>();

  const [orderCurrency, setOrderCurrency] = useState<PropsCurrency>();

  const [orderStatus, setOrderStatus] = useState<PropsOrderStatus>();

  const [orderStatusHistory, setOrderStatusHistory] = useState<
    PropsOrderStatusHistory[]
  >([]);

  const [notifyCustomer, setNotifyCustomer] = useState(true);

  const [newStatus, setNewStatus] = useState<Number>(0);

  const [availableNewStatus, setAvailableNewStatus] = useState<
    OrderStatusEnum[] | null
  >([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateOrderTotal = (cartSubTotal: number) => {
    setOrderTotal(
      cartSubTotal +
        discountTotal +
        taxesTotal +
        shippingCost +
        discountPaymentTotal
    );
  };

  const onSubmit = (orderStatusHistory: any) => {
    setCircularLoading(true);

    // It will be sent according to the CreateOrderStatusHistoryDto

    orderStatusHistory.order_id = id;
    orderStatusHistory.order_status_id = newStatus;
    orderStatusHistory.notify = notifyCustomer;

    orderStatusHistory.username = Cookies.get("username");

    api
      .post(`orders/status-history`, orderStatusHistory)
      .then((response) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Order Status History Created!",
          icon: "success",
          confirmButtonText: "Yes",
        }).then(() => {
          window.location.reload();
        });
        //reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "" + error.response.data.message,
          // footer: '<a href="">Why do I have this issue?</a>',
        });
        setCircularLoading(false);
        setRequestError(true);
        setRequestErrorData(error.response.data.message);
      });
  };

  // After Order is Made, CANNOT CHANGE THE FOLLOWING INFO
  // Store
  // Customer
  // Currency
  // Products
  const fetchOrder = async () => {
    const response = await api.get(`orders/${id}`);
    reset(response.data);
    setOrderInfo(response.data);
    setStore(response.data.store);
    setOrderCurrency(response.data.order_currency);
    setCustomer(response.data.customer);
    setOrderStatus(response.data.order_status);
    setCashier(response.data.cashier);
    setSeller(response.data.cashier);
    // setPaymentMethod(response.data.payment_method);
    setShippingAddress(response.data.order_shipping_address);

    setShippingInfo({
      ...response.data.order_shipping_info,
      description_name: response.data.shipping_method.descriptions[0].name,
      description: response.data.shipping_method.descriptions[0].description,
    });
    // setOrderCurrency(response.data.order_currency);

    // setPaymentAddress(response.data.order_payment_address);
    // setPaymentMethod(response.data.order_payment_info);
    // setUpdatedAt(response.data.updated_at);
    // Map response.data.products to be able to insert at setProducts
    // Load Products according to the function loadOptionsProductsStore
    const product_id_list = response.data.order_products.map(
      (product_mapped: any) =>
        //  product_id: number; image: string; sku: string
        product_mapped.product_id
    );

    setOrderStatusHistory(response.data.order_status_history);

    const responseProducts = await api.get(
      `product-stores/findallfororder/${product_id_list}`
    );

    const productsArray = responseProducts.data.map(
      (product_mapped: PropsProductCart, index: number) => ({
        ...product_mapped,
        quantity: response.data.order_products[index].quantity_sold,
        price_unit_sold: response.data.order_products[index].price_unit_sold,
      })
    );

    setProducts(productsArray);

    updateSubTotalProducts(productsArray);

    const discount = response.data.discount;
    if (discount) {
      setDiscountTotal(discount);
    }

    const taxes = response.data.taxes;
    if (taxes) {
      setTaxesTotal(taxes);
    }

    setShippingCost(Number(response.data.shipping_cost));

    const discountPayment = response.data.discount_payment_method;
    if (discountPayment) {
      setDiscountPaymentTotal(discountPayment);
    }

    // Available New Status
    setAvailableNewStatus(
      getAvailableOrderStatus(response.data.order_status_id)
    );
  };

  useEffect(() => {
    fetchImageFolder();
    fetchOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateOrderTotal(cartSubTotal);
  }, [cartSubTotal, updateOrderTotal]);

  //Flow of Page
  // USe Tabs
  // Store
  // Customer Info (Link to Create Customer in Case it is not registered, future do using Modal)

  return (
    <Container component="main" maxWidth="xl">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{ fontSize: "40px", marginBottom: "10px" }}
        >
          New Order Status History - Order ID: {id}
        </Typography>
        {requestError && (
          <List component="nav" aria-label="BackEnd Return">
            {requestErrorData.map((error) => (
              <ListItem key={error}>
                <ListItemText
                  sx={{
                    color: "red",
                  }}
                  primary={error}
                />
              </ListItem>
            ))}
          </List>
        )}
        <TabContext value={tabValue}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              maxWidth: "xl",
              minWidth: 3 / 4,
            }}
          >
            <TabList onChange={handleChangeTab} centered aria-label="tabs">
              <Tab label="Essential Info*" value="1" />
              <Tab label="Products*" value="2" />
              <Tab label="Shipping Info*" value="3" />
              <Tab label="Payment Info" value="4" />
              <Tab label="Totals/Checkout*" value="5" />

              {/* <Tab label="Status" value="5" /> */}
            </TabList>
          </Box>
          <Box
            component="form"
            sx={{
              mt: 1,
              minWidth: 3 / 4,
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            {/* Essential Info */}
            <TabPanel
              value="1"
              sx={{
                width: "100%",
                typography: "body",
                maxWidth: "xl",
                minWidth: 3 / 4,
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "8px",
                  marginLeft: 1,
                }}
              >
                <p>
                  Order Created At:{" "}
                  <b>
                    {moment(orderInfo?.created_at).format(
                      "DD/MM/YYYY hh:mm:ss"
                    )}
                  </b>{" "}
                  / Last Update At{" "}
                  <b>
                    {moment(orderInfo?.updated_at).format(
                      "DD/MM/YYYY hh:mm:ss"
                    )}
                  </b>{" "}
                  by <b>{orderInfo?.last_change_username}</b>
                </p>
                <br></br>
                <p>
                  Current Order Status: <b>{orderStatus?.code}</b>
                  <br></br>
                  {orderStatus ? (
                    orderStatus.descriptions ? (
                      <>
                        <b>{orderStatus?.descriptions[0].name}</b>
                        <br></br> {orderStatus?.descriptions[0].description}
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </p>
                <br></br>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "8px",
                  marginLeft: 1,
                }}
              >
                <p>
                  Store: <b>{store?.id}</b> / <b>{store?.name}</b>
                </p>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "8px",
                  marginLeft: 1,
                }}
              >
                <p>
                  Customer: <b>{customer?.id}</b> / <b>{customer?.email}</b> /{" "}
                  <b>
                    {customer?.first_name} {customer?.last_name}
                  </b>{" "}
                </p>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "8px",
                  marginLeft: 1,
                }}
              >
                {cashier ? (
                  <p>
                    Cashier: <b>{cashier?.id}</b> / <b>{cashier?.username}</b> /{" "}
                    <b>{cashier?.name}</b> / <b>Code: {cashier?.code}</b>{" "}
                  </p>
                ) : (
                  <></>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "8px",
                  marginLeft: 1,
                }}
              >
                {seller ? (
                  <p>
                    Seller: <b>{seller?.id}</b> / <b>{seller?.username}</b> /{" "}
                    <b>{seller?.name}</b> / <b>Code: {seller?.code}</b>{" "}
                  </p>
                ) : (
                  <></>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "8px",
                  marginLeft: 1,
                }}
              >
                <FormControlLabel
                  label="Notify Customer?"
                  control={
                    <Checkbox
                      checked={notifyCustomer || false}
                      onChange={(e) => setNotifyCustomer(e.target.checked)}
                      inputProps={{ "aria-label": "Notify Customer?" }}
                    />
                  }
                />
                <Select
                  labelId="new-status-select-label"
                  id="new-status-select"
                  value={newStatus}
                  onChange={(e) => setNewStatus(Number(e.target.value))}
                >
                  <MenuItem value={0}>None</MenuItem>
                  {availableNewStatus?.map((status, index) => {
                    return (
                      <MenuItem key={index} value={Number(status)}>
                        {getOrderStatusName(status)}
                      </MenuItem>
                    );
                  })}
                </Select>
                {newStatus === OrderStatusEnum.SHIPPED ||
                newStatus === OrderStatusEnum.SHIPPED_CORRECTION ? (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        marginRight: "8px",
                        marginLeft: 1,
                      }}
                    >
                      <TextField
                        sx={{ mt: 1, minWidth: 1 / 4, width: "25%" }}
                        // InputLabelProps={{ shrink: true }}
                        margin="normal"
                        fullWidth
                        id={"#tracking_code"}
                        type="text"
                        label="Tracking Code"
                        {...register(`tracking_code`)}
                      />
                      <TextField
                        sx={{ mt: 1, minWidth: 1 / 2, width: "75%" }}
                        // InputLabelProps={{ shrink: true }}
                        margin="normal"
                        fullWidth
                        id={"#link_tracking"}
                        type="text"
                        label="Link Tracking"
                        {...register(`link_tracking`)}
                      />
                    </Box>
                  </>
                ) : (
                  <></>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "8px",
                  marginLeft: 1,
                }}
              >
                <TextField
                  // sx={{ mt: 1, minWidth: 3 / 4 }}
                  // InputLabelProps={{ shrink: true }}
                  margin="normal"
                  fullWidth
                  multiline
                  rows={5}
                  id={"#comments"}
                  type="text"
                  label="Internal Comments Section -They are not send to the client"
                  {...register(`comments`)}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "8px",
                  marginLeft: 1,
                }}
              >
                <TextField
                  // sx={{ mt: 1, minWidth: 3 / 4 }}
                  // InputLabelProps={{ shrink: true }}
                  margin="normal"
                  fullWidth
                  multiline
                  rows={5}
                  id={"#comments_client"}
                  type="text"
                  label="Comments Client Section - These are sent to the client"
                  {...register(`comments_client`)}
                />
              </Box>
              <br></br>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "8px",
                  marginLeft: 1,
                }}
              >
                {" "}
                <Typography
                  component="h1"
                  variant="h5"
                  sx={{ fontSize: "40px", marginBottom: "10px" }}
                >
                  Order Status History
                </Typography>
                Use the fields above to Add a New Order History (Change its
                status, send info to customer, etc.) Status Menu
                <br></br>
                {orderStatusHistory.map((statusHistory, index) => {
                  return (
                    <p key={index}>
                      Status: <b>{statusHistory.order_status.code}</b>
                      <br></br>
                      Date Updated:{" "}
                      <b>
                        {moment(statusHistory.created_at).format(
                          "DD/MM/YYYY hh:mm:ss"
                        )}
                      </b>
                      <br></br>
                      Notify Customer: <b>{String(statusHistory.notify)}</b>
                      <br></br>
                      <b>Comments sent to Client: </b>
                      {statusHistory.comments_client}
                      <br></br>
                      <b>Comments: </b>
                      {statusHistory.comments}
                      <br></br>
                      <b>Changes made by Username: </b>
                      {statusHistory.username}
                      <br></br>
                      <br></br>
                    </p>
                  );
                })}
              </Box>
            </TabPanel>
            <TabPanel
              value="2"
              sx={{
                width: "100%",
                typography: "body",
                maxWidth: "xl",
                minWidth: 3 / 4,
                alignItems: "center",
              }}
            >
              <Box sx={{ width: "100%", typography: "body1" }}>
                <p>
                  To change the products, access the Order Edit Section:{" "}
                  <a href={`/orders/${id}`} target="_blank" rel="noreferrer">
                    Edit Order
                  </a>
                </p>
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors?.products?.toString()}
                </p>
                {products.map((input, index) => {
                  return (
                    <p key={index}>
                      {" "}
                      Product ID / SKU: {input.product_id} / {input.sku}
                    </p>
                  );
                })}
                <div style={{ marginTop: "50px", width: "100%" }}>
                  {orderCurrency &&
                    products.map((product, indexProduct) => {
                      return (
                        <Box key={indexProduct}>
                          <Box
                            key={indexProduct}
                            sx={{
                              width: "100%",
                              marginBottom: 0,
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                marginRight: "8px",
                                marginLeft: 1,
                                width: "20%",
                              }}
                            >
                              <div>
                                Product ID / SKU: {product.product_id} /{" "}
                                {product.sku}
                                <br></br>
                                <CardMedia
                                  component="img"
                                  sx={{ width: 151, display: "inline" }}
                                  image={`${imageBaseURL}${product.image}`}
                                  alt={"image"}
                                />
                              </div>
                            </Box>
                            <TextField
                              // ref={textFieldQtyRef[indexProduct]}
                              sx={{
                                mt: 1,
                                marginTop: 10,
                                minWidth: 1 / 10,
                                width: "10%",
                              }}
                              InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{ shrink: true }}
                              margin="normal"
                              required
                              label="Quantity"
                              id={String(indexProduct)}
                              type="number"
                              value={products[indexProduct].quantity}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                marginRight: "8px",
                                marginLeft: 1,
                                width: "5%",
                                marginTop: 12,
                                minWidth: 1 / 20,
                              }}
                            >
                              {product.sell_unit}
                            </Box>
                            <Box
                              sx={{
                                mt: 1,
                                marginTop: 5,
                                minWidth: 1 / 5,
                                width: "20%",
                              }}
                            >
                              <p>
                                Price Online:{" "}
                                {product.product_store_is_sale ? (
                                  <>
                                    <span
                                      style={{ textDecoration: "line-through" }}
                                    >
                                      {Number(
                                        product.product_store_price
                                      ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: orderCurrency?.code,
                                      })}
                                    </span>{" "}
                                    {Number(
                                      product.product_store_price_sale
                                    ).toLocaleString("en-US", {
                                      style: "currency",
                                      currency: orderCurrency?.code,
                                    })}
                                  </>
                                ) : (
                                  Number(
                                    product.product_store_price
                                  ).toLocaleString("en-US", {
                                    style: "currency",
                                    currency: orderCurrency?.code,
                                  })
                                )}
                              </p>
                              <p>
                                Price PDV:{" "}
                                {product.product_pdv_store_is_sale ? (
                                  <>
                                    <span
                                      style={{ textDecoration: "line-through" }}
                                    >
                                      {Number(
                                        product.product_pdv_store_price
                                      ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: orderCurrency?.code,
                                      })}
                                    </span>{" "}
                                    {Number(
                                      product.product_pdv_store_price_sale
                                    ).toLocaleString("en-US", {
                                      style: "currency",
                                      currency: orderCurrency?.code,
                                    })}
                                  </>
                                ) : (
                                  Number(
                                    product.product_pdv_store_price
                                  ).toLocaleString("en-US", {
                                    style: "currency",
                                    currency: orderCurrency?.code,
                                  })
                                )}
                              </p>
                              <p>
                                Price Wholesale Online:{" "}
                                {product.product_wholesale_store_is_sale ? (
                                  <>
                                    <span
                                      style={{ textDecoration: "line-through" }}
                                    >
                                      {Number(
                                        product.product_wholesale_store_price
                                      ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: orderCurrency?.code,
                                      })}
                                    </span>{" "}
                                    {Number(
                                      product.product_wholesale_store_price_sale
                                    ).toLocaleString("en-US", {
                                      style: "currency",
                                      currency: orderCurrency?.code,
                                    })}
                                  </>
                                ) : (
                                  Number(
                                    product.product_wholesale_store_price
                                  ).toLocaleString("en-US", {
                                    style: "currency",
                                    currency: orderCurrency?.code,
                                  })
                                )}
                              </p>
                              <p>
                                Price Wholesale PDV:{" "}
                                {product.product_wholesale_pdv_store_price_sale ? (
                                  <>
                                    <span
                                      style={{ textDecoration: "line-through" }}
                                    >
                                      {Number(
                                        product.product_wholesale_pdv_store_price
                                      ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: orderCurrency?.code,
                                      })}
                                    </span>{" "}
                                    {Number(
                                      product.product_wholesale_pdv_store_price_sale
                                    ).toLocaleString("en-US", {
                                      style: "currency",
                                      currency: orderCurrency?.code,
                                    })}
                                  </>
                                ) : (
                                  Number(
                                    product.product_wholesale_pdv_store_price
                                  ).toLocaleString("en-US", {
                                    style: "currency",
                                    currency: orderCurrency?.code,
                                  })
                                )}
                              </p>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                marginRight: "8px",
                                marginLeft: 1,
                                width: "10%",
                                minWidth: 1 / 10,
                                mt: 1,
                                marginTop: 6,
                              }}
                            >
                              <InputLabel id="currency-label">
                                {orderCurrency?.code}
                              </InputLabel>
                              <TextField
                                InputProps={{
                                  readOnly: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                                margin="normal"
                                required
                                label="Price Unit Sell"
                                id={`#products[${indexProduct}].price_unit_sold`}
                                type="number"
                                value={product.price_unit_sold}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                marginRight: 1,
                                marginLeft: 1,
                                width: "5%",
                                marginTop: 8,
                                minWidth: 1 / 20,
                              }}
                            >
                              Total:{" "}
                              {Number(
                                Number(product.quantity) *
                                  Number(product.price_unit_sold)
                              ).toLocaleString("en-US", {
                                style: "currency",
                                currency: orderCurrency?.code,
                              })}
                            </Box>
                          </Box>

                          <p>
                            {" "}
                            {product.name} -{" "}
                            <a
                              href={
                                store?.url +
                                "/" +
                                inputsName[product.language_id - 1].code +
                                "/product/" +
                                product.seo_url
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              Product Link
                            </a>
                          </p>
                          <p>
                            <br></br>
                          </p>
                        </Box>
                      );
                    })}
                </div>
                <Typography
                  component="h3"
                  variant="h3"
                  sx={{ fontSize: "24px", marginBottom: "10px" }}
                >
                  Total Itens: {cartItensTotal}, Cart Total:{" "}
                  {cartSubTotal ? (
                    cartSubTotal.toLocaleString("en-US", {
                      style: "currency",
                      currency: orderCurrency?.code,
                    })
                  ) : (
                    <span>There are no Products in the Cart</span>
                  )}
                </Typography>
              </Box>
            </TabPanel>
            <TabPanel
              value="3"
              sx={{
                width: "100%",
                typography: "body",
                maxWidth: "xl",
                minWidth: 3 / 4,
                alignItems: "center",
              }}
            >
              <br></br>
              {customer ? (
                <>
                  <p>
                    To change the products, access the Order Edit Section:{" "}
                    <a href={`/orders/${id}`} target="_blank" rel="noreferrer">
                      Edit Order
                    </a>
                  </p>

                  <p>
                    Customer ID: <b>{customer?.id}</b> /{" "}
                    <b>{customer?.first_name + " " + customer?.last_name}</b>
                  </p>
                  <div>
                    <b>
                      {shippingAddress?.name}, {shippingAddress?.phone_number}
                    </b>
                    <p>
                      {shippingAddress?.address}, {shippingAddress?.number}
                    </p>
                    <p>
                      {shippingAddress?.address_2} {shippingAddress?.district}
                    </p>
                    <p>
                      {shippingAddress?.city}, {shippingAddress?.state},{" "}
                      {shippingAddress?.country_code},{" "}
                      {shippingAddress?.postal_code}
                    </p>
                  </div>
                </>
              ) : (
                <></>
              )}
              {orderCurrency ? (
                <>
                  <br></br>
                  <p>Current Shipping Option:</p>
                  <h4>
                    <b>CODE: {shippingInfo?.code}</b>
                  </h4>
                  <h4>
                    <b>{shippingInfo?.description_name}</b>
                  </h4>
                  <p>{shippingInfo?.description}</p>
                  <p>
                    {Number(shippingInfo?.cost).toLocaleString("en-US", {
                      style: "currency",
                      currency: orderCurrency?.code,
                    })}
                    {" - "}
                    {Number(shippingInfo?.deliverable_time)}{" "}
                    {shippingInfo?.delivery_unit} After Payment
                  </p>
                  <br></br>
                  <p>
                    Parcel Weight: <b>{shippingInfo?.weight} Kg</b>
                  </p>
                  <p>
                    Dimensions:{" "}
                    <b>
                      (Width) {shippingInfo?.width} mm x (Height){" "}
                      {shippingInfo?.height} mm x (Length){" "}
                      {shippingInfo?.length} mm
                    </b>
                  </p>
                  <p>
                    Tracking Code: {shippingInfo?.tracking_code} /{" "}
                    <a
                      href={shippingInfo?.link_tracking}
                      target="_blank"
                      rel="noreferrer"
                    >
                      LINK
                    </a>
                  </p>
                  <br></br>
                </>
              ) : (
                <></>
              )}
            </TabPanel>
            <TabPanel
              value="4"
              sx={{
                width: "100%",
                typography: "body",
                maxWidth: "xl",
                minWidth: 3 / 4,
                alignItems: "center",
              }}
            >
              Get Avaliable Payment Methods and Total Amount. If it is credit
              Card, at the Clients Order Page, it will have a link for payment
              there <br></br>
              Show Payment Info here
            </TabPanel>
            <TabPanel
              value="5"
              sx={{
                width: "100%",
                // typography: "body",
                fontSize: "20px",
                maxWidth: "xl",
                minWidth: 3 / 4,
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "40%",
                  }}
                >
                  Sub Total (Products):
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "20%",
                  }}
                >
                  {cartSubTotal ? (
                    <span>{cartItensTotal} Itens in the Cart</span>
                  ) : (
                    <span></span>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "40%",
                    textAlign: "right",
                  }}
                >
                  {cartSubTotal ? (
                    cartSubTotal.toLocaleString("en-US", {
                      style: "currency",
                      currency: orderCurrency?.code,
                    })
                  ) : (
                    <span>There are no Products in the Cart</span>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "30%",
                  }}
                >
                  Discount:
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "20%",
                  }}
                ></Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "10%",
                  }}
                ></Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "40%",
                    textAlign: "right",
                  }}
                >
                  {products.length ? (
                    discountTotal.toLocaleString("en-US", {
                      style: "currency",
                      currency: orderCurrency?.code,
                    })
                  ) : (
                    <span></span>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "30%",
                  }}
                >
                  Taxes:
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "20%",
                  }}
                ></Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "10%",
                  }}
                ></Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "40%",
                    textAlign: "right",
                  }}
                >
                  {products.length ? (
                    taxesTotal.toLocaleString("en-US", {
                      style: "currency",
                      currency: orderCurrency?.code,
                    })
                  ) : (
                    <span></span>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "60%",
                  }}
                >
                  Shipping Cost:
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "40%",
                    textAlign: "right",
                  }}
                >
                  {products.length ? (
                    shippingCost.toLocaleString("en-US", {
                      style: "currency",
                      currency: orderCurrency?.code,
                    })
                  ) : (
                    <span></span>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "40%",
                  }}
                >
                  Discount from Payment Method:
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "20%",
                  }}
                ></Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "40%",
                    textAlign: "right",
                  }}
                >
                  {products.length ? (
                    discountPaymentTotal.toLocaleString("en-US", {
                      style: "currency",
                      currency: orderCurrency?.code,
                    })
                  ) : (
                    <span></span>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  fontSize: "24px",
                  fontWeight: "bold",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "60%",
                  }}
                >
                  Order Total:
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "40%",
                    textAlign: "right",
                  }}
                >
                  {products.length ? (
                    orderTotal.toLocaleString("en-US", {
                      style: "currency",
                      currency: orderCurrency?.code,
                    })
                  ) : (
                    <span></span>
                  )}
                </Box>
              </Box>
            </TabPanel>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  mt: 2,
                  marginBottom: 10,
                }}
              >
                {!!snackbar && (
                  <Snackbar
                    open
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                  >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                  </Snackbar>
                )}
                <Button
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  size="large"
                  endIcon={<SendIcon />}
                  disabled={circularLoading}
                  type="submit"
                >
                  Create New Order Status History
                  {circularLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: green[500],
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Button>
              </Stack>
            </Box>
          </Box>
        </TabContext>
      </Box>
    </Container>
  );
}
