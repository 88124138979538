/* eslint-disable @typescript-eslint/no-unused-vars */
import api from "../../services/api";
import {
  Avatar,
  Button,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import { useState } from "react";
import Swal from "sweetalert2";

export default function Login() {
  const [cookies, setCookie] = useCookies([
    "roles",
    "token",
    "username",
    "user_id",
  ]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState("");
  const onSubmit = (user: any) => {
    api
      .post("auth/login", user)
      .then((response) => {
        setCookie("token", response.data.access_token, {
          path: "/",
          maxAge: 60 * 60 * 24 * 30, // 30 days
        });
        setCookie("roles", response.data.roles, {
          path: "/",
          maxAge: 60 * 60 * 24 * 30, // 30 days
        });
        setCookie("username", response.data.username, {
          path: "/",
          maxAge: 60 * 60 * 24 * 30, // 30 days
        });
        setCookie("user_id", response.data.user_id, {
          path: "/",
          maxAge: 60 * 60 * 24 * 30, // 30 days
        });
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("roles", JSON.stringify(response.data.roles));
        localStorage.setItem(
          "username",
          JSON.stringify(response.data.username)
        );
        localStorage.setItem("user_id", JSON.stringify(response.data.user_id));
        window.location.href = "/";
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "" + error.response.data.message,
          // footer: '<a href="">Why do I have this issue?</a>',
        });
        //Show error on Page
        setRequestError(true);
        setRequestErrorData(error.response.data.message);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar src="/.png" sx={{ width: 150, height: 150 }} />
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        {requestError && (
          <List component="nav" aria-label="errors">
            {/* {requestErrorData.map((error) => ( */}
            <ListItem key={0}>
              <ListItemText
                sx={{
                  color: "red",
                }}
                primary={requestErrorData}
              />
            </ListItem>
            {/* ))} */}
          </List>
        )}
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            type="username"
            label="Username"
            {...register("username", {
              required: "Username is required",
            })}
            autoFocus
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.username?.toString()}
          </p>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Password"
            type="password"
            id="password"
            {...register("password")}
            autoComplete="não"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            size="large"
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href=" " variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
