import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import api from "../../services/api";
import { green } from "@mui/material/colors";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { AsyncPaginate } from "react-select-async-paginate";

function NewWarehouses() {
  const [circularLoading, setCircularLoading] = React.useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const defaultAdditional = {
    page: 1,
  };

  const loadOptionsCountries = async (search: any, page: any) => {
    const results = await api.get(
      `/countries?page=${page}&limit=0&search=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsCountries = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsCountries(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const [country, setCountry] = useState<{ id: number; name: string } | null>(
    null
  );

  const onSubmit = (warehouse: any) => {
    setCircularLoading(true);

    warehouse.country_id = Number(country?.id);

    if (!warehouse.country_id) {
      setCircularLoading(false);
      setRequestError(true);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Country must be selected!",
        footer: "<b>Please select a country</b>",
      });
      return;
    }

    api
      .post("warehouses", warehouse)
      .then((response) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Warehouse created!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          //footer: '<a href="">Why do I have this issue?</a>',
        });
        setCircularLoading(false);
        setRequestError(true);
        setRequestErrorData(error.response.data.message);
      });
  };

  useEffect(() => {}, []);

  return (
    <div>
      <Container component="main" maxWidth="lg">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            marginLeft: "20px",
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            sx={{
              fontSize: "30px",
              marginBottom: "15px",
              color: "#556cd6",
            }}
          >
            New Warehouse
          </Typography>
          {requestError && (
            <List component="nav" aria-label="erros">
              {requestErrorData.map((error) => (
                <ListItem key={error}>
                  <ListItemText
                    sx={{
                      color: "red",
                    }}
                    primary={error}
                  />
                </ListItem>
              ))}
            </List>
          )}
          <Box
            component="form"
            sx={{ mt: 1, minWidth: 3 / 4, width: "100%" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <InputLabel id="country-select">SelectCountry</InputLabel>
            <AsyncPaginate
              placeholder="Select Country"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              additional={defaultAdditional}
              key={"country_id"}
              id="country"
              name="country"
              getOptionValue={(option: any) => option?.id}
              getOptionLabel={(option: any) => option?.name}
              loadOptions={loadPageOptionsCountries}
              value={country}
              onChange={setCountry}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.country?.toString()}
            </p>

            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              type="text"
              label="Name"
              {...register("name")}
              autoFocus
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.name?.toString()}
            </p>
            <TextField
              margin="normal"
              fullWidth
              id="city"
              type="text"
              label="City"
              {...register("city")}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.city?.toString()}
            </p>
            <TextField
              margin="normal"
              fullWidth
              id="postalCode"
              type="text"
              label="Postal Code"
              {...register("postal_code")}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.postal_code?.toString()}
            </p>
            <TextField
              margin="normal"
              fullWidth
              id="address"
              type="text"
              label="Address"
              {...register("address")}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.postal_code?.toString()}
            </p>
            <TextField
              margin="normal"
              fullWidth
              id="address_2"
              type="text"
              label="Address-2"
              {...register("address_2")}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.postal_code?.toString()}
            </p>
            <TextField
              margin="normal"
              fullWidth
              id="size"
              type="number"
              label="Size"
              {...register("size")}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.postal_code?.toString()}
            </p>
            <TextField
              margin="normal"
              fullWidth
              id="comments"
              type="text"
              label="Comments"
              {...register("comments")}
              rows={10}
              multiline
            />

            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.postal_code?.toString()}
            </p>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                mt: 2,
              }}
            >
              <Button
                onClick={() => reset()}
                variant="outlined"
                startIcon={<DeleteIcon />}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                size="large"
                endIcon={<SendIcon />}
                disabled={circularLoading}
                type="submit"
              >
                Send
                {circularLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Container>
    </div>
  );
}

export default NewWarehouses;
