export enum OrderStatusEnum {
  // These Values must be the same in the DB
  NEW = 1,
  PAYMENT_PENDING = 2,
  PAYMENT_APPROVED = 3,
  PROCESSING_PACKING = 4,
  SHIPPED = 5,
  DELIVERED = 6,
  CONCLUDED = 7, // A few days after delivered, asks for review, etc.
  CANCELLED = 8,
  SHIPMENT_EXCEPTION = 9,
  PAYMENT_EXCEPTION = 10,
  RETURNED = 11,
  FULLY_REFUNDED = 12,
  PARTIALLY_REFUNDED = 13,
  SHIPPED_CORRECTION = 14, // Used When some error was caused by not shipping products ordered or correct some error on the order
  DELETED = 15,
}
