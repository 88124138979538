/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Container, Pagination, Box } from "@mui/material";
import TableHorizontal from "../TableHorizontal";
import api from "../../services/api";
function ListMarketplaces() {
  const [pageState, setPagepageState] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [marketplaces, setMarketplaces] = useState([]);
  const columns = [
    "ID",
    "Code",
    "Name",
    "Website",
    "Logo Image Link",
    "Banner Image Link",
    "Username",
    "Store Name",
    "Currency",
    "Actions",
    "Delete",
  ];
  const keys = [
    "id",
    "marketplace_code",
    "name",
    "website",
    "logo",
    "banner",
    "username",
    "store_name",
    "currency_code",
    "action",
    "delete",
  ];
  const fetchData = async (page = 1) => {
    await api.get(`/marketplaces?page=${page}&limit=200`).then((res) => {
      setMarketplaces(res.data.items);
      setTotalPages(res.data.total_pages);
    });
  };
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPagepageState(value);
    fetchData(value);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Container maxWidth="xl" sx={{ mt: 2, mb: 4 }}>
      <TableHorizontal
        screen={"marketplaces"}
        columns={columns}
        data={marketplaces}
        objectKey={keys}
      />
      <Box
        display="flex"
        width={"100%"}
        height={80}
        alignItems="center"
        justifyContent="center"
      >
        <Pagination
          count={totalPages}
          page={pageState}
          onChange={handleChange}
        />
      </Box>
    </Container>
  );
}

export default ListMarketplaces;
