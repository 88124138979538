/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Stack,
  ListItem,
  ListItemText,
  List,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import api from "../../services/api";
import Swal from "sweetalert2";
import { inputsName } from "../../config/languageConst";

function NewShippingMethod() {
  const [circularLoading, setCircularLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (shippingMethod: any) => {
    setCircularLoading(true);
    shippingMethod.default_delivery_additional_time = Number(
      shippingMethod.default_delivery_additional_time
    );
    shippingMethod.default_value = Number(shippingMethod.default_value);
    shippingMethod.maximum_weight = Number(shippingMethod.maximum_weight);

    shippingMethod.has_api_or_function = hasAPIorFunction;

    //Description
    if (shippingMethod.descriptions) {
      for (let index = 0; index < shippingMethod.descriptions.length; index++) {
        shippingMethod.descriptions[index].language_id = Number(
          shippingMethod.descriptions[index].language_id
        );
      }
    }

    api
      .post("shipping-methods", shippingMethod)
      .then((response) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Shipping Method created!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        //reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "" + error.response.data.message,
          // footer: '<a href="">Why do I have this issue?</a>',
        });
        setCircularLoading(false);
        setRequestError(true);
        setRequestErrorData(error.response.data.message);
      });
  };

  const [hasAPIorFunction, setHasAPIorFunction] = useState(false);
  const handleChangeHasAPIorFunction = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHasAPIorFunction(event.target.checked);
  };

  useEffect(() => {
    // fetchCompanies();
  }, []);

  return (
    <Container component="main" maxWidth="xl">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{ fontSize: "30px", marginBottom: "15px" }}
        >
          New Shipping Method
        </Typography>
        {requestError && (
          <List component="nav" aria-label="erros">
            {requestErrorData.map((error, index) => (
              <ListItem key={index}>
                <ListItemText
                  sx={{
                    color: "red",
                  }}
                  primary={error}
                />
              </ListItem>
            ))}
          </List>
        )}
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            minWidth: 3 / 4,
            marginBottom: "30px",
            width: "100%",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            sx={{ width: 4 / 4 }}
            margin="normal"
            required
            fullWidth
            id="code"
            type="text"
            label="Code"
            {...register("code")}
            autoFocus
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.code?.toString()}
          </p>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            type="text"
            label="Name"
            {...register("name")}
            autoFocus
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.name?.toString()}
          </p>
          <TextField
            margin="normal"
            fullWidth
            id="default_delivery_additional_time"
            type="number"
            label="Default Delivery Additional Time (Same Unit as the Delivery Time is Calculated"
            {...register("default_delivery_additional_time")}
            autoFocus
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.default_delivery_additional_time?.toString()}
          </p>
          <TextField
            margin="normal"
            fullWidth
            id="default_value"
            type="number"
            label="Default Value for Shipping Cost"
            {...register("default_value")}
            autoFocus
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.default_value?.toString()}
          </p>
          <TextField
            InputLabelProps={{ shrink: true }}
            margin="normal"
            fullWidth
            id="maximum_weight"
            type="number"
            label="Maximum Weight Supported by the Shipping Method"
            {...register("maximum_weight")}
            autoFocus
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.maximum_weight?.toString()}
          </p>
          <FormControlLabel
            label="Has API or Function"
            control={
              <Checkbox
                checked={hasAPIorFunction}
                onChange={handleChangeHasAPIorFunction}
                inputProps={{ "aria-label": "Has API or Function" }}
                //{...register("has_api_or_function")}
              />
            }
          />
          <TextField
            margin="normal"
            fullWidth
            id="shipping_company_name"
            type="text"
            label="Shipping Company Name"
            {...register("shipping_company_name")}
            autoFocus
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.shipping_company_name?.toString()}
          </p>

          <TextField
            margin="normal"
            fullWidth
            id="additional_info"
            type="text"
            label="Additional Info / Comments Section"
            {...register("additional_info")}
            autoFocus
            multiline
            rows={10}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.additional_info?.toString()}
          </p>

          {/* Description Section  */}
          <Box sx={{ width: "100%", typography: "body1" }}>
            {inputsName.map((input, index) => (
              <Box
                key={index}
                className="flags-container"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div
                    className="fa-flags"
                    style={{
                      height: "50px",
                      width: "200px",
                      marginTop: "10px",
                    }}
                  >
                    <img
                      src={`/assets/language/${input.language_id}.png`}
                      alt=""
                    />
                    &nbsp;-&nbsp;{input.language_name}
                  </div>
                  <input
                    hidden
                    {...register(`descriptions[${index}].language_id`)}
                    value={input.language_id}
                  />

                  <TextField
                    sx={{ mt: 1, minWidth: 3 / 4, marginLeft: "8px" }}
                    InputLabelProps={{ shrink: true }}
                    margin="normal"
                    required
                    fullWidth
                    id={index.toString()}
                    type="text"
                    label="Shipping Method Name that will be shown to the Customer on Store"
                    {...register(`descriptions[${index}].name`)}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.descriptions?.toString()}
                  </p>
                </Box>

                <TextField
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  fullWidth
                  id={"#descriptions[" + index + "].description"}
                  type="text"
                  label="Shipping Method Description that will be shown to the Customer on Store"
                  rows={5}
                  multiline
                  {...register(`descriptions[${index}].description`)}
                />
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors?.descriptions?.toString()}
                </p>

                <TextField
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  fullWidth
                  id={"#descriptions[" + index + "].delivery_time_unit"}
                  type="text"
                  label="Delivery Time Unit that will be shown to the Customer on the Store. Ex.: Days, Hours, Minutes, Weeks, etc."
                  {...register(`descriptions[${index}].delivery_time_unit`)}
                />
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors?.descriptions?.toString()}
                </p>
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              minWidth: 3 / 4,
              marginBottom: "30px",
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              sx={{
                mt: 2,
              }}
            >
              <Button
                onClick={() => reset()}
                variant="outlined"
                startIcon={<DeleteIcon />}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                size="large"
                endIcon={<SendIcon />}
                disabled={circularLoading}
                type="submit"
              >
                Send
                {circularLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
export default NewShippingMethod;
