/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Stack,
  ListItem,
  ListItemText,
  List,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import api from "../../services/api";
import Swal from "sweetalert2";
import { inputsName } from "../../config/languageConst";

function NewCountries() {
  const [circularLoading, setCircularLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (country: any) => {
    setCircularLoading(true);
    let temp = [];
    for (let i of country.descriptions) i && temp.push(i);
    country.descriptions = temp;
    //country.name = String(country.name);
    country.iso_code_2 = String(country.iso_code_2);
    country.iso_code_3 = String(country.iso_code_3);

    api
      .post(`countries`, country)
      .then((response) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Country created!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "" + error.response.data.message,
          footer: '<a href="">Why do I have this issue?</a>',
        });
        setCircularLoading(false);
        setRequestError(true);
        setRequestErrorData(error.response.data.message);
      });
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{ fontSize: "32px", marginBottom: "15px", color: "#556cd6" }}
        >
          New Country
        </Typography>
        {requestError && (
          <List component="nav" aria-label="error">
            {requestErrorData.map((error) => (
              <ListItem key={error}>
                <ListItemText
                  sx={{
                    color: "red",
                  }}
                  primary={error}
                />
              </ListItem>
            ))}
          </List>
        )}
        <Box
          component="form"
          sx={{ mt: 1, minWidth: 2 / 4, width: "100%" }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box sx={{ mt: 1, width: "100%" }}>
            <TextField
              sx={{ width: 3 / 8, flexDirection: "row", maxWidth: 200 }}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              required
              id="iso_code_2"
              type="text"
              label="Iso Code 2"
              {...register("iso_code_2")}
              autoFocus
            />

            <TextField
              sx={{
                width: 3 / 8,
                flexDirection: "row",
                maxWidth: 200,
                marginRight: 10,
                marginLeft: 8,
              }}
              margin="normal"
              InputLabelProps={{ shrink: true }}
              required
              fullWidth
              id="iso_code_3"
              type="text"
              label="Iso Code 3"
              {...register("iso_code_3")}
              autoFocus
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.iso_code_2?.toString()}
            </p>
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.iso_code_3?.toString()}
            </p>
          </Box>

          <div style={{ marginTop: "30px" }}>
            {inputsName.map((input, index) => (
              <div
                className="flags-container"
                style={{ display: "block", marginTop: "50px" }}
                key={index}
              >
                <div className="fa-flags">
                  <img
                    src={`/assets/language/${input.language_id}.png`}
                    alt=""
                  />
                  &nbsp;-&nbsp;{input.language_name}
                </div>
                <input
                  hidden
                  {...register(`descriptions[${index}].language_id`)}
                  value={input.language_id}
                />
                <TextField
                  sx={{ mt: 1, minWidth: 3 / 4 }}
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  required
                  fullWidth
                  id={"#descriptions[" + index + "].name"}
                  type="text"
                  label="Name"
                  {...register(`descriptions[${index}].name`)}
                />
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors?.descriptions?.toString()}
                </p>
              </div>
            ))}
          </div>

          <Stack
            direction="row"
            spacing={2}
            sx={{
              mt: 2,
            }}
          >
            <Button
              onClick={() => reset()}
              variant="outlined"
              startIcon={<DeleteIcon />}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              size="large"
              endIcon={<SendIcon />}
              disabled={circularLoading}
              type="submit"
            >
              Send
              {circularLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
}
export default NewCountries;
