/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Stack,
  List,
  ListItemText,
  ListItem,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import api from "../../../../services/api";
import Swal from "sweetalert2";
import { inputsName } from "../../../../config/languageConst";
import { useLocation, useParams } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import { LogTrackUser } from "../../../../types/log-track-user/log-track-user";
import Cookies from "js-cookie";

export default function EditAttribute() {
  const [circularLoading, setCircularLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);
  const { id } = useParams<{ id: string }>();

  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const defaultAdditional = {
    page: 1,
  };

  const [valueAttributeGroup, setValueAttributeGroup] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const fetchAttributeGroup = async () => {
    const response2 = await api.get(`attributes/${id}/attributeGroup`);
    const insert = {
      id: response2.data.attribute_group_id,
      name: response2.data.attribute_group_name,
    };
    setValueAttributeGroup(insert);
  };

  const loadOptionsAttributeGroups = async (search: any, page: any) => {
    const results = await api.get(
      `/attribute-groups?page=${page}&search=${search}`
    );

    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsAttributeGroups = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsAttributeGroups(q, page);

    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  };

  const onSubmit = (attribute: any) => {
    setCircularLoading(true);

    attribute.attribute_group_id = valueAttributeGroup
      ? valueAttributeGroup.id
      : null;

    for (let index = 0; index < attribute.descriptions.length; index++) {
      attribute.descriptions[index].language_id = Number(
        attribute.descriptions[index].language_id
      );
    }

    api
      .put(`attributes/${id}`, attribute)
      .then((response) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "attributes updated!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        //reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "" + error.response.data.message,
          footer: '<a href="">Why do I have this issue?</a>',
        });
        setCircularLoading(false);
        setRequestError(true);
        setRequestErrorData(error.response.data.message);
      });
  };

  const fetchAttribute = async () => {
    const response = await api.get(`attributes/${id}`);

    reset(response.data);
  };

  useEffect(() => {
    fetchAttribute();
    fetchAttributeGroup();
  }, []);

  return (
    <Container component="main" maxWidth="xl">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{ fontSize: "40px", marginBottom: "10px" }}
        >
          Edit Attribute {id}
        </Typography>
        {requestError && (
          <List component="nav" aria-label="BackEnd Return">
            {requestErrorData.map((error) => (
              <ListItem key={error}>
                <ListItemText
                  sx={{
                    color: "red",
                  }}
                  primary={error}
                />
              </ListItem>
            ))}
          </List>
        )}
        <Box
          component="form"
          sx={{ mt: 1, minWidth: 3 / 4, width: "100%" }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <AsyncPaginate
            placeholder="Select Attribute Group"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"attribute_group_id"}
            id="attribute_group"
            name="attribute_group"
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.name}
            loadOptions={loadPageOptionsAttributeGroups}
            value={valueAttributeGroup}
            //inputValue={inputValue}
            //onInputChange={onInputChangeAttributes}
            //onChange={(cat_chosen) => setValue([...cat_chosen])}
            onChange={setValueAttributeGroup}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.attribute__group_id?.toString()}
          </p>

          <div style={{ marginTop: "30px" }}>
            {inputsName.map((input, index) => (
              <div className="flags-container" key={index}>
                <input
                  hidden
                  {...register(`descriptions[${index}].language_id`, {
                    required: `descriptions[${index}].language_id is required`,
                  })}
                  value={input.language_id}
                />
                <span className="fa-flags">
                  <img
                    src={`/assets/language/${input.language_id}.png`}
                    alt=""
                  />
                </span>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  required
                  id={"#descriptions[" + index + "].name"}
                  type="text"
                  label="Name"
                  {...register(`descriptions[${index}].name`, {
                    required: `descriptions[${index}].name is required`,
                  })}
                />
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors?.descriptions?.toString()}
                </p>
              </div>
            ))}
          </div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              sx={{
                mt: 2,
              }}
            >
              <Button
                onClick={() => reset()}
                variant="outlined"
                startIcon={<DeleteIcon />}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                size="large"
                endIcon={<SendIcon />}
                disabled={circularLoading}
                type="submit"
              >
                Send
                {circularLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
