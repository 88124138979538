/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Container,
  Pagination,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";

import TableHorizontal from "../../../components/TableHorizontal";
import api from "../../../services/api";

import { useLocation } from "react-router-dom";
import { LogTrackUser } from "../../../types/log-track-user/log-track-user";
import Cookies from "js-cookie";

export default function ErrorsLog() {
  const [pageState, setPagepageState] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////
  const [errorslog, setErrorslog] = useState([]);
  const columns = [
    "ID",
    "TableName",
    "TableEntryId",
    "ProblemType",
    "Info",
    "Delete",
  ];
  const keys = [
    "id",
    "table_name",
    "table_entry_id",
    "problem_type",
    "info",
    "delete",
  ];

  const fetchData = async (
    page = 1,
    language = 1,
    numberItemsPerPage = 500
  ) => {
    await api
      .get(
        `/errorslogs?page=${page}&limit=${numberItemsPerPage}&language_id=${language}&table_name=${tablenamefilter}&table_entry_id=${tableentryidfilter}&problem_type=${problemtypefilter}`
      )
      .then((res) => {
        setErrorslog(res.data.items);
        setTotalPages(res.data.total_pages);
      });
  };
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPagepageState(value);
    fetchData(value);
  };

  // const [numberItemsPerPage, setNumberItemsPerPage] = useState("500");
  // const handleNumberItemsPerPage = (event: SelectChangeEvent) => {
  //   setNumberItemsPerPage(event.target.value);
  //   fetchData(1, Number(language), Number(event.target.value));
  // };

  const [language] = useState("1");
  // const handleChangeLanguage = (event: SelectChangeEvent) => {
  //   setLanguage(event.target.value);
  //   fetchData(1, Number(event.target.value), Number(numberItemsPerPage));
  // };

  const [tablenamefilter, setTableNameFilter] = useState("");
  const handleChangeTableNamefilter = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTableNameFilter(e.target.value);
  };

  const [tableentryidfilter, setTableEntryIdFilter] = useState("");
  const handleChangeTableEntryIdfilter = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTableEntryIdFilter(e.target.value);
  };

  const [problemtypefilter, setProblemTypeFilter] = useState("");
  const handleChangeProblemTypeFilter = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProblemTypeFilter(e.target.value);
  };

  const handleFilterButtonClick = () => {
    fetchData(1, Number(language));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container maxWidth="xl" sx={{ mt: 2, mb: 4 }}>
      <Typography
        component="h1"
        variant="h5"
        sx={{ fontSize: "32px", marginBottom: "10px" }}
      >
        Errors Log
      </Typography>
      <TextField
        //sx={{ mt: 1, minWidth: 3/4 }}
        sx={{ minWidth: 1 / 4, marginRight: 1 }}
        margin="normal"
        //fullWidth
        id={"Table-name"}
        type="text"
        label="Table name filter"
        value={tablenamefilter}
        onChange={handleChangeTableNamefilter}
      />
      <TextField
        //sx={{ mt: 1, minWidth: 3/4 }}
        sx={{ minWidth: 1 / 4, marginRight: 1 }}
        margin="normal"
        //fullWidth
        id={"Table-entry-id"}
        type="text"
        label="Table entry id Filter"
        value={tableentryidfilter}
        onChange={handleChangeTableEntryIdfilter}
      />
      <TextField
        //sx={{ mt: 1, minWidth: 3/4 }}
        margin="normal"
        //fullWidth
        id={"Problem-type"}
        type="text"
        label="Problem Type Filter"
        value={problemtypefilter}
        onChange={handleChangeProblemTypeFilter}
      />
      <Button
        variant="contained"
        sx={{ m: 3 }}
        href=""
        onClick={handleFilterButtonClick}
      >
        Filter
      </Button>

      <Box sx={{ alignItems: "start" }}>
        <TableHorizontal
          screen={"errorslogs"}
          columns={columns}
          data={errorslog}
          objectKey={keys}
        />
      </Box>
      <Box
        display="flex"
        width={"100%"}
        height={80}
        alignItems="center"
        justifyContent="center"
      >
        <Pagination
          count={totalPages}
          page={pageState}
          onChange={handleChange}
        />
      </Box>
    </Container>
  );
}
