import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import omitRouteRenderProperties from "../config/omitRouteRenderProperties";
import checkPermissions from "../config/checkPermissions";
import map from "lodash/map";
import isNil from "lodash/isNil";
import Cookies from "js-cookie";
interface IRoutes {
  path: string;
  exact: boolean;
  permissions?: string[];
  redirect: string;
  component: React.ComponentType<any>;
  unauthorized: React.ComponentType<any>;
}

interface IRoutesAnhoterRoles {
  path: string;
  redirect: string;
  exact: boolean;
  component: React.ComponentType<any>;
}

interface IRoutesWithRoles {
  normalRoutes: IRoutesAnhoterRoles[];
  authorizedRoutes: IRoutes[];
  authorizedLayout: React.ComponentType<any>;
  normalLayout: React.ComponentType<any>;
  notFound: React.ComponentType<any>;
  idNotFound: React.ComponentType<any>;
}

const RoutesWithRoles: React.FC<IRoutesWithRoles> = (props) => {
  const { normalRoutes, authorizedRoutes } = props;

  const token = Cookies.get("token");

  // if ("token" in localStorage) {
  if (token) {
    return (
      <Routes>
        {map(authorizedRoutes, (route) => renderAuthorizedRoute(route, props))}
        {renderIdNotFoundRoute(props)}
      </Routes>
    );
  } else {
    return (
      <Routes>
        {map(normalRoutes, (route) => renderUnAuthorizedRoute(route, props))}
        {renderNotFoundRoute(props)}
      </Routes>
    );
  }
};
const renderRedirectRoute = (route: IRoutes | IRoutesAnhoterRoles) => (
  <Route
    key={route.path}
    {...omitRouteRenderProperties(route)}
    // render={() => <Navigate to={route.redirect} />}
    element={<Navigate to={route.redirect} />}
  />
);
const renderUnAuthorizedRoute = (
  route: IRoutesAnhoterRoles,
  props: IRoutesWithRoles
) => {
  const { normalLayout: NormalLayout } = props;
  const { redirect, path, component: RouteComponent } = route;
  if (isNil(RouteComponent) && !isNil(redirect)) {
    return renderRedirectRoute(route);
  }
  return (
    <Route
      key={path}
      {...omitRouteRenderProperties(route)}
      /* render={(props: IRoutesWithRoles) => (
        <NormalLayout {...props}>
          <RouteComponent {...props} />
        </NormalLayout>
      )} */
      element={
        <NormalLayout {...props}>
          <RouteComponent {...props} />
        </NormalLayout>
      }
    />
  );
};

const renderAuthorizedRoute = (route: IRoutes, props: IRoutesWithRoles) => {
  // const token = String(localStorage.getItem("token")) ?? "";
  const token = Cookies.get("token") ?? "";
  let authorities;
  try {
    // authorities = JSON.parse(localStorage.getItem("roles") || "['']");
    authorities = JSON.parse(Cookies.get("roles") || "['']");
  } catch (e) {
    authorities = [""];
  }

  if (isNil(token)) {
    return renderRedirectRoute(route);
  }

  const { authorizedLayout: AuthorizedLayout } = props;
  const {
    permissions,
    path,
    component: RouteComponent,
    unauthorized: Unauthorized,
  } = route;

  const hasPermission = checkPermissions(authorities, permissions);

  if (!hasPermission && route.unauthorized) {
    return (
      <Route
        key={path}
        {...omitRouteRenderProperties(route)}
        /*         render={(props: IRoutesWithRoles) => (
          <AuthorizedLayout {...props}>
            <Unauthorized {...props} />
          </AuthorizedLayout>
        )} */
        element={
          <AuthorizedLayout {...props}>
            <Unauthorized {...props} />
          </AuthorizedLayout>
        }
      />
    );
  }

  if (!hasPermission && route.redirect) {
    return renderRedirectRoute(route);
  }

  return (
    <Route
      key={path}
      {...omitRouteRenderProperties(route)}
      /*       render={(props: IRoutesWithRoles) => (
        <AuthorizedLayout {...props}>
          <RouteComponent {...props} />
        </AuthorizedLayout>
      )} */
      element={
        <AuthorizedLayout {...props}>
          <RouteComponent {...props} />
        </AuthorizedLayout>
      }
    />
  );
};

const renderNotFoundRoute = (props: IRoutesWithRoles) => {
  const { notFound: NotFound } = props;
  return <Route element={<NotFound {...props} />} />;
};

const renderIdNotFoundRoute = (props: IRoutesWithRoles) => {
  const { idNotFound: IdNotFound } = props;
  return <Route element={<IdNotFound {...props} />} />;
};

export default RoutesWithRoles;
