import { useEffect } from "react";
import AuthorizedNotFound from "./pages/AuthorizedNotFound/index";
import { normalRoutes, authorizedRoutes } from "./config/routes";
import NoneLayout from "./components/layouts/NoneLayout";
/* import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider"; */
import RoutesWithRoles from "./routes/RoutesWithRoles";
import DefaultLayout from "./components/layouts/DefaultLayout";
import { useSession } from "./contexts/auth/SessionContext";
import IdNotFound from "./pages/IdNotFound";
export default function App() {
  const { hasSession } = useSession();
  useEffect(() => {
    hasSession();
  });

  return (
    /*     <LocalizationProvider dateAdapter={AdapterDateFns}> */
    <RoutesWithRoles
      notFound={AuthorizedNotFound}
      idNotFound={IdNotFound}
      normalLayout={NoneLayout}
      normalRoutes={normalRoutes}
      authorizedLayout={DefaultLayout}
      authorizedRoutes={authorizedRoutes}
    />
    /*     </LocalizationProvider> */
  );
}

/* import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import ProTip from "./ProTip";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}.
    </Typography>
  );
}

export default function App() {
  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Material UI Create React App example in TypeScript
        </Typography>
        <ProTip />
        <Copyright />
      </Box>
    </Container>
  );
} */
