import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Stack,
  List,
  ListItemText,
  ListItem,
  CardMedia,
  IconButton,
  Tab,
  InputLabel,
  Select,
  MenuItem,
  AlertProps,
  Snackbar,
  Alert,
  Radio,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import api from "../../../../services/api";
import Swal from "sweetalert2";
import { useLocation, useParams } from "react-router-dom";
import { LogTrackUser } from "../../../../types/log-track-user/log-track-user";
import { MultiValue } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { TabContext, TabList, useTabContext } from "@mui/lab";
import { inputsName } from "../../../../config/languageConst";
import moment from "moment";
import Cookies from "js-cookie";

type PropsOrderInfo = {
  id: number;
  store_id: number;
  currency_id: number;
  customer_id: number;
  order_status_id: number;
  seller_id: number;
  cart_id: string;
  payment_method_id: number;
  shipping_method_id: number;
  subtotal: number;
  discount: number;
  taxes: number;
  shipping_cost: number;
  discount_payment_method: number;
  total: number;
  is_paid: boolean;
  created_at: Date;
  updated_at: Date;
  last_change_username: string;
};

type PropsProductDescription = {
  product_id: number;
  language_id: number;
  name: string;
  description: string;
  seo_keyword: string;
  seo_url: string;
};

type PropsProduct = {
  id: number;
  ysin: string;
  sku: string;
  barcode: string;
  barcode_type: string;
  image: string;
  image2: string;
  product_type: number;
  status: number;
  brand_id: number;
  descriptions: PropsProductDescription[];
};

type PropsProductCart = {
  id: number;
  product_id: number;
  image: string;
  sku: string;
  ysin: string;
  product_type: number;
  quantity: number;
  name: string;
  seo_url: string;
  store_id: number;
  store_name: string;
  store: string;
  store_url: string;
  currency_code: string;
  product_store_price: number;
  product_store_status: number;
  product_store_is_sale: boolean;
  product_store_price_sale: number;
  product_pdv_store_price: number;
  product_pdv_store_status: number;
  product_pdv_store_is_sale: boolean;
  product_pdv_store_price_sale: number;
  product_wholesale_store_price: number;
  product_wholesale_store_status: number;
  product_wholesale_store_is_sale: boolean;
  product_wholesale_store_price_sale: number;
  product_wholesale_pdv_store_price: number;
  product_wholesale_pdv_store_status: number;
  product_wholesale_pdv_store_is_sale: boolean;
  product_wholesale_pdv_store_price_sale: number;
  sell_unit: string;
  language_id: number;
  price_unit_sold: number;
  weight: number;
  height: number;
  width: number;
  length: number;
  product: PropsProduct;
};

type PropsStore = {
  id: number;
  name: string;
  currency_id: number;
  currency_code: string;
  store_country_id: number;
  store_warehouse_id: number;
  url: string;
  ssl: string;
  title: string;
  description: string;
  keywords: string;
  email: string;
  phone_number: string;
  phone_number2: string;
  image: string;
  schedule: string;
  logo: string;
  icon: string;
  wholesale_pdv_enabled: boolean;
  wholesale_pdv_minimum_total: number;
  wholesale_pdv_minimum_quantity_item: number;
  wholesale_store_enabled: boolean;
  wholesale_store_minimum_total: number;
  wholesale_store_minimum_quantity_item: number;
  country: string;
  currency: string;
  warehouse: string;
  latitude: number;
  longitude: number;
};

type PropsCustomer = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
};

type PropShippingPaymentAddress = {
  id: number;
  customer_id: number;
  country_id: number;
  country_code: string;
  name: string;
  phone_number: string;
  postal_code: string;
  address: string;
  number: string;
  address_2: string;
  district: string;
  city: string;
  state: string;
  additional_info: string;
};

export type PropsShippingVolume = {
  weight: number; // in Kgs
  height: number; // in mm (same as DB)
  width: number; // in mm (same as DB)
  length: number; // in mm (same as DB)
  value: number; // in store default currency
  quantity: number; // Quantity of products inside Shipping Volume
};

export type PropsShippingCosts = {
  shipping_method_id: number;
  code: string;
  language_id: number;
  deliverable_time: number;
  delivery_unit: string;
  cost: number;
  description_name: string;
  description: string;
  weight: number;
  height: number;
  width: number;
  length: number;
  value: number;
};

export type PropsPaymentInfo = {
  order_id: string;
  payment_method_id: number;
  code: string;
  discount_payment_method: number;
  total_paid: number;
  tracking_code: string;
  link_payment: string;
  preference_id: string;
  collection_id: string;
  collection_status: string;
  client_id: string;
  collector_id: string;
  payment_id: string;
  status: string;
  payment_type: string;
  merchant_order_id: string;
  processing_mode: string;
  merchant_account_id: string;
  site_id: string;
  payment_date?: Date;
  updated_at: Date;
  created_at: Date;
};

type PropsCurrency = {
  currency_id: number;
  code: string;
  value: number;
};

type PropsOrderStatusDescription = {
  order_status_id: number;
  language_id: number;
  name: string;
  description: string;
};

type PropsOrderStatus = {
  id: number;
  code: string;
  descriptions?: PropsOrderStatusDescription[];
};

type PropsOrderStatusHistory = {
  order_status_id: number;
  notify: boolean;
  comments_client: string;
  comments: string;
  order_status: PropsOrderStatus;
  created_at: Date;
};

//I HAD to declare TabPanel like this to prevent Lazy Loading on the TabPanels.
//DUe to Lazy loading, the data form the DB was not being filled into the fields in the other tabs
function TabPanel(props: any) {
  const { children, className, value, index, ...other } = props;

  const context = useTabContext();

  if (context === null) {
    throw new TypeError("No TabContext provided");
  }
  const tabId = context.value;

  return (
    <Typography
      component="div"
      role="tabpanel"
      padding="24px"
      hidden={value !== tabId}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      //visibility={value === tabId ? "visible" : "hidden"}
      {...other}
    >
      {children}
    </Typography>
  );
}

export default function EditOrder(): JSX.Element {
  const [circularLoading, setCircularLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);

  const [tabValue, setTabValue] = useState("1");
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { id } = useParams<{ id: string }>();

  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const [imageBaseURL, setimageBaseURL] = useState(
    process.env.REACT_APP_IMG_CATALOG_URL
  );

  const fetchImageFolder = async () => {
    if (imageBaseURL?.substring(imageBaseURL?.length - 1) !== "/") {
      setimageBaseURL(imageBaseURL + "/");
    }
  };

  const defaultAdditional = {
    page: 1,
  };

  const [store, setStore] = useState<PropsStore | null>(null);
  /* 
  const loadOptionsStores = async (search: any, page: any) => {
    const results = await api.get(`/stores?page=${page}&search=${search}`);
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsStores = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsStores(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }; */

  const [customer, setCustomer] = useState<PropsCustomer | null>(null);

  /*   const loadOptionsCustomers = async (search: any, page: any) => {
    const results = await api.get(`/customers?page=${page}&search=${search}`);

    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsCustomers = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsCustomers(q, page);
    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  }; */

  const [cashier, setCashier] = useState<{
    id: number;
    code: string;
    name: string;
    username: string;
  } | null>(null);

  const loadOptionsCashier = async (search: any, page: any) => {
    const results = await api.get(`/cashiers?page=${page}&search=${search}`);

    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsCashier = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsCashier(q, page);
    if (options.length > 0) {
      options.unshift({ id: 0, code: "None" });
    }
    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  };

  const [seller, setSeller] = useState<{
    id: number;
    code: string;
    name: string;
    username: string;
  } | null>(null);

  const loadOptionsSeller = async (search: any, page: any) => {
    const results = await api.get(`/sellers?page=${page}&search=${search}`);

    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsSeller = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsSeller(q, page);
    if (options.length > 0) {
      options.unshift({ id: 0, code: "None" });
    }
    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  };

  const [cartSubTotal, setCartSubTotal] = useState(0);
  const [cartItensTotal, setItensTotal] = useState(0);
  const [products, setProducts] = useState<PropsProductCart[]>([]);
  const onChangeProducts = async (newValue: MultiValue<PropsProductCart>) => {
    if (newValue.length) newValue[newValue.length - 1].quantity = 0;
    setProducts([...newValue]);
  };

  const loadOptionsProductsStore = async (search: any, page: any) => {
    const results = await api.get(
      `/product-stores/notparentforcart?page=${page}&store_id=${store?.id}&product_sku=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsProductsStore = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsProductsStore(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const deleteProductFromCart = (product_id: number) => {
    //Remove from the product list the product
    setProducts(products.filter((row) => row.product_id !== product_id));
    updateSubTotalProducts(products);
  };

  const updateSubTotalProducts = async (products: PropsProductCart[]) => {
    if (products.length > 0) {
      var totalPerItem = products.map(
        (product) => product.quantity * product.price_unit_sold
      );
      var total = totalPerItem.reduce((a, b) => a + b);
      var totalItem = products.map((product) => product.quantity);
      var totalItens = totalItem.reduce((a, b) => a + b);
      setCartSubTotal(total);
      setItensTotal(totalItens);
      updateOrderTotal(total);

      //
    } else {
      setCartSubTotal(0);
      setItensTotal(0);
      updateOrderTotal(0);
    }
  };

  const changeProductQuantity = (product_id: number, quantity: number) => {
    products.forEach((product, index) => {
      if (product.product_id === product_id) {
        products[index].quantity = quantity;
        updateSubTotalProducts(products);
      }
    });
    setProducts(products);
  };

  const changeProductUnitPrice = (product_id: number, price: number) => {
    products.forEach((product, index) => {
      if (product.product_id === product_id) {
        products[index].price_unit_sold = price;
        updateSubTotalProducts(products);
      }
    });
    setProducts(products);
  };

  const [discountIsFixed, setDiscountIsFixed] = useState(false);
  const [discountRate, setDiscountRate] = useState(0);
  const [discountTotal, setDiscountTotal] = useState(0);

  const [taxesRate, setTaxesRate] = useState(0);
  const [taxesTotal, setTaxesTotal] = useState(0);

  const [shippingAddress, setShippingAddress] =
    useState<PropShippingPaymentAddress | null>(null);
  const [shippings, setShippings] = useState<PropsShippingCosts[]>([]);
  const [shippingInfo, setShippingInfo] = useState<PropsShippingCosts>();
  const [shippingCost, setShippingCost] = useState(0);

  const [paymentAddress, setPaymentAddress] =
    useState<PropShippingPaymentAddress | null>(null);

  const [paymentInfo, setPaymentInfo] = useState<PropsShippingCosts>();
  const [discountPaymentRate, setDiscountPaymentRate] = useState(0);
  const [discountPaymentTotal, setDiscountPaymentTotal] = useState(0);

  const [orderTotal, setOrderTotal] = useState(0);

  const [orderInfo, setOrderInfo] = useState<PropsOrderInfo>();

  const [orderCurrency, setOrderCurrency] = useState<PropsCurrency>();

  const [orderStatus, setOrderStatus] = useState<PropsOrderStatus>();

  const [orderStatusHistory, setOrderStatusHistory] = useState<
    PropsOrderStatusHistory[]
  >([]);

  const updateDiscount = (discountIsFixed: boolean, discountRate: number) => {
    setDiscountIsFixed(discountIsFixed);
    if (discountIsFixed) {
      //Check if it is bigger than SubTotal. Do not allow it
      if (discountRate > cartSubTotal) {
        setDiscountRate(cartSubTotal);
        setDiscountTotal(-cartSubTotal);
      } else {
        setDiscountTotal(-discountRate);
      }
    } else {
      // discountRate cannot be bigger than 100
      if (discountRate > 100) {
        setDiscountRate(100);
        setDiscountTotal(-cartSubTotal);
      } else {
        setDiscountTotal((-cartSubTotal * discountRate) / 100);
      }
    }
  };

  const onChangeDiscountIsFixed = async (discountIsFixed: boolean) => {
    updateDiscount(discountIsFixed, discountRate);
    //Update Taxes
    updateTaxes(taxesRate);
    //Update Total
    updateOrderTotal(cartSubTotal);
  };

  const onChangeDiscountRate = async (discountRate: number) => {
    setDiscountRate(discountRate);
    updateDiscount(discountIsFixed, discountRate);
    //Update Taxes
    updateTaxes(taxesRate);
    //Update Total
    updateOrderTotal(cartSubTotal);
  };

  const updateTaxes = (taxesRate: number) => {
    setTaxesRate(taxesRate);
    setTaxesTotal((taxesRate / 100) * (cartSubTotal + discountTotal));
  };

  const onChangeTaxesRate = async (taxesRate: number) => {
    setTaxesRate(taxesRate);
    //Update Taxes
    updateTaxes(taxesRate);
    //Update Total
    updateOrderTotal(cartSubTotal);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateOrderTotal = (cartSubTotal: number) => {
    setOrderTotal(
      cartSubTotal +
        discountTotal +
        taxesTotal +
        shippingCost +
        discountPaymentTotal
    );
  };

  const loadOptionsCustomerAddresses = async (search: any, page: any) => {
    const results = await api.get(`/customers/address/${customer?.id}`);
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsCustomerAddresses = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsCustomerAddresses(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  /*   const setProductQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    products[Number(e.target.id)].quantity = Number(e.target.value);
    // productsQuantity[Number(e.target.id)] = Number(e.target.value);
    setProductsQuantity(productsQuantity);
    setProducts(products);
  }; */

  const getShippingOptions = async () => {
    // Check if Store & Customer is defined
    if (customer && store) {
      // Mount the bodymessage
      /*       const store_response = await api.get(`/stores/address/${store?.id}`);
      const store_info = store_response.data; */
      const customer_response = await api.get(`/customers/${customer?.id}`);
      const customer_info = customer_response.data;
      if (customer_info.customer_shipping_address) {
        const data = {} as any;
        data.store_id = store?.id;
        // Check if Latitude Longitude has been defined. If not, get coordinates from ZIP CODE
        const destination = {} as any;

        /*         destination.latitude = customer_info.customer_shipping_address?.latitude;
        destination.longitude = customer_info.customer_shipping_address?.longitude; */

        destination.country_id =
          customer_info.customer_shipping_address?.country_id;
        destination.zip_code =
          customer_info.customer_shipping_address.postal_code;
        destination.state = customer_info.customer_shipping_address.state;
        destination.city = customer_info.customer_shipping_address.city;
        destination.address = customer_info.customer_shipping_address.address;

        data.volumes = [];
        products.forEach((product) => {
          const dataVolume = {} as PropsShippingVolume;
          dataVolume.weight = Number(product.weight) || 0.05; // Minimum of 0.05 kg
          dataVolume.height = Number(product.height) || 50; // Minimum of 50mm
          dataVolume.width = Number(product.width) || 50; // Minimum of 50mm
          dataVolume.length = Number(product.length) || 50; // Minimum of 50mm
          dataVolume.value = Number(product.price_unit_sold);
          dataVolume.quantity = product.quantity;
          data.volumes.push(dataVolume);
        });

        data.destination = destination;

        const shipping_costs = await api
          .post(`shipping-method-stores/costs/${store?.id}?language_id=1`, data)
          // http://localhost:5666/erp/shipping-method-stores/costs/2?method&store_id=2&language_id=2
          .then((response) => response.data)
          .catch((error) => {
            console.error(error);
          });
        if (shipping_costs) {
          setShippings(shipping_costs);
          // api.post('shipping-method-stores/costs/:store_id')
        }
      } else {
        //Show Error that the customer has no Shipping Address defined
        setSnackbar({
          children: "No Shipping Address Defined for the Client!",
          severity: "error",
        });
      }
    }
  };

  const setShippingCheck = (i: string, shipping: PropsShippingCosts) => {
    const element = document.getElementById("shipping" + i) as HTMLInputElement;
    element.checked = true;
    setShippingInfo(shipping);
    setShippingCost(shipping.cost);

    // Update Order Total
    updateOrderTotal(cartSubTotal);
  };

  const setPaymentCheck = () => {
    setPaymentAddress({} as PropShippingPaymentAddress);
    // setPaymentMethod("");
    setDiscountPaymentRate(0);
    setDiscountPaymentTotal(0);
  };

  const onSubmit = (order: any) => {
    setCircularLoading(true);

    // Vars that ARE NOT CHANGED
    order.order_status_id = orderInfo?.order_status_id;

    if (!store) {
      setSnackbar({
        children: "No Store Chosen!",
        severity: "error",
      });
      setCircularLoading(false);
      return;
    }
    order.store_id = Number(store?.id);

    // For now, it will use the store main currency, but later, it will be able to change it
    order.currency_id = store?.currency_id;

    if (!customer) {
      setSnackbar({
        children: "No Customer Chosen!",
        severity: "error",
      });
      setCircularLoading(false);
      return;
    }
    order.customer_id = Number(customer?.id);

    if (cashier) {
      order.cashier_id = Number(cashier?.id);
    }

    if (seller) {
      order.seller_id = Number(seller?.id);
    }

    if (paymentAddress) {
      // deal with Payment Method here. Develop it later
      //Check if is_paid will be implemented here later
    }

    if (paymentInfo) {
      // deal with Payment Method here. Develop it later
      //Check if is_paid will be implemented here later
    }

    if (!shippingInfo) {
      setSnackbar({
        children: "No Shipping Method Chosen!",
        severity: "error",
      });
      setCircularLoading(false);
      return;
    }
    order.shipping_method_id = shippingInfo.shipping_method_id;

    order.subtotal = cartSubTotal;
    order.discount = discountTotal;
    order.taxes = taxesTotal;
    order.shipping_cost = shippingCost;
    order.discount_payment_method = discountPaymentTotal;
    order.total = orderTotal;

    order.warehouse_id = store.store_warehouse_id;

    // Shipping Address
    if (!shippingAddress) {
      setSnackbar({
        children: "No Shipping Destination Chosen!",
        severity: "error",
      });
      setCircularLoading(false);
      return;
    }
    const shipping_address = {} as any;
    shipping_address.country_id = shippingAddress?.country_id;
    shipping_address.name = shippingAddress?.name;
    shipping_address.phone_number = shippingAddress?.phone_number;
    shipping_address.postal_code = shippingAddress?.postal_code;
    shipping_address.address = shippingAddress?.address;
    shipping_address.number = shippingAddress?.number;
    shipping_address.address_2 = shippingAddress?.address_2;
    shipping_address.district = shippingAddress?.district;
    shipping_address.city = shippingAddress?.city;
    shipping_address.state = shippingAddress?.state;
    shipping_address.additional_info = shippingAddress?.additional_info;
    order.shipping_address = shipping_address;

    // Shipping Info
    //Verification already made before
    const shipping_info = {} as any;
    shipping_info.shipping_method_id = shippingInfo?.shipping_method_id;
    shipping_info.code = shippingInfo?.code;
    shipping_info.name = shippingInfo?.description_name;
    shipping_info.description = shippingInfo?.description;
    shipping_info.deliverable_time = shippingInfo?.deliverable_time;
    shipping_info.delivery_unit = shippingInfo?.delivery_unit;
    shipping_info.weight = Number(shippingInfo?.weight);
    shipping_info.height = Number(shippingInfo?.height);
    shipping_info.width = Number(shippingInfo?.width);
    shipping_info.length = Number(shippingInfo?.length);
    shipping_info.cost = Number(shippingInfo?.cost);
    /*     shipping_info.tracking_code = shippingInfo?.tracking_code;
    shipping_info.link_tracking = shippingInfo?.link_tracking; 
    shipping_info.shipped_date = shippingInfo?.shipped_date; */ // these will not be set here
    order.shipping_info = shipping_info;

    // Currency
    const currency = {} as any;
    currency.currency_id = orderCurrency?.currency_id;
    currency.code = orderCurrency?.code;
    currency.value = Number(orderCurrency?.value); // Later, when implement Multiple Currencies, this will change
    order.currency = currency;

    if (!products || products.length === 0) {
      setSnackbar({
        children: "No Products Chosen!",
        severity: "error",
      });
      setCircularLoading(false);
      return;
    }

    const order_products = [];
    for (let index = 0; index < products.length; index++) {
      const order_product = {} as any;
      order_product.product_id = Number(products[index].product_id);
      order_product.ysin = products[index].ysin;
      order_product.sku = products[index].sku;
      order_product.product_type = Number(products[index].product_type);
      order_product.price_unit_sold = Number(products[index].price_unit_sold);
      order_product.quantity_sold = Number(products[index].quantity);
      order_product.quantity_unit_sold = products[index].sell_unit;
      order_product.price_total_sold = Number(
        Number(products[index].quantity) *
          Number(products[index].price_unit_sold)
      );
      order_products.push(order_product);
    }
    order.products = order_products;

    // Payment Address
    // do it later
    // Payment Info
    // do it later

    // Order Status

    order.username = Cookies.get("username") || "";

    api
      .put(`orders/${id}`, order)
      .then((response) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Order Updated!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        //reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "" + error.response.data.message,
          // footer: '<a href="">Why do I have this issue?</a>',
        });
        setCircularLoading(false);
        setRequestError(true);
        setRequestErrorData(error.response.data.message);
      });
  };

  // After Order is Made, CANNOT CHANGE THE FOLLOWING INFO
  // Store
  // Customer
  // Currency
  // Products
  const fetchOrder = async () => {
    const response = await api.get(`orders/${id}`);
    reset(response.data);
    setOrderInfo(response.data);
    setStore(response.data.store);
    setOrderCurrency(response.data.order_currency);
    setCustomer(response.data.customer);
    setOrderStatus(response.data.order_status);
    setCashier(response.data.cashier);
    setSeller(response.data.cashier);
    // setPaymentMethod(response.data.payment_method);
    setShippingAddress(response.data.order_shipping_address);

    setShippingInfo({
      ...response.data.order_shipping_info,
      description_name: response.data.shipping_method.descriptions[0].name,
      description: response.data.shipping_method.descriptions[0].description,
    });
    // setOrderCurrency(response.data.order_currency);

    setPaymentAddress(response.data.order_payment_address);
    setPaymentInfo(response.data.order_payment_info);
    // setUpdatedAt(response.data.updated_at);
    // Map response.data.products to be able to insert at setProducts
    // Load Products according to the function loadOptionsProductsStore
    const product_id_list = response.data.order_products.map(
      (product_mapped: any) =>
        //  product_id: number; image: string; sku: string
        product_mapped.product_id
    );

    setOrderStatusHistory(response.data.order_status_history);

    const responseProducts = await api.get(
      `product-stores/findallfororder/${product_id_list}`
    );

    const productsArray = responseProducts.data.map(
      (product_mapped: PropsProductCart, index: number) => ({
        ...product_mapped,
        quantity: response.data.order_products[index].quantity_sold,
        price_unit_sold: response.data.order_products[index].price_unit_sold,
      })
    );

    setProducts(productsArray);

    updateSubTotalProducts(productsArray);

    const discount = response.data.discount;
    if (discount) {
      updateDiscount(true, Number(discount));
    }

    const taxes = response.data.taxes;
    if (taxes) {
      updateTaxes(Number((100 * taxes) / (cartSubTotal + discountTotal)));
    }

    setShippingCost(Number(response.data.shipping_cost));

    const discountPayment = response.data.discount_payment_method;
    if (discountPayment) {
      setDiscountPaymentTotal(discountPayment);
    }

    // reset(response.data);
  };

  useEffect(() => {
    fetchImageFolder();
    fetchOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateOrderTotal(cartSubTotal);
  }, [cartSubTotal, updateOrderTotal]);

  //Flow of Page
  // USe Tabs
  // Store
  // Customer Info (Link to Create Customer in Case it is not registered, future do using Modal)

  return (
    <Container component="main" maxWidth="xl">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{ fontSize: "40px", marginBottom: "10px" }}
        >
          Edit Order {id}
        </Typography>
        {requestError && (
          <List component="nav" aria-label="BackEnd Return">
            {requestErrorData.map((error) => (
              <ListItem key={error}>
                <ListItemText
                  sx={{
                    color: "red",
                  }}
                  primary={error}
                />
              </ListItem>
            ))}
          </List>
        )}
        <TabContext value={tabValue}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              maxWidth: "xl",
              minWidth: 3 / 4,
            }}
          >
            <TabList onChange={handleChangeTab} centered aria-label="tabs">
              <Tab label="Essential Info*" value="1" />
              <Tab label="Products*" value="2" />
              <Tab label="Shipping Info*" value="3" />
              <Tab label="Payment Info" value="4" />
              <Tab label="Totals/Checkout*" value="5" />

              {/* <Tab label="Status" value="5" /> */}
            </TabList>
          </Box>
          <Box
            component="form"
            sx={{
              mt: 1,
              minWidth: 3 / 4,
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            {/* Essential Info */}
            <TabPanel
              value="1"
              sx={{
                width: "100%",
                typography: "body",
                maxWidth: "xl",
                minWidth: 3 / 4,
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "8px",
                  marginLeft: 1,
                }}
              >
                <p>
                  Order Created At:{" "}
                  <b>
                    {moment(orderInfo?.created_at).format(
                      "DD/MM/YYYY hh:mm:ss"
                    )}
                  </b>{" "}
                  / Last Update At{" "}
                  <b>
                    {moment(orderInfo?.updated_at).format(
                      "DD/MM/YYYY hh:mm:ss"
                    )}
                  </b>{" "}
                  by <b>{orderInfo?.last_change_username}</b>
                </p>
                <br></br>
                <p>
                  Current Order Status: <b>{orderStatus?.code}</b>
                  <br></br>
                  {orderStatus ? (
                    orderStatus.descriptions ? (
                      <>
                        <b>{orderStatus?.descriptions[0].name}</b>
                        <br></br> {orderStatus?.descriptions[0].description}
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </p>
                <br></br>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "8px",
                  marginLeft: 1,
                }}
              >
                <p>
                  Store: <b>{store?.id}</b> / <b>{store?.name}</b>
                </p>
              </Box>

              <p>
                <br></br>
              </p>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "8px",
                  marginLeft: 1,
                }}
              >
                <p>
                  Customer: <b>{customer?.id}</b> / <b>{customer?.email}</b> /{" "}
                  <b>
                    {customer?.first_name} {customer?.last_name}
                  </b>{" "}
                </p>
              </Box>
              <p>
                <br></br>
              </p>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "8px",
                  marginLeft: 1,
                }}
              >
                <InputLabel id="cashier-filter-select">Cashier</InputLabel>
                <AsyncPaginate
                  placeholder="Select Cashier"
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                  additional={defaultAdditional}
                  key={"cashier"}
                  id="cashier"
                  name="cashier"
                  value={cashier}
                  getOptionValue={(option: any) => option?.id}
                  getOptionLabel={(option: any) => option?.code}
                  loadOptions={loadPageOptionsCashier}
                  onChange={setCashier}
                />
                <p>
                  Cashier: <b>{cashier?.id}</b> / <b>{cashier?.username}</b> /{" "}
                  <b>{cashier?.name}</b> / <b>Code: {cashier?.code}</b>{" "}
                </p>
              </Box>
              <p>
                <br></br>
              </p>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "8px",
                  marginLeft: 1,
                }}
              >
                <InputLabel id="seller-filter-select">Seller</InputLabel>
                <AsyncPaginate
                  placeholder="Select Seller"
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                  additional={defaultAdditional}
                  key={"seller"}
                  id="seller"
                  name="seller"
                  value={seller}
                  getOptionValue={(option: any) => option?.id}
                  getOptionLabel={(option: any) => option?.code}
                  loadOptions={loadPageOptionsSeller}
                  onChange={setSeller}
                />
                <p>
                  Seller: <b>{seller?.id}</b> / <b>{seller?.username}</b> /{" "}
                  <b>{seller?.name}</b> / <b>Code: {seller?.code}</b>{" "}
                </p>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "8px",
                  marginLeft: 1,
                }}
              >
                <TextField
                  // sx={{ mt: 1, minWidth: 3 / 4 }}
                  // InputLabelProps={{ shrink: true }}
                  margin="normal"
                  fullWidth
                  multiline
                  rows={5}
                  id={"#comments"}
                  type="text"
                  label="Internal Comments Section -They are not send to the client"
                  {...register(`comments`)}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "8px",
                  marginLeft: 1,
                }}
              >
                <TextField
                  // sx={{ mt: 1, minWidth: 3 / 4 }}
                  // InputLabelProps={{ shrink: true }}
                  margin="normal"
                  fullWidth
                  multiline
                  rows={5}
                  id={"#comments_client"}
                  type="text"
                  label="Comments Client Section - These are sent to the client"
                  {...register(`comments_client`)}
                />
              </Box>
              <br></br>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "8px",
                  marginLeft: 1,
                }}
              >
                {" "}
                <Typography
                  component="h1"
                  variant="h5"
                  sx={{ fontSize: "40px", marginBottom: "10px" }}
                >
                  Order Status History
                </Typography>
                To update the Order Status, please access the Update Order
                Status Menu
                <br></br>
                {orderStatusHistory.map((statusHistory, index) => {
                  return (
                    <p key={index}>
                      {" "}
                      Date Updated:{" "}
                      <b>
                        {moment(statusHistory.created_at).format(
                          "DD/MM/YYYY hh:mm:ss"
                        )}
                      </b>
                      <br></br>
                      Notify Customer: <b>{String(statusHistory.notify)}</b>
                      <br></br>
                      Status: <b>{statusHistory.order_status.code}</b>
                      <br></br>
                      <b>Comments sent to Client: </b>
                      {statusHistory.comments_client}
                      <br></br>
                      <b>Comments: </b>
                      {statusHistory.comments}
                      <br></br>
                    </p>
                  );
                })}
              </Box>
            </TabPanel>
            <TabPanel
              value="2"
              sx={{
                width: "100%",
                typography: "body",
                maxWidth: "xl",
                minWidth: 3 / 4,
                alignItems: "center",
              }}
            >
              <Box sx={{ width: "100%", typography: "body1" }}>
                Products*
                <p>If Payment has been made, Products CANNOT BE changed!!!</p>
                {!orderInfo?.is_paid ? (
                  <AsyncPaginate
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Select Products"
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    additional={defaultAdditional}
                    key={"product_ids"}
                    id="products"
                    name="products"
                    getOptionValue={(option: any) => option?.id}
                    getOptionLabel={(option: any) => option?.sku}
                    loadOptions={loadPageOptionsProductsStore}
                    value={products}
                    onChange={onChangeProducts}
                  />
                ) : (
                  <></>
                )}
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors?.products?.toString()}
                </p>
                {products.map((input, index) => {
                  return (
                    <p key={index}>
                      {" "}
                      Product ID / SKU: {input.product_id} / {input.sku}
                    </p>
                  );
                })}
                <div style={{ marginTop: "50px", width: "100%" }}>
                  {orderCurrency &&
                    products.map((product, indexProduct) => {
                      return (
                        <Box key={indexProduct}>
                          <Box
                            key={indexProduct}
                            sx={{
                              width: "100%",
                              marginBottom: 0,
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                marginRight: "8px",
                                marginLeft: 1,
                                width: "20%",
                              }}
                            >
                              <div>
                                Product ID / SKU: {product.product_id} /{" "}
                                {product.sku}
                                <br></br>
                                <CardMedia
                                  component="img"
                                  sx={{ width: 151, display: "inline" }}
                                  image={`${imageBaseURL}${product.image}`}
                                  alt={"image"}
                                />
                              </div>
                            </Box>
                            <TextField
                              // ref={textFieldQtyRef[indexProduct]}
                              sx={{
                                mt: 1,
                                marginTop: 10,
                                minWidth: 1 / 10,
                                width: "10%",
                              }}
                              InputProps={{
                                readOnly: orderInfo ? orderInfo.is_paid : false,
                              }}
                              InputLabelProps={{ shrink: true }}
                              margin="normal"
                              required
                              label="Quantity"
                              id={String(indexProduct)}
                              type="number"
                              value={products[indexProduct].quantity}
                              /*                             {...register(`products[${indexProduct}].quantity`, {
                              required: `products[${indexProduct}].quantity is required`,
                            })} */
                              onChange={(e) =>
                                changeProductQuantity(
                                  product.product_id,
                                  Number(e.target.value)
                                )
                              }
                            />
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                marginRight: "8px",
                                marginLeft: 1,
                                width: "5%",
                                marginTop: 12,
                                minWidth: 1 / 20,
                              }}
                            >
                              {product.sell_unit}
                            </Box>
                            <Box
                              sx={{
                                mt: 1,
                                marginTop: 5,
                                minWidth: 1 / 5,
                                width: "20%",
                              }}
                            >
                              <p>
                                Price Online:{" "}
                                {product.product_store_is_sale ? (
                                  <>
                                    <span
                                      style={{ textDecoration: "line-through" }}
                                    >
                                      {Number(
                                        product.product_store_price
                                      ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: orderCurrency?.code,
                                      })}
                                    </span>{" "}
                                    {Number(
                                      product.product_store_price_sale
                                    ).toLocaleString("en-US", {
                                      style: "currency",
                                      currency: orderCurrency?.code,
                                    })}
                                  </>
                                ) : (
                                  Number(
                                    product.product_store_price
                                  ).toLocaleString("en-US", {
                                    style: "currency",
                                    currency: orderCurrency?.code,
                                  })
                                )}
                              </p>
                              <p>
                                Price PDV:{" "}
                                {product.product_pdv_store_is_sale ? (
                                  <>
                                    <span
                                      style={{ textDecoration: "line-through" }}
                                    >
                                      {Number(
                                        product.product_pdv_store_price
                                      ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: orderCurrency?.code,
                                      })}
                                    </span>{" "}
                                    {Number(
                                      product.product_pdv_store_price_sale
                                    ).toLocaleString("en-US", {
                                      style: "currency",
                                      currency: orderCurrency?.code,
                                    })}
                                  </>
                                ) : (
                                  Number(
                                    product.product_pdv_store_price
                                  ).toLocaleString("en-US", {
                                    style: "currency",
                                    currency: orderCurrency?.code,
                                  })
                                )}
                              </p>
                              <p>
                                Price Wholesale Online:{" "}
                                {product.product_wholesale_store_is_sale ? (
                                  <>
                                    <span
                                      style={{ textDecoration: "line-through" }}
                                    >
                                      {Number(
                                        product.product_wholesale_store_price
                                      ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: orderCurrency?.code,
                                      })}
                                    </span>{" "}
                                    {Number(
                                      product.product_wholesale_store_price_sale
                                    ).toLocaleString("en-US", {
                                      style: "currency",
                                      currency: orderCurrency?.code,
                                    })}
                                  </>
                                ) : (
                                  Number(
                                    product.product_wholesale_store_price
                                  ).toLocaleString("en-US", {
                                    style: "currency",
                                    currency: orderCurrency?.code,
                                  })
                                )}
                              </p>
                              <p>
                                Price Wholesale PDV:{" "}
                                {product.product_wholesale_pdv_store_price_sale ? (
                                  <>
                                    <span
                                      style={{ textDecoration: "line-through" }}
                                    >
                                      {Number(
                                        product.product_wholesale_pdv_store_price
                                      ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: orderCurrency?.code,
                                      })}
                                    </span>{" "}
                                    {Number(
                                      product.product_wholesale_pdv_store_price_sale
                                    ).toLocaleString("en-US", {
                                      style: "currency",
                                      currency: orderCurrency?.code,
                                    })}
                                  </>
                                ) : (
                                  Number(
                                    product.product_wholesale_pdv_store_price
                                  ).toLocaleString("en-US", {
                                    style: "currency",
                                    currency: orderCurrency?.code,
                                  })
                                )}
                              </p>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                marginRight: "8px",
                                marginLeft: 1,
                                width: "10%",
                                minWidth: 1 / 10,
                                mt: 1,
                                marginTop: 6,
                              }}
                            >
                              <InputLabel id="currency-label">
                                {orderCurrency?.code}
                              </InputLabel>
                              <TextField
                                InputProps={{
                                  readOnly: orderInfo
                                    ? orderInfo.is_paid
                                    : false,
                                }}
                                InputLabelProps={{ shrink: true }}
                                margin="normal"
                                required
                                label="Price Unit Sell"
                                id={`#products[${indexProduct}].price_unit_sold`}
                                type="number"
                                value={product.price_unit_sold}
                                /*                             {...register(
                              `products[${indexProduct}].price_unit_sold`,
                              {
                                required: `products[${indexProduct}].price_unit_sold is required`,
                              }
                            )} */
                                onChange={(e) =>
                                  changeProductUnitPrice(
                                    product.product_id,
                                    Number(e.target.value)
                                  )
                                }
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                marginRight: 1,
                                marginLeft: 1,
                                width: "5%",
                                marginTop: 8,
                                minWidth: 1 / 20,
                              }}
                            >
                              Total:{" "}
                              {Number(
                                Number(product.quantity) *
                                  Number(product.price_unit_sold)
                              ).toLocaleString("en-US", {
                                style: "currency",
                                currency: orderCurrency?.code,
                              })}
                            </Box>
                            <IconButton
                              color="primary"
                              aria-label="delete product from cart"
                              component="label"
                              size="large"
                              // className={classes.delete}
                              onClick={() => {
                                deleteProductFromCart(product.product_id);
                              }}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </Box>

                          <p>
                            {" "}
                            {product.name} -{" "}
                            <a
                              href={
                                store?.url +
                                "/" +
                                inputsName[product.language_id - 1].code +
                                "/product/" +
                                product.seo_url
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              Product Link
                            </a>
                          </p>
                          <p>
                            <br></br>
                          </p>
                        </Box>
                      );
                    })}
                </div>
                <Typography
                  component="h3"
                  variant="h3"
                  sx={{ fontSize: "24px", marginBottom: "10px" }}
                >
                  Total Itens: {cartItensTotal}, Cart Total:{" "}
                  {cartSubTotal ? (
                    cartSubTotal.toLocaleString("en-US", {
                      style: "currency",
                      currency: orderCurrency?.code,
                    })
                  ) : (
                    <span>There are no Products in the Cart</span>
                  )}
                </Typography>
              </Box>
            </TabPanel>
            <TabPanel
              value="3"
              sx={{
                width: "100%",
                typography: "body",
                maxWidth: "xl",
                minWidth: 3 / 4,
                alignItems: "center",
              }}
            >
              Get Avaliable Shipping Options: Est. Delivery and Costs
              <br></br>
              {customer ? (
                <>
                  <p>
                    If Payment has been made, Shipping Info CANNOT BE changed!!!
                  </p>
                  {!orderInfo?.is_paid ? (
                    <>
                      <InputLabel id="store-label">
                        Shipping Addresses Avaliable for the Client*
                      </InputLabel>
                      <AsyncPaginate
                        placeholder="Select Address"
                        styles={{
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 9999,
                          }),
                        }}
                        additional={defaultAdditional}
                        key={"customer_address"}
                        id="customer_address"
                        name="customer_address"
                        getOptionValue={(option: any) => option?.id}
                        getOptionLabel={(option: any) =>
                          option?.name +
                          " / Postal Code: " +
                          option?.postal_code +
                          " / " +
                          option?.address +
                          ", " +
                          option?.number
                        }
                        loadOptions={loadPageOptionsCustomerAddresses}
                        value={shippingAddress}
                        onChange={setShippingAddress}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  <p>
                    Customer ID: <b>{customer?.id}</b> /{" "}
                    <b>{customer?.first_name + " " + customer?.last_name}</b>
                  </p>
                  <div>
                    <b>
                      {shippingAddress?.name}, {shippingAddress?.phone_number}
                    </b>
                    <p>
                      {shippingAddress?.address}, {shippingAddress?.number}
                    </p>
                    <p>
                      {shippingAddress?.address_2} {shippingAddress?.district}
                    </p>
                    <p>
                      {shippingAddress?.city}, {shippingAddress?.state},{" "}
                      {shippingAddress?.country_code},{" "}
                      {shippingAddress?.postal_code}
                    </p>
                  </div>
                </>
              ) : (
                <></>
              )}
              {orderCurrency ? (
                <>
                  <br></br>
                  <p>Current Shipping Option:</p>
                  <h4>
                    <b>CODE: {shippingInfo?.code}</b>
                  </h4>
                  <h4>
                    <b>{shippingInfo?.description_name}</b>
                  </h4>
                  <p>{shippingInfo?.description}</p>
                  <p>
                    {Number(shippingInfo?.cost).toLocaleString("en-US", {
                      style: "currency",
                      currency: orderCurrency?.code,
                    })}
                    {" - "}
                    {Number(shippingInfo?.deliverable_time)}{" "}
                    {shippingInfo?.delivery_unit} After Payment
                  </p>
                  <br></br>
                  <p>
                    Parcel Weight: <b>{shippingInfo?.weight} Kg</b>
                  </p>
                  <p>
                    Dimensions:{" "}
                    <b>
                      (Width) {shippingInfo?.width} mm x (Height){" "}
                      {shippingInfo?.height} mm x (Length){" "}
                      {shippingInfo?.length} mm
                    </b>
                  </p>
                  <br></br>
                </>
              ) : (
                <></>
              )}
              {!orderInfo?.is_paid ? (
                <>
                  <p>
                    If it is desired to change the shipping Method, please click
                    the button below
                  </p>
                  <Button
                    variant="contained"
                    sx={{ m: 3 }}
                    onClick={getShippingOptions}
                  >
                    Get Shipping Options
                  </Button>{" "}
                  It accesses external APIs, so might take a few seconds to
                  update
                  <br></br>
                </>
              ) : (
                <></>
              )}
              {shippings.map((shippingsItem, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      marginRight: 1,
                      marginLeft: 1,
                      width: "50%",
                      marginTop: 2,
                      minWidth: 1 / 2,
                    }}
                  >
                    <Radio
                      // label=""
                      checked={shippingInfo?.code === shippingsItem.code}
                      name="shipping_method"
                      id={`shipping${index}`}
                      value={shippingsItem.cost}
                      onClick={() => {
                        setShippingCheck(String(index), shippingsItem);
                      }}
                    />
                    <div>
                      <h4>
                        <b>{shippingsItem.description_name}</b>
                      </h4>
                      <p>
                        {shippingsItem.cost.toLocaleString("en-US", {
                          style: "currency",
                          currency: orderCurrency?.code,
                        })}
                        {" - "}
                        {Number(shippingsItem.deliverable_time)}{" "}
                        {shippingsItem.delivery_unit} After Payment
                      </p>
                    </div>
                  </Box>
                );
              })}
            </TabPanel>
            <TabPanel
              value="4"
              sx={{
                width: "100%",
                typography: "body",
                maxWidth: "xl",
                minWidth: 3 / 4,
                alignItems: "center",
              }}
            >
              Get Avaliable Payment Methods and Total Amount. If it is credit
              Card, at the Clients Order Page, it will have a link for payment
              there
              <Button
                variant="contained"
                sx={{ m: 3 }}
                onClick={setPaymentCheck}
              >
                Get Payment Options - under dev
              </Button>{" "}
            </TabPanel>
            <TabPanel
              value="5"
              sx={{
                width: "100%",
                // typography: "body",
                fontSize: "20px",
                maxWidth: "xl",
                minWidth: 3 / 4,
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "40%",
                  }}
                >
                  Sub Total (Products):
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "20%",
                  }}
                >
                  {cartSubTotal ? (
                    <span>{cartItensTotal} Itens in the Cart</span>
                  ) : (
                    <span></span>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "40%",
                    textAlign: "right",
                  }}
                >
                  {cartSubTotal ? (
                    cartSubTotal.toLocaleString("en-US", {
                      style: "currency",
                      currency: orderCurrency?.code,
                    })
                  ) : (
                    <span>There are no Products in the Cart</span>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "30%",
                  }}
                >
                  Discount:
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "20%",
                  }}
                >
                  <Select
                    readOnly={orderInfo ? orderInfo?.is_paid : false}
                    labelId="percentage-fixed-select-label"
                    id="percentage-fixed-select"
                    value={discountIsFixed}
                    onChange={(e) =>
                      onChangeDiscountIsFixed(Boolean(e.target.value))
                    }
                  >
                    <MenuItem value={false as any}>Percentage</MenuItem>
                    <MenuItem value={true as any}>Fixed</MenuItem>
                  </Select>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "10%",
                  }}
                >
                  <TextField
                    id={"discount-input"}
                    inputProps={{
                      min: 0,
                      style: { textAlign: "right" },
                      readOnly: orderInfo ? orderInfo.is_paid : false,
                    }}
                    type="number"
                    label="Discount Rate"
                    value={discountRate}
                    onChange={(e) =>
                      onChangeDiscountRate(Number(e.target.value))
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "40%",
                    textAlign: "right",
                  }}
                >
                  {products.length ? (
                    discountTotal.toLocaleString("en-US", {
                      style: "currency",
                      currency: orderCurrency?.code,
                    })
                  ) : (
                    <span></span>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "30%",
                  }}
                >
                  Taxes:
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "20%",
                  }}
                >
                  Percentage
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "10%",
                  }}
                >
                  <TextField
                    id={"discount-input"}
                    inputProps={{
                      min: 0,
                      style: { textAlign: "right" },
                      readOnly: orderInfo ? orderInfo.is_paid : false,
                    }}
                    type="number"
                    label="Taxes Rate"
                    value={taxesRate}
                    onChange={(e) => onChangeTaxesRate(Number(e.target.value))}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "40%",
                    textAlign: "right",
                  }}
                >
                  {products.length ? (
                    taxesTotal.toLocaleString("en-US", {
                      style: "currency",
                      currency: orderCurrency?.code,
                    })
                  ) : (
                    <span></span>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "60%",
                  }}
                >
                  Shipping Cost:
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "40%",
                    textAlign: "right",
                  }}
                >
                  {products.length ? (
                    shippingCost.toLocaleString("en-US", {
                      style: "currency",
                      currency: orderCurrency?.code,
                    })
                  ) : (
                    <span></span>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "40%",
                  }}
                >
                  Discount from Payment Method:
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "20%",
                  }}
                >
                  {paymentInfo?.code} {discountPaymentRate}%
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "40%",
                    textAlign: "right",
                  }}
                >
                  {products.length ? (
                    discountPaymentTotal.toLocaleString("en-US", {
                      style: "currency",
                      currency: orderCurrency?.code,
                    })
                  ) : (
                    <span></span>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  fontSize: "24px",
                  fontWeight: "bold",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "60%",
                  }}
                >
                  Order Total:
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "8px",
                    marginLeft: 1,
                    width: "40%",
                    textAlign: "right",
                  }}
                >
                  {products.length ? (
                    orderTotal.toLocaleString("en-US", {
                      style: "currency",
                      currency: orderCurrency?.code,
                    })
                  ) : (
                    <span></span>
                  )}
                </Box>
              </Box>
            </TabPanel>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  mt: 2,
                  marginBottom: 10,
                }}
              >
                {!!snackbar && (
                  <Snackbar
                    open
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                  >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                  </Snackbar>
                )}
                <Button
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  size="large"
                  endIcon={<SendIcon />}
                  disabled={circularLoading}
                  type="submit"
                >
                  Update Order
                  {circularLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: green[500],
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Button>
              </Stack>
            </Box>
          </Box>
        </TabContext>
      </Box>
    </Container>
  );
}
