import { createAction, createReducer } from "@reduxjs/toolkit";
import { LoginAction, LogoutAction } from "../../../types/actions";
import Cookies from "js-cookie";

const INITIAL_STATE = {
  // isAuthenticated: localStorage.getItem("token") ? true : false,
  isAuthenticated: Cookies.get("token") ? true : false,
  // role: localStorage.getItem("roles") ? localStorage.getItem("role") : 0,
  role: Cookies.get("roles") ? Cookies.get("roles") : 0,
  /*   username: localStorage.getItem("username")
    ? localStorage.getItem("username")
    : "", */
  username: Cookies.get("username") ? Cookies.get("username") : "",
  /* user_id: localStorage.getItem("user_id")
    ? localStorage.getItem("user_id")
    : "", */
  user_id: Cookies.get("user_id") ? Cookies.get("user_id") : "",
};

export const login = createAction<LoginAction>("LOGIN");
export const logout = createAction<LogoutAction>("LOGOUT");

export default createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(login.type, (state, action: LoginAction) => ({
      ...state,
      isAuthenticated: action.payload.isAuthenticated,
      role: action.payload.role,
      username: action.payload.username,
    }))
    .addCase(logout.type, (state) => ({
      ...state,
      isAuthenticated: false,
      role: 0,
      username: "",
    }))
    .addDefaultCase((state, action) => {});
});
