/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Stack,
  List,
  ListItemText,
  ListItem,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import api from "../../services/api";
import Swal from "sweetalert2";
import { inputsName } from "../../config/languageConst";

export default function NewOrderStatus() {
  const [circularLoading, setCircularLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data: any) => {
    setCircularLoading(true);

    // data.language_id = Number(data.language_id);
    // if (data.language_id !== 0) {
    //   data.language_id = Number(data.language_id);
    // }
    for (let index = 0; index < data.descriptions.length; index++) {
      data.descriptions[index].language_id = Number(
        data.descriptions[index].language_id
      );
    }

    api
      .post("order-status", data)
      .then((response) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Order Status created!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          // footer: '<a href="">Why do I have this issue?</a>',
        });
        setCircularLoading(false);
        setRequestError(false);
        setRequestErrorData(error.response.data.message);
      });
  };

  useEffect(() => {}, []);

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{
            fontSize: "32px",
            marginBottom: "10px",
            alignItems: "center",
            color: "#556cd6",
          }}
        >
          New Order Status
        </Typography>

        {requestError && (
          <List component="nav" aria-label="error">
            {requestErrorData.map((error) => (
              <ListItem key={error}>
                <ListItemText
                  sx={{
                    color: "red",
                  }}
                  primary={error}
                />
              </ListItem>
            ))}
          </List>
        )}
        <Box
          component="form"
          sx={{ mt: 1, minWidth: 3 / 4, width: "100%" }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            margin="normal"
            // InputLabelProps={{ shrink: true }}
            required
            fullWidth
            id="code"
            type="text"
            label="CODE"
            {...register("code")}
            autoFocus
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.code?.toString()}
          </p>

          <div style={{ marginTop: "30px" }}>
            {inputsName.map((input, index) => (
              <div
                key={index}
                className="flags-container"
                style={{ display: "block", marginTop: "50px" }}
              >
                <div className="fa-flags">
                  <img
                    src={`/assets/language/${input.language_id}.png`}
                    alt=""
                  />
                  &nbsp;-&nbsp;{input.language_name}
                </div>
                <input
                  hidden
                  {...register(`descriptions[${index}].language_id`)}
                  value={input.language_id}
                />

                <TextField
                  sx={{ mt: 1, minWidth: 3 / 4 }}
                  // InputLabelProps={{ shrink: true }}
                  margin="normal"
                  required
                  fullWidth
                  id={"#descriptions[" + index + "].name"}
                  type="text"
                  label="Name"
                  {...register(`descriptions[${index}].name`)}
                />
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors?.descriptions?.toString()}
                </p>

                <p style={{ color: "red", textAlign: "center" }}>
                  {errors?.descriptions?.toString()}
                </p>
                <TextField
                  // InputLabelProps={{ shrink: true }}
                  margin="normal"
                  fullWidth
                  id={"#descriptions[" + index + "].description"}
                  type="text"
                  label="Description (It will be shown to the Customer on his Order Page describing the current Order Status)"
                  rows={10}
                  multiline
                  {...register(`descriptions[${index}].description`)}
                />
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors?.descriptions?.toString()}
                </p>
              </div>
            ))}
          </div>

          <Stack
            direction="row"
            spacing={2}
            sx={{
              mt: 2,
            }}
          >
            <Button
              onClick={() => reset()}
              variant="outlined"
              startIcon={<DeleteIcon />}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              size="large"
              endIcon={<SendIcon />}
              disabled={circularLoading}
              type="submit"
            >
              Send
              {circularLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
}
