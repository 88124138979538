/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  ListItem,
  ListItemText,
  List,
  InputAdornment,
  IconButton,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import api from "../../services/api";
import Swal from "sweetalert2";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function NewUsers() {
  const [circularLoading, setCircularLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (user: any) => {
    setCircularLoading(true);
    user.role_id = Number(user.role_id);
    user.status = Number(user.status);
    if (user.country_id !== "") {
      user.country_id = Number(user.country_id);
    } else {
      delete user.country_id;
    }
    api
      .post("users", user)
      .then((response) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "User created!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "" + error.response.data.message,
          footer: '<a href="">Why do I have this issue?</a>',
        });
        setCircularLoading(false);
        setRequestError(true);
        setRequestErrorData(error.response.data.message);
      });
  };

  const [status, setStatus] = React.useState("");
  const handleChangeStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  // Add these variables to your component to track the state
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [password, setPassword] = useState("");
  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const [role, setRole] = useState("");
  const handleChangeRole = (event: SelectChangeEvent) => {
    setRole(event.target.value as string);
  };

  const [country, setCountry] = useState("");
  const handleChangeCountry = (event: SelectChangeEvent) => {
    setCountry(event.target.value as string);
  };

  const [roles, setRoles] = React.useState<{ id: number; name: string }[]>([]);
  const fetchRoles = async () => {
    const response = await api.get(`roles`);
    setRoles(response.data.items);
  };

  const [countries, setCountries] = React.useState<
    { id: number; name: string }[]
  >([]);
  const fetchCountries = async () => {
    const response = await api.get(`countries`);
    setCountries(response.data.items);
  };

  useEffect(() => {
    fetchRoles();
    fetchCountries();
  }, []);

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{
            fontSize: "32px",
            marginBottom: "10px",
            alignItems: "center",
            color: "#556cd6",
          }}
        >
          New User
        </Typography>
        {requestError && (
          <List component="nav" aria-label="erros">
            {requestErrorData.map((error) => (
              <ListItem key={error}>
                <ListItemText
                  sx={{
                    color: "red",
                  }}
                  primary={error}
                />
              </ListItem>
            ))}
          </List>
        )}
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            minWidth: 3 / 4,
            marginBottom: "30px",
            width: "100%",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormControl fullWidth>
            <InputLabel id="status-select">Status</InputLabel>
            <Select
              labelId="status-select-label"
              id="status"
              value={status}
              {...register("status", {})}
              label="Status"
              onChange={handleChangeStatus}
            >
              <MenuItem value={1}>Enabled</MenuItem>
              <MenuItem value={0}>Disabled</MenuItem>
            </Select>
          </FormControl>
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.status?.toString()}
          </p>

          <TextField
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
            fullWidth
            id="username"
            type="text"
            label="Username"
            autoComplete="new-password"
            {...register("username", {})}
            autoFocus
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.username?.toString()}
          </p>

          <TextField
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
            fullWidth
            id="first_name"
            type="text"
            label="First Name"
            autoComplete="new-password"
            {...register("first_name")}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.first_name?.toString()}
          </p>

          <TextField
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
            fullWidth
            id="last_name"
            type="text"
            label="Last Name"
            autoComplete="new-password"
            {...register("last_name")}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.last_name?.toString()}
          </p>

          <TextField
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
            fullWidth
            id="email"
            type="email"
            label="Email"
            autoComplete="new-password"
            {...register("email")}
            autoFocus
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.email?.toString()}
          </p>

          <TextField
            margin="normal"
            fullWidth
            label="Password"
            //type="password"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="new-password"
            {...register("password")}
            value={password}
            onChange={handleChangePassword}
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.password?.toString()}
          </p>

          <TextField
            margin="normal"
            InputLabelProps={{ shrink: true }}
            fullWidth
            id="profile_photo"
            type="text"
            label="Profile Photo"
            {...register("image")}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.profile_photo?.toString()}
          </p>

          <TextField
            margin="normal"
            InputLabelProps={{ shrink: true }}
            fullWidth
            id="company_position"
            type="text"
            label="Company Position"
            {...register("company_position")}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.company_position?.toString()}
          </p>

          <TextField
            fullWidth
            InputLabelProps={{ shrink: true }}
            multiline
            margin="normal"
            id="comments"
            type="text"
            label="Comments"
            rows={6}
            {...register("comments")}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.comments?.toString()}
          </p>

          <div style={{ marginTop: "30px" }}></div>
          <FormControl fullWidth>
            <InputLabel id="country-label">Country</InputLabel>
            <Select
              labelId="country-select-label"
              id="country"
              value={country}
              {...register("country_id")}
              label="Country"
              onChange={handleChangeCountry}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {countries.map((country, index) => (
                <MenuItem value={country.id} key={index}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.country_id?.toString()}
          </p>

          <div style={{ marginTop: "30px" }}></div>
          <FormControl fullWidth>
            <InputLabel id="role-label">Role</InputLabel>
            <Select
              labelId="role-select-label"
              id="role"
              value={role}
              {...register("role_id", {})}
              label="Role"
              onChange={handleChangeRole}
            >
              {roles.map((role, index) => (
                <MenuItem value={role.id} key={index}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.role_id?.toString()}
          </p>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              mt: 2,
            }}
          >
            <Button
              onClick={() => reset()}
              variant="outlined"
              startIcon={<DeleteIcon />}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              size="large"
              endIcon={<SendIcon />}
              disabled={circularLoading}
              type="submit"
            >
              Send
              {circularLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>
          </Stack>
        </Box>
        <div style={{ marginTop: "100px" }}></div>
      </Box>
    </Container>
  );
}
export default NewUsers;
