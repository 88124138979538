/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import api from "../../../services/api";
import { green } from "@mui/material/colors";
import {
  Alert,
  AlertProps,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Modal,
  Pagination,
  Snackbar,
  Stack,
  TextField,
  Typography,
  CardMedia,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import TableHorizontal from "../../../components/TableHorizontal";
import { LogTrackUser } from "../../../types/log-track-user/log-track-user";
import Cookies from "js-cookie";

export default function EditProductWarehouses() {
  const { id } = useParams<{ id: string }>();

  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////

  //css do modal --------------------------------------------
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  ////////////////////////////////////////////////////////////////

  const [circularLoading, setCircularLoading] = React.useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const [preOrdeSaleEnabled, setPreOrdeSaleEnabled] = useState(false);
  const handleChangePreOrdeSaleEnabled = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPreOrdeSaleEnabled(event.target.checked);
  };
  const [isDiscontinued, setIsDiscontinued] = useState(false);
  const handleChangeIsDiscontinued = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsDiscontinued(event.target.checked);
  };

  const [quantityUnit, setQuantityUnit] = useState("UN");
  /*   const handleChangeQuantityUnit = (event: SelectChangeEvent) => {
    setQuantityUnit(event.target.value);
  }; */

  const [quantityVariation, setQuantityVariation] = useState(0);
  const handleChangeQuantityVariation = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setQuantityVariation(Number(event.target.value));
  };

  const [warehouse, setWarehouse] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [product, setProduct] = useState<{ id: number; sku: string } | null>(
    null
  );
  const [inventory, setInventory] = useState<{
    quantity: number;
    quantity_unit: string;
    location: string;
    pre_order_sale_enabled: boolean;
    is_discontinued: boolean;
  } | null>(null);

  const [imageBaseURL, setimageBaseURL] = useState(
    process.env.REACT_APP_IMG_CATALOG_URL
  );
  const [productImage, setProductImage] = useState("");

  const columns = [
    "Quantity Variation",
    "Unit",
    "Location",
    "Batch Info",
    "Expiration Date",
    "Still In Inventory?",
    "Update",
    "Sale Channel",
    "Created By",
    "When",
  ];
  const keys = [
    "quantity_variation",
    "quantity_unit",
    "location",
    "batch_info",
    "expiration_date",
    "still_in_inventory_yes_no",
    "update_still_in_inventory",
    "sale_channel",
    "username",
    "when",
  ];

  const fetchProductQtyWarehouse = async () => {
    await api.get(`product-qty-warehouse/${id}`).then((response) => {
      reset(response.data);
      setWarehouse({
        id: response.data.warehouse_id,
        name: response.data.warehouse.name,
      });
      setProduct({
        id: response.data.product_id,
        sku: response.data.product.sku,
      });
      setInventory({
        quantity: response.data.quantity,
        quantity_unit:
          response.data.product.product_inventory_sell_attribute.inventory_unit,
        location: response.data.location,
        pre_order_sale_enabled: response.data.product.pre_order_sale_enabled,
        is_discontinued: response.data.product.is_discontinued,
      });
      setQuantityUnit(
        response.data.product.product_inventory_sell_attribute.inventory_unit
      );
      setPreOrdeSaleEnabled(response.data.product.pre_order_sale_enabled);
      setIsDiscontinued(response.data.product.is_discontinued);

      if (imageBaseURL?.substring(imageBaseURL?.length - 1) !== "/") {
        setimageBaseURL(imageBaseURL + "/");
      }
      setProductImage(response.data.product.image);

      fetchHistory(response.data.product_id, response.data.warehouse_id);
    });
  };
  const [pageState, setPageState] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [productQtyWarehouseHistory, setProductQtyWarehouseHistory] = useState(
    []
  );

  const fetchHistory = async (
    product_id: number,
    warehouse_id: number,
    page = 1,
    numberItemsPerPage = 500
  ) => {
    await api
      .get(
        `/product-qty-warehouse/history?page=${page}&limit=${numberItemsPerPage}&product_id=${product_id}&warehouse_id=${warehouse_id}`
      )
      .then((res) => {
        setProductQtyWarehouseHistory(res.data.items);
        setTotalPages(res.data.total_pages);
      });
  };
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageState(value);
    fetchHistory(
      Number(product?.id),
      Number(warehouse?.id),
      pageState,
      numberItemsPerPage
    );
  };

  const [numberItemsPerPage, setNumberItemsPerPage] = useState(500);
  const handleNumberItemsPerPage = (event: SelectChangeEvent) => {
    setNumberItemsPerPage(Number(event.target.value));
    fetchHistory(
      Number(product?.id),
      Number(warehouse?.id),
      pageState,
      Number(event.target.value)
    );
  };

  function fetchHistoryClick() {
    fetchHistory(
      Number(product?.id),
      Number(warehouse?.id),
      pageState,
      numberItemsPerPage
    );
  }

  const [stores, setStores] = React.useState<{ id: number; name: string }[]>(
    []
  );
  const fetchStores = async () => {
    const response = await api.get("stores");
    setStores(response.data.items);
  };

  const [store, setStore] = useState("");
  const handleChangeStore = (event: SelectChangeEvent) => {
    setStore(event.target.value);
  };

  const onSubmit = (productQtyWarehouse: any) => {
    setCircularLoading(true);

    productQtyWarehouse.product_id = Number(product?.id); // Tratar Caso de Null depois
    productQtyWarehouse.warehouse_id = Number(warehouse?.id); // Tratar Caso de Null depois
    productQtyWarehouse.quantity_variation = Number(quantityVariation);
    let username = Cookies.get("username") ? Cookies.get("username") : "";
    productQtyWarehouse.username = username?.replace(/["']/g, "");
    productQtyWarehouse.quantity_unit = quantityUnit;

    if (productQtyWarehouse.batch_info === "") {
      productQtyWarehouse.batch_info = null;
    }
    if (productQtyWarehouse.comments === "") {
      productQtyWarehouse.comments = null;
    }
    if (
      productQtyWarehouse.order_id === "" ||
      productQtyWarehouse.order_id === 0
    ) {
      productQtyWarehouse.order_id = null;
    }
    productQtyWarehouse.order_id = Number(productQtyWarehouse?.order_id);

    if (productQtyWarehouse.sale_channel === "") {
      productQtyWarehouse.sale_channel = null;
    }
    if (productQtyWarehouse.expiration_date === "") {
      productQtyWarehouse.expiration_date = null;
    }
    if (productQtyWarehouse.store_id === "") {
      productQtyWarehouse.store_id = null;
    }

    productQtyWarehouse.pre_order_sale_enabled = preOrdeSaleEnabled;

    //Before Executing, it MUST CHECK whether the pair Product Warehouse already Exists, if YES, then it CANNOT CONTINUE HERE. The route will be another, and it will be redirected there
    //DO IT LATER

    api
      .post("product-qty-warehouse", productQtyWarehouse)
      .then((response) => {
        setCircularLoading(false);
        setRequestError(false);
        /*         Swal.fire({
          title: "Product Quantity Warehouse Updated!",
          icon: "success",
          confirmButtonText: "Yes",
        }); */
        setSnackbar({
          children: "Product Quantity Warehouse Updated!",
          severity: "success",
        });
        //reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "" + error.response.data.message,
          footer: error.message,
        });
        setCircularLoading(false);
        setRequestError(true);
        setRequestErrorData(error.response.data.message);
      });
    setTimeout(function () {
      fetchHistory(
        Number(product?.id),
        Number(warehouse?.id),
        pageState,
        numberItemsPerPage
      );
    }, 1000);
  };

  useEffect(() => {
    fetchProductQtyWarehouse();
    fetchStores();
  }, []);

  return (
    <Container component="main" maxWidth="xl">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{
            fontSize: "32px",
            marginBottom: "10px",
            alignItems: "center",
            color: "#556cd6",
          }}
        >
          Edit Product Quantity Warehouse {id}
        </Typography>

        {requestError && (
          <List component="nav" aria-label="errors">
            {requestErrorData.map((error) => (
              <ListItem>
                <ListItemText
                  sx={{
                    color: "red",
                  }}
                  primary={error}
                />
              </ListItem>
            ))}
          </List>
        )}
        <Box
          component="form"
          sx={{ mt: 1, minWidth: 3 / 4, width: "100%" }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <p>
            Warehouse: <b>{warehouse?.id}</b> / <b>{warehouse?.name}</b>
          </p>
          <p>
            Product: <b>{product?.id}</b> / <b>{product?.sku}</b>
          </p>
          <p>
            Total Inventory: <b>{inventory?.quantity}</b>{" "}
            <b>{inventory?.quantity_unit}</b> located at:{" "}
            <b>{inventory?.location}</b>
          </p>
          <p>
            Pre-Order:{" "}
            <b>{inventory?.pre_order_sale_enabled ? "Enabled" : "Disabled"}</b>
          </p>
          <p>
            Discontinued?: <b>{inventory?.is_discontinued ? "YES" : "NO"}</b>
          </p>
          <br></br>
          Quantity Unit: <b>{quantityUnit}</b>
          <br></br>
          <div>
            <CardMedia
              component="img"
              sx={{ width: 151, display: "inline" }}
              image={`${imageBaseURL}${productImage}`}
              alt={"image"}
            />
          </div>
          <br></br>
          <TextField
            margin="normal"
            required
            sx={{ minWidth: "25%", width: "40%" }}
            id="quantity_variation"
            value={quantityVariation}
            type="number"
            label="Inventory Variation Quantity / Increase (+) or Decrease (-) Inventory"
            {...register("quantity_variation")}
            onChange={handleChangeQuantityVariation}
          />
          {/* <FormControl>
            <InputLabel id="quantity-unit-label">Quantity Unit</InputLabel>
            <Select
              sx={{ minWidth: "140px", width: "15%" }}
              labelId="quantity-unit-label"
              id="warehouse"
              required
              value={quantityUnit}
              {...register("quantity_unit")}
              label="Quantity Unit"
              onChange={handleChangeQuantityUnit}
            >
              <MenuItem value="UN">UN</MenuItem>
              <MenuItem value="KG">KG</MenuItem>
              <MenuItem value="G">G</MenuItem>
              <MenuItem value="M">METERS</MenuItem>
              <MenuItem value="TON">TON</MenuItem>
              <MenuItem value="PACK">PACK</MenuItem>
            </Select>
          </FormControl> */}
          <br></br>
          <TextField
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
            sx={{ minWidth: "25%", width: "45%" }}
            id="location"
            type="string"
            label="Inventory Location in Warehouse"
            {...register("location")}
          />
          <br></br>
          <Button onClick={handleOpen}>Inventory Location Help</Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ color: "#556cd6" }}
              >
                <b>Inventory Location</b>
              </Typography>
              <Typography id="modal-modal-description">
                <b>
                  {" "}
                  BUILDING-FLOOR-STREET-VERTICAL SHELF-TIER/HORIZONTAL
                  SHELF-DIVISION
                </b>
                <br></br>
                <b>PREDIO-ANDAR-RUA-ESTANTE-PRATELEIRA-DIVISÃO</b>
                <br></br>
                Products are stored in one of these locations. The sections is
                separated by slashes (-)<br></br>
                For example: A-1-B-2-C-3 <br></br>
                It means:<br></br>
                Building: A<br></br>
                Floor: 1<br></br>
                Street: B<br></br>
                Shelf: 2<br></br>
                TIER: C<br></br>
                DIVISION: 3<br></br>
              </Typography>
            </Box>
          </Modal>
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.quantity?.toString()}
          </p>
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.location?.toString()}
          </p>
          <FormControlLabel
            label="Pre Order Sale Enabled"
            control={
              <Checkbox
                checked={preOrdeSaleEnabled || false}
                onChange={handleChangePreOrdeSaleEnabled}
                inputProps={{ "aria-label": "Pre Order Sale Enabled" }}
                //{...register("pre_order_sale_enabled")}
              />
            }
          />
          <FormControlLabel
            label="Is Discontinued"
            control={
              <Checkbox
                checked={isDiscontinued || false}
                onChange={handleChangeIsDiscontinued}
                inputProps={{ "aria-label": "Is Discontinued?" }}
                //{...register("is_discontinued")}
              />
            }
          />
          <br></br>
          <TextField
            margin="normal"
            sx={{ minWidth: "25%", width: "45%" }}
            id="batch_info"
            type="string"
            label="Batch Info (If Applicable)"
            {...register("batch_info")}
          />
          <TextField
            id="expiration_date"
            label="Expiration Date  (If Applicable)"
            type="date"
            //defaultValue="2017-05-24"
            sx={{ minWidth: "25%" }}
            InputLabelProps={{
              shrink: true,
            }}
            {...register("expiration_date")}
          />
          <TextField
            margin="normal"
            sx={{ minWidth: "20%", width: "33%" }}
            id="sale_channel"
            type="string"
            label="Sale Channel (If Applicable)"
            {...register("sale_channel")}
          />
          <FormControl sx={{ minWidth: "20%", width: "33%" }}>
            <InputLabel id="store-select-label">
              Store (If Applicable)
            </InputLabel>
            <Select
              labelId="store-select-label"
              id="store_id"
              value={store}
              {...register("store_id")}
              label="Store"
              onChange={handleChangeStore}
            >
              {stores.map((store, index) => (
                <MenuItem key={index} value={store.id}>
                  {store.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="normal"
            sx={{ minWidth: "20%", width: "33%" }}
            id="order_id"
            type="number"
            label="Order ID (If Applicable)"
            {...register("order_id")}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            margin="normal"
            fullWidth
            id={"comments"}
            type="text"
            label="Comments"
            rows={10}
            multiline
            {...register(`comments`)}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              minWidth: 3 / 4,
              marginBottom: "30px",
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              sx={{
                mt: 2,
              }}
            >
              {!!snackbar && (
                <Snackbar
                  open
                  onClose={handleCloseSnackbar}
                  autoHideDuration={6000}
                >
                  <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
              )}
              <Button
                sx={{ marginLeft: "40px" }}
                onClick={() => reset()}
                variant="outlined"
                startIcon={<DeleteIcon />}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                size="large"
                endIcon={<SendIcon />}
                disabled={circularLoading}
                type="submit"
              >
                Send
                {circularLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Button>
            </Stack>
          </Box>
          <InputLabel id="number-items-page-label">
            Number of Items per Page
          </InputLabel>
          <Select
            labelId="number-items-page-label"
            id="number-items-page"
            value={String(numberItemsPerPage)}
            label="Number of Items per Page"
            onChange={handleNumberItemsPerPage}
          >
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={250}>250</MenuItem>
            <MenuItem value={500}>500</MenuItem>
            <MenuItem value={750}>750</MenuItem>
            <MenuItem value={1000}>1000</MenuItem>
          </Select>
          <Button variant="contained" href="" onClick={fetchHistoryClick}>
            Fetch history
          </Button>
          <TableHorizontal
            screen={"product-qty-warehouse"}
            columns={columns}
            data={productQtyWarehouseHistory}
            objectKey={keys}
          />
          <Box
            display="flex"
            width={"100%"}
            height={80}
            alignItems="center"
            justifyContent="center"
          >
            <Pagination
              count={totalPages}
              page={pageState}
              onChange={handleChange}
            />
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
