/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  TextField,
  Stack,
  List,
  ListItem,
  ListItemText,
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CardMedia,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import api from "../../services/api";
import Swal from "sweetalert2";
import { AsyncPaginate } from "react-select-async-paginate";
import { MultiValue } from "react-select";

export default function NewCart() {
  const [circularLoading, setCircularLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [stores, setStores] = useState<{ id: number; name: string }[]>([]);
  const fetchStores = async () => {
    const response = await api.get("stores");
    setStores(response.data.items);
  };

  const fetchImageFolder = async () => {
    if (imageBaseURL?.substring(imageBaseURL?.length - 1) !== "/") {
      setimageBaseURL(imageBaseURL + "/");
    }
  };

  const [store, setStore] = useState(0);

  const [valueCustomer, setValueCustomer] = useState<{
    id: number;
    email: string;
  } | null>(null);

  const loadOptionsCustomers = async (search: any, page: any) => {
    const results = await api.get(`/customers?page=${page}&search=${search}`);

    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsCustomers = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsCustomers(q, page);

    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  };

  const [products, setProducts] = useState<
    { product_id: number; image: string; sku: string }[]
  >([]);
  const onChangeProducts = async (
    newValue: MultiValue<{ product_id: number; image: string; sku: string }>
  ) => {
    setProducts([...newValue]);
  };

  const loadOptionsProductsStore = async (search: any, page: any) => {
    const results = await api.get(
      `/product-stores/notparent?page=${page}&store_id=${store}&product_sku=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsProductsStore = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsProductsStore(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const [imageBaseURL, setimageBaseURL] = useState(
    process.env.REACT_APP_IMG_CATALOG_URL
  );

  const onSubmit = (cart: any) => {
    setCircularLoading(true);

    cart.store_id = Number(store);
    if (!store) return;

    cart.customer_id = Number(valueCustomer?.id);
    if (!valueCustomer) return;

    if (!products) return;
    for (let index = 0; index < products.length; index++) {
      cart.products[index].product_id = Number(products[index].product_id);
      cart.products[index].quantity = Number(cart.products[index].quantity);
    }

    api
      .post("carts", cart)
      .then((res) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Cart created!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        //reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "" + error.response.data.message,
          // footer: error.response,
        });
        setCircularLoading(false);
        setRequestError(true);
        setRequestErrorData(error.response.data.message);
      });
  };

  const defaultAdditional = {
    page: 1,
  };

  useEffect(() => {
    fetchStores();
    fetchImageFolder();
  }, []);

  return (
    <Container component="main" maxWidth="xl">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{ fontSize: "32px", marginBottom: "10px" }}
        >
          New Cart
        </Typography>
        {requestError && (
          <List component="nav" aria-label="BackEnd Return">
            {requestErrorData.map((error) => (
              <ListItem key={error}>
                <ListItemText
                  sx={{
                    color: "red",
                  }}
                  primary={error}
                />
              </ListItem>
            ))}
          </List>
        )}
        <Box
          component="form"
          sx={{ mt: 1, minWidth: 3 / 4, width: "100%" }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormControl
            sx={{
              minWidth: "20%",
              width: "33%",
              marginTop: "16px",
              marginRight: "4px",
            }}
          >
            <InputLabel id="store-select-label">Store</InputLabel>
            <Select
              labelId="store-select-label"
              id="store_id"
              value={store}
              {...register("store_id")}
              label="Store"
              onChange={(e) => setStore(Number(e.target.value))}
            >
              <MenuItem key={0} value={0}>
                None
              </MenuItem>
              {stores.map((store, index) => (
                <MenuItem key={index} value={store.id}>
                  {store.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.store_id?.toString()}
          </p>

          <InputLabel id="customer-group-select">Customer</InputLabel>
          <AsyncPaginate
            placeholder="Select Customer"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"customer_id"}
            id="customer"
            name="customer_id"
            value={valueCustomer}
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) =>
              option?.email +
              " / " +
              option?.first_name +
              " " +
              option?.last_name
            } //.customer_group_descriptions[0]
            loadOptions={loadPageOptionsCustomers}
            onChange={setValueCustomer}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.language_id?.toString()}
          </p>

          <Box sx={{ width: "100%", typography: "body1" }}>
            Selected Products Will be Added to the Cart
            <AsyncPaginate
              isMulti
              closeMenuOnSelect={false}
              placeholder="Select Products"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              additional={defaultAdditional}
              key={"product_ids"}
              id="products"
              name="products"
              getOptionValue={(option: any) => option?.id}
              getOptionLabel={(option: any) => option?.sku}
              loadOptions={loadPageOptionsProductsStore}
              value={products}
              onChange={onChangeProducts}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.products?.toString()}
            </p>
            {products.map((input, index) => {
              return (
                <p key={index}>
                  {" "}
                  Product ID / SKU: {input.product_id} / {input.sku}
                </p>
              );
            })}
            <div style={{ marginTop: "50px", width: "100%" }}>
              {products.map((product, indexProduct) => {
                return (
                  <Box
                    key={indexProduct}
                    sx={{
                      width: "100%",
                      marginBottom: 2,
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <input
                      hidden
                      {...register(`products[${indexProduct}].product_id`)}
                      value={product.product_id}
                    />
                    <div>
                      Product ID / SKU: {product.product_id} / {product.sku}
                      <br></br>
                      <CardMedia
                        component="img"
                        sx={{ width: 151, display: "inline" }}
                        image={`${imageBaseURL}${product.image}`}
                        alt={"image"}
                      />
                    </div>
                    <TextField
                      sx={{
                        mt: 1,
                        marginTop: 10,
                        minWidth: 1 / 4,
                        width: "20%",
                      }}
                      InputLabelProps={{ shrink: true }}
                      margin="normal"
                      required
                      label="Quantity"
                      id={"#products[" + indexProduct + "].quantity"}
                      type="number"
                      {...register(`products[${indexProduct}].quantity`, {
                        required: `products[${indexProduct}].quantity is required`,
                      })}
                    />
                  </Box>
                );
              })}
            </div>
          </Box>

          <Stack
            direction="row"
            spacing={2}
            sx={{
              mt: 2,
            }}
          >
            <Button
              onClick={() => reset()}
              variant="outlined"
              startIcon={<DeleteIcon />}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              size="large"
              endIcon={<SendIcon />}
              disabled={circularLoading}
              type="submit"
            >
              Send
              {circularLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>
          </Stack>
        </Box>
        <div style={{ marginTop: "100px" }}></div>
      </Box>
    </Container>
  );
}
