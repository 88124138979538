/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  TextField,
  Typography,
  Stack,
  List,
  ListItemText,
  ListItem,
  FormControlLabel,
  Checkbox,
  InputLabel,
  InputAdornment,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Tab,
  Grid,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import api from "../../../../services/api";
import Swal from "sweetalert2";
import { useLocation, useParams } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import { LogTrackUser } from "../../../../types/log-track-user/log-track-user";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { TabContext, TabList, useTabContext } from "@mui/lab";
import Cookies from "js-cookie";

interface CountryDescriptionInterface {
  name: string;
}
interface CountryInterface {
  id: number;
  iso_code_2: string;
  iso_code_3: string;
  descriptions: CountryDescriptionInterface[];
}

interface AddressInterface {
  id: number;
  country_id: number;
  name: string;
  phone_number: string;
  postal_code: string;
  address: string;
  number: string;
  address_2: string;
  district: string;
  city: string;
  state: string;
  additional_info: string;
  country: CountryInterface;
}

function TabPanel(props: any) {
  const { children, className, value, index, ...other } = props;

  const context = useTabContext();

  if (context === null) {
    throw new TypeError("No TabContext provided");
  }
  const tabId = context.value;

  return (
    <Typography
      component="div"
      role="tabpanel"
      padding="24px"
      hidden={value !== tabId}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      //visibility={value === tabId ? "visible" : "hidden"}
      {...other}
    >
      {children}
    </Typography>
  );
}

export default function EditCustomer() {
  const [circularLoading, setCircularLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);
  const { id } = useParams<{ id: string }>();

  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const [tabValue, setTabValue] = useState("1");
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const defaultAdditional = {
    page: 1,
  };

  const [status, setStatus] = useState(1);

  const [newsletter, setNewsletter] = useState(true);

  // Add these variables to your component to track the state
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [password, setPassword] = useState("");

  const [updatePassword, setUpdatePassword] = useState(false);

  const [valueLanguage, setValueLanguage] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const loadOptionsLanguages = async (search: any, page: any) => {
    const results = await api.get(`/languages?page=${page}&search=${search}`);

    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsLanguages = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsLanguages(q, page);

    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  };

  const [valueCurrency, setValueCurrency] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const loadOptionsCurrencies = async (search: any, page: any) => {
    const results = await api.get(
      `/currencies?page=${page}&search_name=${search}`
    );

    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsCurrencies = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsCurrencies(q, page);

    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  };

  const [isCompany, setIsCompany] = useState(false);

  const [valueCustomerPaymentAddress, setValueCustomerPaymentAddress] =
    useState<{
      id: number;
      name: string;
    } | null>(null);

  const [valueCustomerShippingAddress, setValueCustomerShippingAddress] =
    useState<{
      id: number;
      name: string;
    } | null>(null);

  const loadOptionsCustomerAddresses = async (search: any, page: any) => {
    const results = await api.get(
      `/customers/address/${id}?page=${page}&search=${search}`
    );

    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsCustomerAddresses = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsCustomerAddresses(q, page);

    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  };

  const [addresses, setAddresses] = useState<AddressInterface[]>([]);

  const [addEditAddressID, setaddEditAddressID] = useState(0);
  const [addEditAddressName, setaddEditAddressName] = useState("");
  const [addEditAddressPhoneNumber, setaddEditAddressPhoneNumber] =
    useState("");
  const [addEditAddressPostalCode, setaddEditAddressPostalCode] = useState("");
  const [addEditAddressAddress, setaddEditAddressAddress] = useState("");
  const [addEditAddressNumber, setaddEditAddressNumber] = useState("");
  const [addEditAddressAddress2, setaddEditAddressAddress2] = useState("");
  const [addEditAddressDistrict, setaddEditAddressDistrict] = useState("");
  const [addEditAddressCity, setaddEditAddressCity] = useState("");
  const [addEditAddressState, setaddEditAddressState] = useState("");
  const [addEditAddressAdditionalInfo, setaddEditAddressAdditionalInfo] =
    useState("");

  /*   const setAddressProperty = async (property: any, value: string) => {
/*     Object.keys(!address).forEach(function(key, index) {
      if (key === property) {
        address[key] = value;
      }
    }); 
    // const temp = address[property as keyof Address] ?? 'fallback value';
    // address[property as keyof Address] = value;
    // address[property] = value;
    setAddress(address);
  } */

  const [addEditcountry, setAddEditCountry] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const loadOptionsCountries = async (search: any, page: any) => {
    const results = await api.get(
      `/countries?page=${page}&limit=0&search=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsCountries = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsCountries(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const addEditAddress = async () => {
    let addressInsertUpdate = {} as any;
    addressInsertUpdate.customer_id = Number(id);
    addressInsertUpdate.country_id = addEditcountry?.id;
    addressInsertUpdate.name = addEditAddressName;
    addressInsertUpdate.address = addEditAddressAddress;

    if (addEditAddressPhoneNumber) {
      addressInsertUpdate.phone_number = addEditAddressPhoneNumber;
    }
    if (addEditAddressPostalCode) {
      addressInsertUpdate.postal_code = addEditAddressPostalCode;
    }
    if (addEditAddressNumber) {
      addressInsertUpdate.number = addEditAddressNumber;
    }
    if (addEditAddressAddress2) {
      addressInsertUpdate.address_2 = addEditAddressAddress2;
    }
    if (addEditAddressDistrict) {
      addressInsertUpdate.district = addEditAddressDistrict;
    }
    if (addEditAddressCity) {
      addressInsertUpdate.city = addEditAddressCity;
    }
    if (addEditAddressState) {
      addressInsertUpdate.state = addEditAddressState;
    }
    if (addEditAddressAdditionalInfo) {
      addressInsertUpdate.additional_info = addEditAddressAdditionalInfo;
    }

    //Add Function the address.id is not set
    if (!addEditAddressID) {
      api
        .post("customers/address", addressInsertUpdate)
        .then((res) => {
          setCircularLoading(false);
          setRequestError(false);
          Swal.fire({
            title: "Customer Address Created!",
            icon: "success",
            confirmButtonText: "Yes",
          });
          refreshAddresses();
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "" + error.response.data.message,
            footer: error.response,
          });
          setCircularLoading(false);
          setRequestError(true);
          setRequestErrorData(error.response.data.message);
        });
      //Edit Condition - the address.id is set
    } else {
      addressInsertUpdate.id = addEditAddressID;

      api
        .put("customers/address/" + addEditAddressID, addressInsertUpdate)
        .then((res) => {
          setCircularLoading(false);
          setRequestError(false);
          Swal.fire({
            title: "Customer Address Updated!",
            icon: "success",
            confirmButtonText: "Yes",
          });
          refreshAddresses();
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "" + error.response.data.message,
            footer: error.response,
          });
          setCircularLoading(false);
          setRequestError(true);
          setRequestErrorData(error.response.data.message);
        });
    }
    //Clear the Edit Section
    setaddEditAddressID(0);
    //Other Fields I will not clear

    // fetchCustomer();
    refreshAddresses();
  };

  const refreshAddresses = async () => {
    await timeout(1000);
    api.get(`customers/${id}`).then((res) => {
      setAddresses(res.data.addresses);
    });
  };

  const editAddress = function (index: number) {
    return async function () {
      /*       const response = await api.get(`customers/${id}`);
      setAddresses(response.data.addresses); */
      //Populate Fields at the New / Save Address Section
      setaddEditAddressID(addresses[index].id);
      setaddEditAddressName(addresses[index].name || "");
      setaddEditAddressPhoneNumber(addresses[index].phone_number || "");
      setaddEditAddressPostalCode(addresses[index].postal_code || "");
      setaddEditAddressAddress(addresses[index].address || "");
      setaddEditAddressNumber(addresses[index].number || "");
      setaddEditAddressAddress2(addresses[index].address_2 || "");
      setAddEditCountry({
        id: addresses[index].country_id,
        name: addresses[index].country.descriptions[0].name,
      });
      setaddEditAddressDistrict(addresses[index].district || "");
      setaddEditAddressCity(addresses[index].city || "");
      setaddEditAddressState(addresses[index].state || "");
      setaddEditAddressAdditionalInfo(addresses[index].additional_info || "");
    };
  };

  const deleteAddress = function (id: number) {
    return async function () {
      /*       const response = await api.get(`customers/${id}`);
      setAddresses(response.data.addresses); */

      Swal.fire({
        title: `Are you sure to delete the Addres ID ${id}?`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .delete(`customers/address/${id}`)
            .then((res) => {
              Swal.fire("Deleted!", "It has been deleted.", "success");
              refreshAddresses();
            })
            .catch((error) => {
              console.error(error);
            });
        }
      });
    };
  };

  const onSubmit = (customer: any) => {
    setCircularLoading(true);

    customer.status = Number(customer.status);

    if (!customer.email_2) {
      delete customer.email_2;
    }

    if (!customer.phone_number) {
      delete customer.phone_number;
    }

    if (!customer.phone_number_2) {
      delete customer.phone_number_2;
    }

    if (!customer.phone_number_3) {
      delete customer.phone_number_3;
    }

    if (!customer.birth_date) {
      delete customer.birth_date;
    }

    customer.newsletter = newsletter;

    if (!customer.profile_photo) {
      delete customer.profile_photo;
    }

    if (valueLanguage) {
      customer.language_id = valueLanguage.id;
    }

    if (valueCurrency) {
      customer.currency_id = valueCurrency.id;
    }

    if (!customer.person_id) {
      delete customer.person_id;
    }

    if (!customer.person_tax_id) {
      delete customer.person_tax_id;
    }

    customer.is_company = isCompany;

    if (!customer.tax_id) {
      delete customer.tax_id;
    }

    if (!customer.tax_id_2) {
      delete customer.tax_id_2;
    }

    customer.updated_at = new Date().toISOString();
    //user.updated_at = Date().toLocaleString();
    if (!customer.password) {
      delete customer.password;
    }

    if (updatePassword) {
      customer.update_password = true;
    } else {
      customer.update_password = false;
    }

    customer.customer_payment_address_id = valueCustomerPaymentAddress?.id;
    customer.customer_shipping_address_id = valueCustomerShippingAddress?.id;

    //password change section

    api
      .put(`customers/${id}`, customer)
      .then((response) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Customer updated!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        //reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "" + error.response.data.message,
          // footer: '<a href="">Why do I have this issue?</a>',
        });
        setCircularLoading(false);
        setRequestError(true);
        setRequestErrorData(error.response.data.message);
      });
  };

  const fetchCustomer = async () => {
    const response = await api.get(`customers/${id}`);
    if (response.data.language_id) {
      setValueLanguage({
        id: response.data.language_id,
        name: response.data.language.name,
      });
    }
    if (response.data.currency_id) {
      setValueCurrency({
        id: response.data.currency_id,
        name: response.data.currency.name,
      });
    }
    setNewsletter(response.data.newsletter);
    setIsCompany(response.data.is_company);

    if (response.data.customer_payment_address_id) {
      setValueCustomerPaymentAddress({
        id: response.data.customer_payment_address_id,
        name: response.data.customer_payment_address.name,
      });
    }
    if (response.data.customer_shipping_address_id) {
      setValueCustomerShippingAddress({
        id: response.data.customer_shipping_address_id,
        name: response.data.customer_shipping_address.name,
      });
    }
    setAddresses(response.data.addresses);

    setUpdatePassword(response.data.update_password);

    reset(response.data);
  };

  useEffect(() => {
    fetchCustomer();
  }, [addEditAddressID]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <Typography
            component="h1"
            variant="h5"
            sx={{
              paddingTop: "40px",
              fontWeight: "bold",
              fontSize: "32px",
              color: "#556cd6",
              paddingLeft: "20px",
            }}
          >
            Edit Customer {id} /
          </Typography>
          {requestError && (
            <List component="nav" aria-label="BackEnd Return">
              {requestErrorData.map((error) => (
                <ListItem key={error}>
                  <ListItemText
                    sx={{
                      color: "red",
                    }}
                    primary={error}
                  />
                </ListItem>
              ))}
            </List>
          )}
          <TabContext value={tabValue}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                maxWidth: "xl",
                minWidth: 3 / 4,
              }}
            >
              <TabList
                onChange={handleChangeTab}
                centered
                aria-label="lab API tabs example"
              >
                <Tab label="Customer Info" value="1" />
                <Tab label="Addresses" value="2" />
              </TabList>
            </Box>
            <Box
              component="form"
              sx={{
                mt: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onSubmit={handleSubmit(onSubmit)}
            >
              {/* Customer Info */}
              <TabPanel
                value="1"
                sx={{
                  width: "100%",
                  typography: "body1",
                  maxWidth: "xl",
                  minWidth: 3 / 4,
                  alignItems: "center",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="status-select">Status</InputLabel>
                  <Select
                    labelId="status-select-label"
                    id="status"
                    value={status}
                    {...register("status")}
                    label="Status"
                    onChange={(e) => setStatus(Number(e.target.value))}
                  >
                    <MenuItem value={1}>Enabled</MenuItem>
                    <MenuItem value={0}>Disabled</MenuItem>
                  </Select>
                </FormControl>
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors?.status?.toString()}
                </p>
                <br></br>
                {/* <InputLabel id="customer-group-select">Customer Group</InputLabel>
                  <AsyncPaginate
                    placeholder="Select Customer Group"
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    additional={defaultAdditional}
                    key={"customer_group_id"}
                    id="customer_group"
                    name="customer_group"
                    value={valueCustomerGroup}
                    getOptionValue={(option: any) => option?.id}
                    getOptionLabel={(option: any) => option?.name} //.customer_group_descriptions[0]
                    loadOptions={loadPageOptionsCustomerGroups}
                    onChange={setValueCustomerGroup}
                  /> */}
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors?.customer_group_id?.toString()}
                </p>
                <Box style={{ display: "flex", flexDirection: "row" }}>
                  <TextField
                    margin="normal"
                    required
                    sx={{ mt: 1, minWidth: 2 / 4, width: "50%" }}
                    id="first_name"
                    type="text"
                    label="First Name"
                    InputLabelProps={{ shrink: true }}
                    {...register("first_name")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.first_name?.toString()}
                  </p>

                  <TextField
                    margin="normal"
                    required
                    sx={{ mt: 1, minWidth: 2 / 4, width: "50%" }}
                    id="last_name"
                    type="text"
                    label="Last Name"
                    InputLabelProps={{ shrink: true }}
                    {...register("last_name")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.last_name?.toString()}
                  </p>
                </Box>
                <TextField
                  margin="normal"
                  required
                  sx={{ mt: 1, minWidth: 2 / 4, width: "100%" }}
                  InputLabelProps={{ shrink: true }}
                  id="email"
                  type="text"
                  label="Main E-mail - Must be an unique email address (not Registered in the database before)"
                  {...register("email")}
                />
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors?.email?.toString()}
                </p>

                <TextField
                  margin="normal"
                  sx={{ mt: 1, minWidth: 2 / 4, width: "100%" }}
                  InputLabelProps={{ shrink: true }}
                  id="email_2"
                  type="text"
                  label="Secondary E-mail Address"
                  {...register("email_2")}
                />
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors?.email_2?.toString()}
                </p>
                <Box style={{ display: "flex", flexDirection: "row" }}>
                  <TextField
                    margin="normal"
                    sx={{ mt: 1, minWidth: 1 / 3, width: "33%" }}
                    InputLabelProps={{ shrink: true }}
                    id="phone_number"
                    type="text"
                    label="Main Phone Number (with Country Code. Ex.: +55)"
                    {...register("phone_number")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.phone_number?.toString()}
                  </p>

                  <TextField
                    margin="normal"
                    sx={{ mt: 1, minWidth: 1 / 3, width: "33%" }}
                    InputLabelProps={{ shrink: true }}
                    id="phone_number_2"
                    type="text"
                    label="Secondary Phone Number (with Country Code. Ex.: +55)"
                    {...register("phone_number_2")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.phone_number_2?.toString()}
                  </p>

                  <TextField
                    margin="normal"
                    sx={{ mt: 1, minWidth: 1 / 3, width: "33%" }}
                    InputLabelProps={{ shrink: true }}
                    id="phone_number_3"
                    type="text"
                    label="Additional Phone Number (with Country Code. Ex.: +55)"
                    {...register("phone_number_3")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.phone_number_3?.toString()}
                  </p>
                </Box>

                <TextField
                  margin="normal"
                  sx={{ mt: 1, minWidth: 1 / 2, width: "50%" }}
                  id="birth_date"
                  type="date"
                  label="Birth Date"
                  InputLabelProps={{ shrink: true }}
                  {...register("birth_date")}
                />
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors?.birth_date?.toString()}
                </p>

                <TextField
                  margin="normal"
                  sx={{ mt: 1, minWidth: 1 / 2, width: "100%" }}
                  id="profile_photo"
                  type="text"
                  label="Profile Photo Address"
                  InputLabelProps={{ shrink: true }}
                  {...register("profile_photo")}
                />
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors?.profile_photo?.toString()}
                </p>

                <FormControlLabel
                  label="Newsletter Subscription?"
                  control={
                    <Checkbox
                      checked={newsletter || false}
                      onChange={(e) => setNewsletter(e.target.checked)}
                      inputProps={{ "aria-label": "Newsletter Subscription?" }}
                      // {...register("newsletter")}
                    />
                  }
                />

                <TextField
                  margin="normal"
                  fullWidth
                  label="Password"
                  //type="password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="new-password"
                  {...register("password")}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors?.password?.toString()}
                </p>

                <FormControlLabel
                  label="Update Password on Login?"
                  control={
                    <Checkbox
                      checked={updatePassword || false}
                      onChange={(e) => setUpdatePassword(e.target.checked)}
                      inputProps={{ "aria-label": "Update Password on Login?" }}
                    />
                  }
                />

                <InputLabel id="customer-group-select">
                  Website / Communication Preferred Language
                </InputLabel>
                <AsyncPaginate
                  placeholder="Select Preferred Language"
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                  additional={defaultAdditional}
                  key={"language_id"}
                  id="language"
                  name="language_id"
                  value={valueLanguage}
                  getOptionValue={(option: any) => option?.id}
                  getOptionLabel={(option: any) => option?.name} //.customer_group_descriptions[0]
                  loadOptions={loadPageOptionsLanguages}
                  onChange={setValueLanguage}
                />
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors?.language_id?.toString()}
                </p>

                <InputLabel id="customer-group-select">
                  Website / Payment Preferred Currency
                </InputLabel>
                <AsyncPaginate
                  placeholder="Select Preferred Currency"
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                  additional={defaultAdditional}
                  key={"currency_id"}
                  id="currency"
                  name="currency_id"
                  value={valueCurrency}
                  getOptionValue={(option: any) => option?.id}
                  getOptionLabel={(option: any) => option?.name}
                  loadOptions={loadPageOptionsCurrencies}
                  onChange={setValueCurrency}
                />
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors?.currency_id?.toString()}
                </p>

                <Box style={{ display: "flex", flexDirection: "row" }}>
                  <TextField
                    margin="normal"
                    sx={{ mt: 1, minWidth: 1 / 2, width: "50%" }}
                    id="person_id"
                    type="text"
                    label="Person ID Number"
                    InputLabelProps={{ shrink: true }}
                    {...register("person_id")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.person_id?.toString()}
                  </p>

                  <TextField
                    margin="normal"
                    sx={{ mt: 1, minWidth: 1 / 2, width: "50%" }}
                    id="person_tax_id"
                    type="text"
                    label="Person Tax ID Number"
                    InputLabelProps={{ shrink: true }}
                    {...register("person_tax_id")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.person_tax_id?.toString()}
                  </p>
                </Box>

                <FormControlLabel
                  label="Is Company?"
                  control={
                    <Checkbox
                      checked={isCompany || false}
                      onChange={(e) => setIsCompany(e.target.checked)}
                      inputProps={{ "aria-label": "Is Company?" }}
                      // {...register("newsletter")}
                    />
                  }
                />

                <Box style={{ display: "flex", flexDirection: "row" }}>
                  <TextField
                    margin="normal"
                    sx={{ mt: 1, minWidth: 1 / 2, width: "50%" }}
                    id="tax_id"
                    type="text"
                    label="Tax ID Number"
                    InputLabelProps={{ shrink: true }}
                    {...register("tax_id")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.tax_id?.toString()}
                  </p>

                  <TextField
                    margin="normal"
                    sx={{ mt: 1, minWidth: 1 / 2, width: "50%" }}
                    id="tax_id_2"
                    type="text"
                    label="Tax ID Number 2"
                    InputLabelProps={{ shrink: true }}
                    {...register("tax_id_2")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.tax_id_2?.toString()}
                  </p>
                </Box>
              </TabPanel>

              <TabPanel
                value="2"
                sx={{
                  width: "100%",
                  typography: "body1",
                  maxWidth: "xl",
                  minWidth: 3 / 4,
                  alignItems: "center",
                }}
              >
                <InputLabel id="customer-payment-address-select">
                  Customer Payment Address
                </InputLabel>
                <AsyncPaginate
                  placeholder="Select Customer Payment Address"
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                  additional={defaultAdditional}
                  key={"customer_payment_address_id"}
                  id="customer_payment_address"
                  name="customer_payment_address_id"
                  value={valueCustomerPaymentAddress}
                  getOptionValue={(option: any) => option?.id}
                  getOptionLabel={(option: any) => option?.name} //.customer_group_descriptions[0]
                  loadOptions={loadPageOptionsCustomerAddresses}
                  onChange={setValueCustomerPaymentAddress}
                />
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors?.customer_payment_address_id?.toString()}
                </p>
                <InputLabel id="customer-shipping-address-select">
                  Customer Shipping Address
                </InputLabel>
                <AsyncPaginate
                  placeholder="Select Customer Shipping Address"
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                  additional={defaultAdditional}
                  key={"customer_shipping_address_id"}
                  id="customer_shipping_address"
                  name="customer_shipping_address_id"
                  value={valueCustomerShippingAddress}
                  getOptionValue={(option: any) => option?.id}
                  getOptionLabel={(option: any) => option?.name} //.customer_group_descriptions[0]
                  loadOptions={loadPageOptionsCustomerAddresses}
                  onChange={setValueCustomerShippingAddress}
                />
                <p style={{ color: "red", textAlign: "center" }}>
                  {errors?.customer_shipping_address_id?.toString()}
                </p>
                <br></br>
                <Button
                  id={"add_edit_address"}
                  key={"add_edit_address"}
                  style={{ minWidth: "200px", marginLeft: 10 }}
                  variant="contained"
                  href=""
                  onClick={addEditAddress}
                >
                  New / Save Address
                </Button>
                <input
                  style={{ margin: "3px" }}
                  readOnly
                  value={
                    addEditAddressID ? "Address ID: " + addEditAddressID : ""
                  }
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "33%",
                      margin: "3px",
                    }}
                  >
                    <TextField
                      sx={{ mt: 1, minWidth: 3 / 4, marginLeft: "8px" }}
                      InputLabelProps={{ shrink: true }}
                      margin="normal"
                      fullWidth
                      id={"address.name"}
                      type="text"
                      label="Address Name*"
                      value={addEditAddressName}
                      onChange={(e) => setaddEditAddressName(e.target.value)}
                    />
                    <TextField
                      sx={{ mt: 1, minWidth: 3 / 4, marginLeft: "8px" }}
                      InputLabelProps={{ shrink: true }}
                      margin="normal"
                      fullWidth
                      id={"address.phone_number"}
                      type="text"
                      label="Phone Number"
                      value={addEditAddressPhoneNumber}
                      onChange={(e) =>
                        setaddEditAddressPhoneNumber(e.target.value)
                      }
                    />
                    <TextField
                      sx={{ mt: 1, minWidth: 3 / 4, marginLeft: "8px" }}
                      InputLabelProps={{ shrink: true }}
                      margin="normal"
                      fullWidth
                      id={"address.postal_code"}
                      type="text"
                      label="Postal Code"
                      value={addEditAddressPostalCode}
                      onChange={(e) =>
                        setaddEditAddressPostalCode(e.target.value)
                      }
                    />
                    <TextField
                      sx={{ mt: 1, minWidth: 3 / 4, marginLeft: "8px" }}
                      InputLabelProps={{ shrink: true }}
                      margin="normal"
                      fullWidth
                      id={"address.address"}
                      type="text"
                      label="Address*"
                      value={addEditAddressAddress}
                      onChange={(e) => setaddEditAddressAddress(e.target.value)}
                    />
                    <TextField
                      sx={{ mt: 1, minWidth: 3 / 4, marginLeft: "8px" }}
                      InputLabelProps={{ shrink: true }}
                      margin="normal"
                      fullWidth
                      id={"address.number"}
                      type="text"
                      label="Number"
                      value={addEditAddressNumber}
                      onChange={(e) => setaddEditAddressNumber(e.target.value)}
                    />
                    <TextField
                      sx={{ mt: 1, minWidth: 3 / 4, marginLeft: "8px" }}
                      InputLabelProps={{ shrink: true }}
                      margin="normal"
                      fullWidth
                      id={"address.address_2"}
                      type="text"
                      label="Address 2"
                      value={addEditAddressAddress2}
                      onChange={(e) =>
                        setaddEditAddressAddress2(e.target.value)
                      }
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "33%",
                      margin: "3px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        margin: "3px",
                        marginLeft: "7px",
                      }}
                    >
                      <InputLabel id="add-edit-address-country-select">
                        Select Country*
                      </InputLabel>
                      <AsyncPaginate
                        placeholder="Select Country*"
                        styles={{
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 9999,
                          }),
                        }}
                        additional={defaultAdditional}
                        key={"country_id"}
                        id="country"
                        name="country"
                        getOptionValue={(option: any) => option?.id}
                        getOptionLabel={(option: any) => option?.name}
                        loadOptions={loadPageOptionsCountries}
                        value={addEditcountry}
                        onChange={setAddEditCountry}
                      />
                    </Box>
                    <TextField
                      sx={{ mt: 1, minWidth: 3 / 4, marginLeft: "8px" }}
                      InputLabelProps={{ shrink: true }}
                      margin="normal"
                      fullWidth
                      id={"address.district"}
                      type="text"
                      label="District Name"
                      value={addEditAddressDistrict}
                      onChange={(e) =>
                        setaddEditAddressDistrict(e.target.value)
                      }
                    />
                    <TextField
                      sx={{ mt: 1, minWidth: 3 / 4, marginLeft: "8px" }}
                      InputLabelProps={{ shrink: true }}
                      margin="normal"
                      fullWidth
                      id={"address.city"}
                      type="text"
                      label="City"
                      value={addEditAddressCity}
                      onChange={(e) => setaddEditAddressCity(e.target.value)}
                    />
                    <TextField
                      sx={{ mt: 1, minWidth: 3 / 4, marginLeft: "8px" }}
                      InputLabelProps={{ shrink: true }}
                      margin="normal"
                      fullWidth
                      id={"address.state"}
                      type="text"
                      label="State"
                      value={addEditAddressState}
                      onChange={(e) => setaddEditAddressState(e.target.value)}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "33%",
                      margin: "3px",
                    }}
                  >
                    <TextField
                      sx={{ mt: 1, minWidth: 3 / 4, marginLeft: "8px" }}
                      InputLabelProps={{ shrink: true }}
                      margin="normal"
                      fullWidth
                      id={"address.additional_info"}
                      type="text"
                      label="Additional Information"
                      rows={10}
                      multiline
                      value={addEditAddressAdditionalInfo}
                      onChange={(e) =>
                        setaddEditAddressAdditionalInfo(e.target.value)
                      }
                    />
                  </Box>
                </Box>
                <br></br>All Addresses of the Current Client
                <Button
                  id={"refresh_addresses"}
                  key={"refresh_addresses"}
                  style={{ minWidth: "100px", marginLeft: 5 }}
                  variant="contained"
                  href=""
                  onClick={refreshAddresses}
                >
                  Refresh Addresses List
                </Button>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  {/* <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      p: 1,
                      m: 1,
                      // bgcolor: 'background.paper',
                      // maxWidth: 300,
                      borderRadius: 1,
                    }}
                  > */}
                  {addresses && (
                    <>
                      {addresses.map((input, index) => {
                        return (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "30%",
                              margin: "3px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                flexDirection: "row",
                                width: "100%",
                              }}
                            >
                              Address {index + 1}
                              <Button
                                id={"edit_address" + input?.id}
                                key={"edit_address" + input?.id}
                                style={{ minWidth: "100px", marginLeft: 5 }}
                                variant="contained"
                                href=""
                                onClick={editAddress(index)}
                              >
                                Edit
                              </Button>
                              <Button
                                id={"delete_address" + input?.id}
                                key={"delete_address" + input?.id}
                                style={{ minWidth: "100px", marginLeft: 5 }}
                                variant="contained"
                                href=""
                                onClick={deleteAddress(input?.id)} //it cannot be an async function
                              >
                                Delete
                              </Button>
                            </Box>
                            <input readOnly value={"ID: " + input?.id} />
                            <input readOnly value={"Name: " + input?.name} />
                            <input
                              readOnly
                              value={
                                "Phone Number: " + (input?.phone_number || "")
                              }
                            />
                            <input
                              readOnly
                              value={
                                "Country: " + (input?.country.iso_code_3 || "")
                              }
                            />
                            <input
                              readOnly
                              value={
                                "Postal Code: " + (input?.postal_code || "")
                              }
                            />
                            <input
                              readOnly
                              value={"Address: " + (input?.address || "")}
                            />
                            <input
                              readOnly
                              value={"Number: " + (input?.number || "")}
                            />
                            <input
                              readOnly
                              value={"Address 2: " + (input?.address_2 || "")}
                            />
                            <input
                              readOnly
                              value={"District: " + (input?.district || "")}
                            />
                            <input
                              readOnly
                              value={"City: " + (input?.city || "")}
                            />
                            <input
                              readOnly
                              value={"State: " + (input?.state || "")}
                            />
                            <input
                              readOnly
                              value={
                                "Additional Info: " +
                                (input?.additional_info || "")
                              }
                            />
                          </Box>
                        );
                      })}
                    </>
                  )}
                  ;
                </Box>
              </TabPanel>

              <Stack
                direction="row"
                spacing={2}
                sx={{
                  mt: 2,
                }}
              >
                <Button
                  onClick={() => reset()}
                  variant="outlined"
                  startIcon={<DeleteIcon />}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  size="large"
                  endIcon={<SendIcon />}
                  disabled={circularLoading}
                  type="submit"
                >
                  Send
                  {circularLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: green[500],
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Button>
              </Stack>
            </Box>
            <div style={{ marginTop: "100px" }}></div>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );
}
