// import { Container } from "@mui/system";
import React, { useState } from "react";
// import Dropzone from "react-dropzone";
import {
  Preview,
  FileInfo,
  Container,
  PreviewName,
  PreviewBottom,
  SubContainer,
} from "./styles";
import CircularProgress from "@mui/material/CircularProgress";
import { MdCheckCircle, MdError, MdLink } from "react-icons/md";
//import {Container} from './styles';
interface Props {
  files: any;
  onDelete: (id: any, data: any) => void;
  changeOrder: (previous_id: number, new_id: number) => void;
  // onDelete: (file: any) => void;
}
const FileList: React.FC<Props> = ({ files, onDelete, changeOrder }) => {
  const [dragOver, setDragOver] = useState(false);
  const [dragAvaliableZone, setDragAvaliableZone] = useState(false);
  const handleDragOverStart = () => {
    setDragOver(true);
    setDragAvaliableZone(true);
  };
  const handleDragOverEnd = () => {
    setDragOver(false);
    setDragAvaliableZone(true);
  };

  const [currentDragID, setCurrentDragID] = useState<number | null>(null);

  const enableDropping = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    const id = Number(event.dataTransfer.getData("text"));
    const dest_id = Number(event.currentTarget.id);
    setDragOver(false);
    setDragAvaliableZone(false);
    changeOrder(id, dest_id);
  };

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    setDragAvaliableZone(true);
    setCurrentDragID(Number(event.currentTarget.id));
    event.dataTransfer.setData("text", event.currentTarget.id);
  };
  return (
    <div className="preview">
      <Container>
        {files.map((uploadedFile: any, index: number) => (
          <SubContainer key={index}>
            <li
              style={{
                visibility:
                  index === currentDragID || index === Number(currentDragID) + 1
                    ? "hidden"
                    : "visible",
              }}
            >
              <FileInfo
                id={String(index)}
                onDragOver={enableDropping}
                onDrop={handleDrop}
                onDragEnter={handleDragOverStart}
                onDragLeave={handleDragOverEnd}
                style={
                  dragAvaliableZone
                    ? dragOver
                      ? { background: "red" }
                      : { background: "blue" }
                    : {}
                }
              >
                &nbsp; &nbsp; &nbsp;
              </FileInfo>
            </li>
            <li>
              <FileInfo
                draggable="true"
                onDragStart={handleDragStart}
                id={String(index)}
              >
                <Preview>
                  <img
                    key={Date.now()}
                    src={`${uploadedFile.preview}`}
                    alt={uploadedFile.name}
                  ></img>
                </Preview>
                <PreviewBottom>
                  <PreviewName>
                    <strong>{uploadedFile.name}</strong>
                    {uploadedFile.uploaded && (
                      <MdCheckCircle size={24} color="#78e5d5" />
                    )}
                    {!uploadedFile.uploaded && !uploadedFile.error && (
                      <CircularProgress
                        size={20}
                        variant="determinate"
                        value={uploadedFile.progress}
                      />
                    )}
                    {uploadedFile.url && (
                      <a
                        href={uploadedFile.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <MdLink size={24} color="#556cd6" />
                      </a>
                    )}
                  </PreviewName>
                  <PreviewName>
                    {uploadedFile.error && (
                      <MdError size={24} color="#e57878" />
                    )}
                    <span>
                      {uploadedFile.readableSize}{" "}
                      {!!uploadedFile.url && (
                        <button
                          onClick={() =>
                            onDelete(uploadedFile.id, uploadedFile)
                          }
                        >
                          Delete
                        </button>
                      )}
                    </span>
                  </PreviewName>
                </PreviewBottom>
              </FileInfo>
            </li>
            {index === files.length - 1 && (
              <li
                style={{
                  visibility:
                    index + 1 === currentDragID ||
                    index + 1 === Number(currentDragID) + 1
                      ? "hidden"
                      : "visible",
                }}
              >
                <FileInfo
                  id={String(index + 1)}
                  onDragOver={enableDropping}
                  onDrop={handleDrop}
                  onDragEnter={handleDragOverStart}
                  onDragLeave={handleDragOverEnd}
                  style={
                    dragAvaliableZone
                      ? dragOver
                        ? { background: "red" }
                        : { background: "blue" }
                      : {}
                  }
                >
                  &nbsp; &nbsp; &nbsp;
                </FileInfo>
              </li>
            )}
          </SubContainer>
        ))}
      </Container>
    </div>
  );
};
export default FileList;
