/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Container,
  Pagination,
  Box,
  SelectChangeEvent,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
} from "@mui/material";
import TableHorizontal from "../../TableHorizontal";
import api from "../../../services/api";
function ListParentChildOptions() {
  const [pageState, setPageState] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [parentChildOptions, setParentChildOptions] = useState([]);
  const columns = [
    "ID",
    "Show Image",
    "Name",
    "Group Name",
    "Image",
    "Action",
    "Delete",
  ];
  const keys = [
    "id",
    "show_image_text",
    "name",
    "group_name",
    "image",
    "action",
    "delete",
  ];
  const fetchData = async (
    page = 1,
    language = 1,
    numberItemsPerPage = 500
  ) => {
    await api
      .get(
        `/product-parent-child-options?page=${page}&limit=${numberItemsPerPage}&search_option_name=${optionNameFilter}&language_id=${language}`
      )
      .then((res) => {
        setParentChildOptions(res.data.items);
        setTotalPages(res.data.total_pages);
      });
  };
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageState(value);
    fetchData(value);
  };

  const [numberItemsPerPage, setNumberItemsPerPage] = useState("500");
  const handleNumberItemsPerPage = (event: SelectChangeEvent) => {
    setNumberItemsPerPage(event.target.value);
    fetchData(1, Number(language), Number(event.target.value));
  };

  const [language, setLanguage] = useState("1");
  const handleChangeLanguage = (event: SelectChangeEvent) => {
    setLanguage(event.target.value);
    fetchData(1, Number(event.target.value), Number(numberItemsPerPage));
  };

  const [languages, setLanguages] = useState<{ id: number; name: string }[]>(
    []
  );
  const fetchLanguages = async () => {
    const response = await api.get(`languages`);
    setLanguages(response.data.items);
  };

  const [optionNameFilter, setOptionNameFilter] = useState("");
  const handleChangeOptionNameFilter = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOptionNameFilter(e.target.value);
  };

  const handleFilterButtonClick = () => {
    fetchData(1, Number(language));
  };

  useEffect(() => {
    fetchData();
    fetchLanguages();
  }, []);
  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="number-items-page-label">
            Number of Items per Page
          </InputLabel>
          <Select
            labelId="number-items-page-select-label"
            id="number-items-page"
            value={numberItemsPerPage}
            label="Number of Items per Page"
            onChange={handleNumberItemsPerPage}
          >
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={250}>250</MenuItem>
            <MenuItem value={500}>500</MenuItem>
            <MenuItem value={750}>750</MenuItem>
            <MenuItem value={1000}>1000</MenuItem>
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mx: "8px",
          }}
        >
          <InputLabel id="language-label">Language</InputLabel>
          <Select
            labelId="language-select-label"
            id="language"
            value={language}
            label="Language"
            onChange={handleChangeLanguage}
          >
            <MenuItem value={0}>None</MenuItem>
            {languages.map((language, index) => (
              <MenuItem value={language.id} key={index}>
                {language.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <InputLabel id="option-name-filter-label">
            Option Name Filter
          </InputLabel>
          <TextField
            //sx={{ mt: 1, minWidth: 3/4 }}
            sx={{ minWidth: 1 / 4 }}
            //fullWidth
            id={"option-Name-filter"}
            type="text"
            label="Option Name Filter"
            value={optionNameFilter}
            onChange={handleChangeOptionNameFilter}
          />
        </Box>
        <Button
          variant="contained"
          sx={{ m: 3 }}
          href=""
          onClick={handleFilterButtonClick}
        >
          Filter
        </Button>
      </Box>
      <TableHorizontal
        screen={"product-parent-child-options"}
        columns={columns}
        data={parentChildOptions}
        objectKey={keys}
      />
      <Box
        display="flex"
        width={"100%"}
        height={80}
        alignItems="center"
        justifyContent="center"
      >
        <Pagination
          count={totalPages}
          page={pageState}
          onChange={handleChange}
        />
      </Box>
    </Container>
  );
}
export default ListParentChildOptions;
