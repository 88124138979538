/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Stack,
  List,
  ListItemText,
  ListItem,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import api from "../../../../../services/api";
import Swal from "sweetalert2";
import { inputsName } from "../../../../../config/languageConst";
import { useLocation, useParams } from "react-router-dom";
import { LogTrackUser } from "../../../../../types/log-track-user/log-track-user";
import Cookies from "js-cookie";

export default function EditProductParentChildOption() {
  const [circularLoading, setCircularLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);
  const { id } = useParams<{ id: string }>();
  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [showImage, setShowImage] = useState(false);
  const handleChangeShowImage = (event: SelectChangeEvent) => {
    setShowImage(event.target.value as any);
  };

  const onSubmit = (data: any) => {
    setCircularLoading(true);

    for (let index = 0; index < data.descriptions.length; index++) {
      data.descriptions[index].language_id = Number(
        data.descriptions[index].language_id
      );
    }

    data.updated_at = new Date().toISOString();

    api
      .put(`product-parent-child-options/${id}`, data)
      .then((response) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Product Parent Child Option Updated!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        //reset();
      })
      .catch((error) => {
        Swal.fire({
          title: "Oops...",
          text: error.response.data.message,
          confirmButtonText: "Yes",
        });
        setCircularLoading(false);
        setRequestError(true);
        setRequestErrorData(error.response.data.message);
      });
  };

  const fetchProductParentChildOptions = async () => {
    const response = await api.get(`product-parent-child-options/${id}`);
    reset(response.data);

    setShowImage(response.data.show_image);
  };

  useEffect(() => {
    fetchProductParentChildOptions();
  }, []);

  return (
    <Container component="main" maxWidth="xl">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{ fontSize: "40px", marginBottom: "10px" }}
        >
          Edit Product Parent Child Option {id}
        </Typography>
        {requestError && (
          <List component="nav" aria-label="BackEnd Return">
            {requestErrorData.map((error) => (
              <ListItem>
                <ListItemText
                  sx={{
                    color: "red",
                  }}
                  primary={error}
                />
              </ListItem>
            ))}
          </List>
        )}
        <Box
          component="form"
          sx={{ mt: 1, minWidth: 3 / 4, width: "100%" }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div style={{ marginTop: "20px", width: "100%" }}></div>
          <FormControl sx={{ width: "50%" }}>
            <InputLabel id="show_image-select">Show Image</InputLabel>
            <Select
              labelId="show_image-select-label"
              required
              id="show_image"
              value={showImage as any}
              {...register("show_image")}
              label="Show Image"
              onChange={handleChangeShowImage}
            >
              <MenuItem value={true as any}>Enabled</MenuItem>
              <MenuItem value={false as any}>Disabled</MenuItem>
            </Select>
          </FormControl>
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.showImage?.toString()}
          </p>
          <div style={{ marginTop: "30px" }}>
            {inputsName.map((input, index) => (
              <div className="flags-container">
                <input
                  hidden
                  {...register(`descriptions[${index}].language_id`, {
                    required: `descriptions[${index}].language_id is required`,
                  })}
                  value={input.language_id}
                />
                <span className="fa-flags">
                  <img
                    src={`/assets/language/${input.language_id}.png`}
                    alt=""
                  />
                </span>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  required
                  id={"#descriptions[" + index + "].name"}
                  type="text"
                  label="Name (Option name that will be shown at the Product Page)"
                  {...register(`descriptions[${index}].name`, {
                    required: `descriptions[${index}].name is required`,
                  })}
                />
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  id={"#descriptions[" + index + "].group_name"}
                  type="text"
                  label="Group Name (Name that will show on Product Page before the Options)"
                  {...register(`descriptions[${index}].group_name`)}
                />

                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  id={"#descriptions[" + index + "].image"}
                  type="text"
                  label="Image (When image is enabled, the option will show an image, which the image address must be here)"
                  {...register(`descriptions[${index}].image`)}
                />

                <p style={{ color: "red", textAlign: "center" }}>
                  {errors?.descriptions?.toString()}
                </p>
              </div>
            ))}
          </div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              sx={{
                mt: 2,
              }}
            >
              <Button
                href="/products/product-parent-childs"
                variant="outlined"
                startIcon={<ArrowBackIosNewIcon />}
              >
                Go Back
              </Button>
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                size="large"
                endIcon={<SendIcon />}
                disabled={circularLoading}
                type="submit"
              >
                Send
                {circularLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
