/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import {
  Container,
  Pagination,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Button,
} from "@mui/material";
import TableHorizontal from "../../components/TableHorizontal";
import api from "../../services/api";
import React from "react";
function ListCategories() {
  const [pageState, setPageState] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [categories, setCategories] = useState([]);
  const columns = ["ID", "Parent ID", "Name", "Path", "Actions", "Delete"];
  const keys = ["id", "parent_id", "name", "path", "action", "delete"];
  const fetchData = async (
    page = 1,
    method = 1,
    language = 1,
    numberItemsPerPage = 500
  ) => {
    await api
      .get(
        `/categories-custom?page=${page}&limit=${numberItemsPerPage}&language_id=${language}&method=${method}&search=${search}`
      )
      .then((res) => {
        setCategories(res.data.items);
        setTotalPages(res.data.total_pages);
      });
  };
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageState(value);
    fetchData(
      value,
      Number(method),
      Number(language),
      Number(numberItemsPerPage)
    );
  };

  const [method, setMethod] = React.useState("1");
  const handleChangeMethod = (event: SelectChangeEvent) => {
    setMethod(event.target.value);
    fetchData(
      1,
      Number(event.target.value),
      Number(language),
      Number(numberItemsPerPage)
    );
  };

  const [search, setSearch] = useState("");
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const [numberItemsPerPage, setNumberItemsPerPage] = useState("500");
  const handleNumberItemsPerPage = (event: SelectChangeEvent) => {
    setNumberItemsPerPage(event.target.value);
    fetchData(1, Number(method), Number(language), Number(event.target.value));
  };

  const [language, setLanguage] = useState("0");
  const handleChangeLanguage = (event: SelectChangeEvent) => {
    setLanguage(event.target.value);
    fetchData(
      pageState,
      Number(method),
      Number(event.target.value),
      Number(numberItemsPerPage)
    );
  };

  const [languages, setLanguages] = useState<{ id: number; name: string }[]>(
    []
  );
  const fetchLanguages = async () => {
    const response = await api.get(`languages`);
    setLanguages(response.data.items);
    setLanguage("1");
  };

  const handleSearchClick = () => {
    fetchData(1, Number(method), Number(language), Number(numberItemsPerPage));
  };

  useEffect(() => {
    fetchData();
    fetchLanguages();
  }, []);
  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="number-items-page-label">
            Number of Items per Page
          </InputLabel>
          <Select
            labelId="number-items-page-select-label"
            id="number-items-page"
            value={numberItemsPerPage}
            label="Number of Items per Page"
            onChange={handleNumberItemsPerPage}
          >
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={250}>250</MenuItem>
            <MenuItem value={500}>500</MenuItem>
            <MenuItem value={750}>750</MenuItem>
            <MenuItem value={1000}>1000</MenuItem>
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <InputLabel id="language-label">Language</InputLabel>
          <Select
            labelId="language-select-label"
            id="language"
            value={language}
            label="Language"
            onChange={handleChangeLanguage}
          >
            <MenuItem value={0}>None</MenuItem>
            {languages.map((language, index) => (
              <MenuItem value={language.id} key={index}>
                {language.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 1,
          }}
        >
          <InputLabel id="table-name-filter-label">
            Category Name filter
          </InputLabel>
          <TextField
            //sx={{ mt: 1, minWidth: 3/4 }}
            sx={{ minWidth: 1 / 4 }}
            //fullWidth
            id={"Search"}
            type="text"
            label="Type here..."
            value={search}
            onChange={handleChangeSearch}
          />
        </Box>
        <Button
          variant="contained"
          sx={{ m: 3 }}
          href=""
          onClick={handleSearchClick}
        >
          Filter
        </Button>
      </Box>
      <FormHelperText sx={{ marginTop: 1, marginBottom: -1, marginLeft: 1 }}>
        Show Method
      </FormHelperText>
      <FormControl sx={{ marginTop: 1, marginLeft: 1 }}>
        <Select
          labelId="method-select-label"
          id="method"
          value={method}
          onChange={handleChangeMethod}
        >
          <MenuItem value={1}>Method 1</MenuItem>
          <MenuItem value={2}>Method 2</MenuItem>
        </Select>
      </FormControl>

      <TableHorizontal
        screen={"categories"}
        columns={columns}
        data={categories}
        objectKey={keys}
      />
      <Box
        display="flex"
        width={"100%"}
        height={80}
        alignItems="center"
        justifyContent="center"
      >
        <Pagination
          count={totalPages}
          page={pageState}
          onChange={handlePageChange}
        />
      </Box>
    </Container>
  );
}
export default ListCategories;
