import { OrderStatusEnum } from "../enums/order-status.enum";

// Reference: \erp-backend-nest\src\orders\orders\helpers\order-status.helper.ts
const getOrderStatusName = (oderStatus: OrderStatusEnum) => {
  switch (oderStatus) {
    case OrderStatusEnum.NEW:
      return "NEW";
    case OrderStatusEnum.PAYMENT_PENDING:
      return "PAYMENT PENDING";
    case OrderStatusEnum.PAYMENT_APPROVED:
      return "PAYMENT APPROVED";
    case OrderStatusEnum.PROCESSING_PACKING:
      return "PROCESSING PACKING";
    case OrderStatusEnum.SHIPPED:
      return "SHIPPED";
    case OrderStatusEnum.DELIVERED:
      return "DELIVERED";
    case OrderStatusEnum.CONCLUDED:
      return "CONCLUDED"; // A few days after delivered, asks for review, etc.
    case OrderStatusEnum.CANCELLED:
      return "CANCELLED";
    case OrderStatusEnum.SHIPMENT_EXCEPTION:
      return "SHIPMENT EXCEPTION";
    case OrderStatusEnum.PAYMENT_EXCEPTION:
      return "PAYMENT EXCEPTION";
    case OrderStatusEnum.RETURNED:
      return "RETURNED";
    case OrderStatusEnum.FULLY_REFUNDED:
      return "FULLY REFUNDED";
    case OrderStatusEnum.PARTIALLY_REFUNDED:
      return "PARTIALLY REFUNDED";
    case OrderStatusEnum.SHIPPED_CORRECTION:
      return "SHIPPED CORRECTION"; // Used When some error was caused by not shipping products ordered or correct some error on the order
    case OrderStatusEnum.DELETED:
      return "DELETED";
    default:
      return null;
  }
};

export default getOrderStatusName;
