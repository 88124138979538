/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
//import InputLabel from "@mui/material/InputLabel";
import SendIcon from "@mui/icons-material/Send";
//import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import api from "../../services/api";
import { green } from "@mui/material/colors";
import {
  Box,
  Button,
  CardMedia,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { AsyncPaginate } from "react-select-async-paginate";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { TabContext, TabList, useTabContext } from "@mui/lab";
import { inputsName } from "../../config/languageConst";
import { ColorBox } from "mui-color";
import Cookies from "js-cookie";

//I HAD to declare TabPanel like this to prevent Lazy Loading on the TabPanels.
//DUe to Lazy loading, the data form the DB was not being filled into the fields in the other tabs
function TabPanel(props: any) {
  const { children, className, value, index, ...other } = props;

  const context = useTabContext();

  if (context === null) {
    throw new TypeError("No TabContext provided");
  }
  const tabId = context.value;

  return (
    <Typography
      component="div"
      role="tabpanel"
      padding="24px"
      hidden={value !== tabId}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      //visibility={value === tabId ? "visible" : "hidden"}
      {...other}
    >
      {children}
    </Typography>
  );
}

export default function NewStore() {
  const imageBaseURL = process.env.REACT_APP_IMG_CATALOG_URL;

  const [circularLoading, setCircularLoading] = React.useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);
  const {
    register,
    //reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [tabValue, setTabValue] = useState("3");
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const defaultAdditional = {
    page: 1,
  };

  const loadOptionsCountries = async (search: any, page: any) => {
    const results = await api.get(
      `/countries?page=${page}&limit=0&search=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsCountries = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsCountries(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const [country, setCountry] = useState<{ id: number; name: string } | null>(
    null
  );

  const loadOptionsCurrencies = async (search: any, page: any) => {
    const results = await api.get(
      `/currencies?page=${page}&limit=0&search_code=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsCurrencies = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsCurrencies(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const [currency, setCurrency] = useState<{ id: number; code: string } | null>(
    null
  );

  const loadOptionsWarehouses = async (search: any, page: any) => {
    const results = await api.get(
      `/warehouses?page=${page}&limit=0&search=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsWarehouses = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsWarehouses(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const [warehouse, setWarehouse] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const [wholesalePdvEnabled, setWholesalePdvEnabled] = useState(false);
  const handleChangeWholesalePdvEnabled = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setWholesalePdvEnabled(event.target.checked);
  };

  const [wholesaleStoreEnabled, setWholesaleStoreEnabled] = useState(false);
  const handleChangeWholesaleStoreEnabled = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setWholesaleStoreEnabled(event.target.checked);
  };

  //Description Section
  const [icon, setIcon] = useState<string[]>([]);
  icon.length = inputsName.length;

  const handleChangeIcon = (event: any) => {
    icon[event.target.id] = event.target.value;
    setIcon(icon);
  };
  ///////////////////////

  //Theme Section
  const [primaryColor, setPrimaryColor] = React.useState(""); //#C02F29
  const [secondaryColor, setSecondaryColor] = React.useState(""); //#0A537D
  const [hoverColor, setHoverColor] = React.useState(""); //#0A537D
  ///////////////////////////////////

  //Contents
  const [contents, setContents] = useState<
    {
      /* store_id: number,  */ language_id: number;
      field: string;
      order: number;
      value: string;
    }[]
  >([]);
  const [addRemoveOrder, setAddRemoveOrder] = React.useState(0);
  const [addRemoveField, setAddRemoveField] = React.useState("");

  const addOrderField = async () => {
    let addContents = [];

    //Check if Field and Order already exists, if exists, then do not add
    let newArrayTemp = [];
    newArrayTemp = contents.filter(function (obj) {
      return obj.field !== addRemoveField || obj.order !== addRemoveOrder;
    });

    if (newArrayTemp.length === contents.length) {
      for (let index = 0; index < inputsName.length; index++) {
        let addition = {
          // store_id: storeId,
          language_id: inputsName[index].language_id,
          field: addRemoveField,
          order: addRemoveOrder,
          value: "",
        };
        addContents.push(addition);
      }
      let newArray = [];
      newArray.push(...contents);
      newArray.push(...addContents);
      setContents([...newArray]);
    }
  };

  const removeOrderField = async () => {
    //Loop on the contents var
    //Check fo the field and order fields, if equal to the field, then remove from the array
    let newArray = [];
    //newArray = contents.splice(contents.findIndex(matchesEl), 1);
    newArray = contents.filter(function (obj) {
      return obj.field !== addRemoveField || obj.order !== addRemoveOrder;
    });

    setContents([...newArray]);
  };

  //Top Icons
  const [topicons, setTopIcons] = useState<
    {
      // store_id: number;
      order: number;
      language_id: number;
      icon: string;
      title: string;
      text: string;
      link: string;
    }[]
  >([]);
  const [addRemoveTopiconOrder, setAddRemoveTopiconOrder] = React.useState(0);

  const addOrderTopicon = async () => {
    let addTopicon = [];
    //Check if Field and Order already exists, if exists, then do not add
    let newArrayTemp = [];
    newArrayTemp = topicons.filter(function (obj) {
      return obj.order !== addRemoveTopiconOrder;
    });

    if (newArrayTemp.length === topicons.length) {
      for (let index = 0; index < inputsName.length; index++) {
        let addition = {
          // store_id: storeId,
          language_id: inputsName[index].language_id,
          order: addRemoveTopiconOrder,
          icon: "",
          title: "",
          text: "",
          link: "",
        };
        addTopicon.push(addition);
      }
      let newArray = [];
      newArray.push(...topicons);
      newArray.push(...addTopicon);
      setTopIcons([...newArray]);
    }
  };
  /* 
  const removeOrderTopicon = async () => {
    Swal.fire({
      title: `Are you sure to delete the Icon Order: ${addRemoveTopiconOrder}?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .delete(
            `stores/topicon?store_id=${storeId}&order=${addRemoveTopiconOrder}`
          )
          .then((response) => {
            Swal.fire({
              title: "Icon Removed!",
              icon: "success",
              confirmButtonText: "Yes",
            });
            fetchTopiconData();
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.message,
              //footer: '<a href="">Why do I have this issue?</a>',
            });
          });
      }
    });
  }; */

  //Menu Bar
  const [menubar, setMenubar] = useState<
    {
      // store_id: number;
      order: number;
      language_id: number;
      text: string;
      link: string;
    }[]
  >([]);
  const [addRemoveMenubarOrder, setAddRemoveMenubarOrder] = React.useState(0);

  const addOrderMenubar = async () => {
    let addMenubar = [];
    //Check if Field and Order already exists, if exists, then do not add
    let newArrayTemp = [];
    newArrayTemp = menubar.filter(function (obj) {
      return obj.order !== addRemoveMenubarOrder;
    });

    if (newArrayTemp.length === menubar.length) {
      for (let index = 0; index < inputsName.length; index++) {
        let addition = {
          // store_id: storeId,
          language_id: inputsName[index].language_id,
          order: addRemoveMenubarOrder,
          icon: "",
          title: "",
          text: "",
          link: "",
        };
        addMenubar.push(addition);
      }
      let newArray = [];
      newArray.push(...menubar);
      newArray.push(...addMenubar);
      setMenubar([...newArray]);
    }
  };

  /* const removeOrderMenubar = async () => {
    Swal.fire({
      title: `Are you sure to delete the Menu Order: ${addRemoveMenubarOrder}?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .delete(
            `stores/menubar?store_id=${storeId}&order=${addRemoveMenubarOrder}`
          )
          .then((response) => {
            Swal.fire({
              title: "Menu Removed!",
              icon: "success",
              confirmButtonText: "Yes",
            });
            fetchMenubarData();
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.message,
              //footer: '<a href="">Why do I have this issue?</a>',
            });
          });
      }
    });
  }; */

  // Menu Bar

  // Address Section
  const [countryAddress, setCountryAddress] = useState<{
    id: number;
    name: string;
  } | null>(null);

  /////////////

  const onSubmit = (store: any) => {
    setCircularLoading(true);

    store.country_id = Number(country?.id);
    store.currency_id = Number(currency?.id);
    store.warehouse_id = Number(warehouse?.id);

    //Check obligatory fields - Async Paginate
    if (!store.country_id) {
      setCircularLoading(false);
      setRequestError(true);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Country must be selected!",
        footer: "<b>Please select a country</b>",
      });
      return;
    }

    if (!store.currency_id) {
      setCircularLoading(false);
      setRequestError(true);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Currency must be selected!",
        footer: "<b>Please select a currency</b>",
      });
      return;
    }

    if (!store.warehouse_id) {
      setCircularLoading(false);
      setRequestError(true);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Warehouse must be selected!",
        footer: "<b>Please select a warehouse</b>",
      });
      return;
    }

    store.wholesale_pdv_enabled = wholesalePdvEnabled;
    store.wholesale_store_enabled = wholesaleStoreEnabled;

    store.wholesale_store_minimum_quantity_item = Number(
      store.wholesale_store_minimum_quantity_item
    );
    store.wholesale_store_minimum_total = Number(
      store.wholesale_store_minimum_total
    );
    store.wholesale_pdv_minimum_quantity_item = Number(
      store.wholesale_pdv_minimum_quantity_item
    );
    store.wholesale_pdv_minimum_total = Number(
      store.wholesale_pdv_minimum_total
    );

    // Moved to the Store Address Section
    /* store.latitude = store.latitude ? store.latitude : null;
    store.longitude = store.longitude ? store.longitude : null; */

    let username: string = Cookies.get("username") || "";
    store.last_change_username = username;

    //Description
    if (store.descriptions) {
      for (let index = 0; index < store.descriptions.length; index++) {
        store.descriptions[index].language_id = Number(
          store.descriptions[index].language_id
        );
      }
    }

    //Theme

    store.theme_layout = store.theme_layout ? store.theme_layout : 1;

    store.theme = {
      primary_color: primaryColor ? primaryColor : "#000",
      secondary_color: secondaryColor ? secondaryColor : "#000",
      hover: hoverColor ? hoverColor : "#000",
    };

    if (store.contents) {
      for (let index = 0; index < store.contents.length; index++) {
        store.contents[index].language_id = Number(
          store.contents[index].language_id
        );
      }
    }

    // Top Icons
    if (store.topicons) {
      store.topicons.length = topicons.length;
      for (let index = 0; index < store.topicons.length; index++) {
        store.topicons[index].language_id = Number(topicons[index].language_id);
        store.topicons[index].order = Number(topicons[index].order);
        store.topicons[index].icon = topicons[index].icon;
        store.topicons[index].title = topicons[index].title;
        store.topicons[index].text = topicons[index].text;
        store.topicons[index].link = topicons[index].link;
      }
    }

    // Menu Bar
    if (store.menubar) {
      store.menubar.length = menubar.length;
      for (let index = 0; index < store.menubar.length; index++) {
        store.menubar[index].language_id = Number(menubar[index].language_id);
        store.menubar[index].order = Number(menubar[index].order);
        store.menubar[index].text = menubar[index].text;
        store.menubar[index].link = menubar[index].link;
      }
    }

    // Address Section

    store.store_address.country_id = countryAddress?.id;
    store.store_address.latitude = Number(store.store_address.latitude);
    store.store_address.longitude = Number(store.store_address.longitude);

    store.latitude = store.store_address.latitude
      ? Number(store.store_address.latitude)
      : null;
    store.longitude = store.store_address.longitude
      ? Number(store.store_address.longitude)
      : null;

    store.updated_at = new Date().toISOString();

    api
      .post(`stores`, store)
      .then((response) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Store created!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        //reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          //footer: '<a href="">Why do I have this issue?</a>',
        });
        setCircularLoading(false);
        setRequestError(true); //trocado por false
        setRequestErrorData(error.response.data.message);
      });
  };

  useEffect(() => {}, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <Typography
            component="h1"
            variant="h5"
            sx={{
              paddingTop: "40px",
              fontWeight: "bold",
              fontSize: "32px",
              color: "#556cd6",
              paddingLeft: "20px",
            }}
          >
            New Store
          </Typography>
          {requestError && (
            <List component="nav" aria-label="errors">
              {requestErrorData.map((error) => (
                <ListItem key={error}>
                  <ListItemText
                    sx={{
                      color: "red",
                    }}
                    primary={error}
                  />
                </ListItem>
              ))}
            </List>
          )}
          <TabContext value={tabValue}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                maxWidth: "xl",
                minWidth: 3 / 4,
              }}
            >
              <TabList
                onChange={handleChangeTab}
                centered
                aria-label="lab API tabs example"
              >
                <Tab label="Essential Info" value="3" />
                <Tab label="Description" value="4" />
                <Tab label="Theme Layout & Colors" value="5" />
                <Tab label="Content Parameters & Values" value="6" />
                <Tab label="Top Icons & Menu" value="7" />
                <Tab label="Social Media" value="8" />
              </TabList>
            </Box>
            <Box
              component="form"
              sx={{
                mt: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onSubmit={handleSubmit(onSubmit)}
            >
              {/* Essential Info */}
              <TabPanel
                value="3"
                sx={{
                  width: "100%",
                  typography: "body1",
                  maxWidth: "xl",
                  minWidth: 3 / 4,
                  alignItems: "center",
                }}
              >
                <Box>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="code"
                    type="text"
                    label="Store Code"
                    {...register("code")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.code?.toString()}
                  </p>

                  <AsyncPaginate
                    placeholder="Select Country"
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    additional={defaultAdditional}
                    key={"country_id"}
                    id="country"
                    name="country"
                    getOptionValue={(option: any) => option?.id}
                    getOptionLabel={(option: any) => option?.name}
                    loadOptions={loadPageOptionsCountries}
                    value={country}
                    onChange={setCountry}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.country?.toString()}
                  </p>

                  <Box sx={{ mt: "8px" }}>
                    <AsyncPaginate
                      placeholder="Select Currency Code"
                      styles={{
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 9999,
                        }),
                      }}
                      additional={defaultAdditional}
                      key={"currency_id"}
                      id="currency"
                      name="currency"
                      getOptionValue={(option: any) => option?.id}
                      getOptionLabel={(option: any) => option?.code}
                      loadOptions={loadPageOptionsCurrencies}
                      value={currency}
                      onChange={setCurrency}
                    />
                    <p style={{ color: "red", textAlign: "center" }}>
                      {errors?.currency?.toString()}
                    </p>
                  </Box>
                  <Box sx={{ mt: "8px" }}>
                    <AsyncPaginate
                      placeholder="Select Warehouse"
                      styles={{
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 9999,
                        }),
                      }}
                      additional={defaultAdditional}
                      key={"warehouse_id"}
                      id="warehouse"
                      name="warehouse"
                      getOptionValue={(option: any) => option?.id}
                      getOptionLabel={(option: any) => option?.name}
                      loadOptions={loadPageOptionsWarehouses}
                      value={warehouse}
                      onChange={setWarehouse}
                    />
                    <p style={{ color: "red", textAlign: "center" }}>
                      {errors?.warehouse?.toString()}
                    </p>
                  </Box>

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    type="text"
                    label="Name"
                    {...register("name")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.name?.toString()}
                  </p>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="theme_layout"
                    type="number"
                    label="Theme Layout Number - It will be developed later"
                    {...register("theme_layout")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.theme_layout?.toString()}
                  </p>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="url"
                    type="text"
                    label="URL"
                    {...register("url")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.url?.toString()}
                  </p>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="ssl"
                    type="text"
                    label="SSL"
                    {...register("ssl")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.ssl?.toString()}
                  </p>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="title"
                    type="text"
                    label="Store Title"
                    {...register("title")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.title?.toString()}
                  </p>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="description"
                    type="text"
                    label="description"
                    {...register("description")}
                    rows={10}
                    multiline
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.description?.toString()}
                  </p>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="keywords"
                    type="text"
                    label="Keywords related to the store"
                    {...register("keywords")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.keywords?.toString()}
                  </p>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="address"
                    type="text"
                    label="Address"
                    {...register("address")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.address?.toString()}
                  </p>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="address2"
                    type="text"
                    label="Additional Address Information"
                    {...register("address2")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.address2?.toString()}
                  </p>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="email"
                    type="text"
                    label="Main Store E-mail"
                    {...register("email")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.email?.toString()}
                  </p>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="phone_number"
                    type="text"
                    label="Main Store Phone Number"
                    {...register("phone_number")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.phone_number?.toString()}
                  </p>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="phone_number2"
                    type="text"
                    label="Whatsapp Store Phone Number"
                    {...register("phone_number2")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.phone_number2?.toString()}
                  </p>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="image"
                    type="text"
                    label="Main Store Image Path"
                    {...register("image")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.image?.toString()}
                  </p>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="schedule"
                    type="text"
                    label="Store Working Hours/Schedule"
                    {...register("schedule")}
                    rows={5}
                    multiline
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.schedule?.toString()}
                  </p>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="logo"
                    type="text"
                    label="Main Store Logo Path"
                    {...register("logo")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.logo?.toString()}
                  </p>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="logo_white"
                    type="text"
                    label="Main Store Logo White Colors Path"
                    {...register("logo_white")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.logo_white?.toString()}
                  </p>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="icon"
                    type="text"
                    label="Main Store Icon Path"
                    {...register("icon")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.icon?.toString()}
                  </p>

                  <FormControlLabel
                    label="Wholesale on PDV Enabled?"
                    control={
                      <Checkbox
                        checked={wholesalePdvEnabled || false}
                        onChange={handleChangeWholesalePdvEnabled}
                        inputProps={{ "aria-label": "Wholesale PDV Enabled?" }}
                        //{...register("pre_order_sale_enabled")}
                      />
                    }
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    id="wholesale_pdv_minimum_quantity_item"
                    type="number"
                    label="Minimun Quantity of the same item to Activate Wholesale Price on PDV (Physical Store)"
                    {...register("wholesale_pdv_minimum_quantity_item")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.wholesale_pdv_minimun_quantity_item?.toString()}
                  </p>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="wholesale_pdv_minimum_total"
                    type="number"
                    label="Minimun Order Total to Activate Wholesale Price on PDV (Physical Store)"
                    {...register("wholesale_pdv_minimum_total")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.wholesale_pdv_minimun_total?.toString()}
                  </p>

                  <FormControlLabel
                    label="Wholesale on Online Store Enabled?"
                    control={
                      <Checkbox
                        checked={wholesaleStoreEnabled || false}
                        onChange={handleChangeWholesaleStoreEnabled}
                        inputProps={{
                          "aria-label": "Wholesale Store Enabled?",
                        }}
                        //{...register("pre_order_sale_enabled")}
                      />
                    }
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    id="wholesale_store_minimum_quantity_item"
                    type="number"
                    label="Minimun Quantity of the same item to Activate Wholesale Price on Online Store"
                    {...register("wholesale_store_minimum_quantity_item")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.wholesale_store_minimun_quantity_item?.toString()}
                  </p>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="wholesale_store_minimum_total"
                    type="number"
                    label="Minimun Order Total to Activate Wholesale Price on Online Store"
                    {...register("wholesale_store_minimum_total")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.wholesale_pdv_minimun_total?.toString()}
                  </p>
                </Box>
                <Box>
                  <Typography
                    component="h1"
                    variant="h5"
                    sx={{
                      paddingTop: "40px",
                      fontWeight: "bold",
                      fontSize: "32px",
                      color: "#556cd6",
                      paddingLeft: "20px",
                    }}
                  >
                    Address Section
                  </Typography>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="store_address.name"
                    type="text"
                    label="Store Address Name"
                    {...register("store_address.name")}
                  />
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.store_address?.toString()}
                  </p>
                  <AsyncPaginate
                    placeholder="Select Country"
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    additional={defaultAdditional}
                    key={"country_id"}
                    id="country"
                    name="country"
                    getOptionValue={(option: any) => option?.id}
                    getOptionLabel={(option: any) => option?.name}
                    loadOptions={loadPageOptionsCountries}
                    value={countryAddress}
                    onChange={setCountryAddress}
                  />
                  <Button
                    href="https://rapidlasso.com/2019/05/06/how-many-decimal-digits-for-storing-longitude-latitude/#:~:text=Longitude%20and%20latitude%20coordinates%20are,right%20of%20the%20decimal%20points"
                    target="_blank"
                  >
                    Help latitude longitude
                  </Button>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="latitude"
                    type="number"
                    inputProps={{ step: "0.0000001", lang: "en-US" }}
                    label="Store Latitude - (Ex. 15.1234567 - Max 9 digits)"
                    {...register("store_address.latitude")}
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    id="longitude"
                    type="number"
                    inputProps={{ step: "0.0000001", lang: "en-US" }}
                    label="Store Longitude - (Ex. 15.1234567 - Max 9 digits)"
                    {...register("store_address.longitude")}
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    id="store_address.phone_number"
                    type="text"
                    label="Store Phone Number"
                    {...register("store_address.phone_number")}
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    id="store_address.postal_code"
                    type="text"
                    label="Store Postal Code"
                    {...register("store_address.postal_code")}
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    id="store_address.address"
                    type="text"
                    label="Store Address"
                    {...register("store_address.address")}
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    id="store_address.number"
                    type="text"
                    label="Store Number"
                    {...register("store_address.number")}
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    id="store_address.address_2"
                    type="text"
                    label="Store Address 2"
                    {...register("store_address.address_2")}
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    id="store_address.district"
                    type="text"
                    label="Store District"
                    {...register("store_address.district")}
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    id="store_address.city"
                    type="text"
                    label="Store City"
                    {...register("store_address.city")}
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    id="store_address.state"
                    type="text"
                    label="Store State"
                    {...register("store_address.state")}
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    id="store_address.additional_info"
                    type="text"
                    label="Store Additional Info"
                    {...register("store_address.additional_info")}
                  />
                </Box>
              </TabPanel>

              <TabPanel
                value="4"
                sx={{
                  width: "100%",
                  typography: "body1",
                  maxWidth: "xl",
                  minWidth: 3 / 4,
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "100%", typography: "body1" }}>
                  {inputsName.map((input, index) => (
                    <Box
                      key={index}
                      className="flags-container"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <div
                          className="fa-flags"
                          style={{
                            height: "50px",
                            width: "200px",
                            marginTop: "10px",
                          }}
                        >
                          <img
                            src={`/assets/language/${input.language_id}.png`}
                            alt=""
                          />
                          &nbsp;-&nbsp;{input.language_name}
                        </div>
                        <input
                          hidden
                          {...register(`descriptions[${index}].language_id`)}
                          value={input.language_id}
                        />

                        <TextField
                          sx={{ mt: 1, minWidth: 3 / 4, marginLeft: "8px" }}
                          margin="normal"
                          required
                          fullWidth
                          id={index.toString()}
                          type="text"
                          label="Store Title"
                          {...register(`descriptions[${index}].name`)}
                        />
                        <p style={{ color: "red", textAlign: "center" }}>
                          {errors?.descriptions?.toString()}
                        </p>
                      </Box>

                      <TextField
                        margin="normal"
                        fullWidth
                        id={"#descriptions[" + index + "].description"}
                        type="text"
                        label="Store Description"
                        rows={10}
                        multiline
                        {...register(`descriptions[${index}].description`)}
                      />
                      <p style={{ color: "red", textAlign: "center" }}>
                        {errors?.descriptions?.toString()}
                      </p>

                      <TextField
                        margin="normal"
                        fullWidth
                        id={"#descriptions[" + index + "].seo_keyword"}
                        type="text"
                        label="Meta keyword (Keywords for SEO - Write Everything related to the Product separated by commas. Max of 2000 Characters)"
                        rows={3}
                        multiline
                        {...register(`descriptions[${index}].seo_keyword`)}
                      />
                      <p style={{ color: "red", textAlign: "center" }}>
                        {errors?.descriptions?.toString()}
                      </p>

                      <TextField
                        sx={{ mt: 1, minWidth: 3 / 4 }}
                        margin="normal"
                        fullWidth
                        id={index.toString()}
                        type="text"
                        label="Store Icon URL - Relative Address"
                        {...register(`descriptions[${index}].icon`)}
                        onChange={handleChangeIcon}
                      />
                      {icon[index] && (
                        <CardMedia
                          component="img"
                          sx={{ width: 151, display: "inline" }}
                          image={imageBaseURL + icon[index]}
                          alt={"image"}
                        />
                      )}
                      <p style={{ color: "red", textAlign: "center" }}>
                        {errors?.descriptions?.toString()}
                      </p>
                    </Box>
                  ))}
                </Box>
              </TabPanel>

              <TabPanel
                value="5"
                sx={{
                  width: "100%",
                  typography: "body1",
                  maxWidth: "xl",
                  minWidth: 3 / 4,
                  alignItems: "center",
                }}
              >
                <Box sx={{ marginTop: "10px" }}>
                  <div>
                    <InputLabel
                      id="main-color-select"
                      sx={{ marginTop: "10px" }}
                    >
                      Select Store Main Color
                    </InputLabel>
                    <ColorBox
                      defaultValue={primaryColor}
                      onChange={(color) => setPrimaryColor("#" + color.hex)}
                    />
                  </div>

                  <div>
                    <InputLabel
                      id="secondary-color-select"
                      sx={{ marginTop: "10px" }}
                    >
                      Select Store Secondary Color
                    </InputLabel>
                    <ColorBox
                      defaultValue={secondaryColor}
                      onChange={(color) => setSecondaryColor("#" + color.hex)}
                    />
                  </div>

                  <div>
                    <InputLabel
                      id="hover-color-select"
                      sx={{ marginTop: "10px" }}
                    >
                      Select Store Hover Color
                    </InputLabel>
                    <ColorBox
                      defaultValue={hoverColor}
                      onChange={(color) => setHoverColor("#" + color.hex)}
                    />
                  </div>
                </Box>
              </TabPanel>

              <TabPanel
                value="6"
                sx={{
                  width: "100%",
                  typography: "body1",
                  maxWidth: "xl",
                  minWidth: 3 / 4,
                  alignItems: "center",
                }}
              >
                <Box sx={{ marginTop: "10px" }}>
                  Add/Remove Fields & Order Button
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginRight: "8px",
                      marginLeft: 1,
                    }}
                  >
                    <TextField
                      margin="normal"
                      fullWidth
                      id="Order Add/Remove"
                      type="number"
                      label="Order to be Added/Removed"
                      defaultValue={addRemoveOrder}
                      onBlur={(e) => setAddRemoveOrder(Number(e.target.value))}
                    />

                    <TextField
                      margin="normal"
                      fullWidth
                      id="Field Add/Remove"
                      type="text"
                      label="Field to be Added/Removed"
                      defaultValue={addRemoveField}
                      onBlur={(e) => setAddRemoveField(e.target.value)}
                    />
                    <Button
                      style={{ minWidth: "200px", marginLeft: 10 }}
                      variant="contained"
                      href=""
                      onClick={addOrderField}
                    >
                      Add Order & Field
                    </Button>
                    <Button
                      style={{
                        minWidth: "200px",
                        marginLeft: 10,
                        marginRight: 10,
                      }}
                      variant="contained"
                      href=""
                      onClick={removeOrderField}
                    >
                      Remove Order & Field
                    </Button>
                  </Box>
                  {contents.map((content, index) => {
                    return (
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginRight: "8px",
                          marginLeft: 1,
                        }}
                        key={index}
                      >
                        <div
                          className="fa-flags"
                          style={{
                            height: "50px",
                            width: "200px",
                            marginTop: "10px",
                          }}
                        >
                          <img
                            src={`/assets/language/${content.language_id}.png`}
                            alt=""
                          />
                          &nbsp;-&nbsp;
                          {inputsName[content.language_id - 1].language_name}
                        </div>
                        <input
                          hidden
                          {...register(`contents[${index}].language_id`, {
                            required: `contents[${index}].language_id is required`,
                          })}
                          value={content.language_id}
                        />
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id={index.toString()}
                          type="number"
                          label="Order (Not Editable)"
                          {...register(`contents[${index}].order`, {
                            required: `contents[${index}].order is required`,
                          })}
                          value={content.order}
                        />

                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id={index.toString()}
                          type="text"
                          label="Field (Not Editable)"
                          {...register(`contents[${index}].field`, {
                            required: `contents[${index}].field is required`,
                          })}
                          value={content.field}
                        />
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id={index.toString()}
                          type="text"
                          label="Value"
                          {...register(`contents[${index}].value`, {
                            required: `contents[${index}].value is required`,
                          })}
                        />
                      </Box>
                    );
                  })}
                </Box>
              </TabPanel>
              <TabPanel
                value="7"
                sx={{
                  width: "100%",
                  typography: "body1",
                  maxWidth: "xl",
                  minWidth: 3 / 4,
                  alignItems: "center",
                }}
              >
                <Typography
                  component="h2"
                  variant="h5"
                  sx={{
                    paddingTop: "20px",
                    fontWeight: "bold",
                    fontSize: "26px",
                    color: "#556cd6",
                    paddingLeft: "10px",
                  }}
                >
                  Top Icons
                </Typography>
                <Box sx={{ marginTop: "10px" }}>
                  Top Icons and Text to be Shown at the top of the store.
                  <br></br>
                  For more informations on which icons are avaliable, please
                  check the FontAwesome page:{" "}
                  <a href="https://fontawesome.com/icons">
                    https://fontawesome.com/icons
                  </a>
                  <br></br>
                  Icons are gotten from @fortawesome/free-solid-svg-icons
                  library, so the names to use in the icon field are to be
                  compatible with it<br></br>
                  Example: faTruck, faCheckCircle, faShoppingBag, etc.
                  <Box sx={{ marginTop: "10px" }}>
                    Add/Remove Top Icons & Order Button
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginRight: "8px",
                        marginLeft: 1,
                      }}
                    >
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        margin="normal"
                        fullWidth
                        id="Order Add/Remove"
                        type="number"
                        label="Order to be Added/Removed"
                        defaultValue={addRemoveTopiconOrder}
                        onBlur={(e) =>
                          setAddRemoveTopiconOrder(Number(e.target.value))
                        }
                      />
                      <Button
                        style={{ minWidth: "200px", marginLeft: 10 }}
                        variant="contained"
                        href=""
                        onClick={addOrderTopicon}
                      >
                        Add Order
                      </Button>
                      <Button
                        style={{
                          minWidth: "200px",
                          marginLeft: 10,
                          marginRight: 10,
                        }}
                        variant="contained"
                        href=""
                        // onClick={removeOrderTopicon}
                      >
                        Remove Order
                      </Button>
                    </Box>
                    {topicons.map((topicon, index) => {
                      return (
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginRight: "8px",
                            marginLeft: 1,
                          }}
                          key={index}
                        >
                          <div
                            className="fa-flags"
                            style={{
                              height: "50px",
                              width: "200px",
                              marginTop: "10px",
                            }}
                          >
                            <img
                              src={`/assets/language/${topicon.language_id}.png`}
                              alt=""
                            />
                            &nbsp;-&nbsp;
                            {inputsName[topicon.language_id - 1].language_name}
                          </div>
                          <input
                            hidden
                            {...register(`topicons[${index}].language_id`, {
                              required: `topicons[${index}].language_id is required`,
                            })}
                            value={topicon.language_id}
                          />
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            margin="normal"
                            required
                            fullWidth
                            id={index.toString()}
                            type="number"
                            label="Order (Not Editable)"
                            {...register(`topicons[${index}].order`, {
                              required: `topicons[${index}].order is required`,
                            })}
                            value={topicon.order}
                          />

                          <TextField
                            InputLabelProps={{ shrink: true }}
                            margin="normal"
                            required
                            fullWidth
                            id={index.toString()}
                            type="text"
                            label="Icon"
                            defaultValue={topicon.icon}
                            onChange={(e) =>
                              (topicons[index].icon = e.target.value)
                            }
                          />
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            margin="normal"
                            fullWidth
                            id={index.toString()}
                            type="text"
                            label="Title"
                            defaultValue={topicons[index].title}
                            onChange={(e) =>
                              (topicons[index].title = e.target.value)
                            }
                          />
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            margin="normal"
                            required
                            fullWidth
                            id={index.toString()}
                            type="text"
                            label="Text"
                            defaultValue={topicons[index].text}
                            onChange={(e) =>
                              (topicons[index].text = e.target.value)
                            }
                          />
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            margin="normal"
                            fullWidth
                            id={index.toString()}
                            type="link"
                            label="Link"
                            defaultValue={topicons[index].link}
                            onChange={(e) =>
                              (topicons[index].link = e.target.value)
                            }
                          />
                        </Box>
                      );
                    })}
                  </Box>
                </Box>

                <Typography
                  component="h2"
                  variant="h5"
                  sx={{
                    paddingTop: "20px",
                    fontWeight: "bold",
                    fontSize: "26px",
                    color: "#556cd6",
                    paddingLeft: "10px",
                  }}
                >
                  Top Menu Bar
                </Typography>
                <Box sx={{ marginTop: "10px" }}>
                  Menu Items to be shown at the top of the store.
                  <br></br>
                  They will be added after the Categories Menu
                  <Box sx={{ marginTop: "10px" }}>
                    Add/Remove Menu Bar & Order Button
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginRight: "8px",
                        marginLeft: 1,
                      }}
                    >
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        margin="normal"
                        fullWidth
                        id="Order Add/Remove"
                        type="number"
                        label="Order to be Added/Removed"
                        defaultValue={addRemoveMenubarOrder}
                        onBlur={(e) =>
                          setAddRemoveMenubarOrder(Number(e.target.value))
                        }
                      />
                      <Button
                        style={{ minWidth: "200px", marginLeft: 10 }}
                        variant="contained"
                        href=""
                        onClick={addOrderMenubar}
                      >
                        Add Order
                      </Button>
                      <Button
                        style={{
                          minWidth: "200px",
                          marginLeft: 10,
                          marginRight: 10,
                        }}
                        variant="contained"
                        href=""
                        // onClick={removeOrderMenubar}
                      >
                        Remove Order
                      </Button>
                    </Box>
                    {menubar.map((menu, index) => {
                      return (
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginRight: "8px",
                            marginLeft: 1,
                          }}
                          key={index}
                        >
                          <div
                            className="fa-flags"
                            style={{
                              height: "50px",
                              width: "200px",
                              marginTop: "10px",
                            }}
                          >
                            <img
                              src={`/assets/language/${menu.language_id}.png`}
                              alt=""
                            />
                            &nbsp;-&nbsp;
                            {inputsName[menu.language_id - 1].language_name}
                          </div>
                          <input
                            hidden
                            {...register(`menubar[${index}].language_id`, {
                              required: `menubar[${index}].language_id is required`,
                            })}
                            value={menu.language_id}
                          />
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            margin="normal"
                            required
                            fullWidth
                            id={index.toString()}
                            type="number"
                            label="Order (Not Editable)"
                            {...register(`menubar[${index}].order`, {
                              required: `menubar[${index}].order is required`,
                            })}
                            value={menu.order}
                          />
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            margin="normal"
                            required
                            fullWidth
                            id={index.toString()}
                            type="text"
                            label="Text"
                            defaultValue={menubar[index].text}
                            onChange={(e) =>
                              (menubar[index].text = e.target.value)
                            }
                          />
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            margin="normal"
                            fullWidth
                            id={index.toString()}
                            type="link"
                            label="Link"
                            defaultValue={menubar[index].link}
                            onChange={(e) =>
                              (menubar[index].link = e.target.value)
                            }
                          />
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel
                value="8"
                sx={{
                  width: "100%",
                  typography: "body1",
                  maxWidth: "xl",
                  minWidth: 3 / 4,
                  alignItems: "center",
                }}
              >
                <Box sx={{ marginTop: "10px" }}>
                  Social Media Web Page Links
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginRight: "8px",
                      marginLeft: 1,
                    }}
                  >
                    <TextField
                      margin="normal"
                      fullWidth
                      id="social_media.instagram_url"
                      type="text"
                      label="Instagram URL"
                      {...register("social_media.instagram_url")}
                    />
                    <TextField
                      margin="normal"
                      fullWidth
                      id="social_media.facebook_url"
                      type="text"
                      label="Facebook URL"
                      {...register("social_media.facebook_url")}
                    />
                    <TextField
                      margin="normal"
                      fullWidth
                      id="social_media.youtube_url"
                      type="text"
                      label="Youtube URL"
                      {...register("social_media.youtube_url")}
                    />
                    <TextField
                      margin="normal"
                      fullWidth
                      id="social_media.tumblr_url"
                      type="text"
                      label="Tumblr URL"
                      {...register("social_media.tumblr_url")}
                    />
                    <TextField
                      margin="normal"
                      fullWidth
                      id="social_media.twitter_url"
                      type="text"
                      label="Twitter URL"
                      {...register("social_media.twitter_url")}
                    />
                    <TextField
                      margin="normal"
                      fullWidth
                      id="social_media.linkedIn_url"
                      type="text"
                      label="LinkedIn URL"
                      {...register("social_media.linkedIn_url")}
                    />
                  </Box>
                </Box>
              </TabPanel>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  minWidth: 3 / 4,
                  marginBottom: "30px",
                }}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    mt: 2,
                  }}
                >
                  <Button
                    href="/stores"
                    variant="outlined"
                    startIcon={<ArrowBackIosNewIcon />}
                  >
                    Go Back
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    size="large"
                    endIcon={<SendIcon />}
                    disabled={circularLoading}
                    type="submit"
                  >
                    Send
                    {circularLoading && (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: green[500],
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </Button>
                </Stack>
              </Box>
            </Box>

            <div style={{ marginTop: "100px", width: "100%" }}></div>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );
}
