/* import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import AdapterLuxon from "@mui/lab/AdapterLuxon";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { SessionContextProvider } from "./contexts/auth/SessionContext";
import { ProductContextProvider } from "./contexts/product/ProductContext";
import { CookiesProvider } from "react-cookie";
import App from "./App";
import "./styles/global.css";
import theme from "./theme";
import store from "./store"; */

/* const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
); */

/* root.render(
  <CookiesProvider>
    <LocalizationProvider dateAdapter={AdapterLuxon} locale={"pt-br"}>
      <SessionContextProvider>
        <ProductContextProvider>
          <Provider store={store}>
            <BrowserRouter>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <App />
              </ThemeProvider>
            </BrowserRouter>
          </Provider>
        </ProductContextProvider>
      </SessionContextProvider>
    </LocalizationProvider>
  </CookiesProvider>,
  document.querySelector("#root")
); */

import * as React from "react";
import * as ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import App from "./App";
import theme from "./theme";
import { CookiesProvider } from "react-cookie";
import { SessionContextProvider } from "./contexts/auth/SessionContext";
import { ProductContextProvider } from "./contexts/product/ProductContext";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement!);
root.render(
  <BrowserRouter>
    <CookiesProvider>
      <SessionContextProvider>
        <ProductContextProvider>
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <App />
            </ThemeProvider>
          </Provider>
        </ProductContextProvider>
      </SessionContextProvider>
    </CookiesProvider>
  </BrowserRouter>
);
