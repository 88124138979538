export enum ProductTypeEnum {
  // These Values must be the same in the DB
  UNIQUE_PRODUCT = 1,
  PARENT_PRODUCT = 2,
  CHILD_PRODUCT = 3,
  COMBINATION_PRODUCT = 4,
}

//product_type = 1 - Produtos Unicos (Unique Products) (Buyable and Shown on Catalog) - Most Basic and Default Type of Product. It can only be part of the Combination Product
//product_type = 2 - Produtos Pai (Parent Products) (Not Buyable and Shown on Catalog) - It cannot be part of the Cobination Product
//product_type = 3 - Produtos Filhos (Child Products) (Buyable and not Shown on Catalog) - It is allowed to have MULTIPLE PARENTS. It can also be part of a Combination Product
//product_type = 4 - Produtos Combinacao (Combination Products) (Buyable and Shown on Catalog) - Composed by Unique Products and/or Child Products
