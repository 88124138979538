/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Container,
  Pagination,
  Box,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Button,
} from "@mui/material";
import TableHorizontal from "../TableHorizontal";
import api from "../../services/api";
import React from "react";
function ListPaymentMethods() {
  const [pageState, setPageState] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const columns = [
    "ID",
    "Code",
    "Name",
    "Payment Company",
    "Dependent Shipping",
    "Actions",
    "Delete",
  ];
  const keys = [
    "id",
    "code",
    "name",
    "payment_company_name",
    "dependent_shipping_method_code",
    "action",
    "delete",
  ];
  const fetchData = async (page = 1, numberItemsPerPage = 250) => {
    await api
      .get(
        `/payment-methods?page=${page}&limit=${numberItemsPerPage}&search=${searchFilter}`
      )
      .then((res) => {
        setPaymentMethods(res.data.items);
        setTotalPages(res.data.total_pages);
      });
  };
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageState(value);
    fetchData(value, Number(numberItemsPerPage));
  };

  const [searchFilter, setSearchFilter] = useState("");

  const [numberItemsPerPage, setNumberItemsPerPage] = useState("250");
  const handleNumberItemsPerPage = (event: SelectChangeEvent) => {
    setNumberItemsPerPage(event.target.value);
    fetchData(1, Number(event.target.value));
  };

  const handleSearchClick = () => {
    fetchData(1, Number(numberItemsPerPage));
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="number-items-page-label">
            Number of Items per Page
          </InputLabel>
          <Select
            labelId="number-items-page-select-label"
            id="number-items-page"
            value={numberItemsPerPage}
            label="Number of Items per Page"
            onChange={handleNumberItemsPerPage}
          >
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={250}>250</MenuItem>
            <MenuItem value={500}>500</MenuItem>
            <MenuItem value={750}>750</MenuItem>
            <MenuItem value={1000}>1000</MenuItem>
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 1,
          }}
        >
          <InputLabel id="table-name-filter-label">
            Payment Method Name filter
          </InputLabel>
          <TextField
            //sx={{ mt: 1, minWidth: 3/4 }}
            sx={{ minWidth: 1 / 4 }}
            //fullWidth
            id={"Search"}
            type="text"
            label="Type here..."
            defaultValue={searchFilter}
            onBlur={(e) => setSearchFilter(e.target.value)}
          />
        </Box>
        <Button
          variant="contained"
          sx={{ m: 3 }}
          href=""
          onClick={handleSearchClick}
        >
          Filter
        </Button>
      </Box>

      <TableHorizontal
        screen={"payment-methods"}
        columns={columns}
        data={paymentMethods}
        objectKey={keys}
      />
      <Box
        display="flex"
        width={"100%"}
        height={80}
        alignItems="center"
        justifyContent="center"
      >
        <Pagination
          count={totalPages}
          page={pageState}
          onChange={handlePageChange}
        />
      </Box>
    </Container>
  );
}
export default ListPaymentMethods;
