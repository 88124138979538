import React from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles } from "@mui/styles";

import List from "@mui/material/List";
/* import ListItem from "@mui/material/ListItem"; */
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";

import IconExpandLess from "@mui/icons-material/ExpandLess";
import IconExpandMore from "@mui/icons-material/ExpandMore";
// import { Padding } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { ListItemButton } from "@mui/material";
/* import { Button } from "@mui/material";
import Items from "../AppMenu/items"; */

// React runtime PropTypes
export const AppMenuItemPropTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
  Icon: PropTypes.elementType,
  items: PropTypes.array,
};

// TypeScript compile-time props type, infered from propTypes
// https://dev.to/busypeoples/notes-on-typescript-inferring-react-proptypes-1g88
type AppMenuItemPropType = PropTypes.InferProps<typeof AppMenuItemPropTypes>;
type AppMenuItemPropsWithoutItems = Omit<AppMenuItemPropType, "items">;
// Improve child items declaration
export type AppMenuItemProps = AppMenuItemPropsWithoutItems & {
  items?: AppMenuItemProps[];
};

const AppMenuItem: React.FC<AppMenuItemProps> = (props) => {
  const { name, Icon, link, items = [] } = props;
  const classes = useStyles();
  const isExpandable = items && items.length > 0;
  const [open, setOpen] = React.useState(false);
  const location = useLocation();

  function handleClick() {
    setOpen(!open);
    for (let i = 0; i < items.length; i++) {
      if (items[i].link === location.pathname) {
      }
    }
  }

  function handleroute(link: string | null | undefined) {
    if (link) {
      window.location.href = link;
    }
  }
  const MenuItemRoot = (
    <ListItemButton className={classes.menuItem} onClick={handleClick}>
      {/* Display an icon if any */}
      {!!Icon && (
        /* <a href={String(link)}> */
        <ListItemIcon
          className={classes.menuItemIcon}
          sx={{
            width: "30px",
            minWidth: "30px",
          }}
          onClick={() => {
            handleroute(link);
          }}
        >
          <Icon className={classes.menuItemIcon} />
        </ListItemIcon>
        /* </a> */
      )}
      {/* <a href={String(link)} style={{display: "block", textDecoration: "none"}}> */}
      <ListItemText
        primary={name}
        inset={!Icon}
        onClick={() => {
          handleroute(link);
        }}
      />
      {/* </a> */}
      {/* Display the expand menu if the item has children */}
      {isExpandable && !open && <IconExpandMore />}
      {isExpandable && open && <IconExpandLess />}
    </ListItemButton>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Divider className={classes.divider} />
      <List component="div" disablePadding>
        {items.map((item, index) => (
          <AppMenuItem {...item} key={index} />
        ))}
      </List>
      <Divider className={classes.divider} />
    </Collapse>
  ) : null;

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  );
};

AppMenuItem.propTypes = AppMenuItemPropTypes;

const useStyles: any = makeStyles(() =>
  createStyles({
    menuItem: {
      paddingTop: "25px",
    },
    menuItemIcon: {
      color: "#556cd6",
      fill: "#556cd6",
    },
    divider: {
      borderColor: "#556cd6!important",
      borderWidth: "2px!important",
    },
  })
);

export default AppMenuItem;
