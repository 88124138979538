/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Stack,
  List,
  ListItemText,
  ListItem,
  Alert,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  SelectChangeEvent,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import api from "../../services/api";
import Swal from "sweetalert2";
import { inputsName } from "../../config/languageConst";
import { AsyncPaginate } from "react-select-async-paginate";
import Cookies from "js-cookie";
import { GridAddIcon } from "@mui/x-data-grid";

type PageContent = {
  id?: number;
  page_description_id?: number;
  content_type: number;
  content: string;
  sort_order: number;
  updated_at?: Date;
  created_at?: string;
};

type PageDescription = {
  id?: number;
  page_id?: number;
  language_id: number;
  title: string;
  description: string;
  seo_keyword: string;
  seo_url: string;
  updated_at?: Date;
  created_at?: string;
  contents: PageContent[];
};

type PageProps = {
  id?: number;
  store_id: number;
  last_change_username: string;
  updated_at?: Date;
  created_at?: string;
  descriptions: PageDescription[];
};

export default function NewPages() {
  const [circularLoading, setCircularLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);
  const regex = /\W|_/;
  const [compareseourl, setcompareseourl] = useState(false);
  const [inputSEOURL, setInputSEOURL] = useState("");
  const handleChangeSEOURL = (event: any) => {
    //setMessage(event.target.value);
    setInputSEOURL(event.target.value);
  };
  useEffect(() => {
    if (regex.test(inputSEOURL)) {
      setcompareseourl(true);
    } else {
      setcompareseourl(false);
    }
  }, [inputSEOURL]);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const defaultAdditional = {
    page: 1,
  };

  const loadOptionsStores = async (search: any, page: any) => {
    const results = await api.get(`/stores?page=${page}&search=${search}`);
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsStores = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsStores(q, page);
    if (options.length > 0) {
      options.unshift({ id: 0, name: "None" });
    }
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const [store, setStore] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const [pageData, setPageData] = useState<PageProps>();
  const [pageDataUpdate, setPageDataUpdate] = useState(false);

  const createPageData = async () => {
    if (!pageData) {
      let tempPageData = {} as PageProps;
      tempPageData.store_id = store ? store.id : 0;
      tempPageData.last_change_username = Cookies.get("username") || "";
      tempPageData.descriptions = [] as PageDescription[];
      for (let i = 0; i < inputsName.length; i++) {
        let tempPageDescriptionData = {} as PageDescription;
        tempPageDescriptionData.language_id = inputsName[i].language_id;
        tempPageDescriptionData.title = "";
        tempPageDescriptionData.description = "";
        tempPageDescriptionData.seo_keyword = "";
        tempPageDescriptionData.seo_url = "";
        tempPageDescriptionData.contents = [] as PageContent[];
        tempPageData.descriptions.push(tempPageDescriptionData);
      }
      setPageData(tempPageData);
    }
  };

  const addContent = async (language_id: number) => {
    await createPageData();
    let addContent: PageContent = {
      content_type: 0,
      content: "",
      sort_order:
        pageData?.descriptions[language_id - 1]?.contents?.length || 0,
    };
    pageData?.descriptions[language_id - 1].contents.push(addContent);

    setPageData(pageData);
    setPageDataUpdate(true);
  };

  const handleContentSelectChange = async (
    event: SelectChangeEvent,
    language_id_index: number,
    content_id_index: number
  ) => {
    await createPageData();
    if (pageData) {
      pageData.descriptions[language_id_index].contents[
        content_id_index
      ].content_type = Number(event.target.value);
      setPageDataUpdate(true);
    }
    /* setNumberItemsPerPage(event.target.value);
    fetchData(1, Number(language), Number(event.target.value)); */
  };

  const onSubmit = (data: any) => {
    // setCircularLoading(true);

    if (!store) return;
    data.store_id = store?.id;

    let username = Cookies.get("username") ? Cookies.get("username") : "";
    data.last_change_username = username;

    for (let index = 0; index < data.descriptions.length; index++) {
      data.descriptions[index].language_id = Number(
        data.descriptions[index].language_id
      );

      // Content section
      if (
        pageData &&
        pageData.descriptions[index].contents &&
        pageData.descriptions[index].contents.length
      ) {
        data.descriptions[index].contents = [];
        for (
          let index_content = 0;
          index_content < pageData.descriptions[index].contents.length;
          index_content++
        ) {
          let content = {} as PageContent;
          content.content_type = Number(
            pageData.descriptions[index].contents[index_content].content_type
          );
          content.content =
            pageData.descriptions[index].contents[index_content].content;
          content.sort_order = Number(
            pageData.descriptions[index].contents[index_content].sort_order
          );
          data.descriptions[index].contents.push(content);
        }
      }
    }

    api
      .post("page", data)
      .then((response) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Page created!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          // footer: '<a href="">Why do I have this issue?</a>',
        });
        setCircularLoading(false);
        setRequestError(false);
        setRequestErrorData(error.response.data.message);
      });
  };

  useEffect(() => {
    createPageData();
  }, []);

  useEffect(() => {
    setPageDataUpdate(false);
  }, [pageData, pageDataUpdate]);

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{
            fontSize: "32px",
            marginBottom: "10px",
            alignItems: "center",
            color: "#556cd6",
          }}
        >
          New Page
        </Typography>

        {requestError && (
          <List component="nav" aria-label="error list">
            {requestErrorData.map((error) => (
              <ListItem key={error}>
                <ListItemText
                  sx={{
                    color: "red",
                  }}
                  primary={error}
                />
              </ListItem>
            ))}
          </List>
        )}
        <Box
          component="form"
          sx={{ mt: 1, minWidth: 3 / 4, width: "100%" }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <InputLabel id="store-label">Store*</InputLabel>
          <AsyncPaginate
            placeholder="Select Store"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"store"}
            id="store"
            name="store"
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.name}
            loadOptions={loadPageOptionsStores}
            value={store}
            onChange={setStore}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.store_id?.toString()}
          </p>

          <div style={{ marginTop: "30px" }}>
            {inputsName.map((input, index) => (
              <div
                key={index}
                className="flags-container"
                style={{ display: "block", marginTop: "50px" }}
              >
                <Divider sx={{ borderBottomWidth: "thick" }}></Divider>
                <div className="fa-flags">
                  <img
                    src={`/assets/language/${input.language_id}.png`}
                    alt=""
                  />
                  &nbsp;-&nbsp;{input.language_name}
                </div>
                <input
                  hidden
                  {...register(`descriptions[${index}].language_id`)}
                  value={input.language_id}
                />

                <p style={{ color: "red", textAlign: "center" }}>
                  {errors?.descriptions?.toString()}
                </p>

                <TextField
                  sx={{ mt: 1, minWidth: 3 / 4 }}
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  required
                  fullWidth
                  id={"#descriptions[" + index + "].title"}
                  type="text"
                  label="Title"
                  {...register(`descriptions[${index}].title`)}
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  fullWidth
                  id={"#descriptions[" + index + "].description"}
                  type="text"
                  label="Description of the Page Content"
                  rows={5}
                  multiline
                  {...register(`descriptions[${index}].description`)}
                />
                <TextField
                  sx={{ mt: 1, minWidth: 3 / 4 }}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ pattern: "[-a-zA-Z0-9_-]+" }}
                  required
                  margin="normal"
                  fullWidth
                  id={"#descriptions[" + index + "].seo_url"}
                  type="text"
                  label="SEO URL (Friendly URL)"
                  {...register(`descriptions[${index}].seo_url`)}
                  onChange={handleChangeSEOURL}
                />
                {compareseourl && (
                  <Alert severity="warning">
                    Warning: caracteres specials are forbidden (Only _ and -
                    allowed)
                  </Alert>
                )}

                <TextField
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  fullWidth
                  id={"#descriptions[" + index + "].seo_keyword"}
                  type="text"
                  label="Meta keyword (Keywords for SEO - Write Everything related to the Brand separated by commas. Max of 2000 Characters)"
                  rows={3}
                  multiline
                  {...register(`descriptions[${index}].seo_keyword`)}
                />

                <Typography
                  component="h2"
                  variant="h6"
                  sx={{
                    fontSize: "24px",
                    marginBottom: "4px",
                    alignItems: "center",
                    color: "#556cd6",
                  }}
                >
                  Page Contents
                </Typography>
                <Button
                  onClick={() => addContent(input.language_id)}
                  variant="contained"
                  startIcon={<GridAddIcon />}
                >
                  Add Content
                </Button>
                {pageData?.descriptions[index]?.contents.map(
                  (content_input, content_index) => (
                    <div
                      key={content_index}
                      style={{ display: "block", marginTop: "20px" }}
                    >
                      <Box style={{ display: "flex", flexDirection: "row" }}>
                        <InputLabel id="content-type-label">
                          ID: {content_index}
                        </InputLabel>
                        <Box
                          sx={{
                            mt: "8px",
                            minWidth: 1 / 6,
                            width: 1 / 6,
                            marginRight: 1,
                            flexDirection: "column",
                          }}
                        >
                          <InputLabel id="content-type-label">
                            Content Type
                          </InputLabel>
                          <Select
                            labelId="content-type-select-label"
                            id="content-type"
                            value={String(
                              pageData.descriptions[index].contents[
                                content_index
                              ].content_type
                            )}
                            label="Content Type"
                            onChange={(e) =>
                              handleContentSelectChange(e, index, content_index)
                            }
                            /*                             onChange={(e) =>
                              (pageData.descriptions[index].contents[
                                content_index
                              ].content_type = Number(e.target.value))
                            } */
                            sx={{ maxHeight: "2.4em" }}
                          >
                            <MenuItem value={0}>Text</MenuItem>
                            <MenuItem value={1}>Title</MenuItem>
                            <MenuItem value={2}>SubTitle</MenuItem>
                            <MenuItem value={3}>Image</MenuItem>
                            <MenuItem value={4}>Topics</MenuItem>
                          </Select>
                        </Box>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          margin="normal"
                          id={`#descriptions[${index}].contents[${content_index}].sort_order`}
                          type="number"
                          label="Sort Order"
                          defaultValue={Number(
                            pageData.descriptions[index].contents[content_index]
                              .sort_order
                          )}
                          onBlur={(e) =>
                            (pageData.descriptions[index].contents[
                              content_index
                            ].sort_order = Number(e.target.value))
                          }
                        />
                      </Box>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        margin="normal"
                        fullWidth
                        id={`#descriptions[${index}].contents[${content_index}].content`}
                        type="text"
                        label="Content"
                        rows={
                          pageData.descriptions[index].contents[content_index]
                            .content_type === 0 ||
                          pageData.descriptions[index].contents[content_index]
                            .content_type === 4
                            ? 7
                            : 1
                        }
                        multiline={
                          pageData.descriptions[index].contents[content_index]
                            .content_type === 0 ||
                          pageData.descriptions[index].contents[content_index]
                            .content_type === 4
                            ? true
                            : false
                        }
                        onBlur={(e) =>
                          (pageData.descriptions[index].contents[
                            content_index
                          ].content = e.target.value)
                        }
                      />
                    </div>
                  )
                )}
              </div>
            ))}
          </div>

          <Stack
            direction="row"
            spacing={2}
            sx={{
              mt: 10,
            }}
          >
            <Button
              onClick={() => reset()}
              variant="outlined"
              startIcon={<DeleteIcon />}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              size="large"
              endIcon={<SendIcon />}
              disabled={circularLoading}
              type="submit"
            >
              Send
              {circularLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
}
