/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import {
  DataGrid,
  GridRowModel,
  GridColDef,
  GridRenderCellParams,
  useGridApiContext,
} from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Alert, { AlertProps } from "@mui/material/Alert";
import { LogTrackUser } from "../../../types/log-track-user/log-track-user";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "../../../services/api";
import {
  Box,
  CardMedia,
  Container,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { AsyncPaginate } from "react-select-async-paginate";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

interface ProductStoresAll {
  product_id: number;
  sku: string;
  product: string;
  store_id: number;
  store_name: string;
  store: string;
  currency_code: string;
  sell_unit: string;
  product_store_price: number;
  product_store_status: number;
  product_store_status_text: string;
  product_pdv_store_price: number;
  product_pdv_store_status: number;
  product_pdv_store_status_text: string;
  product_wholesale_store_price: number;
  product_wholesale_store_status: number;
  product_wholesale_store_status_text: string;
  product_wholesale_pdv_store_price: number;
  product_wholesale_pdv_store_status: number;
  product_wholesale_pdv_store_status_text: string;
}

const useExecuteMutation = () => {
  return React.useCallback(
    (productStore: Partial<ProductStoresAll>) =>
      new Promise<Partial<ProductStoresAll>>((resolve, reject) =>
        setTimeout(() => {
          if (
            productStore.product_store_price === 0 ||
            productStore.product_pdv_store_price === 0 ||
            productStore.product_wholesale_store_price === 0 ||
            productStore.product_wholesale_pdv_store_price === 0
          ) {
            reject();
          } else {
            resolve(productStore);
          }
        }, 200)
      ),
    []
  );
};

function SelectEditStatus(props: GridRenderCellParams) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = async (event: SelectChangeEvent) => {
    await apiRef.current.setEditCellValue({
      id,
      field,
      value: event.target.value,
    });
    apiRef.current.stopCellEditMode({ id, field });
  };

  return (
    <Select
      value={value}
      onChange={handleChange}
      size="small"
      sx={{ height: 1 }}
      native
      autoFocus
    >
      <option>DISABLED</option>
      <option>ACTIVE</option>
      <option>ACTIVE, BUT NOT BUYABLE</option>
      <option>ACTIVE, BUYABLE, DO NOT SHOW ON CATALOG</option>
    </Select>
  );
}

const renderSelectEditStatus: GridColDef["renderCell"] = (params) => {
  return <SelectEditStatus {...params} />;
};

function computeMutation(newRow: GridRowModel, oldRow: GridRowModel) {
  //Price Section
  if (newRow.product_store_price !== oldRow.product_store_price) {
    return `Product: '${oldRow.product}', Store: '${oldRow.store}' Online Store Price from '${oldRow.product_store_price}' to '${newRow.product_store_price}'`;
  }
  if (newRow.product_pdv_store_price !== oldRow.product_pdv_store_price) {
    return `Product: '${oldRow.product}', Store: '${oldRow.store}' PDV Store Price from '${oldRow.product_pdv_store_price}' to '${newRow.product_pdv_store_price}'`;
  }
  if (
    newRow.product_wholesale_store_price !==
    oldRow.product_wholesale_store_price
  ) {
    return `Product: '${oldRow.product}', Store: '${oldRow.store}' Wholesale Online Store Price from '${oldRow.product_wholesale_store_price}' to '${newRow.product_wholesale_store_price}'`;
  }
  if (
    newRow.product_wholesale_pdv_store_price !==
    oldRow.product_wholesale_pdv_store_price
  ) {
    return `Product: '${oldRow.product}', Store: '${oldRow.store}' Wholesale PDV Store Price from '${oldRow.product_wholesale_pdv_store_price}' to '${newRow.product_wholesale_pdv_store_price}'`;
  }
  //Status Section
  if (newRow.product_store_status_text !== oldRow.product_store_status_text) {
    return `Product: '${oldRow.product}', Store: '${oldRow.store}' Online Store Status from '${oldRow.product_store_status_text}' to '${newRow.product_store_status_text}'`;
  }
  if (
    newRow.product_pdv_store_status_text !==
    oldRow.product_pdv_store_status_text
  ) {
    return `Product: '${oldRow.product}', Store: '${oldRow.store}' PDV Store Status from '${oldRow.product_pdv_store_status_text}' to '${newRow.product_pdv_store_status_text}'`;
  }
  if (
    newRow.product_wholesale_store_status_text !==
    oldRow.product_wholesale_store_status_text
  ) {
    return `Product: '${oldRow.product}', Store: '${oldRow.store}' Wholesale Online Store Status from '${oldRow.product_wholesale_store_status_text}' to '${newRow.product_wholesale_store_status_text}'`;
  }
  if (
    newRow.product_wholesale_pdv_store_status_text !==
    oldRow.product_wholesale_pdv_store_status_text
  ) {
    return `Product: '${oldRow.product}', Store: '${oldRow.store}' Wholesale PDV Store Status from '${oldRow.product_wholesale_pdv_store_status_text}' to '${newRow.product_wholesale_pdv_store_status_text}'`;
  }
  return null;
}

export default function ListProductStoresAll() {
  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////

  const [loading, setLoading] = useState(false);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 100,
  });
  const [rowCountState, setRowCountState] = React.useState(0);

  const [productStoreAll, setProductStoreAll] = useState<
    {
      product_id: number;
      sku: string;
      product: string;
      store_id: number;
      store_name: string;
      store: string;
      currency_code: string;
      sell_unit: string;
      product_store_price: number;
      product_store_status: number;
      product_store_status_text: string;
      product_pdv_store_price: number;
      product_pdv_store_status: number;
      product_pdv_store_status_text: string;
      product_wholesale_store_price: number;
      product_wholesale_store_status: number;
      product_wholesale_store_status_text: string;
      product_wholesale_pdv_store_price: number;
      product_wholesale_pdv_store_status: number;
      product_wholesale_pdv_store_status_text: string;
      created_at: Date;
      updated_at: Date;
    }[]
  >([]);

  const fetchData = async (page = 1, numberItemsPerPage = 100) => {
    await api
      .get(
        `/product-stores?page=${page}&limit=${numberItemsPerPage}
        &product_sku=${productSKU}&store_id=${store}
        &price_min=${priceMin}&price_max=${priceMax}&status=${status}`
      )
      .then((res) => {
        setProductStoreAll(res.data.items);
        //setRows(res.data.items);
        //setTotalPages(res.data.total_pages);
        setRowCountState(res.data.total);
        //setPageState(0);
      });
  };

  //Product SKU Filter
  const [productSKU, setProductSKU] = useState("");
  const handleChangeProductSKU = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProductSKU(e.target.value);
  };

  //Stores Filter
  const [store, setStore] = useState(0);
  const handleChangeStore = (event: SelectChangeEvent) => {
    setStore(Number(event.target.value));
  };
  const [stores, setStores] = useState<{ id: number; name: string }[]>([]);
  const fetchStores = async () => {
    const response = await api.get(`stores?page=0&limit=500&language_id=1`);
    setStores(response.data.items);
  };

  //Price Min Filter
  const [priceMin, setPriceMin] = useState(0);
  const handleChangePriceMin = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPriceMin(Number(e.target.value));
  };

  //Price Max Filter
  const [priceMax, setPriceMax] = useState(0);
  const handleChangePriceMax = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPriceMax(Number(e.target.value));
  };

  //Status Filter
  const [status, setSatus] = useState(10);
  const handleChangeStatus = (event: SelectChangeEvent) => {
    setSatus(Number(event.target.value));
  };

  const handleSearchClick = () => {
    // fetchData(1, Number(numberItemsPerPage));
    fetchData();
  };

  //New Product Store Section
  const [newProductStoreProduct, setNewProductStoreProduct] = useState<{
    id: number;
    sku: string;
    image: string;
  } | null>(null);
  const [newProductStoreStore, setNewProductStoreStore] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [newProductStorePrice, setNewProductStorePrice] = useState(0);

  const loadOptionsProducts = async (search: any, page: any) => {
    const results = await api.get(
      `/products-custom?page=${page}&search=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsProducts = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsProducts(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadOptionsStores = async (search: any, page: any) => {
    const results = await api.get(`/stores?page=${page}&search=${search}`);
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsStores = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsStores(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const defaultAdditional = {
    page: 1,
  };

  const handleNewClick = async () => {
    if (
      newProductStoreProduct &&
      newProductStoreStore &&
      newProductStorePrice > 0
    ) {
      let productStore = {} as any;
      productStore.product_id = newProductStoreProduct?.id;
      productStore.store_id = newProductStoreStore?.id;
      productStore.price = newProductStorePrice;
      productStore.product_condition_id = 1;
      productStore.status = 1;
      productStore.show_catalog = true;
      api
        .post(`product-stores`, productStore)
        .then((response) => {
          Swal.fire({
            title: "Product Store Created!",
            icon: "success",
            confirmButtonText: "Yes",
          });
          // fetchData(pageState, Number(numberItemsPerPage));
          fetchData();
        })
        .catch((error) => {
          Swal.fire({
            title: "Ops...",
            icon: "error",
            text: error.response.data.message,
            confirmButtonText: "Yes",
          });
        });
    } else {
      Swal.fire({
        title: "Ops...",
        icon: "error",
        text: "Product, Store must be defined and Price must be > 0.",
        confirmButtonText: "Yes",
      });
    }
  };

  // FIX PARENT PRODUCTS NOT AT STORE SECTION

  const [productStoreStoreFixParents, setProductStoreStoreFixParent] =
    useState<{
      id: number;
      name: string;
    } | null>(null);

  const handleFixParentProductAtStore = async () => {
    if (productStoreStoreFixParents) {
      api
        .get(
          `product-stores/fixproductsparentsnotatstore/${productStoreStoreFixParents.id}`
        )
        .then(() => {
          Swal.fire({
            title: "Parent Products for Store Executed!",
            icon: "success",
            confirmButtonText: "Yes",
          });
          fetchData();
        })
        .catch((error) => {
          Swal.fire({
            title: "Ops...",
            icon: "error",
            text: error.response,
            confirmButtonText: "Yes",
          });
        });
    }
  };

  //DATA GRID SECTION
  const mutateRow = useExecuteMutation();
  const noButtonRef = React.useRef<HTMLButtonElement>(null);
  const [promiseArguments, setPromiseArguments] = React.useState<any>(null);

  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const processRowUpdate = React.useCallback(
    (newRow: GridRowModel, oldRow: GridRowModel) =>
      new Promise<GridRowModel>((resolve, reject) => {
        const mutation = computeMutation(newRow, oldRow);
        if (mutation) {
          // Save the arguments to resolve or reject the promise later
          setPromiseArguments({ resolve, reject, newRow, oldRow });
        } else {
          resolve(oldRow); // Nothing was changed
        }
      }),
    []
  );

  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments;
    resolve(oldRow); // Resolve with the old row to not update the internal state
    setPromiseArguments(null);
  };

  const handleYes = async () => {
    const { newRow, oldRow, reject, resolve } = promiseArguments;

    try {
      // Make the HTTP request to save in the backend
      let productStoreUpdate = {} as any;
      productStoreUpdate.product_id = newRow.product_id;
      productStoreUpdate.store_id = newRow.store_id;
      productStoreUpdate.sell_unit = newRow.sell_unit;
      productStoreUpdate.updated_at = new Date().toISOString();
      productStoreUpdate.username = Cookies.get("username") || "";
      //Check What has been changed, so the appropriateAPI is called
      if (newRow.product_store_price !== oldRow.product_store_price) {
        productStoreUpdate.price = newRow.product_store_price;
        await api.put(`product-stores/productstore`, productStoreUpdate);
      }
      if (newRow.product_pdv_store_price !== oldRow.product_pdv_store_price) {
        productStoreUpdate.price = newRow.product_pdv_store_price;
        await api.put(`product-stores/productpdvstore`, productStoreUpdate);
      }
      if (
        newRow.product_wholesale_store_price !==
        oldRow.product_wholesale_store_price
      ) {
        productStoreUpdate.price = newRow.product_wholesale_store_price;
        await api.put(
          `product-stores/productwholesalestore`,
          productStoreUpdate
        );
      }
      if (
        newRow.product_wholesale_pdv_store_price !==
        oldRow.product_wholesale_pdv_store_price
      ) {
        productStoreUpdate.price = newRow.product_wholesale_pdv_store_price;
        await api.put(
          `product-stores/productwholesalepdvstore`,
          productStoreUpdate
        );
      }
      //Status Section
      if (
        newRow.product_store_status_text !== oldRow.product_store_status_text
      ) {
        switch (String(newRow.product_store_status_text).toLowerCase()) {
          case "active, buyable, do not show on catalog":
            productStoreUpdate.status = 3;
            newRow.product_store_status = 3;
            break;
          case "active, but not buyable":
            productStoreUpdate.status = 2;
            newRow.product_store_status = 2;
            break;
          case "active":
            productStoreUpdate.status = 1;
            newRow.product_store_status = 1;
            break;
          default:
            productStoreUpdate.status = 0;
            newRow.product_store_status = 0;
        }
        await api.put(`product-stores/productstore`, productStoreUpdate);
      }
      if (
        newRow.product_pdv_store_status_text !==
        oldRow.product_pdv_store_status_text
      ) {
        switch (String(newRow.product_pdv_store_status_text).toLowerCase()) {
          case "active, buyable, do not show on catalog":
            productStoreUpdate.status = 3;
            newRow.product_pdv_store_status = 3;
            break;
          case "active, but not buyable":
            productStoreUpdate.status = 2;
            newRow.product_pdv_store_status = 2;
            break;
          case "active":
            productStoreUpdate.status = 1;
            newRow.product_pdv_store_status = 1;
            break;
          default:
            productStoreUpdate.status = 0;
            newRow.product_pdv_store_status = 0;
        }
        await api.put(`product-stores/productpdvstore`, productStoreUpdate);
      }
      if (
        newRow.product_wholesale_store_status_text !==
        oldRow.product_wholesale_store_status_text
      ) {
        switch (
          String(newRow.product_wholesale_store_status_text).toLowerCase()
        ) {
          case "active, buyable, do not show on catalog":
            productStoreUpdate.status = 3;
            newRow.product_wholesale_store_status = 3;
            break;
          case "active, but not buyable":
            productStoreUpdate.status = 2;
            newRow.product_wholesale_store_status = 2;
            break;
          case "active":
            productStoreUpdate.status = 1;
            newRow.product_wholesale_store_status = 1;
            break;
          default:
            productStoreUpdate.status = 0;
            newRow.product_wholesale_store_status = 0;
        }
        await api.put(
          `product-stores/productwholesalestore`,
          productStoreUpdate
        );
      }
      if (
        newRow.product_wholesale_pdv_store_status_text !==
        oldRow.product_wholesale_pdv_store_status_text
      ) {
        switch (
          String(newRow.product_wholesale_pdv_store_status_text).toLowerCase()
        ) {
          case "active, buyable, do not show on catalog":
            productStoreUpdate.status = 3;
            newRow.product_wholesale_pdv_store_status = 3;
            break;
          case "active, but not buyable":
            productStoreUpdate.status = 2;
            newRow.product_wholesale_pdv_store_status = 2;
            break;
          case "active":
            productStoreUpdate.status = 1;
            newRow.product_wholesale_pdv_store_status = 1;
            break;
          default:
            productStoreUpdate.status = 0;
            newRow.product_wholesale_pdv_store_status = 0;
        }
        await api.put(
          `product-stores/productwholesalepdvstore`,
          productStoreUpdate
        );
      }

      const response = await mutateRow(newRow);
      setSnackbar({
        children: "Product Store successfully saved",
        severity: "success",
      });
      resolve(response);
      setPromiseArguments(null);
      //fetchData(pageState, numberItemsPerPage);
    } catch (error) {
      setSnackbar({
        children: "Product Store fields can't be empty",
        severity: "error",
      });
      reject(oldRow);
      setPromiseArguments(null);
    }
  };

  const handleEntered = () => {
    // The `autoFocus` is not used because, if used, the same Enter that saves
    // the cell triggers "No". Instead, we manually focus the "No" button once
    // the dialog is fully open.
    // noButtonRef.current?.focus();
  };

  const renderConfirmDialog = () => {
    if (!promiseArguments) {
      return null;
    }

    const { newRow, oldRow } = promiseArguments;
    const mutation = computeMutation(newRow, oldRow);

    return (
      <Dialog
        maxWidth="xs"
        TransitionProps={{ onEntered: handleEntered }}
        open={!!promiseArguments}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent dividers>
          {`Pressing 'Yes' will change ${mutation}.`}
        </DialogContent>
        <DialogActions>
          <Button ref={noButtonRef} onClick={handleNo}>
            No
          </Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  };

  //LOAD INITIAL VALUES
  useEffect(() => {
    fetchData();
    fetchStores();
  }, []);

  useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);
      fetchData(paginationModel.page + 1, paginationModel.pageSize);

      if (!active) {
        return;
      }

      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [paginationModel.page]);

  return (
    <Container maxWidth="xl" sx={{ width: "100%", mt: 2, mb: 4 }}>
      <Typography
        component="h1"
        variant="h5"
        sx={{
          paddingTop: "40px",
          fontWeight: "bold",
          fontSize: "32px",
          color: "#556cd6",
          paddingLeft: "20px",
        }}
      >
        Product & Store Prices - Consolidated View
      </Typography>
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="number-items-page-label">
            Filters: Product SKU
          </InputLabel>
          <TextField
            id={"productSKU"}
            type="text"
            label="Product SKU filter"
            value={productSKU}
            onChange={handleChangeProductSKU}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="store-select-label">Store</InputLabel>
          <Select
            labelId="store-select-label"
            id="store"
            value={String(store)}
            label="Store"
            onChange={handleChangeStore}
          >
            <MenuItem value={0}>None</MenuItem>
            {stores.map((store, index) => (
              <MenuItem value={store.id} key={index}>
                {store.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="priceMin-select-label">Minimum Price:</InputLabel>
          <TextField
            id={"priceMin"}
            type="number"
            label="Minimum Price filter"
            value={priceMin}
            onChange={handleChangePriceMin}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="priceMax-select-label">Maximum Price:</InputLabel>
          <TextField
            id={"priceMax"}
            type="number"
            label="Maximum Price filter"
            value={priceMax}
            onChange={handleChangePriceMax}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="status-select-label">Status</InputLabel>
          <Select
            labelId="status-select-label"
            id="status"
            value={String(status)}
            label="Status"
            onChange={handleChangeStatus}
          >
            <MenuItem value={10}>Don`t Filter</MenuItem>
            <MenuItem value={0}>Disabled</MenuItem>
            <MenuItem value={1}>Enabled</MenuItem>
            <MenuItem value={2}>Active but not Buyable</MenuItem>
            <MenuItem value={3}>
              Active, Buyable, but do not show on Catalog
            </MenuItem>
          </Select>
        </Box>

        <Button
          variant="contained"
          sx={{ m: 3 }}
          href=""
          onClick={handleSearchClick}
        >
          Filter
        </Button>
      </Box>
      To insert a new Entry, please fill the fields below and press the Insert
      Button
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
            minWidth: 1 / 4,
          }}
        >
          <InputLabel id="productstore-product-label">Product:</InputLabel>
          <AsyncPaginate
            placeholder="Select Product"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"productstore_product"}
            id="productstore_product"
            name="productstore_product"
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.sku}
            loadOptions={loadPageOptionsProducts}
            value={newProductStoreProduct}
            onChange={setNewProductStoreProduct}
          />
          <CardMedia
            component="img"
            sx={{ width: 100, display: "inline" }}
            image={`${imageBaseURL}${newProductStoreProduct?.image}`}
            alt={"image"}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
            minWidth: 1 / 4,
          }}
        >
          <InputLabel id="productstore-store-label">Store:</InputLabel>
          <AsyncPaginate
            placeholder="Select Store"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"productstore_store"}
            id="productstore_store"
            name="productstore_store"
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.name}
            loadOptions={loadPageOptionsStores}
            value={newProductStoreStore}
            onChange={setNewProductStoreStore}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 1,
            marginRight: "8px",
            minWidth: 1 / 4,
          }}
        >
          <InputLabel id="price-store-label">Initial Price:</InputLabel>
          <TextField
            id={"productstore_price"}
            type="number"
            label="Initial Price"
            value={newProductStorePrice}
            onChange={(event) =>
              setNewProductStorePrice(Number(event.target.value))
            }
          />
        </Box>
        <Button
          variant="contained"
          sx={{ m: 3 }}
          href=""
          onClick={handleNewClick}
        >
          New Entry
        </Button>
      </Box>
      Only Price and Status are changeable. To change any of it, DOUBLE CLICK on
      the row field.
      <div style={{ height: 600, width: "100%" }}>
        {renderConfirmDialog()}
        <DataGrid
          rows={productStoreAll}
          columns={columns}
          processRowUpdate={processRowUpdate}
          initialState={{
            pagination: {
              paginationModel,
            },
          }}
          pagination
          paginationModel={paginationModel}
          pageSizeOptions={[25, 50, 100]}
          rowCount={rowCountState}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          loading={loading}
        />
        {!!snackbar && (
          <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </div>
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
            minWidth: 1 / 4,
          }}
        >
          <InputLabel id="productstore-store-fixParent-label">
            Store:
          </InputLabel>
          <AsyncPaginate
            placeholder="Select Store"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"productstore_store_fixparent"}
            id="productstore_store_fixparent"
            name="productstore_store_fixparent"
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.name}
            loadOptions={loadPageOptionsStores}
            value={productStoreStoreFixParents}
            onChange={setProductStoreStoreFixParent}
          />
        </Box>
        <Button
          variant="contained"
          sx={{ m: 3 }}
          href=""
          onClick={handleFixParentProductAtStore}
        >
          Fix Parent Products Not at Store (USE WITH CAUTION - May Crash the
          Server)
        </Button>
      </Box>
    </Container>
  );
}

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

//Image Folder
const imageBaseURL = process.env.REACT_APP_IMG_CATALOG_URL;

const columns: GridColDef[] = [
  { field: "product_id", headerName: "Product ID", width: 80, editable: false },
  { field: "sku", headerName: "Product SKU", width: 180, editable: false },
  {
    field: "image",
    headerName: "Image",
    width: 100,
    //renderCell: (params) => <img alt={params.value} src={imageBaseURL+params.value} />
    renderCell: (params) => {
      return (
        <CardMedia
          component="img"
          sx={{ width: 100, display: "inline" }}
          image={`${imageBaseURL}${params.value}`}
          alt={"image"}
        />
      );
    },
  }, // renderCell will render the component },
  { field: "store_name", headerName: "Store", width: 150, editable: false },
  {
    field: "currency_code",
    headerName: "Currency",
    width: 100,
    editable: false,
  },
  {
    field: "sell_unit",
    headerName: "Unit",
    type: "string",
    width: 50,
    editable: false,
  },
  {
    field: "product_store_price",
    headerName: "Price Online Store",
    type: "number",
    width: 100,
    editable: true,
    valueFormatter: (value) =>
      value === null ? "" : currencyFormatter.format(value),
  },
  {
    field: "product_store_status_text",
    headerName: "Status Online Store",
    type: "string",
    width: 200,
    editable: true,
    renderEditCell: renderSelectEditStatus,
  },
  {
    field: "product_pdv_store_price",
    headerName: "Price PDV Store",
    type: "number",
    width: 100,
    editable: true,
    valueFormatter: (value) =>
      value === null ? "" : currencyFormatter.format(value),
  },
  {
    field: "product_pdv_store_status_text",
    headerName: "Status PDV Store",
    type: "string",
    width: 200,
    editable: true,
    renderEditCell: renderSelectEditStatus,
  },
  {
    field: "product_wholesale_store_price",
    headerName: "Price Wholesale Online Store",
    type: "number",
    width: 100,
    editable: true,
    valueFormatter: (value) =>
      value === null ? "" : currencyFormatter.format(value),
  },
  {
    field: "product_wholesale_store_status_text",
    headerName: "Status Wholesale Online Store",
    type: "string",
    width: 200,
    editable: true,
    renderEditCell: renderSelectEditStatus,
  },
  {
    field: "product_wholesale_pdv_store_price",
    headerName: "Price Wholesale PDV Store",
    type: "number",
    width: 100,
    editable: true,
    valueFormatter: (value) =>
      value === null ? "" : currencyFormatter.format(value),
  },
  {
    field: "product_wholesale_pdv_store_status_text",
    headerName: "Status Wholesale PDV Store",
    type: "string",
    width: 200,
    editable: true,
    renderEditCell: renderSelectEditStatus,
  },

  /*   {
    field: 'lastLogin',
    headerName: 'Last Login',
    type: 'dateTime',
    width: 220,
  }, */
];
