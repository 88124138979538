import { OrderStatusEnum } from "../enums/order-status.enum";

// Reference: \erp-backend-nest\src\orders\orders\helpers\order-status.helper.ts
const getAvailableOrderStatus = (current_status: OrderStatusEnum) => {
  if (!current_status) {
    return null;
  }

  const returnOptions = [];
  if (current_status === OrderStatusEnum.NEW) {
    returnOptions.push(OrderStatusEnum.NEW);
    returnOptions.push(OrderStatusEnum.PAYMENT_PENDING);
    returnOptions.push(OrderStatusEnum.PAYMENT_APPROVED);
    returnOptions.push(OrderStatusEnum.DELETED);
    returnOptions.push(OrderStatusEnum.CANCELLED);
    returnOptions.push(OrderStatusEnum.PAYMENT_EXCEPTION);
  }

  if (current_status === OrderStatusEnum.PAYMENT_PENDING) {
    returnOptions.push(OrderStatusEnum.PAYMENT_PENDING);
    returnOptions.push(OrderStatusEnum.PAYMENT_APPROVED);
    returnOptions.push(OrderStatusEnum.CANCELLED);
    returnOptions.push(OrderStatusEnum.PAYMENT_EXCEPTION);
  }

  if (current_status === OrderStatusEnum.PAYMENT_APPROVED) {
    returnOptions.push(OrderStatusEnum.PAYMENT_APPROVED);
    returnOptions.push(OrderStatusEnum.CANCELLED);
    returnOptions.push(OrderStatusEnum.PROCESSING_PACKING);
    returnOptions.push(OrderStatusEnum.SHIPPED);
    returnOptions.push(OrderStatusEnum.DELIVERED);
    returnOptions.push(OrderStatusEnum.SHIPMENT_EXCEPTION);
    returnOptions.push(OrderStatusEnum.PARTIALLY_REFUNDED);
    returnOptions.push(OrderStatusEnum.FULLY_REFUNDED);
  }

  if (current_status === OrderStatusEnum.PROCESSING_PACKING) {
    returnOptions.push(OrderStatusEnum.PROCESSING_PACKING);
    returnOptions.push(OrderStatusEnum.CANCELLED);
    returnOptions.push(OrderStatusEnum.SHIPPED);
    returnOptions.push(OrderStatusEnum.DELIVERED);
    returnOptions.push(OrderStatusEnum.SHIPMENT_EXCEPTION);
    returnOptions.push(OrderStatusEnum.PARTIALLY_REFUNDED);
    returnOptions.push(OrderStatusEnum.FULLY_REFUNDED);
  }

  if (current_status === OrderStatusEnum.SHIPPED) {
    returnOptions.push(OrderStatusEnum.SHIPPED);
    returnOptions.push(OrderStatusEnum.DELIVERED);
    returnOptions.push(OrderStatusEnum.SHIPMENT_EXCEPTION);
    returnOptions.push(OrderStatusEnum.RETURNED);
    returnOptions.push(OrderStatusEnum.SHIPPED_CORRECTION);
    returnOptions.push(OrderStatusEnum.PARTIALLY_REFUNDED);
  }

  if (current_status === OrderStatusEnum.DELIVERED) {
    returnOptions.push(OrderStatusEnum.DELIVERED);
    returnOptions.push(OrderStatusEnum.CONCLUDED);
    returnOptions.push(OrderStatusEnum.RETURNED);
    returnOptions.push(OrderStatusEnum.PARTIALLY_REFUNDED);
    returnOptions.push(OrderStatusEnum.SHIPPED_CORRECTION);
  }

  if (current_status === OrderStatusEnum.CONCLUDED) {
    returnOptions.push(OrderStatusEnum.CONCLUDED);
    returnOptions.push(OrderStatusEnum.RETURNED);
    returnOptions.push(OrderStatusEnum.PARTIALLY_REFUNDED);
    returnOptions.push(OrderStatusEnum.SHIPPED_CORRECTION);
  }

  if (current_status === OrderStatusEnum.CANCELLED) {
    returnOptions.push(OrderStatusEnum.CANCELLED);
    returnOptions.push(OrderStatusEnum.FULLY_REFUNDED);
    returnOptions.push(OrderStatusEnum.PARTIALLY_REFUNDED);
    returnOptions.push(OrderStatusEnum.DELETED);
  }

  if (current_status === OrderStatusEnum.SHIPMENT_EXCEPTION) {
    returnOptions.push(OrderStatusEnum.SHIPMENT_EXCEPTION);
    returnOptions.push(OrderStatusEnum.SHIPPED);
    returnOptions.push(OrderStatusEnum.DELIVERED);
    returnOptions.push(OrderStatusEnum.CANCELLED);
    returnOptions.push(OrderStatusEnum.SHIPPED_CORRECTION);
    returnOptions.push(OrderStatusEnum.PARTIALLY_REFUNDED);
    returnOptions.push(OrderStatusEnum.FULLY_REFUNDED);
  }

  if (current_status === OrderStatusEnum.PAYMENT_EXCEPTION) {
    returnOptions.push(OrderStatusEnum.PAYMENT_EXCEPTION);
    returnOptions.push(OrderStatusEnum.PAYMENT_APPROVED);
    returnOptions.push(OrderStatusEnum.DELETED);
    returnOptions.push(OrderStatusEnum.CANCELLED);
    returnOptions.push(OrderStatusEnum.PAYMENT_PENDING);
    returnOptions.push(OrderStatusEnum.PARTIALLY_REFUNDED);
    returnOptions.push(OrderStatusEnum.FULLY_REFUNDED);
  }

  if (current_status === OrderStatusEnum.RETURNED) {
    returnOptions.push(OrderStatusEnum.RETURNED);
    returnOptions.push(OrderStatusEnum.CANCELLED);
    returnOptions.push(OrderStatusEnum.SHIPPED_CORRECTION);
    returnOptions.push(OrderStatusEnum.SHIPMENT_EXCEPTION);
    returnOptions.push(OrderStatusEnum.PARTIALLY_REFUNDED);
    returnOptions.push(OrderStatusEnum.FULLY_REFUNDED);
  }

  if (current_status === OrderStatusEnum.FULLY_REFUNDED) {
    returnOptions.push(OrderStatusEnum.FULLY_REFUNDED);
    returnOptions.push(OrderStatusEnum.CANCELLED);
    returnOptions.push(OrderStatusEnum.DELETED);
  }

  if (current_status === OrderStatusEnum.PARTIALLY_REFUNDED) {
    returnOptions.push(OrderStatusEnum.PARTIALLY_REFUNDED);
    returnOptions.push(OrderStatusEnum.SHIPPED_CORRECTION);
    returnOptions.push(OrderStatusEnum.CANCELLED);
    returnOptions.push(OrderStatusEnum.DELETED);
  }
  if (current_status === OrderStatusEnum.SHIPPED_CORRECTION) {
    returnOptions.push(OrderStatusEnum.DELIVERED);
    returnOptions.push(OrderStatusEnum.SHIPMENT_EXCEPTION);
    returnOptions.push(OrderStatusEnum.RETURNED);
    returnOptions.push(OrderStatusEnum.SHIPPED_CORRECTION);
    returnOptions.push(OrderStatusEnum.PARTIALLY_REFUNDED);
  }

  return returnOptions;
};

export default getAvailableOrderStatus;
