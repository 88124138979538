/* eslint-disable react-hooks/exhaustive-deps */
import api from "../../services/api";
import Cookies from "js-cookie";
import { createAction } from "@reduxjs/toolkit";
import { LogoutAction } from "../../types/actions";

export function LogTrackUser(user_log: any, location: any) {
  // const dispatch = useAppDispatch();
  // If the username cookies has been erased, then it forces to do login again
  // Temporary solution, since it is causing some errors on log when that happens.
  // Correct way of doing - Sytem must verify if the cookies are there. If they are not, then forces login
  if (!user_log) {
    // dispatch(logoutRequestNoConfirmation());
    // window.location.href = "/login";
    localStorage.removeItem("token");
    localStorage.removeItem("roles");
    localStorage.removeItem("username");
    localStorage.removeItem("user_id");
    Cookies.remove("token");
    Cookies.remove("roles");
    Cookies.remove("username");
    Cookies.remove("user_id");
    createAction<LogoutAction>("LOGOUT");
    return (window.location.href = "/login");

    // Register User Track Log
  } else {
    const logs = { user_log: user_log, route_log: location };
    api.post("history-logs", logs);
  }
}
