/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  TextField,
  Stack,
  List,
  Tab,
  ListItem,
  ListItemText,
  Typography,
  Container,
  Alert,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import api from "../../services/api";
import Swal from "sweetalert2";
import { AsyncPaginate } from "react-select-async-paginate";
import { inputsName } from "../../config/languageConst";
import { TabContext, TabList, useTabContext } from "@mui/lab";
import { MultiValue } from "react-select";
function TabPanel(props: any) {
  const { children, className, value, index, ...other } = props;

  const context = useTabContext();

  if (context === null) {
    throw new TypeError("No TabContext provided");
  }
  const tabId = context.value;

  return (
    <Typography
      component="div"
      role="tabpanel"
      padding="24px"
      hidden={value !== tabId}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      //visibility={value === tabId ? "visible" : "hidden"}
      {...other}
    >
      {children}
    </Typography>
  );
}

function NewCategories() {
  const regex = /\W|_/;
  const [compareseourl, setcompareseourl] = useState(false);
  const [inputSEOURLTemp, setInputSEOURLTemp] = useState("");

  const [inputTitle, setInputTitle] = useState<string[]>([]);
  const [inputSEOURL, setInputSEOURL] = useState<string[]>([]);

  inputTitle.length = inputsName.length;
  inputSEOURL.length = inputsName.length;

  const handleChangeInputTitle = (event: any) => {
    inputTitle[event.target.id] = event.target.value;
    setInputTitle(inputTitle);
  };

  const handleChangeSEOURL = (event: any) => {
    inputSEOURL[event.target.id] = event.target.value;
    setInputSEOURL(inputSEOURL);
    setInputSEOURLTemp(event.target.value);
  };

  const GenerateSEOURL = async () => {
    //setMessage(event.target.value);
    const seourl_temp = [];
    for (var i = 0; i < inputTitle.length; i++) {
      if (inputTitle[i]) {
        let language_code: string;
        switch (i) {
          case 0:
            language_code = "-en";
            break;
          case 1:
            language_code = "-pt";
            break;
          case 2:
            language_code = "-es";
            break;
          case 3:
            language_code = "-jp";
            break;
          default:
            language_code = "";
          // code block
        }
        inputSEOURL[i] = TitleToURL(inputTitle[i] + language_code);
        setInputSEOURL(inputSEOURL);
        seourl_temp.push(inputSEOURL[i]);
      }
    }
    setInputSEOURL(seourl_temp);
  };

  const TitleToURL = function (str: string) {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from =
      "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;";
    var to =
      "AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa------";
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes

    return str;
  };

  useEffect(() => {
    if (regex.test(inputSEOURLTemp)) {
      setcompareseourl(true);
    } else {
      setcompareseourl(false);
    }
  }, [inputSEOURL]);
  const [circularLoading, setCircularLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [parent, setParent] = useState<{
    id: number;
  } | null>(null);

  const defaultAdditional = {
    page: 1,
  };

  const loadOptionsCategories = async (search: any, page: any) => {
    const results = await api.get(`/categories?page=${page}&search=${search}`);

    const hasMore = results.data.total_pages === results.data.current_page;

    return {
      options: [{ id: 0, name: "No Parent Category" }, ...results.data.items],
      hasMore: !hasMore,
    };
  };
  const loadPageOptionsCategories = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsCategories(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadOptionsAttributeGroups = async (search: any, page: any) => {
    const results = await api.get(
      `/attribute-groups?page=${page}&search=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsAttributeGroups = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsAttributeGroups(q, page);
    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  };

  const [valueAttributeGroups, setValueAttributeGroups] = useState<
    { id: number; name: string }[]
  >([]);
  const onChangeAttributeGroups = async (
    newValue: MultiValue<{ id: number; name: string }>
  ) => {
    setValueAttributeGroups([...newValue]);
  };

  const loadOptionsAttributes = async (search: any, page: any) => {
    const results = await api.get(`/attributes?page=${page}&search=${search}`);
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsAttributes = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsAttributes(q, page);
    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  };

  const [valueAttributes, setValueAttributes] = useState<
    { id: number; name: string }[]
  >([]);
  const onChangeAttributes = async (
    newValue: MultiValue<{ id: number; name: string }>
  ) => {
    setValueAttributes([...newValue]);
  };

  const [tabValue, setTabValue] = useState("3");
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const onSubmit = (category: any) => {
    setCircularLoading(true);

    for (let index = 0; index < category.descriptions.length; index++) {
      category.descriptions[index].language_id = Number(
        category.descriptions[index].language_id
      );
      if (category.descriptions[index].seo_url === "") {
        category.descriptions[index].seo_url = null;
      }
    }

    if (parent) {
      if (parent.id !== 0) {
        category.parent_id = parent ? parent.id : null;
      } else {
        category.parent_id = null;
      }
    }

    //Attribute Groups/ Filters
    let uniq_attribute_groups = valueAttributeGroups;
    let uniq_attribute_groups_ids = [];
    for (let index = 0; index < uniq_attribute_groups.length; index++) {
      uniq_attribute_groups_ids.push(Number(uniq_attribute_groups[index].id));
    }
    category.attribute_group_ids = uniq_attribute_groups_ids;

    //Attributes/Filters
    let uniq_attributes = valueAttributes;
    let uniq_attributes_ids = [];
    for (let index = 0; index < uniq_attributes.length; index++) {
      uniq_attributes_ids.push(Number(uniq_attributes[index].id));
    }
    category.attribute_ids = uniq_attributes_ids;

    api
      .post("categories", category)
      .then((res) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Category created!",
          icon: "success",
          confirmButtonText: "Yes",
        }).then(function () {
          //fizemos isso para nao confundir o usuario para nao salvar novamente
          window.location.href = "/categories";
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "" + error.response.data.message,
          //footer: '<a href="">Why do I have this issue?</a>',
        });
        setCircularLoading(false);
        setRequestError(false);
        setRequestErrorData(error.response.data.message);
      });
  };

  useEffect(() => {
    /* if (parent) {
        reset({
          parent_id: parent ? parent.id : null,
        });
      } */
    //}, [parent]);
  }, []);

  return (
    <Container component="main" maxWidth="xl">
      <Typography
        component="h1"
        variant="h5"
        sx={{ fontSize: "32px", marginBottom: "15px", color: "#556cd6" }}
      >
        New Category
      </Typography>
      {requestError && (
        <List component="nav" aria-label="Errors">
          {requestErrorData.map((error) => (
            <ListItem key={error}>
              <ListItemText
                sx={{
                  color: "red",
                }}
                primary={error}
              />
            </ListItem>
          ))}
        </List>
      )}
      <TabContext value={tabValue}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            maxWidth: "xl",
            minWidth: 3 / 4,
          }}
        >
          <TabList onChange={handleChangeTab} centered aria-label="Tab List">
            <Tab label="New Category" value="3" />
            <Tab label="Attributes" value="4" />
          </TabList>
        </Box>
        <Box
          component="form"
          sx={{ mt: 1, minWidth: 3 / 4 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <TabPanel
            value="3"
            sx={{
              width: "100%",
              typography: "body1",
              maxWidth: "xl",
              minWidth: 3 / 4,
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "100%", typography: "body1" }}>
              <AsyncPaginate
                placeholder="Select Parent Category"
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                additional={defaultAdditional}
                value={parent}
                getOptionValue={(option: any) => option?.id}
                getOptionLabel={(option: any) => option?.name}
                loadOptions={loadPageOptionsCategories}
                onChange={setParent}
              />

              <div style={{ marginTop: "30px" }}>
                <Button variant="contained" href="" onClick={GenerateSEOURL}>
                  Generate SEO URLs
                </Button>
                {inputsName.map((input, index) => (
                  <div
                    key={index}
                    className="flags-container"
                    style={{ display: "block", marginTop: "50px" }}
                  >
                    <div className="fa-flags">
                      <img
                        src={`/assets/language/${input.language_id}.png`}
                        alt=""
                      />
                      &nbsp;-&nbsp;{input.language_name}
                    </div>

                    <input
                      hidden
                      {...register(`descriptions[${index}].language_id`, {
                        required: `descriptions[${index}].language_id is required`,
                      })}
                      value={input.language_id}
                    />

                    <TextField
                      sx={{ mt: 1, minWidth: 3 / 4 }}
                      InputLabelProps={{ shrink: true }}
                      margin="normal"
                      required
                      fullWidth
                      id={index.toString()}
                      type="text"
                      label="Name"
                      {...register(`descriptions[${index}].name`)}
                      onChange={handleChangeInputTitle}
                    />

                    {inputSEOURL[index] && (
                      <p>
                        Suggested New SEO URL: (Copy and Paste in the box below
                        to change the SEO URL) {inputSEOURL[index]}
                      </p>
                    )}
                    <br />

                    <TextField
                      sx={{ mt: 1, minWidth: 3 / 4 }}
                      InputLabelProps={{ shrink: true }}
                      //inputProps={{ pattern: "[a-zA-Z0-9]" }}
                      inputProps={{ pattern: "[-a-zA-Z0-9_-]+" }}
                      margin="normal"
                      fullWidth
                      id={index.toString()}
                      type="text"
                      label="SEO URL (Friendly URL)"
                      {...register(`descriptions[${index}].seo_url`)}
                      onChange={handleChangeSEOURL}
                    />
                    {compareseourl && (
                      <Alert severity="warning">
                        Warning: caracteres specials are forbidden (Only _ and -
                        allowed)
                      </Alert>
                    )}

                    <TextField
                      sx={{ mt: 1, minWidth: 3 / 4 }}
                      InputLabelProps={{ shrink: true }}
                      margin="normal"
                      fullWidth
                      id={"#descriptions[" + index + "].image"}
                      type="text"
                      label="Image URL (relative path)"
                      {...register(`descriptions[${index}].image`)}
                    />

                    <TextField
                      InputLabelProps={{ shrink: true }}
                      margin="normal"
                      fullWidth
                      id={"#descriptions[" + index + "].content_html"}
                      type="text"
                      label="Description (Do not use HTML format)"
                      rows={10}
                      multiline
                      {...register(`descriptions[${index}].content_html`)}
                    />

                    <TextField
                      InputLabelProps={{ shrink: true }}
                      margin="normal"
                      fullWidth
                      id={"#descriptions[" + index + "].meta_keyword"}
                      type="text"
                      label="Meta keyword (Keywords for SEO - Write Everything related to the Category separated by commas. Max of 2000 Characters)"
                      rows={3}
                      multiline
                      {...register(`descriptions[${index}].meta_keyword`)}
                    />
                  </div>
                ))}
              </div>
            </Box>
          </TabPanel>

          <TabPanel
            value="4"
            sx={{
              width: "100%",
              typography: "body1",
              maxWidth: "xl",
              minWidth: 3 / 4,
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "100%", typography: "body1" }}>
              <AsyncPaginate
                isMulti
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                placeholder="Select Attributes Groups/Filter Groups"
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                additional={defaultAdditional}
                key={"attribute_group_ids"}
                id="attribute"
                name="attribute"
                getOptionValue={(option: any) => option?.id}
                getOptionLabel={(option: any) => option?.name}
                loadOptions={loadPageOptionsAttributeGroups}
                value={valueAttributeGroups}
                onChange={onChangeAttributeGroups}
              />

              {valueAttributeGroups.map((input, index) => {
                return (
                  <p>
                    {" "}
                    Attribute Group ID: {input.id} / {input.name}
                  </p>
                );
              })}

              <div style={{ marginTop: "50px" }}></div>

              <AsyncPaginate
                isMulti
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                placeholder="Select Attributes/Filters"
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                additional={defaultAdditional}
                key={"attribute_ids"}
                id="attribute"
                name="attribute"
                getOptionValue={(option: any) => option?.id}
                getOptionLabel={(option: any) => option?.name}
                loadOptions={loadPageOptionsAttributes}
                value={valueAttributes}
                onChange={onChangeAttributes}
              />

              {valueAttributes.map((input, index) => {
                return (
                  <p>
                    {" "}
                    Attribute ID: {input.id} / {input.name}
                  </p>
                );
              })}
            </Box>
          </TabPanel>

          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.descriptions?.toString()}
          </p>
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.attribute_ids?.toString()}
          </p>

          <Stack
            direction="row"
            spacing={2}
            sx={{
              mt: 2,
            }}
          >
            <Button
              onClick={() => reset()}
              variant="outlined"
              startIcon={<DeleteIcon />}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              size="large"
              endIcon={<SendIcon />}
              disabled={circularLoading}
              type="submit"
            >
              Send
              {circularLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>
          </Stack>
          <div style={{ marginTop: "100px" }}></div>
        </Box>
      </TabContext>
    </Container>
  );
}
export default NewCategories;
