/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  ListItem,
  ListItemText,
  List,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import api from "../../../../../services/api";
import Swal from "sweetalert2";
import { useLocation, useParams } from "react-router-dom";
import { LogTrackUser } from "../../../../../types/log-track-user/log-track-user";
import Cookies from "js-cookie";
export default function EditLanguage() {
  const { id } = useParams<{ id: string }>();

  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////

  const [circularLoading, setCircularLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (language: any) => {
    setCircularLoading(true);
    //{language.status === 1 ? status_text = 'Enabled' : language.status = 'Disabled'}
    //language.image = "";
    //user.role_id = Number(user.role_id);
    api
      .put(`languages/${id}`, language)
      .then(() => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Language updated!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        //reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "" + error.response.data.message,
          footer: '<a href="">Why do I have this issue?</a>',
        });
        setCircularLoading(false);
        setRequestError(true);
        setRequestErrorData(error.response.data.message);
      });
  };

  const [status, setStatus] = React.useState("");
  const handleChangeStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  const fetchLanguage = async () => {
    const response = await api.get(`languages/${id}`);
    reset(response.data);
    setStatus(response.data.status);
  };

  useEffect(() => {
    fetchLanguage();
  }, []);
  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          color: "#556cd6",
        }}
      >
        <Typography component="h1" variant="h5">
          Edit Language {id}
        </Typography>
        {requestError && (
          <List component="nav" aria-label="erros">
            {requestErrorData.map((error) => (
              <ListItem key={error}>
                <ListItemText
                  sx={{
                    color: "red",
                  }}
                  primary={error}
                />
              </ListItem>
            ))}
          </List>
        )}
        <Box
          component="form"
          sx={{ mt: 1, minWidth: 3 / 4 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormControl fullWidth>
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-select-label"
              id="status"
              value={status}
              {...register("status", {
                required: "Status is required",
              })}
              label="Status"
              onChange={handleChangeStatus}
            >
              <MenuItem value={1}>Enabled</MenuItem>
              <MenuItem value={0}>Disabled</MenuItem>
            </Select>
          </FormControl>
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.status?.toString()}
          </p>

          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            type="text"
            label="Language Name (In the language of the country)"
            InputLabelProps={{ shrink: true }}
            {...register("name", {})}
            autoFocus
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.name?.toString()}
          </p>

          <TextField
            margin="normal"
            required
            fullWidth
            id="code"
            type="text"
            label="Language Code"
            InputLabelProps={{ shrink: true }}
            {...register("code", {})}
            autoFocus
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.code?.toString()}
          </p>

          <TextField
            margin="normal"
            required
            fullWidth
            id="locale"
            type="text"
            label="Locale Parameters (; separated)"
            InputLabelProps={{ shrink: true }}
            {...register("locale", {})}
            autoFocus
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.locale?.toString()}
          </p>

          <TextField
            margin="normal"
            required
            fullWidth
            id="image"
            type="text"
            label="Image Address"
            InputLabelProps={{ shrink: true }}
            {...register("image")}
            autoFocus
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.image?.toString()}
          </p>

          <Stack
            direction="row"
            spacing={2}
            sx={{
              mt: 2,
            }}
          >
            <Button
              size="large"
              sx={{ marginTop: "10px", marginBottom: "10px" }}
              href="/languages"
            >
              Back
            </Button>
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              size="large"
              endIcon={<SendIcon />}
              disabled={circularLoading}
              type="submit"
            >
              Send
              {circularLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>
          </Stack>
          <div style={{ marginTop: "100px" }}></div>
        </Box>
      </Box>
    </Container>
  );
}
