import { StoreEnum } from "../enums/store.enum";

const getStoreName = (store_id: StoreEnum) => {
  switch (store_id) {
    case StoreEnum.SOS_MASCARAS:
      return "SOS MASCARAS";
    case StoreEnum.TAINARA_VICTORY:
      return "TAINARA VICTORY";
    case StoreEnum.SHOP_FROM_BRAZIL:
      return "SHOP FROM BRAZIL";
    default:
      return null;
  }
};

export default getStoreName;
