/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Stack,
  List,
  ListItemText,
  ListItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import api from "../../../../services/api";
import Swal from "sweetalert2";
import { useLocation, useParams } from "react-router-dom";
import { LogTrackUser } from "../../../../types/log-track-user/log-track-user";
import { inputsName } from "../../../../config/languageConst";
import moment from "moment";
import Cookies from "js-cookie";

export default function EditContact() {
  const [circularLoading, setCircularLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);
  const { id } = useParams<{ id: string }>();

  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [store, setStore] = useState<{ id: number; name: string } | null>(null);
  const [customer, setCustomer] = useState<{
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    language_id: number;
  } | null>(null);
  const [language, setLanguage] = useState<{
    id: number;
    code: string;
    name: string;
  } | null>(null);
  const [question, setQuestion] = useState("");
  const [isAnswered, setIsAnswered] = useState(false);
  const [user, setUser] = useState("");
  const [userDateAnswer, setUserDateAnswer] = useState<Date | null>(null);
  const [createdAt, setCreatedAt] = useState<Date | null>(null);

  const onSubmit = (contact: any) => {
    setCircularLoading(true);

    if (contact.answer) {
      contact.is_answered = true;
      contact.username_answered = Cookies.get("username");
      contact.user_id_answered = Number(Cookies.get("user_id"));
    }

    api
      .put(`contacts/${id}`, contact)
      .then((response) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Contacts updated!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        //reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "" + error.response.data.message,
          // footer: '<a href="">Why do I have this issue?</a>',
        });
        setCircularLoading(false);
        setRequestError(true);
        setRequestErrorData(error.response.data.message);
      });
  };

  const fetchContact = async () => {
    const response = await api.get(`contacts/${id}`);
    setStore(response.data.store);
    setCustomer(response.data.customer);
    setLanguage(response.data.language);
    setQuestion(response.data.question);
    setIsAnswered(response.data.is_answered);
    setUser(response.data.username_answered);
    setUserDateAnswer(response.data.date_answered);
    setCreatedAt(response.data.created_at);

    reset(response.data);
  };

  useEffect(() => {
    fetchContact();
  }, []);

  return (
    <Container component="main" maxWidth="xl">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{ fontSize: "40px", marginBottom: "10px" }}
        >
          Edit Contact {id}
        </Typography>
        {requestError && (
          <List component="nav" aria-label="BackEnd Return">
            {requestErrorData.map((error) => (
              <ListItem key={error}>
                <ListItemText
                  sx={{
                    color: "red",
                  }}
                  primary={error}
                />
              </ListItem>
            ))}
          </List>
        )}
        <Box
          component="form"
          sx={{ mt: 1, minWidth: 3 / 4, width: "100%" }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <p>
            Store: <b>{store?.id}</b> / <b>{store?.name}</b>
          </p>
          <p>
            Customer: <b>{customer?.id}</b> / <b>{customer?.email}</b> /{" "}
            <b>
              {customer?.first_name} {customer?.last_name}
            </b>{" "}
            / Prefered Language:{" "}
            <b>
              {customer?.language_id
                ? inputsName[customer?.language_id].language_name
                : ""}
            </b>
          </p>
          <p>
            Create At: <b>{moment(createdAt).format("DD/MM/YYYY hh:mm:ss")}</b>
          </p>

          <TextField
            InputLabelProps={{ shrink: true }}
            margin="normal"
            required
            sx={{ minWidth: "25%", width: "100%" }}
            id="name"
            type="text"
            label="Customer Name (Contact Page Field)"
            {...register("name")}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.name?.toString()}
          </p>

          <TextField
            InputLabelProps={{ shrink: true }}
            margin="normal"
            required
            sx={{ minWidth: "25%", width: "100%" }}
            id="name"
            type="text"
            label="Customer E-mail (Contact Page Field)"
            {...register("email")}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.email?.toString()}
          </p>
          <p>
            Language (at the Contact Page):{" "}
            <b>
              {language?.code} / {language?.name}
            </b>
          </p>
          <TextField
            InputLabelProps={{ shrink: true }}
            margin="normal"
            rows={10}
            multiline
            sx={{ minWidth: "25%", width: "100%" }}
            id="question"
            type="text"
            label="Question (Read Only Field)"
            value={question}
            {...register("question")}
          />
          <FormControlLabel
            label="Is Answered? (Read Only Field)"
            control={
              <Checkbox
                checked={isAnswered}
                inputProps={{ "aria-label": "Is Answered? (Read Only Field)" }}
              />
            }
          />
          <p>
            User that Answered: <b>{user}</b> at{" "}
            {userDateAnswer
              ? moment(userDateAnswer).format("DD/MM/YYYY hh:mm:ss")
              : ""}
          </p>
          <TextField
            InputLabelProps={{ shrink: true }}
            margin="normal"
            rows={10}
            multiline
            sx={{ minWidth: "25%", width: "100%" }}
            id="answer"
            type="text"
            label="Answer"
            {...register("answer")}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            margin="normal"
            rows={10}
            multiline
            sx={{ minWidth: "25%", width: "100%" }}
            id="comments"
            type="text"
            label="Comments (Internal Section)"
            {...register("comments")}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              sx={{
                mt: 2,
              }}
            >
              <Button
                onClick={() => reset()}
                variant="outlined"
                startIcon={<DeleteIcon />}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                size="large"
                endIcon={<SendIcon />}
                disabled={circularLoading}
                type="submit"
              >
                Send
                {circularLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
