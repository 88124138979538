/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Container, Pagination, Box, SelectChangeEvent, InputLabel, Select, MenuItem,TextField, Button} from "@mui/material";
import TableHorizontal from "../TableHorizontal";
import api from "../../services/api";
function ListParentChilds() {
  const [pageState, setPageState] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [productQtyWarehouses, setProductQtyWarehouses] = useState([]);
  const columns = ["Product", "Image", "Warehouse", "Quantity", "Location", "Pre Order" , "Discontinued" , "Modified By" ,"When" ,"Action", "Delete"];
  const keys = ["product", "image", "warehouse", "quantity", "location", "pre_order_sale", "discontinued","last_change_username","when", "action", "delete"];
  const fetchData = async (page = 1,
    numberItemsPerPage = 500
  ) => {
    await api.get(`/product-qty-warehouse?page=${page}&limit=${numberItemsPerPage}&product_id=${productIDFilter}&product_sku=${productSKUFilter}&warehouse_id=${warehouseIDFilter}&
    location=${locationFilter}&last_change_user_id=${userIdFilter}&order_by${orderBy}&order_ascending${orderAscending}`).then((res) => {
      setProductQtyWarehouses(res.data.items);
      setTotalPages(res.data.total_pages);
    });
  };
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageState(value);
    fetchData(value);
  };

  const [numberItemsPerPage, setNumberItemsPerPage] = useState("500");
  const handleNumberItemsPerPage = (event: SelectChangeEvent) => {
    setNumberItemsPerPage(event.target.value);
    fetchData(1, Number(event.target.value));
  };

  const [productSKUFilter, setProductSKUFilter] = useState("");

  const [productIDFilter, setProductIDFilter] = useState("");
/*   const handleChangeProductIDFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProductIDFilter(e.target.value);
  }; */

  const [warehouseIDFilter, setWarehouseIDFilter] = useState("");
  /* const handleChangeWarehouseIDFilter = (e: SelectChangeEvent) => {
    setWarehouseIDFilter(e.target.value);
  }; */

  const [warehouses, setWarehouses] = useState<{ id: number; name: string }[]>([]);
  const fetchWarehouses = async () => {
    const response = await api.get(`warehouses`);
    setWarehouses(response.data.items);
  };

  const [locationFilter, setLocationFilter] = useState("");
/*   const handleChangeLocationFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocationFilter(e.target.value);
  }; */

  const [userIdFilter, setUserIdFilter] = useState("");
  const handleChangeUserFilter = (event: SelectChangeEvent) => {
    setUserIdFilter(event.target.value);
  };

  const [users, setUsers] = useState<{ id: number; username: string }[]>([]);
  const fetchUsers = async () => {
    const response = await api.get(`users`);
    setUsers(response.data.items);
  };

  const [orderBy, setOrderBy] = useState("");
  const handleChangetOrderBy = (e: SelectChangeEvent) => {
    setOrderBy(e.target.value);
  };

  const [orderAscending, setOrderAscending] = useState("");
  const handleChangetOrderAscending = (e: SelectChangeEvent) => {
    setOrderAscending(e.target.value);
  };

  const handleFilterButtonClick = () => {
    fetchData(1);
  };

  
  useEffect(() => {
    fetchData();
    fetchUsers();
    fetchWarehouses();
  }, []);
  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box  sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
          }}
          >
        <InputLabel id="number-items-page-label">Number of Items per Page</InputLabel>
        <Select
          labelId="number-items-page-label"
          id="number-items-page"
          value={numberItemsPerPage}
          label="Number of Items per Page"
          onChange={handleNumberItemsPerPage}
        >
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={250}>250</MenuItem>
          <MenuItem value={500}>500</MenuItem>
          <MenuItem value={750}>750</MenuItem>
          <MenuItem value={1000}>1000</MenuItem>
        </Select>
      </Box>
      <Box  sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
          }}
          >
        <InputLabel id="order-by-label">Order By</InputLabel>
        <Select
          sx={{minWidth: '150px'}}
          labelId="order_by-select-label"
          id="order_by"
          value={orderBy}
          label="Order By"
          onChange={handleChangetOrderBy}
        >
          <MenuItem value="">None</MenuItem>
          <MenuItem value="id">ID</MenuItem>
          <MenuItem value="product_id">Product ID</MenuItem>
          <MenuItem value="product.sku">Product SKU</MenuItem>
          <MenuItem value="warehouse_id">Warehouse ID</MenuItem>
          <MenuItem value="warehouse.name">Warehouse Name</MenuItem>
          <MenuItem value="quantity">Quantity</MenuItem>
          <MenuItem value="location">Location</MenuItem>
          <MenuItem value="pre_order_sale_enabled">Pre Order Sale Enabled</MenuItem>
          <MenuItem value="is_discontinued">Is Discontinued</MenuItem>
          <MenuItem value="last_change_user_id">Last Change User ID</MenuItem>
          <MenuItem value="user.username">Last Change UserName</MenuItem>
          <MenuItem value="updated_at">Updated At</MenuItem>
        </Select>
      </Box>
      </Box>

      <InputLabel id="filters-label" sx={{marginTop: 3}}> Filters </InputLabel>

      <Box style={{ display: "flex", flexDirection: "row" }} >
        <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: "8px",
            }}
          >
            <InputLabel id="product-id-filter-label">Product SKU Filter</InputLabel>
            <TextField
              //sx={{ mt: 1, minWidth: 3/4 }}
              //sx={{ marginRight: 1, marginLeft: 1 }}
              //fullWidth
              id={"Product-SKU-filter"}
              type="text"
              label="Product SKU Filter"
              //value={productSKUFilter}
              defaultValue={productSKUFilter}
              //onChange={handleChangeProductIDFilter}
              onBlur={(e) => setProductSKUFilter(e.target.value)}
            />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
          }}
        >
          <InputLabel id="product-id-filter-label">Product ID Filter</InputLabel>
          <TextField
            //sx={{ mt: 1, minWidth: 3/4 }}
            //sx={{ marginRight: 1, marginLeft: 1 }}
            //fullWidth
            id={"Product-ID-filter"}
            type="text"
            label="Product ID Filter"
            //value={productIDFilter}
            defaultValue={productIDFilter}
            //onChange={handleChangeProductIDFilter}
            onBlur={(e) => setProductIDFilter(e.target.value)}
          />
      </Box>
      
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginRight: "8px",
        }}
      >
        <InputLabel id="warehouse-select-label">Warehouse</InputLabel>
        <Select
        sx={{minWidth: '150px'}}
          labelId="warehouse-select-label"
          id="warehouse"
          value={warehouseIDFilter}
          label="Warehouse"
          onChange={(e) => setWarehouseIDFilter(e.target.value)}
        >
          {warehouses.map((warehouse, index) => (
            <MenuItem key={index} value={warehouse.id}>{warehouse.name}</MenuItem>
          ))}
        </Select>
      </Box>
      
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginRight: "8px",
        }}
      >
        <InputLabel id="location-filter-label">Location Filter</InputLabel>
        <TextField
          //sx={{ minWidth: 1 / 8, maxWidth: 1/6, marginRight: 1 }}
          sx={{minWidth: '150px'}}
          id={"location-filter"}
          type="text"
          label="Location Filter"
          //value={locationFilter}
          defaultValue={locationFilter}
          //onChange={handleChangeLocationFilter}
          onBlur={(e) => setLocationFilter(e.target.value)}
        />
      </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
          }}
        >
          <InputLabel id="user-select-label">User</InputLabel>
          <Select
            sx={{minWidth: '150px'}}
            labelId="user-select-label"
            id="user"
            value={userIdFilter}
            label="User"
            onChange={handleChangeUserFilter}
          >
            <MenuItem value="">None</MenuItem>
            {users.map((user, index) => (
              <MenuItem key={index} value={user.id}>{user.username}</MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
          }}
        >
          <InputLabel id="orderAscending-select-label">Order Asceding/Descending</InputLabel>
          <Select
            sx={{minWidth: '150px'}}
            labelId="orderAscending-select-label"
            id="orderAscending"
            value={orderAscending}
            label="Order Ascending/Descending"
            onChange={handleChangetOrderAscending}
          >
            <MenuItem value={true as any}>Ascending</MenuItem>
            <MenuItem value={false as any}>Desceding</MenuItem>
          </Select>
        </Box>
      </Box>
     
        

      <Button variant="contained" href="" onClick={handleFilterButtonClick} sx={{marginTop: 2, marginLeft: 1}}>
        Filter
      </Button>
      <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
      <TableHorizontal
        screen={"product-qty-warehouse"}
        columns={columns}
        data={productQtyWarehouses}
        objectKey={keys}
      />
      </Container>
      <Box
        display="flex"
        width={"100%"}
        height={80}
        alignItems="center"
        justifyContent="center"
      >
        <Pagination
          count={totalPages}
          page={pageState}
          onChange={handleChange}
        />
    </Box>
    </Container>
  );
}
export default ListParentChilds;