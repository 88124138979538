import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Stack,
  ListItem,
  ListItemText,
  List,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import api from "../../services/api";
import Swal from "sweetalert2";
import { AsyncPaginate } from "react-select-async-paginate";

function NewSellers() {
  const [circularLoading, setCircularLoading] = useState(false);

  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);
  const [user, setUser] = useState<{
    id: number;
  } | null>(null);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (seller: any) => {
    setCircularLoading(true);
    if (user) {
      seller.user_id = user ? user.id : null;
    }
    seller.image = "notimage";
    seller.status = 1;
    seller.image = "";
    seller.role_id = Number(seller.role_id);
    api
      .post("sellers", seller)
      .then((response) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Seller created!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: '<a href="">Why do I have this issue?</a>',
        });
        setCircularLoading(false);
        setRequestError(false); //trocado de true para false
        setRequestErrorData(error.response.data.message);
      });
  };

  const defaultAdditional = {
    page: 1,
  };

  const loadOptions = async (search: any, page: any) => {
    const results = await api.get(`/users?page=${page}`);

    const hasMore = results.data.total_pages === results.data.current_page;

    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptions = async (q: any, _prevOptions: any, { page }: any) => {
    const { options, hasMore } = await loadOptions(q, page);

    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  };

  // const [age, setAge] = React.useState("");
  // const handleChange = (event: SelectChangeEvent) => {
  //   setAge(event.target.value as string);
  // };
  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{
            fontSize: "32px",
            marginBottom: "10px",
            alignItems: "center",
            color: "#556cd6",
          }}
        >
          New Seller
        </Typography>
        {requestError && (
          <List component="nav" aria-label="secondary mailbox folder">
            {requestErrorData.map((error) => (
              <ListItem key={error}>
                <ListItemText
                  sx={{
                    color: "red",
                  }}
                  primary={error}
                />
              </ListItem>
            ))}
          </List>
        )}
        <AsyncPaginate
          placeholder="Users"
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 9999,
            }),
          }}
          additional={defaultAdditional}
          value={user}
          getOptionValue={(option: any) => option?.id}
          getOptionLabel={(option: any) => option?.name}
          loadOptions={loadPageOptions}
          onChange={setUser}
        />
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            minWidth: 3 / 4,
            marginBottom: "30px",
            width: "100%",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            sx={{ width: 4 / 4 }}
            margin="normal"
            required
            fullWidth
            id="username"
            type="string"
            label="Name"
            {...register("name", {
              required: "Name is required",
            })}
            autoFocus
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.username?.toString()}
          </p>
          <TextField
            margin="normal"
            required
            fullWidth
            id="phone_number"
            type="number"
            label="Phone Number"
            {...register("phone_number", {
              required: "phone_number is required",
            })}
            autoFocus
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.email?.toString()}
          </p>

          <Stack
            direction="row"
            spacing={2}
            sx={{
              mt: 2,
            }}
          >
            <Button
              onClick={() => reset()}
              variant="outlined"
              startIcon={<DeleteIcon />}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              size="large"
              endIcon={<SendIcon />}
              disabled={circularLoading}
              type="submit"
            >
              Send
              {circularLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
}
export default NewSellers;
