import styled from "styled-components";
export const Container = styled.ul`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  li {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: #444;
    & + li {
      margin-top: 15px;
    }
  }
`;

export const SubContainer = styled.ul`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  li {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: #444;
    & + li {
      margin-top: 15px;
    }
  }
`;

export const FileInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 350px;
  padding-top: 10px;
  span {
    font-size: 12px;
    color: #999;
    button {
      border: 0;
      background: transparent;
      color: #e57878;
      margin-left: 5px;
      cursor: pointer;
    }
  }
`;

export const PreviewBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    span {
      font-size: 12px;
      color: #999;
      button {
        border: 0;
        background: transparent;
        color: #e57878;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
`;

export const Preview = styled.div`
  width: 250px;
  height: auto;
  max-height: 250px;
  margin-top: 20px;
  padding-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  // background-repeat: no-repeat center center;
  background-size: cover;
  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
`;

export const PreviewName = styled.div`
  display: flex;
  flex-direction: row;
`;
