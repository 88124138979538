export const inputsName = [
  {
    language_id: 1,
    language_name: "english",
    name: "",
    image: "en-gb",
    code: "en",
  },
  {
    language_id: 2,
    language_name: "portuguese",
    name: "",
    image: "pt-br",
    code: "pt",
  },
  {
    language_id: 3,
    language_name: "spanish",
    name: "",
    image: "es-ES",
    code: "es",
  },
  {
    language_id: 4,
    language_name: "japanese",
    name: "",
    image: "japan",
    code: "jp",
  },
];
