import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  background-color: #f5f5f5;
  /* padding: 20px;
  margin-top: 20px;
  border-radius: 10px; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;
export const DropContainer = styled.div.attrs({
  className: "dropzone",
})`
  border: 1px dashed #ddd;
  border-radius: 4px;
  width: 100%;
  /* background-color: red; */
  padding: 30px;
  cursor: pointer;
  transition: height 0.2s ease;
  //typescript
  ${(props: any) => props.isDragAccept && "border-color: #78e5d5;"}
  ${(props: any) => props.isDragReject && "border-color: #e57878;"}
`;
