/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Container,
  Pagination,
  Box,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  TextField,
  Button,
} from "@mui/material";
import TableHorizontal from "../../../components/TableHorizontal";
import api from "../../../services/api";
function ListCountries() {
  const [pageState, setPageState] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [users, setUsers] = useState([]);
  const columns = ["ID", "Name", "Action", "Delete"];
  const keys = ["id", "name", "action", "delete"];
  const fetchData = async (
    page = 1,
    numberItemsPerPage = 500,
    language = 1,
    search: string = '',
  ) => {
    await api
      .get(
        `/product-conditions?page=${page}&limit=${numberItemsPerPage}&search=${search}=language_id=${language}`
      )
      .then((res) => {
        setUsers(res.data.items);
        setTotalPages(res.data.total_pages);
      });
  };
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageState(value);
    fetchData(value, numberItemsPerPage,language, search);
  };

  const [numberItemsPerPage, setNumberItemsPerPage] = useState(500);
  const handleNumberItemsPerPage = (event: SelectChangeEvent) => {
    setNumberItemsPerPage(Number(event.target.value));
    fetchData(pageState, Number(event.target.value),language, search);
  };

  const [language, setLanguage] = useState(0);
  const handleChangeLanguage = (event: SelectChangeEvent) => {
    setLanguage(Number(event.target.value));
    fetchData(pageState, numberItemsPerPage,Number(event.target.value), search);
  };

  const [languages, setLanguages] = useState<{ id: number; name: string }[]>(
    []
  );
  const fetchLanguages = async () => {
    const response = await api.get(`languages`);
    setLanguages(response.data.items);
    setLanguage(1);
  };

  const [search, setSearch] = useState("");
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSearchClick = () => {
    fetchData(1, numberItemsPerPage,language, search);
  };

  useEffect(() => {
    fetchData();
    fetchLanguages();
  }, []);
  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
      <Box style={{ display: "flex", flexDirection:"row" }}>
      <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
        <InputLabel id="number-items-page-label">
          Number of Items per Page
        </InputLabel>
        <Select
          labelId="number-items-page-select-label"
          id="number-items-page"
          value={String(numberItemsPerPage)}
          label="Number of Items per Page"
          onChange={handleNumberItemsPerPage}
        >
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={250}>250</MenuItem>
          <MenuItem value={500}>500</MenuItem>
          <MenuItem value={750}>750</MenuItem>
          <MenuItem value={1000}>1000</MenuItem>
        </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
        <InputLabel id="language-label">Language</InputLabel>
        <Select
          labelId="language-select-label"
          id="language"
          value={String(language)}
          label="Language"
          onChange={handleChangeLanguage}
        >
          <MenuItem value={0}>None</MenuItem>
          {languages.map((language, index) => (
            <MenuItem value={language.id} key={index}>
              {language.name}
            </MenuItem>
          ))}
        </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <InputLabel id="search-filter-label">
            Product Condition Name filter
          </InputLabel>
          <TextField
            //sx={{ mt: 1, minWidth: 3/4 }}
            sx={{ minWidth: 1 / 4 }}
            //fullWidth
            id={"Search"}
            type="text"
            label="Type here..."
            value={search}
            onChange={handleChangeSearch}
          />
        </Box>
        <Button
          variant="contained"
          sx={{ m: 3 }}
          href=""
          onClick={handleSearchClick}
        >
          Filter
        </Button>
      </Box>
      <TableHorizontal
        screen={"product-conditions"}
        columns={columns}
        data={users}
        objectKey={keys}
      />
      <Box
        display="flex"
        width={"100%"}
        height={80}
        alignItems="center"
        justifyContent="center"
      >
        <Pagination
          count={totalPages}
          page={pageState}
          onChange={handleChange}
        />
      </Box>
    </Container>
  );
}
export default ListCountries;
