/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Container,
  Pagination,
  Box,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Button,
  TextField,
} from "@mui/material";
import TableHorizontal from "../TableHorizontal";
import api from "../../services/api";
import { AsyncPaginate } from "react-select-async-paginate";
export default function ListPages() {
  const [pageState, setPageState] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pages, setPages] = useState([]);
  const columns = [
    "ID",
    "Store",
    "Title",
    "URL",
    "Username",
    "Action",
    "Delete",
  ];
  const keys = [
    "id",
    "store_name",
    "descriptions_title",
    "seo_url",
    "username",
    "action",
    "delete",
  ];

  const fetchData = async (
    page = 1,
    languageFilter = 1,
    numberItemsPerPage = 500
  ) => {
    await api
      .get(
        `/page?page=${page}&limit=${numberItemsPerPage}&language_id=${languageFilter}` +
          `&title=${titleFilter}&search=${searchFilter}&username=${usernameFilter}` +
          `${storeFilter ? "&store_id=" + storeFilter.id.toString() : ""}`
      )
      .then((res) => {
        setPages(res.data.items);
        setTotalPages(res.data.total_pages);
      });
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageState(value);
    fetchData(value, languageFilter, Number(numberItemsPerPage));
  };
  const [numberItemsPerPage, setNumberItemsPerPage] = useState("500");
  const handleNumberItemsPerPage = (event: SelectChangeEvent) => {
    setNumberItemsPerPage(event.target.value);
    fetchData(1, languageFilter, Number(event.target.value));
  };

  const defaultAdditional = {
    page: 1,
  };

  const loadOptionsStores = async (search: any, page: any) => {
    const results = await api.get(`/stores?page=${page}&search=${search}`);
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsStores = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsStores(q, page);
    if (options.length > 0) {
      options.unshift({ id: 0, name: "None" });
    }
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const [storeFilter, setStoreFilter] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const [languageFilter, setLanguageFilter] = useState(0);

  const [languages, setLanguages] = useState<{ id: number; name: string }[]>(
    []
  );
  const fetchLanguages = async () => {
    const response = await api.get(`languages`);
    setLanguages(response.data.items);
    setLanguageFilter(1);
  };

  const [titleFilter, setTitleFilter] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [usernameFilter, setUsernameFilter] = useState("");

  const handleFilterButtonClick = () => {
    fetchData();
  };

  useEffect(() => {
    fetchLanguages();
    fetchData();
  }, []);
  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            mt: "8px",
            minWidth: 1 / 6,
            width: 1 / 6,
            marginRight: 1,
            marginLeft: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <InputLabel id="number-items-page-label">
            Number of Items per Page
          </InputLabel>
          <Select
            labelId="number-items-page-select-label"
            id="number-items-page"
            value={numberItemsPerPage}
            label="Number of Items per Page"
            onChange={handleNumberItemsPerPage}
            sx={{ maxHeight: "2.4em" }}
          >
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={250}>250</MenuItem>
            <MenuItem value={500}>500</MenuItem>
            <MenuItem value={750}>750</MenuItem>
            <MenuItem value={1000}>1000</MenuItem>
          </Select>
        </Box>
        <Box
          sx={{
            mt: "8px",
            minWidth: 1 / 4,
            marginRight: 1,
            flexDirection: "column",
          }}
        >
          <InputLabel id="store-filter-label">Store</InputLabel>
          <AsyncPaginate
            placeholder="Select Store"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"store-filter"}
            id="store-filter"
            name="store-filter"
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.name}
            loadOptions={loadPageOptionsStores}
            value={storeFilter}
            onChange={setStoreFilter}
          />
        </Box>

        <Box
          sx={{
            mt: "8px",
            minWidth: 1 / 6,
            width: 1 / 6,
            marginRight: 1,
            flexDirection: "column",
          }}
        >
          <InputLabel id="language-label">Language</InputLabel>
          <Select
            labelId="language-select-label"
            id="language"
            value={String(languageFilter)}
            label="Language"
            onChange={(e) => setLanguageFilter(Number(e.target.value))}
            sx={{ maxHeight: "2.4em" }}
          >
            <MenuItem value={0}>None</MenuItem>
            {languages.map((language, index) => (
              <MenuItem value={language.id} key={index}>
                {language.name}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box
          sx={{
            mt: "8px",
            minWidth: 1,
            marginRight: 1,
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <Button
            variant="contained"
            sx={{ m: 3 }}
            href=""
            onClick={handleFilterButtonClick}
          >
            Filter
          </Button>
        </Box>
      </Box>
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <TextField
          sx={{
            mt: "8px",
            minWidth: 1 / 4,
            width: 1 / 4,
            marginRight: 1,
            marginLeft: 1,
            display: "flex",
          }}
          margin="normal"
          id={"title-filter"}
          type="text"
          label="Title Filter"
          onBlur={(e) => setTitleFilter(e.target.value)}
        />
        <TextField
          sx={{
            mt: "8px",
            minWidth: 1 / 4,
            width: 1 / 4,
            marginRight: 1,
            marginLeft: 1,
            display: "flex",
          }}
          margin="normal"
          id={"search-filter"}
          type="text"
          label="Search"
          onBlur={(e) => setSearchFilter(e.target.value)}
        />
        <TextField
          sx={{
            mt: "8px",
            minWidth: 1 / 4,
            width: 1 / 4,
            marginRight: 1,
            marginLeft: 1,
            display: "flex",
          }}
          margin="normal"
          id={"username-filter"}
          type="text"
          label="Username"
          onBlur={(e) => setUsernameFilter(e.target.value)}
        />
      </Box>
      <TableHorizontal
        screen={"page"}
        columns={columns}
        data={pages}
        objectKey={keys}
      />
      <Box
        display="flex"
        width={"100%"}
        height={80}
        alignItems="center"
        justifyContent="center"
      >
        <Pagination
          count={totalPages}
          page={pageState}
          onChange={handleChange}
        />
      </Box>
    </Container>
  );
}
