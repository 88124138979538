import {
  createContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  FC,
} from "react";
import api from "../../services/api";
import { AxiosResponseStores } from "../../types/stores";

type PropsStoreContext = {
  state: AxiosResponseStores;
  setState: Dispatch<SetStateAction<AxiosResponseStores>>;
};

const INITIAL_STATE = {
  state: { items: [] },
  setState: () => {},
};

const StoreContext = createContext<PropsStoreContext>(INITIAL_STATE);

// not working yet
type Props = {
  children: any;
};

const StoreContextProvider: FC<Props> = ({ children }) => {
  const [state, setState] = useState<AxiosResponseStores>({ items: [] });
  useEffect(() => {
    api.get("/stores").then((res) => {
      const storeInfo = res.data;
      setState(storeInfo);
    });
  }, []);
  return (
    <StoreContext.Provider value={{ state, setState }}>
      {children}
    </StoreContext.Provider>
  );
};
export { StoreContextProvider };

export default StoreContext;
