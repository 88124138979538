/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import api from "../../services/api";
import { green } from "@mui/material/colors";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Modal,
  Stack,
  Snackbar,
  Alert,
  AlertProps,
  CardMedia,
} from "@mui/material";
import { useForm } from "react-hook-form";

import { AsyncPaginate } from "react-select-async-paginate";
import { SingleValue } from "react-select";
import Cookies from "js-cookie";

export default function NewProductWarehouse() {
  const [circularLoading, setCircularLoading] = React.useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  /*   //User Tracking Logging Section
  const user_log = Cookies.get("username");
  const location = useLocation();
  const route_log = location.pathname;
  const [logs, setLogs] = useState({});
  function Log(logs: any) {
    api.post("history-logs", logs);
  }
  const logHistoryUser = async () => {
    await setLogs({ user_log, route_log });
  };
  Log(logs);
  /////////////////// */

  //css do modal --------------------------------------------
  const [open, setOpen] = useState(false);
  const [openProductsHelp, setOpenProductsHelp] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenProductsHelp = () => setOpenProductsHelp(true);
  const handleCloseProductsHelp = () => setOpenProductsHelp(false);
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  ////////////////////////////////////////////////////////////////

  const defaultAdditional = {
    page: 1,
  };

  const [warehouses, setWarehouses] = React.useState<
    { id: number; name: string }[]
  >([]);
  const fetchWarehouses = async () => {
    const response = await api.get("warehouses");
    setWarehouses(response.data.items);
  };

  const [warehouse, setWarehouse] = useState("");
  const handleChangeWarehouse = (event: SelectChangeEvent) => {
    setWarehouse(event.target.value);
  };

  const [product, setProduct] = useState<{ id: number; sku: string } | null>(
    null
  );
  const setProductAsyncPaginate = async (
    newvalue: SingleValue<{ id: number; sku: string }>
  ) => {
    setProduct(newvalue);
    fetchQuantityUnit(Number(newvalue?.id));
    fetchProductImage(Number(newvalue?.id));
  };
  /*   (property) onChange?: ((newValue: SingleValue<{
    id: number;
    sku: string;
}>, actionMeta: ActionMeta<{
    id: number;
    sku: string;
}>) => void) | undefined */

  const loadOptionsItemProducts = async (search: any, page: any) => {
    const results = await api.get(
      `/products-custom/notparent-notcombination-products?page=${page}&search=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsItemProducts = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsItemProducts(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const [quantityUnit, setQuantityUnit] = useState("UN");
  const fetchQuantityUnit = async (product_id: number) => {
    const response = await api.get(
      `products-custom/${product_id}/productinventorysellattribute`
    );
    setQuantityUnit(response.data.inventory_unit);
  };

  const [imageBaseURL, setimageBaseURL] = useState(
    process.env.REACT_APP_IMG_CATALOG_URL
  );
  const [productImage, setProductImage] = useState("");
  const fetchProductImage = async (product_id: number) => {
    if (imageBaseURL?.substring(imageBaseURL?.length - 1) !== "/") {
      setimageBaseURL(imageBaseURL + "/");
    }
    const response = await api.get(`products-custom/${product_id}`);
    setProductImage(response.data.image);
  };

  const [preOrdeSaleEnabled, setPreOrdeSaleEnabled] = useState(false);
  const handleChangePreOrdeSaleEnabled = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPreOrdeSaleEnabled(event.target.checked);
  };
  const [isDiscontinued, setIsDiscontinued] = useState(false);
  const handleChangeIsDiscontinued = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsDiscontinued(event.target.checked);
  };

  const [stores, setStores] = React.useState<{ id: number; name: string }[]>(
    []
  );
  const fetchStores = async () => {
    const response = await api.get("stores");
    setStores(response.data.items);
  };

  const [store, setStore] = useState("");
  const handleChangeStore = (event: SelectChangeEvent) => {
    setStore(event.target.value);
  };

  const onSubmit = (productQtyWarehouse: any) => {
    setCircularLoading(true);

    productQtyWarehouse.product_id = Number(product?.id); // Tratar Caso de Null depois
    productQtyWarehouse.warehouse_id = Number(warehouse); // Tratar Caso de Null depois
    productQtyWarehouse.quantity_variation = Number(
      productQtyWarehouse.quantity_variation
    );
    let username: string = Cookies.get("username") || "";
    productQtyWarehouse.username = username.replace(/["']/g, "");

    if (productQtyWarehouse.batch_info === "") {
      productQtyWarehouse.batch_info = null;
    }
    if (productQtyWarehouse.comments === "") {
      productQtyWarehouse.comments = null;
    }
    if (productQtyWarehouse.order_id === "") {
      productQtyWarehouse.order_id = null;
    }
    productQtyWarehouse.order_id = Number(productQtyWarehouse?.order_id);

    if (productQtyWarehouse.sale_channel === "") {
      productQtyWarehouse.sale_channel = null;
    }
    if (productQtyWarehouse.expiration_date === "") {
      productQtyWarehouse.expiration_date = null;
    }
    if (productQtyWarehouse.store_id === "") {
      productQtyWarehouse.store_id = null;
    }

    productQtyWarehouse.pre_order_sale_enabled = preOrdeSaleEnabled;

    //Before Executing, it MUST CHECK whether the pair Product Warehouse already Exists, if YES, then it CANNOT CONTINUE HERE. The route will be another, and it will be redirected there
    //DO IT LATER

    api
      .post("product-qty-warehouse", productQtyWarehouse)
      .then((response) => {
        setCircularLoading(false);
        setRequestError(false);
        /* Swal.fire({
          title: "Product Quantity Warehouse created!",
          icon: "success",
          confirmButtonText: "Yes",
        }); */
        setSnackbar({
          children: "Product Quantity Warehouse created!",
          severity: "success",
        });
        reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "" + error.response.data.message,
          footer: error.message,
        });
        setCircularLoading(false);
        setRequestError(true);
        setRequestErrorData(error.response.data.message);
      });
  };

  useEffect(() => {
    fetchWarehouses();
    fetchStores();
  }, []);

  return (
    <Container component="main" maxWidth="xl">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{
            fontSize: "32px",
            marginBottom: "10px",
            alignItems: "center",
            color: "#556cd6",
          }}
        >
          New Product Quantity Warehouse
        </Typography>

        {requestError && (
          <List component="nav" aria-label="errors">
            {requestErrorData.map((error) => (
              <ListItem>
                <ListItemText
                  sx={{
                    color: "red",
                  }}
                  primary={error}
                />
              </ListItem>
            ))}
          </List>
        )}
        <Box
          component="form"
          sx={{ mt: 1, minWidth: 3 / 4, width: "100%" }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormControl fullWidth sx={{ marginBottom: "10px" }}>
            <InputLabel id="warehouse-select-label">Warehouse</InputLabel>
            <Select
              sx={{ minWidth: "25%", width: "40%" }}
              labelId="warehouse-select-label"
              id="warehouse"
              value={warehouse}
              {...register("warehouse_id")}
              label="warehouse"
              onChange={handleChangeWarehouse}
            >
              {warehouses.map((warehouse, index) => (
                <MenuItem key={index} value={warehouse.id}>
                  {warehouse.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <InputLabel id="product-select">
            Select Product (Only Product_type = 1 - Unique
            Products and product_type = 3 - Child Products)
          </InputLabel> */}
          <AsyncPaginate
            placeholder="Select Product"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"product_id"}
            id="product"
            name="product"
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.sku}
            loadOptions={loadPageOptionsItemProducts}
            value={product}
            onChange={setProductAsyncPaginate}
          />
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.product_id?.toString()}
          </p>
          <Button onClick={handleOpenProductsHelp}>products help</Button>
          <Modal
            open={openProductsHelp}
            onClose={handleCloseProductsHelp}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ color: "#556cd6" }}
              >
                <b>Products Type</b>
              </Typography>
              <Typography id="modal-modal-description">
                (Only Products with product type = 1 - Unique Products and
                product type = 3 - Child Products)
              </Typography>
            </Box>
          </Modal>
          {/* Adicionar Explicação do tipo de Produto */}
          <div>
            <CardMedia
              component="img"
              sx={{ width: 151, display: "inline" }}
              image={`${imageBaseURL}${productImage}`}
              alt={"image"}
            />
          </div>
          <br />
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <TextField
              required
              sx={{ minWidth: "25%", width: "40%", marginRight: "8px" }}
              id="quantity"
              type="number"
              label="Initial Total Quantity"
              {...register("quantity_variation")}
            />
          </Box>
          Quantity Unit: <b>{quantityUnit}</b> - *Inventory Quantity Unit is
          Defined at the Products Info
          <br></br>
          <TextField
            margin="normal"
            required
            sx={{ minWidth: "25%", width: "45%" }}
            id="location"
            type="string"
            label="Inventory Location in Warehouse"
            {...register("location")}
          />
          <br></br>
          <Button onClick={handleOpen}>Inventory Location Help</Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ color: "#556cd6" }}
              >
                <b>Inventory Location</b>
              </Typography>
              <Typography id="modal-modal-description">
                <b>
                  {" "}
                  BUILDING-FLOOR-STREET-VERTICAL SHELF-TIER/HORIZONTAL
                  SHELF-DIVISION
                </b>
                <br></br>
                <b>PREDIO-ANDAR-RUA-ESTANTE-PRATELEIRA-DIVISÃO</b>
                <br></br>
                Products are stored in one of these locations. The sections is
                separated by slashes (-)<br></br>
                For example: A-1-B-2-C-3 <br></br>
                It means:<br></br>
                Building: A<br></br>
                Floor: 1<br></br>
                Street: B<br></br>
                Shelf: 2<br></br>
                TIER: C<br></br>
                DIVISION: 3<br></br>
              </Typography>
            </Box>
          </Modal>
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.quantity?.toString()}
          </p>
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.location?.toString()}
          </p>
          <FormControlLabel
            label="Pre Order Sale Enabled"
            control={
              <Checkbox
                checked={preOrdeSaleEnabled}
                onChange={handleChangePreOrdeSaleEnabled}
                inputProps={{ "aria-label": "Pre Order Sale Enabled" }}
                //{...register("pre_order_sale_enabled")}
              />
            }
          />
          <FormControlLabel
            label="Is Discontinued"
            control={
              <Checkbox
                checked={isDiscontinued}
                onChange={handleChangeIsDiscontinued}
                inputProps={{ "aria-label": "Is Discontinued?" }}
                //{...register("is_discontinued")}
              />
            }
          />
          <br></br>
          <TextField
            sx={{ minWidth: "25%", width: "45%", marginRight: "8px" }}
            id="batch_info"
            type="string"
            label="Batch Info (If Applicable)"
            {...register("batch_info")}
          />
          <TextField
            id="expiration_date"
            label="Expiration Date  (If Applicable)"
            type="date"
            //defaultValue="2017-05-24"
            sx={{ minWidth: "25%" }}
            InputLabelProps={{
              shrink: true,
            }}
            {...register("expiration_date")}
          />
          <TextField
            sx={{
              minWidth: "20%",
              width: "33%",
              marginTop: "16px",
              marginRight: "4px",
            }}
            id="sale_channel"
            type="string"
            label="Sale Channel (If Applicable)"
            {...register("sale_channel")}
          />
          <FormControl
            sx={{
              minWidth: "20%",
              width: "33%",
              marginTop: "16px",
              marginRight: "4px",
            }}
          >
            <InputLabel id="store-select-label">Store</InputLabel>
            <Select
              labelId="store-select-label"
              id="store_id"
              value={store}
              {...register("store_id")}
              label="Store"
              onChange={handleChangeStore}
            >
              {stores.map((store, index) => (
                <MenuItem key={index} value={store.id}>
                  {store.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            sx={{ minWidth: "20%", width: "33%", marginTop: "16px" }}
            id="order_id"
            type="number"
            label="Order ID (If Applicable)"
            {...register("order_id")}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            margin="normal"
            fullWidth
            id={"comments"}
            type="text"
            label="Comments"
            rows={10}
            multiline
            {...register(`comments`)}
          />
          <Stack
            direction="row"
            spacing={2}
            sx={{
              mt: 2,
            }}
          >
            {!!snackbar && (
              <Snackbar
                open
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
              >
                <Alert {...snackbar} onClose={handleCloseSnackbar} />
              </Snackbar>
            )}
            <Button
              onClick={() => reset()}
              variant="outlined"
              startIcon={<DeleteIcon />}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              size="large"
              endIcon={<SendIcon />}
              disabled={circularLoading}
              type="submit"
            >
              Send
              {circularLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
}
