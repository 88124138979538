/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Container, Pagination, Box, SelectChangeEvent, InputLabel, Select, MenuItem,TextField, Button, } from "@mui/material";
import TableHorizontal from "../../TableHorizontal";
import api from "../../../services/api";
function ListCombinations() {
  const [pageState, setPageState] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [parentChildOptions, setParentChildOptions] = useState([]);
  const columns = ["Combination Product", "Item Product", "Qty of Item", "Show in Catalog?", "Action", "Delete"];
  const keys = ["combination_product", "item_product", "qty_in_combination","show_catalog_text", "action", "delete"];
  const fetchData = async (page = 1,
    numberItemsPerPage = 500
  ) => {
    await api.get(`/product-combinations?page=${page}&limit=${numberItemsPerPage}&product_id=${productIDFilter}&search_product_sku=${productSKUFilter}`).then((res) => {
      setParentChildOptions(res.data.items);
      setTotalPages(res.data.total_pages);
    });
  };
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageState(value);
    fetchData(value);
  };

  const [numberItemsPerPage, setNumberItemsPerPage] = useState("500");
  const handleNumberItemsPerPage = (event: SelectChangeEvent) => {
    setNumberItemsPerPage(event.target.value);
    fetchData(1,Number(event.target.value));
  };

  const [productIDFilter, setProductIDFilter] = useState("");
  const handleChangeProductIDFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProductIDFilter(e.target.value);
  };

  const [productSKUFilter, setProductSKUFilter] = useState("");
  const handleChangeProductSKUFilter = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProductSKUFilter(e.target.value);
  };

  const handleFilterButtonClick = () => {
    fetchData(1);
  };

  
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
      <Box style={{ display: "flex", flexDirection:"row" }}>
        <Box
         sx={{
          display: "flex",
          flexDirection: "column",
          marginRight: "8px",
          marginLeft: 1,
        }}
        >
      <InputLabel id="number-items-page-label">
          Number of Items per Page
        </InputLabel>
        <Select
          labelId="number-items-page-select-label"
          id="number-items-page"
          value={numberItemsPerPage}
          label="Number of Items per Page"
          onChange={handleNumberItemsPerPage}
        >
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={250}>250</MenuItem>
          <MenuItem value={500}>500</MenuItem>
          <MenuItem value={750}>750</MenuItem>
          <MenuItem value={1000}>1000</MenuItem>
        </Select>
        </Box>
        <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
        >
          <InputLabel id="product-id-filter-label">
          Product ID Filter
        </InputLabel>
        <TextField
          //sx={{ mt: 1, minWidth: 3/4 }}
          //fullWidth
          id={"Product-ID-filter"}
          type="text"
          label="Type here..."
          value={productIDFilter}
          onChange={handleChangeProductIDFilter}
        />
        </Box>
        <Box
        sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "8px",
          }}
        >
          <InputLabel id="product-sku-filter-label">
          Product SKU Filter
        </InputLabel>
        <TextField
          //sx={{ mt: 1, minWidth: 3/4 }}
          //fullWidth
          id={"Product-SKU-filter"}
          type="text"
          label="Type here..."
          value={productSKUFilter}
          onChange={handleChangeProductSKUFilter}
        />
        </Box>
        <Button variant="contained" sx={{ m: 3 }} href="" onClick={handleFilterButtonClick}>
          Filter
        </Button>
        </Box>
      <TableHorizontal
        screen={"product-combinations"}
        columns={columns}
        data={parentChildOptions}
        objectKey={keys}
      />
      <Box
        display="flex"
        width={"100%"}
        height={80}
        alignItems="center"
        justifyContent="center"
      >
        <Pagination
          count={totalPages}
          page={pageState}
          onChange={handleChange}
        />
      </Box>
    </Container>
  );
}
export default ListCombinations;
