/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Container,
  Pagination,
  Box,
  SelectChangeEvent,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
} from "@mui/material";
import TableHorizontal from "../TableHorizontal";
import api from "../../services/api";

function ListWarehouses() {
  const [pageState, setPagepageState] = useState(1);
  const [total_pages, setTotalPages] = useState(0);
  const [warehouses, setwarehouses] = useState([]);
  const columns = [
    "ID",
    "Name",
    "city",
    "PostalCode",
    "Addres",
    "Addres 2",
    "Size",
    "Coments",
    "Actions",
    "Delete",
  ];
  const keys = [
    "id",
    "name",
    "city",
    "postal_code",
    "address",
    "address_2",
    "size",
    "coments",
    "action",
    "delete",
  ];
  const fetchData = async (
    page = 1,
    language = 1,
    numberItemsPerPage = 500
  ) => {
    await api
      .get(
        `/warehouses?page=${page}&limit=${numberItemsPerPage}&search=${search}&language_id=${language}`
      )
      .then((res) => {
        setwarehouses(res.data.items);
        setTotalPages(res.data.total_pages);
      });
  };
  const [numberItemsPerPage, setNumberItemsPerPage] = useState("500");
  const handleNumberItemsPerPage = (event: SelectChangeEvent) => {
    setNumberItemsPerPage(event.target.value);
    fetchData(1, Number(event.target.value));
  };
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPagepageState(value);
    fetchData(value, Number(numberItemsPerPage));
  };
  const [search, setSearch] = useState("");
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const [language, setLanguage] = useState(0);
  const handleChangeLanguage = (event: SelectChangeEvent) => {
    setLanguage(Number(event.target.value));
    fetchData(1, Number(event.target.value), Number(numberItemsPerPage));
  };

  const [languages, setLanguages] = useState<{ id: number; name: string }[]>(
    []
  );
  const fetchLanguages = async () => {
    const response = await api.get(`languages`);
    setLanguages(response.data.items);
    setLanguage(1);
  };

  const handleSearchClick = () => {
    fetchData(1);
  };
  useEffect(() => {
    fetchData();
    fetchLanguages();
  }, []);
  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
      <Box 
        sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
      >
        <InputLabel id="number-items-page-label">
          Number of Items per Page
        </InputLabel>
        <Select
          labelId="number-items-page-select-label"
          id="number-items-page"
          value={numberItemsPerPage}
          label="Number of Items per Page"
          onChange={handleNumberItemsPerPage}
        >
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={250}>250</MenuItem>
          <MenuItem value={500}>500</MenuItem>
          <MenuItem value={750}>750</MenuItem>
          <MenuItem value={1000}>1000</MenuItem>
        </Select>
        <InputLabel id="language-select-label">Language</InputLabel>
        <Select
          labelId="language-select-label"
          id="language"
          value={String(language)}
          label="Language"
          onChange={handleChangeLanguage}
        >
          <MenuItem value={0}>None</MenuItem>
          {languages.map((language, index) => (
            <MenuItem key={index} value={language.id}>{language.name}</MenuItem>
          ))}
        </Select>
        <TextField
          //sx={{ mt: 1, minWidth: 3/4 }}
          sx={{ minWidth: 1 / 4 }}
          //fullWidth
          id={"Search"}
          type="text"
          label="Search Warehouse"
          value={search}
          onChange={handleChangeSearch}
        />
      
     
      <Button
        variant="contained"
        sx={{ m: 3 }}
        href=""
        onClick={handleSearchClick}
      >
        Filter
      </Button>
      </Box>
      <TableHorizontal
        screen={"warehouses"}
        columns={columns}
        data={warehouses}
        objectKey={keys}
      />
      <Box
        display="flex"
        width={"100%"}
        height={80}
        alignItems="center"
        justifyContent="center"
      >
        <Pagination
          count={total_pages}
          page={pageState}
          onChange={handleChange}
        />
      </Box>
    </Container>
  );
}
export default ListWarehouses;
