import { useEffect, useState } from "react";
import {
  Container,
  Pagination,
  Box,
  SelectChangeEvent,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import TableHorizontal from "../TableHorizontal";
import api from "../../services/api";
function ListShippingMethods() {
  const [pageState, setPageState] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [shippingMethods, setShippingMethods] = useState([]);
  const columns = [
    "ID",
    "Code",
    "Name",
    "Default Delivery Additional Time",
    "Default Value",
    "Has API or Function",
    "Shipping Company Name",
    "Additional Information",
    "Actions",
    "Delete",
  ];
  const keys = [
    "id",
    "code",
    "name",
    "default_delivery_additional_time",
    "default_value",
    "has_api_or_function_text",
    "shipping_company_name",
    "additional_info",
    "action",
    "delete",
  ];
  const fetchData = async (page = 1, numberItemsPerPage = 500) => {
    await api
      .get(`/shipping-methods?page=${page}&limit=${numberItemsPerPage}`)
      .then((res) => {
        setShippingMethods(res.data.items);
        setTotalPages(res.data.total_pages);
      });
  };
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageState(value);
    fetchData(value);
  };
  const [numberItemsPerPage, setNumberItemsPerPage] = useState("500");
  const handleNumberItemsPerPage = (event: SelectChangeEvent) => {
    setNumberItemsPerPage(event.target.value);
    fetchData(1, Number(event.target.value));
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="number-items-page-label">
            Number of Items per Page
          </InputLabel>
          <Select
            labelId="number-items-page-select-label"
            id="number-items-page"
            value={numberItemsPerPage}
            label="Number of Items per Page"
            onChange={handleNumberItemsPerPage}
          >
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={250}>250</MenuItem>
            <MenuItem value={500}>500</MenuItem>
            <MenuItem value={750}>750</MenuItem>
            <MenuItem value={1000}>1000</MenuItem>
          </Select>
        </Box>
      </Box>
      <TableHorizontal
        screen={"shipping-methods"}
        columns={columns}
        data={shippingMethods}
        objectKey={keys}
      />
      <Box
        display="flex"
        width={"100%"}
        height={80}
        alignItems="center"
        justifyContent="center"
      >
        <Pagination
          count={totalPages}
          page={pageState}
          onChange={handleChange}
        />
      </Box>
    </Container>
  );
}
export default ListShippingMethods;
