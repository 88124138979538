/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { inputsName } from "../../config/languageConst";
import api from "../../services/api";
import { green } from "@mui/material/colors";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";

function NewCoupons() {
  const [circularLoading, setCircularLoading] = React.useState(false);

  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (coupon: any) => {
    setCircularLoading(true);
    let temp = [];
    coupon.image = "notimage";
    for (let i of coupon.descriptions) i && temp.push(i);
    coupon.descriptions = temp;
    coupon.quantity = Number(coupon.quantity);
    coupon.quantity_client = Number(coupon.quantity_client);
    coupon.discount_type = Number(coupon.discount_type);

    api
      .post("coupons", coupon)
      .then((response) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Coupon created!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          footer: '<a href="">Why do I have this issue?</a>',
        });
        setCircularLoading(false);
        setRequestError(true); //trocado por false
        setRequestErrorData(error.response.data.message);
      });
  };

  const [coupons, setCoupons] = useState("");
  const handleChangeCoupons = (event: SelectChangeEvent) => {
    setCoupons(event.target.value as string);
  };

  const [store, setStore] = React.useState<{ id: number; name: string }[]>([]);

  const fetchStores = async () => {
    const response = await api.get("stores");
    setStore(response.data.items);
  };

  useEffect(() => {
    fetchStores();
  }, []);

  const [type, setType] = React.useState("");
  const handleChange = (event: SelectChangeEvent) => {
    setType(event.target.value);
  };

  return (
    <div>
      <Container component="main" maxWidth="lg">
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            sx={{ fontSize: "32px", marginBottom: "15px", color: "#556cd6" }}
          >
            New Coupon
          </Typography>
          {requestError && (
            <List component="nav" aria-label="erros">
              {requestErrorData.map((error) => (
                <ListItem key={error}>
                  <ListItemText
                    sx={{
                      color: "red",
                    }}
                    primary={error}
                  />
                </ListItem>
              ))}
            </List>
          )}
          <FormControl
            sx={{ m: 5, minWidth: 3 / 4 }} // equivalent to width: '75%' - usar fracao para %
            size="small"
          >
            <InputLabel id="demo-select-small">Tipo de desconto</InputLabel>
            <Select
              sx={{ marginBottom: "30px" }}
              labelId="demo-select-small"
              required
              id="demo-select-small"
              value={type}
              label="Type"
              {...register("discount_type", {})}
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={0}>Dinheiro</MenuItem>
              <MenuItem value={1}>Porcentagem</MenuItem>
            </Select>
            <FormControl fullWidth>
              <InputLabel id="country-label">Store</InputLabel>
              <Select
                labelId="country-select-label"
                id="country"
                value={coupons}
                {...register("store_id")}
                label="Store"
                onChange={handleChangeCoupons}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {store.map((store, index) => (
                  <MenuItem value={store.id} key={index}>
                    {store.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              margin="normal"
              required
              fullWidth
              id="coupon_code"
              type="text"
              label="Coupon code"
              {...register("coupon_code", {})}
              autoFocus
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.coupon_code?.toString()}
            </p>

            <TextField
              margin="normal"
              required
              fullWidth
              id="discount"
              type="number"
              label="Discount($)"
              {...register("discount", {})}
              autoFocus
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.discount?.toString()}
            </p>
            <TextField
              margin="normal"
              required
              fullWidth
              id="quantity"
              type="number"
              label="Quantity Total"
              {...register("quantity", {})}
              autoFocus
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.quantity?.toString()}
            </p>
            <TextField
              margin="normal"
              required
              fullWidth
              id="quantity"
              type="number"
              label="Quantity Client"
              {...register("quantity_client", {})}
              autoFocus
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.quantity?.toString()}
            </p>
            <Typography
              sx={{ fontSize: 18 }}
              color="text.secondary"
              align="center"
              gutterBottom
            ></Typography>
            <div style={{ width: "100%" }}>
              {inputsName.map((input, index) => (
                <div key={index} className="flags-container">
                  <input
                    hidden
                    {...register(
                      `descriptions[${input.language_id}].language_id`,
                      {
                        required: `descriptions[${input.language_id}].language_id is required`,
                      }
                    )}
                    value={input.language_id}
                  />
                  <span className="fa-flags" style={{ height: "320px" }}>
                    <img
                      src={`/assets/language/${input.language_id}.png`}
                      alt=""
                    />
                  </span>
                  <Box sx={{ minWidth: 2 / 4, width: "100%" }}>
                    <Box sx={{ width: "100%" }}>
                      <TextField
                        sx={{ width: "100%" }}
                        label={input.language_name + " (Name)"}
                        {...register(`descriptions[${input.language_id}].name`)}
                      />
                    </Box>
                    <TextField
                      id="filled-multiline-static"
                      label={input.language_name + " (Description)"}
                      {...register(
                        `descriptions[${input.language_id}].description`
                      )}
                      multiline
                      rows={10}
                      variant="filled"
                      fullWidth
                    />
                  </Box>
                </div>
              ))}
            </div>
            <Typography
              sx={{ fontSize: 18, mt: 3 }}
              color="text.secondary"
              align="center"
              gutterBottom
            ></Typography>

            <Stack
              direction="row"
              spacing={2}
              sx={{
                mt: 2,
              }}
            >
              <Button
                onClick={() => reset()}
                variant="outlined"
                startIcon={<DeleteIcon />}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                size="large"
                endIcon={<SendIcon />}
                disabled={circularLoading}
                type="submit"
              >
                Send
                {circularLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Button>
            </Stack>
          </FormControl>
        </Box>
      </Container>
    </div>
  );
}
export default NewCoupons;
