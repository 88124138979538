/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
/* import AddIcon from "@mui/icons-material/Add";
 import EditIcon from "@mui/icons-material/Edit";  */
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
/* import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close"; */
import {
  // GridRowsProp,
  // GridRowModesModel,
  // GridRowModes,
  DataGrid,
  // GridRowParams,
  // MuiEvent,
  // GridToolbarContainer,
  GridActionsCellItem,
  // GridEventListener,
  GridRowId,
  GridRowModel,
  // GridValueFormatterParams,
  GridColDef,
  // GridRenderCellParams,
  // useGridApiContext,
} from "@mui/x-data-grid";
import {
  Alert,
  AlertProps,
  // Autocomplete,
  // AutocompleteInputChangeReason,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  // TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../services/api";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import { LogTrackUser } from "../../../types/log-track-user/log-track-user";
import { AsyncPaginate } from "react-select-async-paginate";
import Cookies from "js-cookie";

//DATA GRID FUNCTIONS
//Autocomplete section

interface PaymentMethodStore {
  id: number;
  payment_method_id: number;
  store_id: number;
  destination_country_id: number;
  payment_method: string;
  store: string;
}

const useExecuteMutation = () => {
  return React.useCallback(
    (paymentMethodStore: Partial<PaymentMethodStore>) =>
      new Promise<Partial<PaymentMethodStore>>((resolve, reject) =>
        setTimeout(() => {
          if (
            paymentMethodStore.payment_method_id === 0 ||
            paymentMethodStore.store_id === 0 ||
            paymentMethodStore.destination_country_id === 0
          ) {
            reject();
          } else {
            resolve(paymentMethodStore);
          }
        }, 200)
      ),
    []
  );
};

function computeMutation(newRow: GridRowModel, oldRow: GridRowModel) {
  if (newRow.discount !== oldRow.discount) {
    return `Payment Method Code: '${oldRow.payment_method_code}', Store: '${oldRow.store}', Destination Country: '${oldRow.destination_country}' Payment Method Discount from '${oldRow.discount}' to '${newRow.discount}'`;
  }
  if (newRow.activation_initial_value !== oldRow.activation_initial_value) {
    return `Payment Method Code: '${oldRow.payment_method_code}', Store: '${oldRow.store}', Destination Country: '${oldRow.destination_country}' Minimum Total Order Value to Activate Payment Method from '${oldRow.activation_initial_value}' to '${newRow.activation_initial_value}'`;
  }
  if (newRow.sort_order !== oldRow.sort_order) {
    return `Payment Method Code: '${oldRow.payment_method_code}', Store: '${oldRow.store}', Destination Country: '${oldRow.destination_country}' Sort Order from '${oldRow.sort_order}' to '${newRow.sort_order}'`;
  }
  return null;
}

export default function ListPaymentMethodStores() {
  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////

  const [loading, setLoading] = useState(false);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 100,
  });
  const [rowCountState, setRowCountState] = React.useState(0);
  const [paymentMethodStores, setPaymentMethodStores] = useState<
    {
      id: number;
      payment_method_id: number;
      payment_method: string;
      store: string;
      store_id: number;
      destination_country_id: number;
      destination_country: string;
      discount: number;
      activation_initial_value: number;
      sort_order: number;
      created_at: Date;
      updated_at: Date;
    }[]
  >([]);
  //const [nextID, setNextID] = React.useState(0);

  //const columns = ["ID", "Parent ID", "Name", "Path", "Actions", "Delete"];
  //const keys = ["id", "parent_id", "name", "path", "action", "delete"];
  const fetchData = async (page = 1, numberItemsPerPage = 100) => {
    await api
      .get(
        `/payment-method-stores?page=${page}&limit=${numberItemsPerPage}&country_id=${
          country?.id
        }&payment_method_id=${
          paymentMethodFilter?.id || ""
        }&store_id=${storeFilter}`
      )
      .then((res) => {
        setPaymentMethodStores(res.data.items);
        // setRows(res.data.items);
        //setTotalPages(res.data.total_pages);
        setRowCountState(res.data.total);
      });
  };

  const defaultAdditional = {
    page: 1,
  };

  //COUNTRY
  const loadOptionsCountries = async (search: any, page: any) => {
    const results = await api.get(
      `/countries?page=${page}&limit=0&search=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsCountries = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsCountries(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const [country, setCountry] = useState<{ id: number; name: string } | null>(
    null
  );

  //PAYMENT METHODS
  const loadOptionsPaymentMethods = async (search: any, page: any) => {
    const results = await api.get(
      `/payment-methods?page=${page}&limit=0&search=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsPaymentMethods = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsPaymentMethods(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const [paymentMethodFilter, setPaymentMethodFilter] = useState<{
    id: number;
    code: string;
    name: string;
  } | null>(null);

  // STORES
  const [storeFilter, setStoreFilter] = useState(0);
  const [stores, setStores] = useState<{ id: number; name: string }[]>([]);
  const fetchStores = async () => {
    const response = await api.get(`stores?page=0&limit=500`);
    setStores(response.data.items);
  };

  const handleSearchClick = () => {
    fetchData(1, 100);
  };

  // NEW INSERT SECTION
  const [countryNew, setCountryNew] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [paymentMethodNew, setPaymentMethodNew] = useState<{
    id: number;
    code: string;
    name: string;
  } | null>(null);
  const [storeNew, setStoreNew] = useState(0);

  const handleNewClick = async () => {
    if (countryNew && paymentMethodNew && storeNew > 0) {
      let paymentMethodStore = {} as any;
      paymentMethodStore.payment_method_id = paymentMethodNew?.id;
      paymentMethodStore.store_id = storeNew;
      paymentMethodStore.destination_country_id = countryNew?.id;

      api
        .post(`payment-method-stores`, paymentMethodStore)
        .then((response) => {
          Swal.fire({
            title: "Payment Method Store Created!",
            icon: "success",
            confirmButtonText: "Yes",
          });
          fetchData(1, 100);
        })
        .catch((error) => {
          console.error(error.response);
          Swal.fire({
            title: "Ops...",
            icon: "error",
            text: error.response.data.message,
            confirmButtonText: "Yes",
          });
        });
    } else {
      Swal.fire({
        title: "Ops...",
        icon: "error",
        text: "Payment Method, Store and Country must be defined!",
        confirmButtonText: "Yes",
      });
    }
  };

  //DATAGRID SECTION
  //Edit Section
  /* const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  ); */

  const handleDeleteClick = (id: GridRowId) => () => {
    //Message to Confirm DELETION
    Swal.fire({
      title: `Are you sure to delete the ID ${id}?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        //Call API to delete de database
        api
          .delete(`payment-method-stores/${id}`)
          .then((res) => {
            Swal.fire("Deleted!", "It has been deleted.", "success");
            fetchData(1, 100);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  };

  const mutateRow = useExecuteMutation();
  const noButtonRef = React.useRef<HTMLButtonElement>(null);
  const [promiseArguments, setPromiseArguments] = React.useState<any>(null);

  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const processRowUpdate = React.useCallback(
    (newRow: GridRowModel, oldRow: GridRowModel) =>
      new Promise<GridRowModel>((resolve, reject) => {
        const updatedRow = { ...newRow, isNew: false };
        const mutation = computeMutation(newRow, oldRow);
        if (mutation) {
          // Save the arguments to resolve or reject the promise later
          setPromiseArguments({ resolve, reject, newRow, oldRow });
        } else {
          resolve(oldRow); // Nothing was changed
        }
        //setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
      }),
    []
  );

  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments;
    resolve(oldRow); // Resolve with the old row to not update the internal state
    setPromiseArguments(null);
  };

  const handleYes = async () => {
    const { newRow, oldRow, reject, resolve } = promiseArguments;

    try {
      // Make the HTTP request to save in the backend
      let paymentMethodStoreUpdate = {} as any;
      paymentMethodStoreUpdate.payment_method_id = newRow.payment_method_id;
      paymentMethodStoreUpdate.store_id = newRow.store_id;
      paymentMethodStoreUpdate.destination_country_id =
        newRow.destination_country_id;
      paymentMethodStoreUpdate.discount = Number(newRow.discount);
      paymentMethodStoreUpdate.activation_initial_value = Number(
        newRow.activation_initial_value
      );
      paymentMethodStoreUpdate.sort_order = Number(newRow.sort_order);

      await api.put(
        `payment-method-stores/${newRow.id}`,
        paymentMethodStoreUpdate
      );

      const response = await mutateRow(newRow);
      setSnackbar({
        children: "Payment Method Store successfully saved",
        severity: "success",
      });
      resolve(response);
      setPromiseArguments(null);
    } catch (error) {
      console.error(error);
      setSnackbar({ children: "Error! Invalid Data!", severity: "error" });
      reject(oldRow);
      setPromiseArguments(null);
    }
  };

  const handleEntered = () => {
    // The `autoFocus` is not used because, if used, the same Enter that saves
    // the cell triggers "No". Instead, we manually focus the "No" button once
    // the dialog is fully open.
    // noButtonRef.current?.focus();
  };

  const renderConfirmDialog = () => {
    if (!promiseArguments) {
      return null;
    }

    const { newRow, oldRow } = promiseArguments;
    const mutation = computeMutation(newRow, oldRow);

    return (
      <Dialog
        maxWidth="xs"
        TransitionProps={{ onEntered: handleEntered }}
        open={!!promiseArguments}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent dividers>
          {`Pressing 'Yes' will ${mutation}.`}
        </DialogContent>
        <DialogActions>
          <Button ref={noButtonRef} onClick={handleNo}>
            No
          </Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      editable: false,
      width: 70,
    },
    {
      field: "code",
      headerName: "CODE",
      width: 150,
      editable: false,
    },
    {
      field: "payment_method",
      headerName: "Payment Method",
      width: 250,
      editable: false,
    },

    {
      field: "store",
      headerName: "Store",
      width: 150,
      editable: false,
    },
    {
      field: "destination_country",
      headerName: "Available Country",
      width: 150,
      editable: false,
    },
    {
      field: "discount",
      headerName: "Discount",
      type: "number",
      width: 100,
      editable: true,
      valueFormatter: (params: number) => {
        if (params == null) {
          return "";
        }

        const valueFormatted = Number(params).toLocaleString();
        return `${valueFormatted} %`;
      },
    },
    {
      field: "activation_initial_value",
      headerName: "Min. Order Total Act.",
      type: "number",
      width: 150,
      editable: true,
      valueFormatter: (value) => currencyFormatter.format(value),
    },
    {
      field: "sort_order",
      headerName: "Sort Order",
      type: "number",
      width: 100,
      editable: true,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  //LOAD INITIAL VALUES
  useEffect(() => {
    fetchData();
    fetchStores();
  }, []);

  useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);
      fetchData(paginationModel.page + 1, paginationModel.pageSize);

      if (!active) {
        return;
      }

      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [paginationModel.page]);

  return (
    <Container maxWidth="xl" sx={{ width: "100%", mt: 2, mb: 4 }}>
      <Typography
        component="h1"
        variant="h5"
        sx={{
          paddingTop: "40px",
          fontWeight: "bold",
          fontSize: "32px",
          color: "#556cd6",
          paddingLeft: "20px",
        }}
      >
        Payment Methods & Store
      </Typography>
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
            width: "25%",
          }}
        >
          <InputLabel id="store-filter-label">Store Filter:</InputLabel>
          <Select
            labelId="store-select-label"
            id="store"
            value={String(storeFilter)}
            label="Store"
            onChange={(e) => setStoreFilter(Number(e.target.value))}
          >
            <MenuItem value={0}>None</MenuItem>
            {stores.map((store, index) => (
              <MenuItem value={store.id} key={index}>
                {store.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
            width: "25%",
          }}
        >
          <InputLabel id="country-filter-label">Country Filter:</InputLabel>
          <AsyncPaginate
            placeholder="Select Country"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"country_id"}
            id="country"
            name="country"
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.name}
            loadOptions={loadPageOptionsCountries}
            value={country}
            onChange={setCountry}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
            width: "25%",
          }}
        >
          <InputLabel id="payment-method-filter-label">
            Payment Method Filter:
          </InputLabel>
          <AsyncPaginate
            placeholder="Select Payment Method"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"payment_method_id"}
            id="paymentMethod"
            name="paymentMethod"
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.name}
            loadOptions={loadPageOptionsPaymentMethods}
            value={paymentMethodFilter}
            onChange={setPaymentMethodFilter}
          />
        </Box>

        <Button
          variant="contained"
          sx={{ m: 3 }}
          href=""
          onClick={handleSearchClick}
        >
          Filter
        </Button>
      </Box>
      To insert a new Entry, please fill the fields below and press the Insert
      Button
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
            minWidth: 1 / 4,
          }}
        >
          <InputLabel id="store-select-label">Store</InputLabel>
          <Select
            labelId="store-select-label"
            id="store"
            value={String(storeNew)}
            label="Store"
            onChange={(e) => setStoreNew(Number(e.target.value))}
          >
            <MenuItem value={0}>None</MenuItem>
            {stores.map((store, index) => (
              <MenuItem value={store.id} key={index}>
                {store.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
            minWidth: 1 / 4,
          }}
        >
          <InputLabel id="productstore-store-label">Country:</InputLabel>
          <AsyncPaginate
            placeholder="Select Country"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"country_id_new"}
            id="countryNew"
            name="countryNew"
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.name}
            loadOptions={loadPageOptionsCountries}
            value={countryNew}
            onChange={setCountryNew}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 1,
            marginRight: "8px",
            minWidth: 1 / 4,
          }}
        >
          <InputLabel id="payment-method-store-label">
            Payment Method:
          </InputLabel>
          <AsyncPaginate
            placeholder="Select Payment Method"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"payment_method_id_new"}
            id="paymentMethodNew"
            name="paymentMethodNew"
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.name}
            loadOptions={loadPageOptionsPaymentMethods}
            value={paymentMethodNew}
            onChange={setPaymentMethodNew}
          />
        </Box>
        <Button
          variant="contained"
          sx={{ m: 3 }}
          href=""
          onClick={handleNewClick}
        >
          New Entry
        </Button>
      </Box>
      Only Delivery Additional Time, Fixed Additional Cost, Cost Multiplier and
      Minimum Order Total are changeable. To change any of it, DOUBLE CLICK on
      the row field.
      <Box
        sx={{
          marginLeft: "8px",
          mt: "8px",
          height: 600,
          width: "100%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
        }}
      >
        {renderConfirmDialog()}
        <DataGrid
          rows={paymentMethodStores}
          columns={columns}
          processRowUpdate={processRowUpdate}
          initialState={{
            pagination: {
              paginationModel,
            },
          }}
          pagination
          paginationModel={paginationModel}
          pageSizeOptions={[25, 50, 100]}
          rowCount={rowCountState}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          loading={loading}
        />
        {!!snackbar && (
          <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </Box>
    </Container>
  );
}
