import { Box, CardMedia, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
export default function IdNotFound() {
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CardMedia
          component="img"
          height="194"
          image="/404.png"
          alt="404 Image"
        />
        <Typography component="h1" variant="h4">
          Página não encontrada
        </Typography>
        <Link to={"/"}>Link</Link>
      </Box>
    </Container>
  );
}
