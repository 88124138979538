import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import api from "../../services/api";
import { green } from "@mui/material/colors";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormHelperText,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { AsyncPaginate } from "react-select-async-paginate";
import Cookies from "js-cookie";

export function NewProductSuppliers() {
  const [circularLoading, setCircularLoading] = React.useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (suppliers: any) => {
    setCircularLoading(true);
    suppliers.product_id = Number(product?.id);
    suppliers.supplier_id = Number(supplier?.id);
    suppliers.currency_id = Number(currency?.id);
    suppliers.quantity_purchased = Number(suppliers.quantity_purchased);
    let username: string = Cookies.get("username") || "";
    suppliers.username = username.replace(/["']/g, "");

    api
      .post("product-suppliers", suppliers)
      .then((response) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "product suppliers created!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          footer: '<a href="">Why do I have this issue?</a>',
        });
        setCircularLoading(false);
        setRequestError(true); //trocado por false
        setRequestErrorData(error.response.data.message);
      });
  };

  const defaultAdditional = {
    page: 1,
  };

  const [product, setProduct] = useState<{ id: number; sku: string } | null>(
    null
  );
  const loadOptionsItemProducts = async (search: any, page: any) => {
    const results = await api.get(
      `/products-custom/notparent-notcombination-products?page=${page}&search=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const [quantityUnit, setQuantityUnit] = useState("UN");
  const handleChangeQuantityUnit = (event: SelectChangeEvent) => {
    setQuantityUnit(event.target.value);
  };

  const loadPageOptionsItemProducts = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsItemProducts(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const [supplier, setSupplier] = useState<{ id: number; name: string } | null>(
    null
  );
  const loadOptionsSupplier = async (search: any, page: any) => {
    const results = await api.get(`/suppliers?page=${page}&name=${search}`);
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsSupplier = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsSupplier(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const [currency, setCurrency] = useState<{ id: number; name: string } | null>(
    null
  );
  const loadOptionsCurrency = async (search: any, page: any) => {
    const results = await api.get(`/currencies?page=${page}&name=${search}`);
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsCurrency = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsCurrency(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  useEffect(() => {}, []);

  return (
    <div>
      <Container component="main" maxWidth="lg">
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            marginLeft: "20px",
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            sx={{
              fontSize: "30px",
              marginBottom: "15px",
              color: "#556cd6",
            }}
          >
            New Product Supplier
          </Typography>
          {requestError && (
            <List component="nav" aria-label="erros">
              {requestErrorData.map((error, index) => (
                <ListItem key={index}>
                  <ListItemText
                    sx={{
                      color: "red",
                    }}
                    primary={error}
                  />
                </ListItem>
              ))}
            </List>
          )}
          <FormControl
            sx={{ m: 5, minWidth: 3 / 4 }} // equivalent to width: '75%' - usar fracao para %
            size="small"
          >
            <AsyncPaginate
              placeholder="Select Product"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              additional={defaultAdditional}
              key={"product_id"}
              id="product"
              name="product"
              getOptionValue={(option: any) => option?.id}
              getOptionLabel={(option: any) => option?.sku}
              loadOptions={loadPageOptionsItemProducts}
              value={product}
              onChange={setProduct}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.product_id?.toString()}
            </p>
            <FormHelperText>
              Only Products with product type = 1 - Unique Products and product
              type = 3 - Child Products
            </FormHelperText>
            <Box sx={{ mt: "8px" }}>
              <AsyncPaginate
                placeholder="Supplier"
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                additional={defaultAdditional}
                key={"supplier_id"}
                id="supplier"
                name="supplier"
                getOptionValue={(option: any) => option?.id}
                getOptionLabel={(option: any) => option?.name}
                loadOptions={loadPageOptionsSupplier}
                value={supplier}
                onChange={setSupplier}
              />
              <p style={{ color: "red", textAlign: "center" }}>
                {errors?.supplier_id?.toString()}
              </p>
            </Box>
            <Box sx={{ mt: "8px" }}>
              <AsyncPaginate
                placeholder="Currency"
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                additional={defaultAdditional}
                key={"currency_id"}
                id="currency"
                name="currency"
                getOptionValue={(option: any) => option?.id}
                getOptionLabel={(option: any) => option?.name}
                loadOptions={loadPageOptionsCurrency}
                value={currency}
                onChange={setCurrency}
              />
              <p style={{ color: "red", textAlign: "center" }}>
                {errors?.currency_id?.toString()}
              </p>
            </Box>
            <TextField
              margin="normal"
              required
              fullWidth
              id="unit_price_purchased"
              type="number"
              label="Unit Price"
              {...register("unit_price_purchased")}
              autoFocus
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.unit_price_purchased?.toString()}
            </p>
            <TextField
              margin="normal"
              required
              fullWidth
              id="quantity_purchased"
              type="number"
              label="Quantity Purchased"
              {...register("quantity_purchased")}
              autoFocus
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.quantity_purchased?.toString()}
            </p>
            <FormControl>
              <InputLabel id="quantity-unit-label" sx={{ mt: "8px" }}>
                Quantity Unit
              </InputLabel>
              <Select
                sx={{ minWidth: "140px", width: "15%", mt: "8px" }}
                labelId="quantity-unit-label"
                id="warehouse"
                required
                value={quantityUnit}
                {...register("quantity_unit")}
                label="Quantity Unit"
                onChange={handleChangeQuantityUnit}
              >
                <MenuItem value="UN">UN</MenuItem>
                <MenuItem value="KG">KG</MenuItem>
                <MenuItem value="G">G</MenuItem>
                <MenuItem value="TON">TON</MenuItem>
                <MenuItem value="PACK">PACK</MenuItem>
              </Select>
            </FormControl>
            <TextField
              margin="normal"
              required
              fullWidth
              id="purchase_order_number"
              type="string"
              label="Order Number"
              {...register("purchase_order_number")}
              autoFocus
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.purchase_order_number?.toString()}
            </p>
            <TextField
              id="purchase_date"
              label="Purchase Date"
              type="date"
              //defaultValue="2017-05-24"
              sx={{ minWidth: "25%", mt: "8px" }}
              InputLabelProps={{
                shrink: true,
              }}
              {...register("purchase_date")}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="comments"
              type="text"
              label="Comments"
              rows={10}
              multiline
              {...register("comments")}
              autoFocus
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.comments?.toString()}
            </p>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                mt: 2,
              }}
            >
              <Button
                onClick={() => reset()}
                variant="outlined"
                startIcon={<DeleteIcon />}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                size="large"
                endIcon={<SendIcon />}
                disabled={circularLoading}
                type="submit"
              >
                Send
                {circularLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Button>
            </Stack>
          </FormControl>
        </Box>
      </Container>
    </div>
  );
}
