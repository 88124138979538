import React from "react";
// import { makeStyles, createStyles } from "@mui/styles";
import {
  /*   ThemeProvider,
  createTheme, */
  makeStyles,
} from "@material-ui/core/styles";

import List from "@mui/material/List";

import AppMenuItem from "../AppMenuItem";
import Items from "./items";
import StoreContext from "../../contexts/store/StoreContext";

/* const theme = createTheme(); */

const AppMenu: React.FC = () => {
  const classes = useStyles();

  return (
    <List component="nav" className={classes.appMenu} disablePadding>
      <StoreContext.Provider
        value={{
          state: { items: [] },
          setState: () => {},
        }}
      >
        {Items.map((item, index) => (
          <AppMenuItem {...item} key={index} />
        ))}
      </StoreContext.Provider>
    </List>
  );
};

const drawerWidth = 240;

const useStyles: any = makeStyles((theme) => ({
  appMenu: {
    width: "100%",
    color: "#363636",
  },
  navList: {
    width: drawerWidth,
  },
  menuItem: {
    width: drawerWidth,
  },
  menuItemIcon: {
    color: "#97c05c",
  },
}));

export default AppMenu;
