/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  Pagination,
  MenuItem,
  Select,
  InputLabel,
  SelectChangeEvent,
  Button,
  TextField,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { LogTrackUser } from "../../../types/log-track-user/log-track-user";
import api from "../../../services/api";
import TableHorizontal from "../../../components/TableHorizontal";
import { AsyncPaginate } from "react-select-async-paginate";
import Cookies from "js-cookie";

export default function Contacts() {
  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////

  const [pageState, setPageState] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [contacts, setContacts] = useState([]);
  const columns = [
    "ID",
    "Store",
    "Cust. ID",
    "Cust. Email",
    "Name",
    "Email",
    "Language",
    "Is Answered",
    "User",
    "Date Answered",
    "Date Created",
    "Action",
    "Delete",
  ];
  const keys = [
    "id",
    "store_name",
    "customer_id",
    "customer_email",
    "name",
    "email",
    "language",
    "is_answered_text",
    "username_answered",
    "date_answered",
    "when",
    "action",
    "delete",
  ];

  const fetchData = async (page = 1, numberItemsPerPage = 500) => {
    await api
      .get(
        `/contacts?page=${page}&limit=${numberItemsPerPage}&store_id=${store}&language_id=${language}&search=${search}&email=${email}&user_id=${user?.id}&customer_id=${customer?.id}&is_answered=${isAnswered}`
      )
      .then((res) => {
        setContacts(res.data.items);
        setTotalPages(res.data.total_pages);
      });
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageState(value);
    fetchData(value, Number(numberItemsPerPage));
  };
  const [numberItemsPerPage, setNumberItemsPerPage] = useState("500");
  const handleNumberItemsPerPage = (event: SelectChangeEvent) => {
    setNumberItemsPerPage(event.target.value);
    fetchData(1, Number(event.target.value));
  };

  const [store, setStore] = useState(0);

  const [language, setLanguage] = useState(0);

  const [search, setSearch] = useState("");
  const [isAnswered, setIsAnswered] = useState(0); //1 = true // 2 = false
  const [email, setEmail] = useState("");

  const [user, setUser] = useState<{ id: number; username: string } | null>(
    null
  );
  const [customer, setCustomer] = useState<{ id: number; name: string } | null>(
    null
  );

  const [languages, setLanguages] = useState<{ id: number; name: string }[]>(
    []
  );
  const fetchLanguages = async () => {
    const response = await api.get(`languages`);
    setLanguages(response.data.items);
    setLanguage(1);
  };

  const [stores, setStores] = useState<{ id: number; name: string }[]>([]);
  const fetchStores = async () => {
    const response = await api.get(`stores`);
    setStores(response.data.items);
  };

  const defaultAdditional = {
    page: 1,
  };

  const loadOptionsCustomers = async (search: any, page: any) => {
    const results = await api.get(
      `/customers?page=${page}&limit=${numberItemsPerPage}&search=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsCustomers = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsCustomers(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadOptionsUsers = async (search: any, page: any) => {
    const results = await api.get(
      `/users?page=${page}&limit=${numberItemsPerPage}&search=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsUsers = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsUsers(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const handleSearchClick = () => {
    fetchData(1, Number(language));
  };

  useEffect(() => {
    fetchData();
    fetchLanguages();
    fetchStores();
  }, []);
  return (
    <Container
      component="main"
      maxWidth="xl"
      sx={{
        marginTop: 4,
      }}
    >
      <Typography
        component="h1"
        variant="h5"
        sx={{
          paddingTop: "40px",
          fontWeight: "bold",
          fontSize: "32px",
          color: "#556cd6",
          paddingLeft: "20px",
        }}
      >
        Customer Contacts through Websites
      </Typography>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          marginRight: "8px",
          marginLeft: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="number-items-page-label">
            Number of Items per Page
          </InputLabel>
          <Select
            labelId="number-items-page-select-label"
            id="number-items-page"
            value={numberItemsPerPage}
            label="Number of Items per Page"
            onChange={handleNumberItemsPerPage}
          >
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={250}>250</MenuItem>
            <MenuItem value={500}>500</MenuItem>
            <MenuItem value={750}>750</MenuItem>
            <MenuItem value={1000}>1000</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          marginRight: "8px",
          marginLeft: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="store-label">Store</InputLabel>
          <Select
            labelId="store-select-label"
            id="store"
            value={String(store)}
            label="Store"
            onChange={(e) => setStore(Number(e.target.value))}
          >
            <MenuItem value={0}>None</MenuItem>
            {stores.map((store, index) => (
              <MenuItem value={store.id} key={index}>
                {store.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="language-label">Language</InputLabel>
          <Select
            labelId="language-select-label"
            id="language"
            value={String(language)}
            label="Language"
            onChange={(e) => setLanguage(Number(e.target.value))}
          >
            <MenuItem value={0}>None</MenuItem>
            {languages.map((language, index) => (
              <MenuItem value={language.id} key={index}>
                {language.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="search-label">Search</InputLabel>
          <TextField
            id={"search"}
            type="text"
            label="Type here"
            defaultValue={search}
            onBlur={(e) => setSearch(e.target.value)}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="isAnswered-label">Is Answered?</InputLabel>
          <Select
            labelId="isAnswered-select-label"
            id="isAnswered"
            value={String(isAnswered)}
            label="Is Answered?"
            onChange={(e) => setIsAnswered(Number(e.target.value))}
          >
            <MenuItem value={0}>None</MenuItem>
            <MenuItem value={1}>Yes</MenuItem>
            <MenuItem value={2}>No</MenuItem>
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="email-label">Email</InputLabel>
          <TextField
            id={"email"}
            type="text"
            label="Type here"
            defaultValue={email}
            onBlur={(e) => setEmail(e.target.value)}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="user-label">Answered User</InputLabel>
          <AsyncPaginate
            placeholder="Select User"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"user_id_answered"}
            name="user_id_answered"
            id="user_id_answered"
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.username}
            loadOptions={loadPageOptionsUsers}
            onChange={setUser}
            value={user}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="customer-label">Customer</InputLabel>
          <AsyncPaginate
            placeholder="Select Customer"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"customer_id"}
            name="customer_id"
            id="customer_id"
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.email}
            loadOptions={loadPageOptionsCustomers}
            onChange={setCustomer}
            value={customer}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <Button
            variant="contained"
            sx={{ m: 3, fontSize: 18 }}
            href=""
            onClick={handleSearchClick}
          >
            Filter
          </Button>
        </Box>
      </Box>
      <TableHorizontal
        screen={"website-contacts"}
        columns={columns}
        data={contacts}
        objectKey={keys}
      />
      <Box
        display="flex"
        width={"100%"}
        height={80}
        alignItems="center"
        justifyContent="center"
      >
        <Pagination
          count={totalPages}
          page={pageState}
          onChange={handleChange}
        />
      </Box>
    </Container>
  );
}
