import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  FC,
} from "react";
import api from "../../services/api";
import { useCookies } from "react-cookie";
import Cookies from "js-cookie";
type State = boolean;

type PropsSessionContext = {
  sessionIsOk: State;
  setSessionIsOk: Dispatch<SetStateAction<State>>;
  hasSession: () => void;
};

const INITIAL_STATE = {
  sessionIsOk: false,
  setSessionIsOk: () => {},
  hasSession: () => {},
};

interface Props {
  children: React.ReactNode;
}

const SessionContext = createContext<PropsSessionContext>(INITIAL_STATE);

export const SessionContextProvider: FC<Props> = ({ children }) => {
  const [sessionIsOk, setSessionIsOk] = useState<State>(false);
  const [cookies, setCookie] = useCookies(["token", "username"]);
  const hasSession = async () => {
    if (
      (!cookies.token || !cookies.username) &&
      window.location.pathname !== "/login"
    ) {
      Cookies.remove("token");
      window.location.href = "/login";
      return;
    }
    if (!cookies.token || !cookies.username) {
      return;
    }
    api
      .get("auth/session")
      .then((response) => {
        setSessionIsOk(true);
      })
      .catch((error) => {
        console.error(error);
        refreshToken();
      });
  };
  const refreshToken = async () => {
    if (!cookies.token || !cookies.username) {
      return;
    }
    api
      .put("auth/refresh", {
        oldToken: cookies.token,
      })
      .then((response) => {
        setCookie("token", response.data.access_token, {
          path: "/",
          maxAge: response.data.expires_in,
        });
        localStorage.setItem("roles", JSON.stringify(response.data.roles));
        localStorage.setItem(
          "username",
          JSON.stringify(response.data.username)
        );
        setSessionIsOk(true);
      })
      .catch((error) => {
        localStorage.clear();
        setSessionIsOk(false);
      });
  };
  return (
    <SessionContext.Provider
      value={{ sessionIsOk, setSessionIsOk, hasSession }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export function useSession() {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error("useSession must be used within SessionProvider");
  }
  return context;
}
