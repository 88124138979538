import { useState } from "react";
import { Container, Box, Typography, Tab } from "@mui/material";
import { TabContext, TabList, useTabContext } from "@mui/lab";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import NewMarketplace from "../../components/Marketplace/NewMarketplace";
import ListMarketplaces from "../../components/Marketplace/ListMarketplaces";
import { LogTrackUser } from "../../types/log-track-user/log-track-user";
function TabPanel(props: any) {
  const { children, className, value, index, ...other } = props;

  const context = useTabContext();

  if (context === null) {
    throw new TypeError("No TabContext provided");
  }
  const tabId = context.value;
  return (
    <Typography
      component="div"
      role="tabpanel"
      padding="24px"
      hidden={value !== tabId}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      //visibility={value === tabId ? "visible" : "hidden"}
      {...other}
    >
      {children}
    </Typography>
  );
}
export default function Marketplaces() {
  const [tabValue, setTabValue] = useState("1");
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////
  return (
    <Container
      component="main"
      maxWidth="xl"
      sx={{
        marginTop: 4,
      }}
    >
      <div>
        <TabContext value={tabValue}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              maxWidth: "xl",
              minWidth: 3 / 4,
            }}
          >
            <TabList
              onChange={handleChangeTab}
              centered
              aria-label="lab API tabs example"
            >
              <Tab label="List Marketplaces" value="1" />
              <Tab label="New Marketplace" value="2" />
            </TabList>
          </Box>
          <TabPanel
            value="1"
            sx={{
              width: "100%",
              typography: "body1",
              maxWidth: "xl",
              minWidth: 3 / 4,
              alignItems: "center",
            }}
          >
            <ListMarketplaces />
          </TabPanel>
          <TabPanel
            value="2"
            sx={{
              width: "100%",
              typography: "body1",
              maxWidth: "xl",
              minWidth: 3 / 4,
              alignItems: "center",
            }}
          >
            <NewMarketplace />
          </TabPanel>
        </TabContext>
      </div>
    </Container>
  );
}
