/* eslint-disable react-hooks/exhaustive-deps */

import {
  Box,
  TextField,
  Grid,
  Typography,
  CardMedia,
  InputLabel,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { LogTrackUser } from "../../../types/log-track-user/log-track-user";
import api from "../../../services/api";
import { AsyncPaginate } from "react-select-async-paginate";
import { MultiValue } from "react-select";

type ProductDescription = {
  product_id: number;
  language_id: number;
  name: string;
  description?: string;
  seo_keyword?: string;
  seo_url?: string;
  updated_at?: Date;
  created_at?: Date;
};

type ProductMeasurement = {
  product_id: number;
  weight: string;
  width: string;
  height: string;
  length: string;
  updated_at?: Date;
  created_at?: Date;
};

type Images = {
  product_id: number;
  image: string;
  sort_order?: number;
  updated_at?: Date;
  created_at?: Date;
};

type ProductCategoriesDescription = {
  category_id: number;
  language_id: number;
  name: string;
  meta_keyword: string;
  image: string;
  content_html: string;
  seo_url: string;
  created_at: Date;
  updated_at: Date;
};

type ProductCategories = {
  id: number;
  parent_id?: number;
  created_at: Date;
  updated_at: Date;
  descriptions: ProductCategoriesDescription[];
};

type ProductBrandDescriptions = {
  brand_id: number;
  language_id: number;
  name: string;
  content_html: string;
  meta_keyword: string;
  seo_url: string;
  created_at: Date;
  updated_at: Date;
};

type ProductBrand = {
  id: number;
  image: string;
  updated_at?: Date;
  created_at?: Date;
  descriptions?: ProductBrandDescriptions[];
};

type Product = {
  id: number;
  ysin: string;
  sku: string;
  barcode?: string;
  barcode_type?: string;
  image?: string;
  image2?: string;
  brand_id?: number;
  descriptions: ProductDescription[];
  product_measurement: ProductMeasurement;
  images?: Images[];
  categories?: ProductCategories[];
  brand?: ProductBrand;
  updated_at?: Date;
  created_at?: Date;
};

type MarketplaceAccount = {
  id: number;
  name: string;
  marketplace_code: string;
  website: string;
  logo: string;
  banner: string;
  currency_code: string;
  store_name: string;
  intro_text: string;
};

export default function MarketplaceProductTemplateMaker() {
  const [imageBaseURL, setimageBaseURL] = useState(
    process.env.REACT_APP_IMG_CATALOG_URL
  );

  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////

  const [totalItens, setTotalItens] = useState(0);

  const [marketplaceEbay, setMarketplaceEbay] = useState(true);
  const [marketplaceYahooAuctionJP, setMarketplaceYahooAuctionJP] =
    useState(false);

  const defaultAdditional = {
    page: 1,
  };

  const loadOptionsMarketplacesAccounts = async (search: any, page: any) => {
    const results = await api.get(
      `/marketplaces?page=${page}&search=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsMarketplacesAccounts = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsMarketplacesAccounts(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const [marketplacesAccounts, setMarketplacesAccounts] = useState<
    MarketplaceAccount[]
  >([]);
  const onChangeMarketplaceAccounts = async (
    newValue: MultiValue<MarketplaceAccount>
  ) => {
    setMarketplacesAccounts([...newValue]);
    // At the Moment, only Ebay is implemented. When Implementing new Marketplaces, change here too
    setTotalItens(newValue.length * products.length);
  };

  const loadOptionsProducts = async (search: any, page: any) => {
    const results = await api.get(
      `/products-custom?page=${page}&search=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsProducts = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsProducts(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const [products, setProducts] = useState<
    { id: number; sku: string; image: string }[]
  >([]);
  const onChangeProducts = async (
    newValue: MultiValue<{ id: number; sku: string; image: string }>
  ) => {
    setProducts([...newValue]);
    // At the Moment, only Ebay is implemented. When Implementing new Marketplaces, change here too
    setTotalItens(newValue.length * marketplacesAccounts.length);
  };

  const [showBanners, setShowBanners] = useState(false);

  const [introTextTop, setIntroTextTop] = useState(true);

  const fetchProductTemplateEbay = async (
    marketplaceAccount: MarketplaceAccount,
    id: number
  ) => {
    //setimageBaseURL(imageBaseURL);
    if (imageBaseURL?.substring(imageBaseURL?.length - 1) !== "/") {
      setimageBaseURL(imageBaseURL + "/");
    }

    const response = await api.get(`products-custom/${id}`);

    return await createEbayTemplate(marketplaceAccount, response.data);
  };

  const createEbayTemplate = async (
    markeplaceAccount: MarketplaceAccount,
    product: Product
  ) => {
    /*     let text = `<img src="${bannerImageLink}" "="" width="100%" border="0">\n`;
    text += "<br><br>\n"; */
    /*     let text =
      "<p>Welcome to BR Vintages, your premier destination for classic car parts and vehicles, specializing in iconic Volkswagen (VW) models. Immerse yourself in the world of automotive nostalgia with our curated collection. What sets BR Vintages apart is our dedication to providing unique VW parts at the best prices in the market. Whether you're restoring a vintage gem or adding to your collection, we offer a wide range of meticulously sourced parts, components and iconic vehicles. Our team of experts is committed to providing a personalized and customer-centric experience. Explore the elegance, charm, and craftsmanship of classic cars with BR Vintages—where the past meets the present, and driving becomes a celebration of history, style, and Classic Cars excellence.</p>";
    text += "<br>\n"; */
    let text = "";
    if (showBanners) {
      text += `<img src="${markeplaceAccount.banner}" "="" width="100%" border="0">\n<br><br>\n`;
    }
    if (introTextTop) {
      text += `<p>${markeplaceAccount.intro_text}</p><br>\n`;
    }
    text += `<table id="outer-table">
    <tbody><tr style="vertical-align:top;">
    <td>`;
    text += `<img src="${imageBaseURL}${product?.image}" width="500px">\n`;
    text += `</td>
    <td>
    <table id="inner-table">
    <tbody><tr id="desc-row">
    <td id="desc-box" colspan="3" style="width:50px">\n`;
    text += `<div style="font-size:1.7em; font-weight:bold; margin-bottom:.5em;">${product?.descriptions[0].name}</div>\n`;
    text += `<div>Sku # ${product?.sku}</div>\n`;
    text += `</td>
    </tr>
    <tr id="detail-row" style="background:#FF0000; color:white;">
    <td id="condition-cell" style="padding:.5em;font-size:.8em">
    <div style="font-weight:bold">Condition</div>
    <div>New</div>
    </td>
    <td id="brand-cell" style="padding:.5em;font-size:.8em">
    <div style="font-weight:bold">Brand</div>
    <div>${
      product?.brand?.descriptions && product?.brand?.descriptions[0]
        ? product?.brand?.descriptions[0]?.name
        : ""
    }</div>
    </td>
    <td id="uom-cell" style="padding:.5em;font-size:.8em">
    <div style="font-weight:bold">Size</div>`;
    text += `<div>${Number(product?.product_measurement?.length) / 10}cm x ${
      Number(product?.product_measurement?.width) / 10
    }cm (${Math.round(
      Number(product?.product_measurement?.length) / 25.2
    )}in x ${Math.round(
      Number(product?.product_measurement?.width) / 25.2
    )}in)</div>\n`;
    text += `</td>
    </tr>\n`;
    for (var i = 0; i < (product.images?.length || 0) + 2; i++) {
      if (i % 3 === 0) {
        if (i > 0) {
          text += `</tr>\n`;
        }
        text += `<tr id="image-row-1" class="image-row">\n`;
      }
      text += `<td style="width:150px;height:150px;padding:0px">\n`;
      if (i === 0) {
        text += `<img src="${imageBaseURL}${product.image}" width="150px">\n`;
      }
      if (i === 1) {
        text += `<img src="${imageBaseURL}${product.image2}" width="150px">\n`;
      } else {
        if (product && product.images && product?.images[i]) {
          text += `<img src="${imageBaseURL}${product?.images[i]?.image}" width="150px">\n`;
        }
      }

      text += `</td>\n`;
    }
    text += `</tr>\n`;
    text += `</tbody></table> <!-- end inner table -->
    </td>
    </tr>
    </tbody></table> <!-- end outer table -->
    <br>\n`;
    text += `<p>${String(product?.descriptions[0]?.description)
      .replace(/ \\ r \\ n/g, "<br>")
      .replace(/\\r\\n/g, "<br>")
      .replace(/(?:\r\n|\r|\n)/g, "<br>")}</p>\n`;
    if (showBanners) {
      text += `<br><br><br><img src="${markeplaceAccount.banner}" "="" alt="Footer" width="100%" border="0">\n<br><br>\n`;
    }
    /* text += `<br>
    <br>
    <br>
    <img src="${bannerImageLink}" alt="Footer" width="100%" border="0">\n`; */
    text += `<br>
    <p><b>CONDITIONS:</b></p>
    <p><b>Shipping Policy:</b></p>
    <p>In order to have faster service please pay for all items at the same time of purchase. If shipping rates do not combine properly, contact us or send a buyer requested total. You must contact us before making payment to combine your items. Failure to do so will result in separate shipping fees for each individual item. I am sorry but cannot combine your order if you have already paid.</p>
    <br>
    <p><b>Returns:</b></p>
    <p>We want you to be satisfied with your purchase. If within 60 days after you receive your shipment and you are not satisfied, you may return the item for a refund or an exchange. Please note all exchanged or returned merchandise must be in original factory condition with no modifications or alterations. You must ship orders back prepaid. We do not accept COD packages.  All exchanges need to have reshipping charges included. Please note there is no returns on special order, custom made, or closeout merchandise</p>
    <br>
    <p><b>Warranty:</b></p>
    <p>For warranty information please contact the product manufacturer directly. In some cases stock photos may not be an exact representation of the item. Please read all product info, descriptions and terms before purchasing.</p>
    <br>
    <p><b>Duties &amp; Taxes:</b></p>
    <p>You are responsible for all duties and taxes for your local area. This includes but not limited to international shipments. We can not quote you these fees, so you need to contact your local tax authority to confirm the amounts if any in your area.</p>
    <br>`;
    if (showBanners) {
      text += `<img src="${markeplaceAccount.banner}" "="" alt="Footer" width="100%" border="0">\n<br>`;
    }
    if (!introTextTop) {
      text += `<p>${markeplaceAccount.intro_text}</p><br>\n`;
    }
    /* <img src="${bannerImageLink}" alt="Footer" width="100%" border="0">` */

    return text;
  };

  const createYahooAuctionJPTemplate = async (
    markeplaceAccount: MarketplaceAccount,
    product: Product
  ) => {
    let text = "";
    if (showBanners) {
      text += `<img src="${markeplaceAccount.banner}" "="" width="100%" border="0">\n<br><br>\n`;
    }
    if (introTextTop) {
      text += `<p>${markeplaceAccount.intro_text}</p><br>\n`;
    }
    text += `<table id="outer-table">
    <tbody><tr style="vertical-align:top;">
    <td>`;
    text += `<img src="${imageBaseURL}${product?.image}" width="500px">\n`;
    text += `</td>
    <td>
    <table id="inner-table">
    <tbody><tr id="desc-row">
    <td id="desc-box" colspan="3" style="width:50px">\n`;
    text += `<div style="font-size:1.7em; font-weight:bold; margin-bottom:.5em;">${product?.descriptions[3].name}</div>\n`;
    text += `<div>SKU # ${product?.sku}</div>\n`;
    text += `</td>
    </tr>
    <tr id="detail-row" style="background:#FF0000; color:white;">
    <td id="condition-cell" style="padding:.5em;font-size:.8em">
    <div style="font-weight:bold">品質</div>
    <div>新品</div>
    </td>
    <td id="brand-cell" style="padding:.5em;font-size:.8em">
    <div style="font-weight:bold">ブランド</div>
        <div>${
          product?.brand?.descriptions && product?.brand?.descriptions[3]
            ? product?.brand?.descriptions[3]?.name
            : ""
        }</div>
    </td>
    <td id="uom-cell" style="padding:.5em;font-size:.8em">
    <div style="font-weight:bold">Size</div>`;
    text += `<div>${Number(product?.product_measurement?.length) / 10}cm x ${
      Number(product?.product_measurement?.width) / 10
    }cm (${Math.round(
      Number(product?.product_measurement?.length) / 25.2
    )}in x ${Math.round(
      Number(product?.product_measurement?.width) / 25.2
    )}in)</div>\n`;
    text += `</td>
    </tr>\n`;
    for (var i = 0; i < (product.images?.length || 0) + 2; i++) {
      if (i % 3 === 0) {
        if (i > 0) {
          text += `</tr>\n`;
        }
        text += `<tr id="image-row-1" class="image-row">\n`;
      }
      text += `<td style="width:150px;height:150px;padding:0px">\n`;
      if (i === 0) {
        text += `<img src="${imageBaseURL}${product.image}" width="150px">\n`;
      }
      if (i === 1) {
        text += `<img src="${imageBaseURL}${product.image2}" width="150px">\n`;
      } else {
        if (product && product.images && product?.images[i]) {
          text += `<img src="${imageBaseURL}${product?.images[i]?.image}" width="150px">\n`;
        }
      }

      text += `</td>\n`;
    }
    text += `</tr>\n`;
    text += `</tbody></table> <!-- end inner table -->
    </td>
    </tr>
    </tbody></table> <!-- end outer table -->
    <br>\n`;
    text += `<p>${String(product?.descriptions[3]?.description)
      .replace(/ \\ r \\ n/g, "<br>")
      .replace(/\\r\\n/g, "<br>")
      .replace(/(?:\r\n|\r|\n)/g, "<br>")}</p>\n`;
    if (showBanners) {
      text += `<br><br><br><img src="${markeplaceAccount.banner}" "="" alt="Footer" width="100%" border="0">\n<br><br>\n`;
    }
    /* text += `<br>
    <br>
    <br>
    <img src="${bannerImageLink}" alt="Footer" width="100%" border="0">\n`; */
    text += `<br>
    <p><b>まとめて取引 / 同梱発送:</b></p>
    <p>他の商品との同梱を希望する場合は「まとめて取引」のご利用をお願いいたします。
    <a href="https://support.yahoo-net.jp/PccAuctions/s/article/H000005231">https://support.yahoo-net.jp/PccAuctions/s/article/H000005231</a>
    をご覧ください。</p>
    <br>
    <p><b>支払詳細:</b></p>
    <p>■Yahoo!かんたん決済</p>
    <br>
    <p><b>発送詳細:</b></p>
    <p>●入金確認後、3日以内の発送となります。<br>
    (土曜・日曜・祝日をはさみますと、発送日が遅れる場合があります)</p>
    <br>
    <p><b>注意事項:</b></p>
    <p>■実際の色と異なって見える場合や、輸送中に多少の擦れや気にならない程度の傷などがある場合がございますので、予めご了承下さい。<br>
    <br>
    ■写真は通常詳細を示すために拡大されているため、リストに記載されている実際のサイズを考慮してください。<br>
    <br>
    ■完璧な設置には、優れた専門家を選ぶことが不可欠であることをすべてのお客様にアドバイスします。<br>
    <br>
    ■初期不良(破損など)があった場合は商品到着後、1週間以内にご連絡下さい。それ以降ですと対応出来かねますのでご了承下さい。<br>
    <br>
    ■オークション終了日時より48時間を経過しても、ご連絡がない場合や、落札後に、事前にお知らせのないままご連絡が取れない方のご入札はご遠慮ください。お取引を「キャンセル」とさせて頂きます。<br>
    <br>
    ◆いたずら防止のため、新規の方及び悪いの評価が多い方は、必ず、質問欄から購入意思がある旨をお知らせ下さい。<br>
    連絡無き場合は、入札や落札されても削除させて頂く場合があります。<br>
    <br>
    落札者様の都合になりますので、オークションシステムにより落札者様に「非常に悪い」評価が自動的に付きますので予めご了解願います。<br>
    </p>
    <br><br>`;
    if (showBanners) {
      text += `<img src="${markeplaceAccount.banner}" "="" alt="Footer" width="100%" border="0">\n<br>`;
    }
    if (!introTextTop) {
      text += `<p>${markeplaceAccount.intro_text}</p><br>\n`;
    }
    /* <img src="${bannerImageLink}" alt="Footer" width="100%" border="0">` */

    return text;
  };

  const fetchProductTemplateYahooAuctionJP = async (
    marketplaceAccount: MarketplaceAccount,
    id: number
  ) => {
    //setimageBaseURL(imageBaseURL);
    if (imageBaseURL?.substring(imageBaseURL?.length - 1) !== "/") {
      setimageBaseURL(imageBaseURL + "/");
    }

    const response = await api.get(`products-custom/${id}`);

    return await createYahooAuctionJPTemplate(
      marketplaceAccount,
      response.data
    );
  };

  const [htmlCode, setHTMLCode] = useState<string[]>([]);

  const updateProductTemplates = async () => {
    if (totalItens) {
      // Ebay Templates
      if (marketplaceEbay) {
        //Clear HTML Code Array
        setHTMLCode([]);
        let text = [];
        // Do a loop for every Marketplace account
        for (var iEbay = 0; iEbay < marketplacesAccounts.length; iEbay++) {
          //THen a  loop for every Product
          for (var jEbay = 0; jEbay < products.length; jEbay++) {
            text.push(
              await fetchProductTemplateEbay(
                marketplacesAccounts[iEbay],
                products[jEbay].id
              )
            );
          }
        }
        setHTMLCode(text);
      }

      if (marketplaceYahooAuctionJP) {
        //Clear HTML Code Array
        setHTMLCode([]);
        let text = [];
        // Do a loop for every Marketplace account
        for (
          var iYahooAuctionJP = 0;
          iYahooAuctionJP < marketplacesAccounts.length;
          iYahooAuctionJP++
        ) {
          //THen a  loop for every Product
          for (
            var jYahooAuctionJP = 0;
            jYahooAuctionJP < products.length;
            jYahooAuctionJP++
          ) {
            text.push(
              await fetchProductTemplateYahooAuctionJP(
                marketplacesAccounts[iYahooAuctionJP],
                products[jYahooAuctionJP].id
              )
            );
          }
        }
        setHTMLCode(text);
      }
    }
  };

  useEffect(() => {
    // fetchSpecifications(); //It must be loaded first the specifications because it is made a loop based on the valueSpecifications first
    //fetchBrand();
    //fetchCategories();
    //fetchAttributes();
    // fetchProduct();
    updateProductTemplates();
  }, [
    totalItens,
    showBanners,
    introTextTop,
    marketplaceEbay,
    marketplaceYahooAuctionJP,
  ]);

  useEffect(() => {}, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <Typography
            component="h1"
            variant="h5"
            sx={{
              paddingTop: "40px",
              fontWeight: "bold",
              fontSize: "32px",
              color: "#556cd6",
              paddingLeft: "20px",
            }}
          >
            Export Product for Marketplaces
          </Typography>
          <Box
            sx={{
              width: "100%",
              typography: "body1",
              maxWidth: "xl",
              minWidth: 2 / 4,
              marginRight: 1,
              marginLeft: 1,
              alignItems: "center",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <InputLabel id="marketplaces-label">
                Marketplaces (Activate Only one at time):
              </InputLabel>
              <FormControlLabel
                label="Ebay"
                control={
                  <Checkbox
                    checked={marketplaceEbay}
                    onChange={(e) => setMarketplaceEbay(e.target.checked)}
                    inputProps={{ "aria-label": "Ebay?" }}
                  />
                }
              />
              <FormControlLabel
                label="Yahoo Auction JP"
                control={
                  <Checkbox
                    checked={marketplaceYahooAuctionJP}
                    onChange={(e) =>
                      setMarketplaceYahooAuctionJP(e.target.checked)
                    }
                    inputProps={{ "aria-label": "Yahoo Auction JP?" }}
                  />
                }
              />
            </Box>
            <InputLabel id="marketplaces-accounts-label">
              Marketplaces Accounts:
            </InputLabel>
            <AsyncPaginate
              isMulti
              closeMenuOnSelect={false}
              placeholder="Select Marketplaces Accounts"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              additional={defaultAdditional}
              key={"marketplaces-accounts"}
              id="marketplaces-accounts"
              name="marketplaces-accounts"
              getOptionValue={(option: any) => option?.id}
              getOptionLabel={(option: any) => option?.marketplace_code}
              loadOptions={loadPageOptionsMarketplacesAccounts}
              value={marketplacesAccounts}
              onChange={onChangeMarketplaceAccounts}
            />
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <InputLabel id="banners-label">Show Banners?</InputLabel>
              <FormControlLabel
                label=""
                control={
                  <Checkbox
                    checked={showBanners}
                    onChange={(e) => setShowBanners(e.target.checked)}
                    inputProps={{ "aria-label": "Show Banners?" }}
                  />
                }
              />
              <InputLabel id="intro-text-label">
                Show Intro Text at the Top?
              </InputLabel>
              <FormControlLabel
                label=""
                control={
                  <Checkbox
                    checked={introTextTop}
                    onChange={(e) => setIntroTextTop(e.target.checked)}
                    inputProps={{ "aria-label": "Show Intro Text at the Top?" }}
                  />
                }
              />
            </Box>
            <InputLabel id="products-select">Select Products</InputLabel>
            <AsyncPaginate
              isMulti
              closeMenuOnSelect={false}
              placeholder="Select Products"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              additional={defaultAdditional}
              key={"product_ids"}
              id="products"
              name="products"
              getOptionValue={(option: any) => option?.id}
              getOptionLabel={(option: any) => option?.sku}
              loadOptions={loadPageOptionsProducts}
              value={products}
              onChange={onChangeProducts}
              // onInputChange={asyncPaginateInputChange}
            />
            {(marketplaceEbay || marketplaceYahooAuctionJP) &&
              marketplacesAccounts.map(
                (inputMarketplaceAccount, indexMarketplaceAccount) => {
                  return (
                    <div key={indexMarketplaceAccount}>
                      <div>
                        <br></br>
                        <b>
                          Marketplace Account:{" "}
                          {inputMarketplaceAccount.marketplace_code}
                        </b>{" "}
                        / Currency: {inputMarketplaceAccount.currency_code}
                        <br></br>
                        <b>Intro Text: </b>
                        {inputMarketplaceAccount.intro_text}
                        <br></br>
                        <br></br>
                        <b>Banner Image Link: </b>
                        {inputMarketplaceAccount.banner}
                        <br></br>
                        <CardMedia
                          component="img"
                          sx={{ width: "75%" }}
                          image={inputMarketplaceAccount.banner}
                          alt={"image"}
                        />
                        <br></br>
                        <b>Logo Image Link: </b>
                        {inputMarketplaceAccount.logo}
                        <br></br>
                        <CardMedia
                          component="img"
                          sx={{ width: "20%" }}
                          image={inputMarketplaceAccount.logo}
                          alt={"image"}
                        />
                      </div>
                      {products.map((input, index) => {
                        return (
                          <div key={index}>
                            <br></br>
                            Product: {input.id} / SKU: {input.sku}
                            <br></br>
                            <CardMedia
                              component="img"
                              sx={{ width: 100, display: "inline" }}
                              image={`${imageBaseURL}${input?.image}`}
                              alt={"image"}
                            />
                            <br></br>
                            <Box sx={{ width: "100%", typography: "body1" }}>
                              <p>HTML Code:</p>
                              <TextField
                                sx={{
                                  mt: 1,
                                  minWidth: 3 / 4,
                                  marginLeft: "8px",
                                }}
                                slotProps={{ inputLabel: { shrink: true } }}
                                margin="normal"
                                fullWidth
                                id="HTMLCode"
                                type="text"
                                label="HTML Code"
                                multiline
                                rows={15}
                                value={
                                  htmlCode[
                                    indexMarketplaceAccount * products.length +
                                      index
                                  ]
                                }
                              />
                            </Box>
                          </div>
                        );
                      })}
                    </div>
                  );
                }
              )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
