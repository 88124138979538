/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import {
  DataGrid,
  GridRowModel,
  GridColDef,
  GridRowId,
  GridActionsCellItem,
  GridRowModesModel,
  GridRowModes,
  GridEventListener,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
/* import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions"; */
import Button from "@mui/material/Button";
import Alert, { AlertProps } from "@mui/material/Alert";
import { LogTrackUser } from "../../../types/log-track-user/log-track-user";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "../../../services/api";
import {
  Box,
  CardMedia,
  Container,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { AsyncPaginate } from "react-select-async-paginate";
import Cookies from "js-cookie";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";

/* interface ProductMarketplaces {
  id: number;
  product_id: number;
  sku: string;
  image: string;
  marketplace_id: number;
  marketplace_code: string;
  listing_link: string;
  currency_code: string;
  price: number;
  shipping_cost: number;
  quantity: number;
  description: string;
  last_change_username: string;
  created_at: Date;
  updated_at: Date;
} */

/* const useExecuteMutation = () => {
  return React.useCallback(
    (productMarketplace: Partial<ProductMarketplaces>) =>
      new Promise<Partial<ProductMarketplaces>>((resolve, reject) =>
        setTimeout(() => {
          if (
            productMarketplace.product_id === 0 ||
            productMarketplace.marketplace_id === 0
          ) {
            reject();
          } else {
            resolve(productMarketplace);
          }
        }, 200)
      ),
    []
  );
}; */

/* function computeMutation(newRow: GridRowModel, oldRow: GridRowModel) {
  //Link Section
  if (newRow.listing_link !== oldRow.listing_link) {
    return `Product: '${oldRow.sku}', Marketplace: '${oldRow.marketplace_code}' Listing Link from '${oldRow.listing_link}' to '${newRow.listing_link}'`;
  }

  //Price Section
  if (newRow.price !== oldRow.price) {
    return `Product: '${oldRow.sku}', Marketplace: '${oldRow.marketplace_code}' Price from '${oldRow.price}' to '${newRow.price}'`;
  }

  //Shipping Cost Section
  if (newRow.shipping_cost !== oldRow.shipping_cost) {
    return `Product: '${oldRow.sku}', Marketplace: '${oldRow.marketplace_code}' Shipping Cost from '${oldRow.shipping_cost}' to '${newRow.shipping_cost}'`;
  }

  //Quantity Section
  if (newRow.quantity !== oldRow.quantity) {
    return `Product: '${oldRow.sku}', Marketplace: '${oldRow.marketplace_code}' Shipping Cost from '${oldRow.quantity}' to '${newRow.quantity}'`;
  }

  return null;
} */

export default function ListProductMarketplaces() {
  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////

  const [loading, setLoading] = useState(false);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 100,
  });
  const [rowCountState, setRowCountState] = React.useState(0);

  const [productMarketplaces, setProductMarketplaces] = useState<
    {
      id: number;
      product_id: number;
      sku: string;
      image: string;
      marketplace_id: number;
      marketplace_code: string;
      listing_link: string;
      currency_code: string;
      price: number;
      shipping_cost: number;
      quantity: number;
      description: string;
      last_change_username: string;
      created_at: Date;
      updated_at: Date;
    }[]
  >([]);

  const fetchData = async (page = 1, numberItemsPerPage = 100) => {
    await api
      .get(
        `/product-marketplaces?page=${page}&limit=${numberItemsPerPage}
        &product_id=${productFilter?.id}&product_sku${product_SKUFilter}&marketplace_id=${marketplaceFilter?.id}
        &search=${searchFilter}&currency_id=${currencyFilter?.id}&sort=${sort}&asc_desc=${asc_desc}`
      )
      .then((res) => {
        setProductMarketplaces(res.data.items);
        //setRows(res.data.items);
        //setTotalPages(res.data.total_pages);
        setRowCountState(res.data.total);
        //setPageState(0);
      });
  };

  //Filters
  const [productFilter, setProductFilter] = useState<{
    id: number;
    sku: string;
    image: string;
  } | null>(null);

  const [product_SKUFilter, setProduct_SKUFilter] = useState("");

  const [marketplaceFilter, setMarketplaceFilter] = useState<{
    id: number;
    code: string;
  } | null>(null);

  const [searchFilter, setSearchFilter] = useState("");

  const [currencyFilter, setCurrencyFilter] = useState<{
    id: number;
    code: string;
  } | null>(null);

  const [sort, setSort] = useState("id");

  const [asc_desc, setAsc_Desc] = useState("desc");

  const handleSearchClick = () => {
    // fetchData(1, Number(numberItemsPerPage));
    fetchData();
  };

  //New Product Marketplace Section
  const [newProductMarketplaceProduct, setNewProductMarketplaceProduct] =
    useState<{
      id: number;
      sku: string;
      image: string;
    } | null>(null);
  const [
    newProductMarketplaceMarketplace,
    setNewProductMarketplaceMarketplace,
  ] = useState<{
    id: number;
    marketplace_code: string;
  } | null>(null);
  const [
    newProductMarketplaceListingLink,
    setNewProductMarketplaceListingLink,
  ] = useState("");
  const [newProductMarketplacePrice, setNewProductMarketplacePrice] =
    useState(0);
  const [
    newProductMarketplaceShippingCost,
    setNewProductMarketplaceShippingCost,
  ] = useState(0);
  const [newProductMarketplaceQuantity, setNewProductMarketplaceQuantity] =
    useState(0);
  const [
    newProductMarketplaceDescription,
    setNewProductMarketplaceDescription,
  ] = useState("");

  const loadOptionsProducts = async (search: any, page: any) => {
    const results = await api.get(
      `/products-custom?page=${page}&search=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsProducts = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsProducts(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadOptionsMarketplaces = async (search: any, page: any) => {
    const results = await api.get(
      `/marketplaces?page=${page}&search=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsMarketplaces = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsMarketplaces(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadOptionsCurrencies = async (search: any, page: any) => {
    const results = await api.get(`/currencies?page=${page}&search=${search}`);
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsCurrencies = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsCurrencies(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const defaultAdditional = {
    page: 1,
  };

  const handleNewClick = async () => {
    if (
      newProductMarketplaceProduct &&
      newProductMarketplaceMarketplace &&
      newProductMarketplaceListingLink
    ) {
      let productMarketplace = {} as any;
      productMarketplace.product_id = newProductMarketplaceProduct?.id;
      productMarketplace.marketplace_id = newProductMarketplaceMarketplace?.id;
      productMarketplace.listing_link = newProductMarketplaceListingLink;
      productMarketplace.price = newProductMarketplacePrice;
      productMarketplace.shipping_cost = newProductMarketplaceShippingCost;
      productMarketplace.quantity = newProductMarketplaceQuantity;
      productMarketplace.description = newProductMarketplaceDescription;
      productMarketplace.last_change_username = Cookies.get("username") || "";

      api
        .post(`product-marketplaces`, productMarketplace)
        .then((response) => {
          /* Swal.fire({
            title: "Product Marketplace Created!",
            icon: "success",
            confirmButtonText: "Yes",
          }); */
          setSnackbar({
            children: "Product Marketplace successfully Created",
            severity: "success",
          });
          // fetchData(pageState, Number(numberItemsPerPage));
          fetchData();
        })
        .catch((error) => {
          /* Swal.fire({
            title: "Ops...",
            icon: "error",
            text: error.response.data.message,
            confirmButtonText: "Yes",
          }); */
          setSnackbar({
            children: error.response.data.message,
            severity: "error",
          });
        });
    } else {
      /* Swal.fire({
        title: "Ops...",
        icon: "error",
        text: "Product, Marketplace and Listing Link must be defined!!!",
        confirmButtonText: "Yes",
      }); */
      setSnackbar({
        children: "Product, Marketplace and Listing Link must be defined!!!",
        severity: "error",
      });
    }
  };

  //DATA GRID SECTION
  /*   const mutateRow = useExecuteMutation();
  const noButtonRef = React.useRef<HTMLButtonElement>(null);
  const [promiseArguments, setPromiseArguments] = React.useState<any>(null); */

  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  /*   const processRowUpdate = React.useCallback(
    (newRow: GridRowModel, oldRow: GridRowModel) =>
      new Promise<GridRowModel>((resolve, reject) => {
        const mutation = computeMutation(newRow, oldRow);
        if (mutation) {
          // Save the arguments to resolve or reject the promise later
          setPromiseArguments({ resolve, reject, newRow, oldRow });
        } else {
          resolve(oldRow); // Nothing was changed
        }
      }),
    []
  ); */

  /*   const handleNo = () => {
    const { oldRow, resolve } = promiseArguments;
    resolve(oldRow); // Resolve with the old row to not update the internal state
    setPromiseArguments(null);
  }; */

  const handleYes = async (newRow: GridRowModel) => {
    // const { newRow, oldRow, reject, resolve } = promiseArguments;Posso pagar agor
    try {
      // Make the HTTP request to save in the backend
      let productMarketplaceUpdate = {} as any;
      productMarketplaceUpdate.product_id = newRow.product_id;
      productMarketplaceUpdate.marketplace_id = newRow.marketplace_id;
      productMarketplaceUpdate.listing_link = newRow.listing_link;
      productMarketplaceUpdate.price = Number(newRow.price);
      productMarketplaceUpdate.shipping_cost = Number(newRow.shipping_cost);
      productMarketplaceUpdate.quantity = newRow.quantity;
      productMarketplaceUpdate.description = newRow.description;
      productMarketplaceUpdate.last_change_username =
        Cookies.get("username") || "";

      await api
        .put(`product-marketplaces/${newRow?.id}`, productMarketplaceUpdate)
        .then((response) => {
          setSnackbar({
            children: "Product Marketplace successfully Saved",
            severity: "success",
          });
        })
        .catch((error) => {
          setSnackbar({
            children: error.response.data.message,
            severity: "error",
          });
        });

      return newRow;
      // const response = await mutateRow(newRow);
      // resolve(response);
    } catch (error) {
      setSnackbar({
        children: "Error when saving Product Marketplace",
        severity: "error",
      });
      // reject(oldRow);
    }
  };

  const handleDelete = async (id: GridRowId) => {
    // const { newRow, oldRow, reject, resolve } = promiseArguments;Posso pagar agor
    try {
      await api
        .delete(`product-marketplaces/${id}`)
        .then((response) => {
          setSnackbar({
            children: "Product Marketplace Row successfully Deleted",
            severity: "success",
          });
        })
        .catch((error) => {
          setSnackbar({
            children: error.response.data.message,
            severity: "error",
          });
        });

      return true;
      // const response = await mutateRow(newRow);
      // resolve(response);
    } catch (error) {
      setSnackbar({
        children: "Error when Deleting Product Marketplace Row",
        severity: "error",
      });
      // reject(oldRow);
    }
  };

  /*   const handleEntered = () => {
    // The `autoFocus` is not used because, if used, the same Enter that saves
    // the cell triggers "No". Instead, we manually focus the "No" button once
    // the dialog is fully open.
    // noButtonRef.current?.focus();
  }; */

  /*   const renderConfirmDialog = () => {
    if (!promiseArguments) {
      return null;
    }

    const { newRow, oldRow } = promiseArguments;
    const mutation = computeMutation(newRow, oldRow);

    return (
      <Dialog
        maxWidth="xs"
        TransitionProps={{ onEntered: handleEntered }}
        open={!!promiseArguments}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent dividers>
          {`Pressing 'Yes' will change ${mutation}.`}
        </DialogContent>
        <DialogActions>
          <Button ref={noButtonRef} onClick={handleNo}>
            No
          </Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  }; */

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    console.log(id);
    setProductMarketplaces(productMarketplaces.filter((row) => row.id !== id));
    handleDelete(id);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    // setProductMarketplaces(productMarketplaces.filter((row) => row.id !== id));
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  //Image Folder
  const imageBaseURL = process.env.REACT_APP_IMG_CATALOG_URL;

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 30, editable: false },
    {
      field: "product_id",
      headerName: "Product ID",
      width: 100,
      editable: false,
    },
    { field: "sku", headerName: "Product SKU", width: 180, editable: false },
    {
      field: "image",
      headerName: "Image",
      width: 100,
      //renderCell: (params) => <img alt={params.value} src={imageBaseURL+params.value} />
      renderCell: (params) => {
        return (
          <CardMedia
            component="img"
            sx={{ width: 100, display: "inline" }}
            image={`${imageBaseURL}${params.value}`}
            alt={"image"}
          />
        );
      },
    }, // renderCell will render the component },
    {
      field: "marketplace_code",
      headerName: "Marketplace",
      width: 150,
      editable: false,
    },
    {
      field: "currency_code",
      headerName: "Currency",
      width: 100,
      editable: false,
    },
    {
      field: "listing_link",
      headerName: "Listing Link",
      type: "string",
      width: 200,
      editable: true,
    },
    {
      field: "price",
      headerName: "Price",
      type: "number",
      width: 100,
      editable: true,
      valueFormatter: (value) =>
        value === null ? "" : currencyFormatter.format(value),
    },
    {
      field: "shipping_cost",
      headerName: "Shipping Cost",
      type: "number",
      width: 150,
      editable: true,
      valueFormatter: (value) =>
        value === null ? "" : currencyFormatter.format(value),
    },
    {
      field: "quantity",
      headerName: "Quantity",
      type: "number",
      width: 100,
      editable: true,
    },
    {
      field: "description",
      headerName: "Description",
      type: "string",
      width: 500,
      editable: true,
    },
    {
      field: "last_change_username",
      headerName: "Last Change by",
      type: "string",
      width: 150,
      editable: false,
    },
    {
      field: "updated_at",
      headerName: "Last Change at:",
      type: "string",
      width: 150,
      editable: false,
      valueFormatter: (params) =>
        params === null ? "" : moment(params)?.format("DD/MM/YYYY hh:mm:ss"), // This is needed in order to show adjusted to the local time
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    /*   {
      field: 'lastLogin',
      headerName: 'Last Login',
      type: 'dateTime',
      width: 220,
    }, */
  ];

  //LOAD INITIAL VALUES
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);
      fetchData(paginationModel.page + 1, paginationModel.pageSize);

      if (!active) {
        return;
      }

      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [paginationModel.page]);

  return (
    <Container maxWidth="xl" sx={{ width: "100%", mt: 2, mb: 4 }}>
      <Typography
        component="h1"
        variant="h5"
        sx={{
          paddingTop: "40px",
          fontWeight: "bold",
          fontSize: "32px",
          color: "#556cd6",
          paddingLeft: "20px",
        }}
      >
        Product & Marketplaces Prices & Info
      </Typography>
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
            minWidth: 1 / 8,
          }}
        >
          <InputLabel id="search-filter-label">Filters: Search</InputLabel>
          <TextField
            id={"searchFilter"}
            type="text"
            label="Search filter"
            value={searchFilter}
            onChange={(e) => setSearchFilter(e.target.value)}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
            minWidth: 1 / 8,
          }}
        >
          <InputLabel id="product-sku-filter-label">Product SKU</InputLabel>
          <TextField
            id={"product_SKU"}
            type="text"
            label="Product SKU filter"
            value={product_SKUFilter}
            onChange={(e) => setProduct_SKUFilter(e.target.value)}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
            minWidth: 1 / 6,
          }}
        >
          <InputLabel id="product-filter-label">Product:</InputLabel>
          <AsyncPaginate
            placeholder="Select Product"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"productFilter"}
            id="productFilter"
            name="productFilter"
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.sku}
            loadOptions={loadPageOptionsProducts}
            value={productFilter}
            onChange={setProductFilter}
          />
          <CardMedia
            component="img"
            sx={{ width: 100, display: "inline" }}
            image={`${imageBaseURL}${productFilter?.image}`}
            alt={"image"}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
            minWidth: 1 / 6,
          }}
        >
          <InputLabel id="marketplace-filter-label">Marketplace:</InputLabel>
          <AsyncPaginate
            placeholder="Select Marketplace"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"marketplaceFilter"}
            id="marketplaceFilter"
            name="marketplaceFilter"
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.marketplace_code}
            loadOptions={loadPageOptionsMarketplaces}
            value={marketplaceFilter}
            onChange={setMarketplaceFilter}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
            minWidth: 1 / 8,
          }}
        >
          <InputLabel id="currency-filter-label">Currency:</InputLabel>
          <AsyncPaginate
            placeholder="Select Currency"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"currencyFilter"}
            id="currencyFilter"
            name="currencyFilter"
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.code}
            loadOptions={loadPageOptionsCurrencies}
            value={currencyFilter}
            onChange={setCurrencyFilter}
          />
        </Box>

        <Button
          variant="contained"
          sx={{ m: 3 }}
          href=""
          onClick={handleSearchClick}
        >
          Filter
        </Button>
      </Box>
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
            minWidth: 1 / 6,
          }}
        >
          <InputLabel id="store-select-label">Sort:</InputLabel>
          <Select
            labelId="sort-select-label"
            id="sort"
            value={String(sort)}
            label="Store"
            onChange={(e) => setSort(e.target.value)}
          >
            <MenuItem value={"id"} key={"id"}>
              ID
            </MenuItem>
            <MenuItem value={"product_id"} key={"product_id"}>
              Product ID
            </MenuItem>
            <MenuItem value={"marketplace_id"} key={"marketplace_id"}>
              Marketplace ID
            </MenuItem>
            <MenuItem value={"price"} key={"price"}>
              Price
            </MenuItem>
            <MenuItem value={"shipping_cost"} key={"shipping_cost"}>
              Shipping Cost
            </MenuItem>
            <MenuItem value={"quantity"} key={"quantity"}>
              Quantity
            </MenuItem>
            <MenuItem
              value={"last_change_username"}
              key={"last_change_username"}
            >
              Last Change Username
            </MenuItem>
            <MenuItem value={"updated_at"} key={"updated_at"}>
              Updated At
            </MenuItem>
            <MenuItem value={"created_at"} key={"created_at"}>
              Created At
            </MenuItem>
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
            minWidth: 1 / 8,
          }}
        >
          <InputLabel id="store-select-label">
            Ascending / Descending:
          </InputLabel>
          <Select
            labelId="asc_desc-select-label"
            id="asc_desc"
            value={String(asc_desc)}
            label="Ascending / Descending"
            onChange={(e) => setAsc_Desc(e.target.value)}
          >
            <MenuItem value={"asc"} key={"asc"}>
              Ascending
            </MenuItem>
            <MenuItem value={"desc"} key={"desc"}>
              Descending
            </MenuItem>
          </Select>
        </Box>
        <Button
          variant="contained"
          sx={{ m: 3 }}
          href=""
          onClick={handleSearchClick}
        >
          Sort
        </Button>
      </Box>
      <p>
        <br></br>
      </p>
      To insert a new Entry, please fill the fields below and press the Insert
      Button
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
            minWidth: 1 / 6,
          }}
        >
          <InputLabel id="productmarketplaces-product-label">
            Product:
          </InputLabel>
          <AsyncPaginate
            placeholder="Select Product"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"productmarketplaces_product"}
            id="productmarketplaces_product"
            name="productmarketplaces_product"
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.sku}
            loadOptions={loadPageOptionsProducts}
            value={newProductMarketplaceProduct}
            onChange={setNewProductMarketplaceProduct}
          />
          <CardMedia
            component="img"
            sx={{ width: 100, display: "inline" }}
            image={`${imageBaseURL}${newProductMarketplaceProduct?.image}`}
            alt={"image"}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
            minWidth: 1 / 8,
          }}
        >
          <InputLabel id="productstore-store-label">Marketplace:</InputLabel>
          <AsyncPaginate
            placeholder="Select Marketplace"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"productmarketplace_marketplace"}
            id="productmarketplace_marketplace"
            name="productmarketplace_marketplace"
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.marketplace_code}
            loadOptions={loadPageOptionsMarketplaces}
            value={newProductMarketplaceMarketplace}
            onChange={setNewProductMarketplaceMarketplace}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 1,
            marginRight: "8px",
            minWidth: 1 / 6,
          }}
        >
          <InputLabel id="listing_link-label">Listing Link:</InputLabel>
          <TextField
            id={"productmarketplace_listing_link"}
            type="text"
            label="Listing Link"
            value={newProductMarketplaceListingLink}
            onChange={(event) =>
              setNewProductMarketplaceListingLink(event.target.value)
            }
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 1,
            marginRight: "8px",
            maxWidth: 1 / 16,
          }}
        >
          <InputLabel id="price-label">Price:</InputLabel>
          <TextField
            id={"productmarketplace_price"}
            type="number"
            label="Price"
            value={newProductMarketplacePrice}
            onChange={(event) =>
              setNewProductMarketplacePrice(Number(event.target.value))
            }
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 1,
            marginRight: "8px",
            maxWidth: 1 / 12,
          }}
        >
          <InputLabel id="shipping_cost-label">Shipping Cost:</InputLabel>
          <TextField
            id={"productmarketplace_shipping_cost"}
            type="number"
            label="Shipping Cost"
            value={newProductMarketplaceShippingCost}
            onChange={(event) =>
              setNewProductMarketplaceShippingCost(Number(event.target.value))
            }
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 1,
            marginRight: "8px",
            maxWidth: 1 / 16,
          }}
        >
          <InputLabel id="quantity-label">Quantity:</InputLabel>
          <TextField
            id={"productmarketplace_quantity"}
            type="number"
            label="Quantity"
            value={newProductMarketplaceQuantity}
            onChange={(event) =>
              setNewProductMarketplaceQuantity(Number(event.target.value))
            }
          />
        </Box>

        <Button
          variant="contained"
          sx={{ m: 3 }}
          href=""
          onClick={handleNewClick}
        >
          New Entry
        </Button>
      </Box>
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <TextField
          id={"productmarketplace_description"}
          type="text"
          label="Description"
          fullWidth
          rows={7}
          multiline
          value={newProductMarketplaceDescription}
          onChange={(event) =>
            setNewProductMarketplaceDescription(event.target.value)
          }
        />
      </Box>
      <p>
        <br></br>
      </p>
      Everything Except Product and Marketplace Info are changeable. To change
      any of it, DOUBLE CLICK on the row field.
      <div style={{ height: 600, width: "100%" }}>
        <DataGrid
          editMode="row"
          rows={productMarketplaces}
          columns={columns}
          // processRowUpdate={processRowUpdate}
          processRowUpdate={(updatedRow, originalRow) => handleYes(updatedRow)}
          onProcessRowUpdateError={(error) => console.log(error)}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          initialState={{
            pagination: {
              paginationModel,
            },
          }}
          pagination
          paginationModel={paginationModel}
          pageSizeOptions={[25, 50, 100]}
          rowCount={rowCountState}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          loading={loading}
        />
        {!!snackbar && (
          <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </div>
    </Container>
  );
}
