import axios from "axios";
// import Cookies from "js-cookie";

export default axios.create({
  /*   baseURL: String(process.env.REACT_APP_ERP_URL_API),
  headers: {
    Authorization: token ? `Bearer ${token}` : "",
    accept: "application/json",
    "accept-language-id": "1", // Default Language is English: 1 // To change the chosen language, just need to change it here later
  }, */
});
//axios.defaults.withCredentials = true;
// axios.defaults.withCredentials = false;
