/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Container,
  Pagination,
  Box,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  TextField,
  Button,
} from "@mui/material";
import TableHorizontal from "../../TableHorizontal";
import api from "../../../services/api";
export default function ListCustomers() {
  const [pageState, setPageState] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [users, setUsers] = useState([]);
  const columns = ["ID", "Name", "Email", "Phone Number" , "Birth Date", "Newsletter", "Language", "Currency", "Status", "Actions", "Delete"];
  const keys = [
    "id",
    "name",
    "email",
    "phone_number",
    "birth_date",
    "newsletter_text",
    "language",
    "currency",
    "status_text",
    "action",
    "delete",
  ];
  const fetchData = async (
    page = 1,
    numberItemsPerPage = 500
  ) => {
    await api
      .get(
        `/customers?page=${page}&limit=${numberItemsPerPage}&search=${search}`
      )
      .then((res) => {
        setUsers(res.data.items);
        setTotalPages(res.data.total_pages);
      });
  };
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageState(value);
    fetchData(value, Number(numberItemsPerPage));
  };

  const [numberItemsPerPage, setNumberItemsPerPage] = useState("500");
  const handleNumberItemsPerPage = (event: SelectChangeEvent) => {
    setNumberItemsPerPage(event.target.value);
    fetchData(1, Number(event.target.value));
  };

  const [search, setSearch] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const handleSearchClick = () => {
    fetchData(1);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
    <Box style={{ display: "flex", flexDirection:"row" }}>
      <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginRight: "8px",
        marginLeft: 1,
      }}
      >
    <InputLabel id="number-items-page-label">
      Number of Items per Page
    </InputLabel>
    <Select
      labelId="number-items-page-select-label"
      id="number-items-page"
      value={numberItemsPerPage}
      label="Number of Items per Page"
      onChange={handleNumberItemsPerPage}
    >
      <MenuItem value={100}>100</MenuItem>
      <MenuItem value={250}>250</MenuItem>
      <MenuItem value={500}>500</MenuItem>
      <MenuItem value={750}>750</MenuItem>
      <MenuItem value={1000}>1000</MenuItem>
    </Select>
    </Box>
    <Box
    sx={{
      display: "flex",
      flexDirection: "column",
    }}
    >
      <InputLabel id="email-label">
    E-Mail
    </InputLabel>
    <TextField
      sx={{ minWidth: 1 / 4, marginLeft: "8px" }}
      id={"email-input"}
      type="text"
      label="Email"
      defaultValue={email}
      onBlur={(e) => setEmail(e.target.value)}
    />
    </Box>
    <Box
    sx={{
      display: "flex",
      flexDirection: "column",
    }}
    >
      <InputLabel id="name-label">
    Name
    </InputLabel>
    <TextField
      sx={{ minWidth: 1 / 4, marginLeft: "8px" }}
      id={"name-input"}
      type="text"
      label="Name"
      defaultValue={name}
      onBlur={(e) => setName(e.target.value)}
    />
    </Box>
    <Box
    sx={{
      display: "flex",
      flexDirection: "column",
    }}
    >
      <InputLabel id="search-label">
    Search
    </InputLabel>
    <TextField
      sx={{ minWidth: 1 / 4, marginLeft: "8px" }}
      id={"search-input"}
      type="text"
      label="Search"
      defaultValue={search}
      onBlur={(e) => setSearch(e.target.value)}
    />
    </Box>
    <Button
      variant="contained"
      sx={{ m: 3 }}
      href=""
      onClick={handleSearchClick}
    >
      Filter
    </Button>
    </Box>
    <TableHorizontal
      screen={"customers"}
      columns={columns}
      data={users}
      objectKey={keys}
    />
    <Box
      display="flex"
      width={"100%"}
      height={80}
      alignItems="center"
      justifyContent="center"
    >
      <Pagination
        count={totalPages}
        page={pageState}
        onChange={handleChange}
      />
    </Box>
  </Container>
  );
}