import {
  Box,
  Button,
  TextField,
  Stack,
  Grid,
  Tab,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import { useForm } from "react-hook-form";
import { useState } from "react";
import api from "../../../../services/api";
//import Swal from "sweetalert2";
import { AsyncPaginate } from "react-select-async-paginate";
import { inputsName } from "../../../../config/languageConst";
import { useLocation } from "react-router-dom";
import { TabContext, TabList, useTabContext } from "@mui/lab";
import { MultiValue } from "react-select";
import DeleteIcon from "@mui/icons-material/Delete";
import { LogTrackUser } from "../../../../types/log-track-user/log-track-user";
import Cookies from "js-cookie";

//I HAD to declare TabPanel like this to prevent Lazy Loading on the TabPanels.
//DUe to Lazy loading, the data form the DB was not being filled into the fields in the other tabs
function TabPanel(props: any) {
  const { children, className, value, index, ...other } = props;

  const context = useTabContext();

  if (context === null) {
    throw new TypeError("No TabContext provided");
  }
  const tabId = context.value;

  return (
    <Typography
      component="div"
      role="tabpanel"
      padding="24px"
      hidden={value !== tabId}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      //visibility={value === tabId ? "visible" : "hidden"}
      {...other}
    >
      {children}
    </Typography>
  );
}

export default function EditProductsBatch() {
  const [circularLoading, setCircularLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);

  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////

  const { register, reset, handleSubmit } = useForm();

  const [tabValue, setTabValue] = useState("1");
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const defaultAdditional = {
    page: 1,
  };

  //This function exists only so the Submit Button works when changing values of the Async Paginate
  //Submit Button reads the On InputChange Value from the Asyncs paginate. Otherwise, the Submit Button will not work
  const asyncPaginateInputChange = (newInputValue: string) => {
    //Do something here
  };

  const [status, setStatus] = useState("1");
  const handleChangeStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  const loadOptionsBrands = async (search: any, page: any) => {
    const results = await api.get(
      `/brands?page=${page}&name=${search}&language_id=1`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsBrands = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsBrands(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const [valueBrand, setValueBrand] = useState<{
    id: number;
    name: string;
  } | null>(null);

  //Categories
  const [categories, setCategories] = useState<{ id: number; name: string }[]>(
    []
  );

  //(cat_chosen) => setValue([...cat_chosen])
  const onChangeCategories = async (
    newValue: MultiValue<{ id: number; name: string }>
  ) => {
    //when Adding, do the code below
    if (newValue.length > categories.length) {
      //check to see if the added Category parents have been already added, if not, then add then too
      let last_inserted_category = newValue.slice(-1)[0];
      categories.push(last_inserted_category);
      /* let results = await api.get(`/categories-custom/${last_inserted_category.id}/parents-categories`).then(response => {
    
    }); */
      const results = await api.get(
        `/categories-custom/${last_inserted_category.id}/parents-categories`
      );
      const parents_categories = results.data;
      parents_categories.forEach((category: { id: number; name: string }) => {
        let cat_search = newValue.filter((obj) => {
          return obj.id === category.id;
        })[0];
        if (cat_search === undefined) {
          const insert = { id: category.id, name: category.name };
          categories.push(insert);
        }
      });
      setCategories(Array.from(new Set(categories)));
    } else {
      setCategories([...newValue]);
    }
  };

  const [products, setProducts] = useState<{ id: number; sku: string }[]>([]);
  const onChangeProducts = async (
    newValue: MultiValue<{ id: number; sku: string }>
  ) => {
    setProducts([...newValue]);
  };

  const loadOptionsProducts = async (search: any, page: any) => {
    const results = await api.get(
      `/products-custom?page=${page}&search=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsProducts = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsProducts(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadOptionsCategories = async (search: any, page: any) => {
    const results = await api.get(`/categories?page=${page}&search=${search}`);
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsCategories = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsCategories(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadOptionsAttributes = async (search: any, page: any) => {
    const results = await api.get(`/attributes?page=${page}&search=${search}`);
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsAttributes = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsAttributes(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const [valueAttributes, setValueAttributes] = useState<
    { id: number; name: string }[]
  >([]);
  const onChangeAttributes = async (
    newValue: MultiValue<{ id: number; name: string }>
  ) => {
    setValueAttributes([...newValue]);
  };

  const loadOptionsSpecifications = async (search: any, page: any) => {
    const results = await api.get(
      `/specifications?page=${page}&search=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsSpecifications = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsSpecifications(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const [valueSpecifications, setValueSpecifications] = useState<
    { id: number; name: string }[]
  >([]);

  const onChangeSpecifications = async (
    newValue: MultiValue<{ id: number; name: string }>
  ) => {
    setValueSpecifications([...newValue]);
  };

  const [updateStatus, setUpdateStatus] = useState<string[]>([]);

  const onSubmit = async (product: any) => {
    setCircularLoading(true);

    if (valueBrand) {
      product.brand_id = Number(valueBrand.id);
    }
    if (status) {
      product.status = Number(status);
    }

    product.updated_at = new Date().toISOString();

    //Remove ID Duplicates
    if (categories) {
      let uniq_categories = categories;
      let uniq_categories_ids = [];

      for (let index = 0; index < uniq_categories.length; index++) {
        uniq_categories_ids.push(Number(uniq_categories[index].id));
      }
      product.category_ids = uniq_categories_ids;
    }

    //Measurements
    //Measurements
    if (
      product.product_measurement.width &&
      product.product_measurement.width !== ""
    ) {
      product.product_measurement.width = Number(
        product.product_measurement.width
      );
    } else {
      delete product.product_measurement.width;
    }
    if (
      product.product_measurement.height &&
      product.product_measurement.height !== ""
    ) {
      product.product_measurement.height = Number(
        product.product_measurement.height
      );
    } else {
      delete product.product_measurement.height;
    }
    if (
      product.product_measurement.length &&
      product.product_measurement.length !== ""
    ) {
      product.product_measurement.length = Number(
        product.product_measurement.length
      );
    } else {
      delete product.product_measurement.length;
    }
    if (
      product.product_measurement.weight &&
      product.product_measurement.weight !== ""
    ) {
      product.product_measurement.weight = Number(
        product.product_measurement.weight
      );
    } else {
      delete product.product_measurement.weight;
    }

    //Attributes/Filters
    if (valueAttributes) {
      let uniq_attributes = valueAttributes;
      let uniq_attributes_ids = [];
      for (let index = 0; index < uniq_attributes.length; index++) {
        uniq_attributes_ids.push(Number(uniq_attributes[index].id));
      }
      product.attribute_ids = uniq_attributes_ids;
    }

    //Specifications
    if (valueSpecifications) {
      if (inputsName.length * valueSpecifications.length > 0) {
        for (
          let index = 0;
          index < inputsName.length * valueSpecifications.length;
          index++
        ) {
          product.specifications[index].specification_id = Number(
            product.specifications[index].specification_id
          );
          product.specifications[index].language_id = Number(
            product.specifications[index].language_id
          );
          product.specifications[index].updated_at = new Date().toISOString();
        }
      }
    }

    setUpdateStatus([]);

    products.map(async (item, index) => {
      const response = await api.get(`products-custom/${item.id}`);

      let product_send: any = response.data;
      //Brand
      if (valueBrand) {
        product_send.brand_id = Number(valueBrand.id);
      }
      //Status
      if (status) {
        product_send.status = Number(status);
      }
      //Categories
      if (categories) {
        let uniq_categories_ids = [];
        //First Get Current Categories of the selected product
        for (let index = 0; index < product_send.categories.length; index++) {
          uniq_categories_ids.push(product_send.categories[index].id);
        }

        let uniq_categories = categories;
        //Now add the new categories to the product
        for (let index = 0; index < uniq_categories.length; index++) {
          //check if it already exists in the category list
          if (!uniq_categories_ids.includes(uniq_categories[index].id)) {
            uniq_categories_ids.push(Number(uniq_categories[index].id));
          }
        }

        //Remove the categories property from product_send
        delete product_send.categories;
        //The post uses as category_ids the information
        product_send.category_ids = uniq_categories_ids;
      }

      //Measurements
      if (
        product.product_measurement.width &&
        product.product_measurement.width !== ""
      ) {
        product_send.product_measurement.width = Number(
          product.product_measurement.width
        );
      }
      product_send.product_measurement.width = Number(
        product_send.product_measurement.width
      );
      if (
        product.product_measurement.height &&
        product.product_measurement.height !== ""
      ) {
        product_send.product_measurement.height = Number(
          product.product_measurement.height
        );
      }
      product_send.product_measurement.height = Number(
        product_send.product_measurement.height
      );
      if (
        product.product_measurement.length &&
        product.product_measurement.length !== ""
      ) {
        product_send.product_measurement.length = Number(
          product.product_measurement.length
        );
      }
      product_send.product_measurement.length = Number(
        product_send.product_measurement.length
      );
      if (
        product.product_measurement.weight &&
        product.product_measurement.weight !== ""
      ) {
        product_send.product_measurement.weight = Number(
          product.product_measurement.weight
        );
      }
      product_send.product_measurement.weight = Number(
        product_send.product_measurement.weight
      );

      //Attributes/Filters
      if (valueAttributes) {
        let uniq_attributes_ids = [];
        //First Get Current Attributes of the selected product
        for (let index = 0; index < product_send.attributes.length; index++) {
          uniq_attributes_ids.push(product_send.attributes[index].id);
        }

        let uniq_attributes = valueAttributes;
        for (let index = 0; index < uniq_attributes.length; index++) {
          uniq_attributes_ids.push(Number(uniq_attributes[index].id));
        }
        delete product_send.attributes;
        product_send.attribute_ids = uniq_attributes_ids;
      }

      //Specifications
      if (valueSpecifications) {
        for (
          let index = 0;
          index < inputsName.length * valueSpecifications.length;
          index++
        ) {
          let insert_obj: any;
          insert_obj["specification_id"] = Number(
            product.specifications[index].specification_id
          );
          insert_obj["language_id"] = Number(
            product.specifications[index].language_id
          );
          insert_obj["updated_at"] = new Date().toISOString();

          product.specifications.push(insert_obj);
        }
      }

      await api
        .put(`products-custom/${item.id}`, product_send)
        .then((res) => {
          setCircularLoading(false);
          setRequestError(false);
          //Update Status Info
          updateStatus.push(
            "Product ID " + item.id + " SKU " + item.sku + " Updated"
          );
          setUpdateStatus([...updateStatus]);
          //reset();
        })
        .catch((error) => {
          updateStatus.push(
            "Product ID " + item.id + "/" + error.response.data.message
          );
          setUpdateStatus([...updateStatus]);
          setCircularLoading(false);
          setRequestError(true);
          setRequestErrorData(error.response.data.message);
        });
    });

    /* api
    .put(`products/batch-update`, product)
    .then((res) => {
      setCircularLoading(false);
      setRequestError(false);
      Swal.fire({
        title: "Product Updated!",
        icon: "success",
        confirmButtonText: "Yes",
      });
      //reset();
    })
    .catch((error) => {
      Swal.fire({
        title: "Oops...",
        text: error.response.data,
        confirmButtonText: "Yes",
      });
      setCircularLoading(false);
      setRequestError(true);
      setRequestErrorData(error.response.data.message);
    }); */
    setCircularLoading(false);
  };

  const clearFields = () => {
    reset();
    setProducts([]);
    products.length = 0;
    setCategories([]);
    categories.length = 0;
    setValueAttributes([]);
    valueAttributes.length = 0;
    setValueSpecifications([]);
    valueSpecifications.length = 0;
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <Typography
            component="h1"
            variant="h5"
            sx={{ textAlign: "center", paddingTop: "20px" }}
          >
            Batch Products Edit
          </Typography>
          {requestError && (
            <List component="nav" aria-label="error label">
              {requestErrorData.map((error) => (
                <ListItem key={error}>
                  <ListItemText
                    sx={{
                      color: "red",
                    }}
                    primary={error}
                  />
                </ListItem>
              ))}
            </List>
          )}
          <Box
            sx={{
              mt: 1,
              //display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderBottom: 1,
              borderColor: "divider",
              minWidth: 1 / 2,
              maxWidth: "xl",
              padding: "50px",
            }}
          >
            <InputLabel id="products-select">Select Products</InputLabel>
            <AsyncPaginate
              isMulti
              closeMenuOnSelect={false}
              placeholder="Select Products"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              additional={defaultAdditional}
              key={"product_ids"}
              id="products"
              name="products"
              getOptionValue={(option: any) => option?.id}
              getOptionLabel={(option: any) => option?.sku}
              loadOptions={loadPageOptionsProducts}
              value={products}
              onChange={onChangeProducts}
              onInputChange={asyncPaginateInputChange}
            />

            {products.map((input, index) => {
              return (
                <p key={index}>
                  Product: {input.id} / {input.sku}
                </p>
              );
            })}
          </Box>

          <TabContext value={tabValue}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                maxWidth: "xl",
                minWidth: 3 / 4,
              }}
            >
              <TabList onChange={handleChangeTab} centered aria-label="Tabs">
                <Tab label="Essential Info" value="1" />
                <Tab label="Categories" value="2" />
                <Tab label="Measurements" value="5" />
                <Tab label="Attributes/Filters" value="6" />
                <Tab label="Specifications" value="7" />
              </TabList>
            </Box>
            <Box
              component="form"
              sx={{
                mt: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onSubmit={handleSubmit(onSubmit)}
              id="formBatchProductEdit"
            >
              {/* Essential Info */}
              <TabPanel
                value="1"
                sx={{
                  width: "100%",
                  typography: "body1",
                  maxWidth: "xl",
                  minWidth: 3 / 4,
                  alignItems: "center",
                }}
              >
                {/* Brand */}
                <InputLabel id="brand-select">Brand</InputLabel>
                <AsyncPaginate
                  placeholder="Select Brand"
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                  defaultOptions
                  additional={defaultAdditional}
                  key={"brand_id"}
                  id="brand"
                  name="brand"
                  getOptionValue={(option: any) => option?.id}
                  getOptionLabel={(option: any) => option?.name}
                  loadOptions={loadPageOptionsBrands}
                  value={valueBrand}
                  onChange={setValueBrand}
                  onInputChange={asyncPaginateInputChange}
                />

                {/* Status Produto */}
                <div style={{ marginTop: "20px", width: "100%" }}></div>
                <FormControl sx={{ width: "50%" }}>
                  <InputLabel id="status-select-label">Status</InputLabel>
                  <Select
                    labelId="status-select-label"
                    id="status"
                    value={status}
                    {...register("status", {
                      required: "Status is required",
                    })}
                    label="Status"
                    onChange={handleChangeStatus}
                  >
                    <MenuItem value={""}>None</MenuItem>
                    <MenuItem value={1}>Enabled</MenuItem>
                    <MenuItem value={0}>Disabled</MenuItem>
                  </Select>
                </FormControl>
              </TabPanel>

              <TabPanel
                value="2"
                sx={{
                  width: "100%",
                  typography: "body1",
                  maxWidth: "xl",
                  minWidth: 3 / 4,
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "100%", typography: "body1" }}>
                  Selected Categories Will be Added to the current Categories
                  the Product already has linked
                  <AsyncPaginate
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Select Categories"
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    additional={defaultAdditional}
                    key={"category_ids"}
                    id="category"
                    name="category"
                    getOptionValue={(option: any) => option?.id}
                    getOptionLabel={(option: any) => option?.name}
                    loadOptions={loadPageOptionsCategories}
                    value={categories}
                    onChange={onChangeCategories}
                    onInputChange={asyncPaginateInputChange}
                  />
                  {categories.map((input, index) => {
                    return (
                      <p key={index}>
                        {" "}
                        Category ID: {input.id} / {input.name}
                      </p>
                    );
                  })}
                </Box>
              </TabPanel>

              <TabPanel
                value="5"
                sx={{
                  width: "100%",
                  typography: "body1",
                  maxWidth: "xl",
                  minWidth: 3 / 4,
                  alignItems: "center",
                }}
              >
                Fields that have some value written in the Input Boxes, will
                replace the correspondent product Measurement
                <Box sx={{ width: "50%", typography: "body1" }}>
                  <TextField
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    sx={{ mt: 1, minWidth: 3 / 4 }}
                    id="#product_measurement.width"
                    type="number"
                    label="Width (in mm)"
                    {...register(`product_measurement.width`)}
                  />

                  <TextField
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    sx={{ mt: 1, minWidth: 3 / 4 }}
                    id="#product_measurement.height"
                    type="number"
                    label="Height (in mm)"
                    {...register(`product_measurement.height`)}
                  />
                  <TextField
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    sx={{ mt: 1, minWidth: 3 / 4 }}
                    id="#product_measurement.length"
                    type="number"
                    label="Length (in mm)"
                    {...register(`product_measurement.length`)}
                  />

                  <TextField
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    sx={{ mt: 1, minWidth: 3 / 4 }}
                    id="#product_measurement.weight"
                    type="number"
                    label="Weight (in Kg)"
                    {...register(`product_measurement.weight`)}
                  />
                </Box>
              </TabPanel>

              <TabPanel
                value="6"
                sx={{
                  width: "100%",
                  typography: "body1",
                  maxWidth: "xl",
                  minWidth: 3 / 4,
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "100%", typography: "body1" }}>
                  Selected Attributes Will be Added to the current Attributes
                  the Product already has linked
                  <AsyncPaginate
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Select Attributes/Filters"
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    additional={defaultAdditional}
                    key={"attributes_ids"}
                    id="attribute"
                    name="attribute"
                    getOptionValue={(option: any) => option?.id}
                    getOptionLabel={(option: any) => option?.name}
                    loadOptions={loadPageOptionsAttributes}
                    value={valueAttributes}
                    onChange={onChangeAttributes}
                    onInputChange={asyncPaginateInputChange}
                  />
                  {valueAttributes.map((input, index) => {
                    return (
                      <p key={index}>
                        {" "}
                        Attribute ID: {input.id} / {input.name}
                      </p>
                    );
                  })}
                </Box>
              </TabPanel>

              <TabPanel
                value="7"
                sx={{
                  width: "100%",
                  typography: "body1",
                  maxWidth: "xl",
                  minWidth: 3 / 4,
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "100%", typography: "body1" }}>
                  Selected Specifications Will be Added to the current
                  Specifications the Product already has linked
                  <AsyncPaginate
                    isMulti
                    closeMenuOnSelect={false}
                    placeholder="Select Specifications"
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    additional={defaultAdditional}
                    key={"specifications"}
                    id="specification"
                    name="specification"
                    getOptionValue={(option: any) => option?.id}
                    getOptionLabel={(option: any) => option?.name}
                    loadOptions={loadPageOptionsSpecifications}
                    value={valueSpecifications}
                    onChange={onChangeSpecifications}
                    onInputChange={asyncPaginateInputChange}
                  />
                  <div style={{ marginTop: "30px" }}>
                    {valueSpecifications.map((input, index) => {
                      return (
                        <p>
                          {" "}
                          Specification ID: {input.id} / {input.name}
                        </p>
                      );
                    })}
                  </div>
                  <div style={{ marginTop: "50px", width: "100%" }}>
                    {valueSpecifications.map((inputSpec, indexSpec) => {
                      return (
                        <div style={{ marginBottom: "30px", width: "100%" }}>
                          <p>Specification {inputSpec.name}</p>
                          {inputsName.map((inputLanguage, indexLanguage) => {
                            return (
                              <div
                                className="flags-container"
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  marginTop: 0,
                                }}
                              >
                                <input
                                  hidden
                                  {...register(
                                    `specifications[${
                                      indexSpec * inputsName.length +
                                      indexLanguage
                                    }].specification_id`,
                                    {
                                      required: `specifications[${
                                        indexSpec * inputsName.length +
                                        indexLanguage
                                      }].specification_id is required`,
                                    }
                                  )}
                                  value={inputSpec.id}
                                />
                                <input
                                  hidden
                                  {...register(
                                    `specifications[${
                                      indexSpec * inputsName.length +
                                      indexLanguage
                                    }].language_id`,
                                    {
                                      required: `specifications[${
                                        indexSpec * inputsName.length +
                                        indexLanguage
                                      }].language_id is required`,
                                    }
                                  )}
                                  value={inputLanguage.language_id}
                                />
                                <span className="fa-flags">
                                  <img
                                    src={`/assets/language/${inputLanguage.language_id}.png`}
                                    alt=""
                                  />
                                </span>
                                <Box sx={{ width: "100%" }}>
                                  <TextField
                                    fullWidth
                                    sx={{
                                      mt: 1,
                                      minWidth: 3 / 4,
                                      width: "100%",
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    margin="normal"
                                    required
                                    label="Specification Name"
                                    id={
                                      "#specifications[" +
                                      (indexSpec * inputsName.length +
                                        indexLanguage) +
                                      "].name"
                                    }
                                    type="text"
                                    {...register(
                                      `specifications[${
                                        indexSpec * inputsName.length +
                                        indexLanguage
                                      }].name`,
                                      {
                                        required: `specifications[${
                                          indexSpec * inputsName.length +
                                          indexLanguage
                                        }].name is required`,
                                      }
                                    )}
                                  />
                                </Box>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </Box>
              </TabPanel>
              <ul>
                {updateStatus.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  mt: 2,
                }}
              >
                <Button
                  onClick={clearFields}
                  variant="outlined"
                  startIcon={<DeleteIcon />}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  size="large"
                  endIcon={<SendIcon />}
                  disabled={circularLoading}
                  type="submit"
                  form="formBatchProductEdit"
                >
                  Send
                  {circularLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: green[500],
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Button>
              </Stack>
              <div style={{ marginTop: "100px" }}></div>
            </Box>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );
}
