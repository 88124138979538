/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Stack,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import api from "../../services/api";
import Swal from "sweetalert2";

function NewCompetitors() {
  const [circularLoading, setCircularLoading] = useState(false);

  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (competitors: any) => {
    setCircularLoading(true);
    if (competitors.country_id !== "") {
      competitors.country_id = Number(competitors.country_id);
    } else {
      delete competitors.country_id;
    }
    api
      .post("competitors", competitors)
      .then((response) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Competitor created!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "" + error.response.data.message,
          footer: '<a href="">Why do I have this issue?</a>',
        });
        setCircularLoading(false);
        setRequestError(false);
        setRequestErrorData(error.response.data.message);
      });
  };

  // const [roles, setRoles] = React.useState<{ id: number; name: string }[]>([]);
  // const fetchRoles = async () => {
  //   const response = await api.get(`roles`);
  //   setRoles(response.data.items);
  // };

  const [country, setCountry] = useState("");
  const handleChangeCountry = (event: SelectChangeEvent) => {
    setCountry(event.target.value as string);
  };

  const [countries, setCountries] = React.useState<
    { id: number; name: string }[]
  >([]);
  const fetchCountries = async () => {
    const response = await api.get(`countries`);
    setCountries(response.data.items);
  };

  useEffect(() => {
    // fetchRoles();
    fetchCountries();
  }, []);

  // const [age, setAge] = React.useState("");
  // const handleChange = (event: SelectChangeEvent) => {
  //   setAge(event.target.value as string);
  // };

  return (
    <Container component="main" maxWidth="xl">
      <Typography
        component="h1"
        variant="h5"
        sx={{ fontSize: "32px", marginBottom: "15px", color: "#556cd6" }}
      >
        New Competitors
      </Typography>
      {requestError && (
        <List component="nav" aria-label="erros">
          {requestErrorData.map((error) => (
            <ListItem key={error}>
              <ListItemText
                sx={{
                  color: "red",
                }}
                primary={error}
              />
            </ListItem>
          ))}
        </List>
      )}
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          minWidth: 3 / 4,
          marginBottom: "30px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextField
          margin="normal"
          InputLabelProps={{ shrink: true }}
          required
          fullWidth
          id="username"
          type="text"
          label="Name"
          {...register("name", {})}
          autoFocus
        />
        <p style={{ color: "red", textAlign: "center" }}>
          {errors?.username?.toString()}
        </p>
        <TextField
          margin="normal"
          InputLabelProps={{ shrink: true }}
          required
          fullWidth
          id="phone_number"
          type="text"
          label="Phone Number"
          {...register("phone_number", {})}
          autoFocus
        />
        <p style={{ color: "red", textAlign: "center" }}>
          {errors?.phone_number?.toString()}
        </p>
        <TextField
          margin="normal"
          InputLabelProps={{ shrink: true }}
          required
          fullWidth
          id="email"
          type="text"
          label="Email"
          {...register("email", {})}
          autoFocus
        />
        <p style={{ color: "red", textAlign: "center" }}>
          {errors?.email?.toString()}
        </p>
        <TextField
          margin="normal"
          InputLabelProps={{ shrink: true }}
          required
          fullWidth
          id="website"
          type="text"
          label="Website"
          {...register("website")}
          autoFocus
        />
        <p style={{ color: "red", textAlign: "center" }}>
          {errors?.website?.toString()}
        </p>
        <TextField
          margin="normal"
          InputLabelProps={{ shrink: true }}
          required
          fullWidth
          id="responsible_salesperson"
          type="text"
          label="Responsible Salesperson"
          {...register("responsible_salesperson", {})}
          autoFocus
        />
        <p style={{ color: "red", textAlign: "center" }}>
          {errors?.responsible_salesperson?.toString()}
        </p>
        <TextField
          margin="normal"
          InputLabelProps={{ shrink: true }}
          multiline
          rows={5}
          required
          fullWidth
          id="General Comments"
          type="text"
          label="General Comments"
          {...register("general_comments")}
          autoFocus
        />
        <p style={{ color: "red", textAlign: "center" }}>
          {errors?.general_comments?.toString()}
        </p>
        <TextField
          margin="normal"
          InputLabelProps={{ shrink: true }}
          required
          fullWidth
          id="image"
          type="text"
          label="Image Address"
          {...register("image")}
          autoFocus
        />
        <p style={{ color: "red", textAlign: "center" }}>
          {errors?.image?.toString()}
        </p>

        <div style={{ marginTop: "30px" }}></div>
        <FormControl fullWidth>
          <InputLabel id="country-label">Country</InputLabel>
          <Select
            labelId="country-select-label"
            id="country"
            value={country}
            {...register("country_id")}
            label="Country"
            autoFocus
            onChange={handleChangeCountry}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {countries.map((country, index) => (
              <MenuItem value={country.id} key={index}>
                {country.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <p style={{ color: "red", textAlign: "center" }}>
          {errors?.country_id?.toString()}
        </p>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            mt: 2,
          }}
        >
          <Button
            onClick={() => reset()}
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Clear
          </Button>
          <Button
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            size="large"
            endIcon={<SendIcon />}
            disabled={circularLoading}
            type="submit"
          >
            Send
            {circularLoading && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Button>
        </Stack>
      </Box>
    </Container>
  );
}
export default NewCompetitors;
