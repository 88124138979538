import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
//import Paper from "@mui/material/Paper";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import { TableProps } from "../../types/tableHorizontal";
import { makeStyles } from "@mui/styles";
import { Autorenew, Visibility } from "@mui/icons-material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InventoryIcon from "@mui/icons-material/Inventory";
import Swal from "sweetalert2";
import api from "../../services/api";
import moment from "moment";

const imageBaseURL = process.env.REACT_APP_IMG_CATALOG_URL;

const useStyles = makeStyles((theme) => ({
  eye: {
    backgroundColor: "transparent",
    position: "relative",
    "&:hover": {
      backgroundColor: "#f2f2f2",
    },
  },

  delete: {
    backgroundColor: "transparent",
    position: "relative",
    "&:hover": {
      backgroundColor: "#f2f2f2",
    },
  },

  inventory: {
    backgroundColor: "transparent",
    position: "relative",
    "&:hover": {
      backgroundColor: "#f2f2f2",
    },
  },
}));

const handleToPage = (id: any, screen: any, store: any) => {
  //window.location.href = `${screen}/${id}`;
  window.open(`${screen}/${id}`);
};

const handleToPageOrderStatusHistory = (id: any, screen: any, store: any) => {
  //window.location.href = `${screen}/${id}`;
  window.open(`${screen}/status-history/${id}`);
};

const deleteItem = (id: any, screen: any, store: any) => {
  Swal.fire({
    title: `Are you sure to delete the ID ${id}?`,
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      api
        .delete(`${screen}/${id}`)
        .then((res) => {
          Swal.fire("Deleted!", "It has been deleted.", "success");
          window.location.href = `${screen}`; // This has to be inside due to the delay on the online version. It cannot be outside of the response from the api
        })
        .catch((error) => {
          console.error(error);
        });

      if (screen === "product-parent-child-options") {
        screen = "product-parent-childs";
      } //GAMBIARRA - Because on the product-parent-childs screen it has 2 different listing pages
    }
  });
};
const UpdateStillInStock = (id: any, screen: any) => {
  Swal.fire({
    title: `Are you sure to update the inventory history for entry ${id}?`,
    text: "This item will be classified as no longer in inventory. You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, Update it!",
  }).then((result) => {
    if (result.isConfirmed) {
      api
        .put(`${screen}/history-update-still-in-inventory-to-no/${id}`)
        .then((res) => {
          Swal.fire("Updated!", "Item is no longer in inventory", "success");
        })
        .catch((error) => {
          console.error(error);
        });
    }
  });
};
const parseLines = (value: string) => value.replace(/(\\n)/g, "\n");
const TableHorizontal = (props: TableProps) => {
  const classes = useStyles();

  return (
    //<Paper className="container">
    <Table>
      <TableHead>
        <TableRow>
          {props.columns.map((column, index) => (
            <TableCell key={index}>
              <b>{column}</b>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {props.data.map((data, index) => {
          let id = data.id;
          //let still_in_inventory_yes_no: string = data["still_in_inventory_yes_no"];
          //let image: string = data["image"];
          if (data.IdOrder) {
            id = data.IdOrder;
          }
          if (data.IdProduct) {
            id = data.IdProduct;
          }
          return (
            <TableRow key={index}>
              {props.objectKey.map((key) => {
                return (
                  <TableCell key={key}>
                    {key === "action" ? (
                      <IconButton
                        color="inherit"
                        className={classes.eye}
                        onClick={() => {
                          handleToPage(id, props.screen, props.store);
                        }}
                      >
                        <Visibility />
                      </IconButton>
                    ) : (
                      <>
                        {key === "price" ? (
                          parseLines(data[key])
                        ) : (
                          <>
                            {key === "image" ? (
                              <>
                                {data["image"] !== null &&
                                data["image"] !== "" ? (
                                  <CardMedia
                                    component="img"
                                    sx={{ width: 151 }}
                                    //image={`https://sosmascaras.s3.us-east-2.amazonaws.com/${data[key]}`} isso são o caminho antigo das imagens. Todas serão no novo caminho
                                    image={`${imageBaseURL}${data[key]}`}
                                    //image={`https://s3.us-east-2.amazonaws.com/static.ystevo.com/${data[key]}`}
                                    alt={`${data[key]}`}
                                  />
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <>
                                {key === "when" ? (
                                  moment(data["when"]).format(
                                    "DD/MM/YYYY hh:mm:ss"
                                  )
                                ) : key === "date_answered" ? (
                                  data["date_answered"] ? (
                                    moment(data["date_answered"]).format(
                                      "DD/MM/YYYY hh:mm:ss"
                                    )
                                  ) : (
                                    ""
                                  )
                                ) : key === "update_still_in_inventory" &&
                                  data["still_in_inventory_yes_no"] ===
                                    "YES" ? (
                                  <IconButton
                                    color="inherit"
                                    className={classes.inventory}
                                    onClick={() => {
                                      UpdateStillInStock(id, props.screen);
                                    }}
                                  >
                                    <InventoryIcon />
                                  </IconButton>
                                ) : props.screen === "orders" &&
                                  (key === "subtotal" ||
                                    key === "discount" ||
                                    key === "shipping_cost" ||
                                    key === "discount_payment_method" ||
                                    key === "total") ? (
                                  Number(data[key]).toLocaleString("en-US", {
                                    style: "currency",
                                    currency: data["currency"],
                                  })
                                ) : props.screen === "orders" &&
                                  key === "update_order_status" ? (
                                  <IconButton
                                    color="inherit"
                                    className={classes.eye}
                                    onClick={() => {
                                      handleToPageOrderStatusHistory(
                                        id,
                                        props.screen,
                                        props.store
                                      );
                                    }}
                                  >
                                    <Autorenew />
                                  </IconButton>
                                ) : (
                                  data[key]
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                    {key === "delete" ? (
                      <IconButton
                        color="inherit"
                        className={classes.eye}
                        onClick={() => {
                          deleteItem(id, props.screen, props.store);
                        }}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    ) : (
                      <></>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
    //</Paper>
  );
};
export default TableHorizontal;
