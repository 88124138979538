import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  FC,
} from "react";
import api from "../../services/api";
import { Description } from "../../types/products";

type AttributesType = {
  id: number;
  name: string;
  attribute_group_id: number;
};
type Prices = {
  store_id: number;
  currency_id: number;
  value: string;
};
type VariationsType = {
  attributesstring: string;
  attributes: number[];
  sku: string;
  height: string;
  width: string;
  length: string;
  weight: string;
  images: string[];
  main_image: string;
  prices: Prices[];
};

type PropsProductContext = {
  attributeGroups: number[];
  attributeString: string[];
  setAttributeGroups: Dispatch<SetStateAction<number[]>>;
  brand: string;
  setBrand: Dispatch<SetStateAction<string>>;
  category_id: number;
  setCategory_id: Dispatch<SetStateAction<number>>;
  descriptions: Description[];
  setDescriptions: Dispatch<SetStateAction<Description[]>>;
  model: string;
  setModel: Dispatch<SetStateAction<string>>;
  status: Number;
  stores: number[];
  setStoreIds: Dispatch<SetStateAction<number[]>>;
  specifications: number[];
  setSpecifications: Dispatch<SetStateAction<number[]>>;
  variations: VariationsType[];
  setVariations: Dispatch<SetStateAction<VariationsType[]>>;
  attributesAlgorithm: (
    attributes: AttributesType
  ) => boolean | undefined | void;
  confirmAttributes: () => void;
  isAtrributesConfirmed: boolean;
  setIsAtrributesConfirmed: Dispatch<SetStateAction<boolean>>;
  cadastroProduto: () => void;
};

const INITIAL_STATE = {
  attributeGroups: [],
  setAttributeGroups: () => {},
  brand: "",
  setBrand: () => {},
  category_id: 0,
  setCategory_id: () => {},
  descriptions: [] as Description[],
  setDescriptions: () => {},
  model: "",
  setModel: () => {},
  status: 0,
  stores: [],
  setStoreIds: () => {},
  specifications: [],
  setSpecifications: () => {},
  variations: [],
  setVariations: () => {},
  attributesAlgorithm: () => {},
  confirmAttributes: () => {},
  attributeString: [] as string[],
  isAtrributesConfirmed: false,
  setIsAtrributesConfirmed: () => {},
  cadastroProduto: () => {},
};

interface Props {
  children: React.ReactNode;
}

const ProductContext = createContext<PropsProductContext>(INITIAL_STATE);

export const ProductContextProvider: FC<Props> = ({ children }) => {
  const [isAtrributesConfirmed, setIsAtrributesConfirmed] = useState(false);
  const [attributeGroups, setAttributeGroups] = useState<number[]>([]);
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [category_id, setCategory_id] = useState<number>(0);
  const [descriptions, setDescriptions] = useState<Description[]>([]);
  const [stores, setStoreIds] = useState<number[]>([]);
  const [specifications, setSpecifications] = useState<number[]>([]);
  const [variations, setVariations] = useState<VariationsType[]>([]);
  const [attributesSelecteds /* , setAttributesSelecteds */] = useState<
    Array<Array<AttributesType>>
  >([]);
  const [attributeString /* , setAttributeString */] = useState<string[]>([]);
  let status = 1;

  const attributesAlgorithm = (attributes: AttributesType) => {
    if (attributesSelecteds.length === 0) {
      attributesSelecteds.push([attributes]);
      return;
    }
    if (attributesSelecteds.length === 1) {
      attributesSelecteds[0].forEach((attribute) => {
        if (attribute.attribute_group_id === attributes.attribute_group_id) {
          if (attribute.id === attributes.id) {
            return;
          }
          attributesSelecteds[0].push(attributes);
          return;
        } else {
          attributesSelecteds.push([attributes]);
          return;
        }
      });
    }
    if (attributesSelecteds.length === 2) {
      attributesSelecteds[1].forEach((attribute) => {
        if (attribute.attribute_group_id === attributes.attribute_group_id) {
          if (attribute.id === attributes.id) {
            return;
          }
          attributesSelecteds[1].push(attributes);
          return;
        } else {
          attributesSelecteds.push([attributes]);
          return;
        }
      });
    }
    if (attributesSelecteds.length === 3) {
      attributesSelecteds[2].forEach((attribute) => {
        if (attribute.attribute_group_id === attributes.attribute_group_id) {
          if (attribute.id === attributes.id) {
            return;
          }
          attributesSelecteds[2].push(attributes);
          return;
        }
      });
    }
  };

  const confirmAttributes = () => {
    const arrTodos = [attributesSelecteds[1], attributesSelecteds[2]];
    for (let i = 0; i < attributesSelecteds[0].length; i++) {
      for (let j = 0; j < arrTodos.length - 1; j++) {
        for (let k = 0; k < arrTodos[j].length; k++) {
          const pricearr = [
            {
              store_id: 0,
              currency_id: 0,
              value: "",
            },
          ];
          setVariations([
            {
              attributesstring: `${attributesSelecteds[0][i].name} -> ${
                arrTodos[j][k].name
              } -> ${arrTodos[j + 1][k].name}`,
              attributes: [
                attributesSelecteds[0][i].id,
                arrTodos[j][k].id,
                arrTodos[j + 1][k].id,
              ],
              sku: "",
              height: "",
              width: "",
              length: "",
              weight: "",
              images: [],
              main_image: "",
              prices: pricearr,
            },
          ]);
        }
      }
    }
  };

  const cadastroProduto = () => {
    const payload = {
      clicks: 0,
      status: status,
      category_id: category_id,
      stores: stores,
      brand: brand,
      descriptions: descriptions,
      specifications: specifications,
      variations: variations,
    };
    api.post("/products", payload).catch((err) => {
      console.error(err);
    });
  };

  return (
    <ProductContext.Provider
      value={{
        attributeGroups,
        setAttributeGroups,
        brand,
        setBrand,
        category_id,
        setCategory_id,
        descriptions,
        setDescriptions,
        model,
        setModel,
        status,
        stores,
        setStoreIds,
        specifications,
        setSpecifications,
        variations,
        setVariations,
        attributesAlgorithm,
        attributeString,
        confirmAttributes,
        isAtrributesConfirmed,
        setIsAtrributesConfirmed,
        cadastroProduto,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export function useProduct() {
  const context = useContext(ProductContext);
  if (!context) {
    throw new Error("useProduct must be used within SessionProvider");
  }
  return context;
}
