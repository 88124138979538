/* eslint-disable react-hooks/exhaustive-deps */
import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  Link,
} from "@mui/material";
import TableHorizontal from "../../components/TableHorizontal";
import api from "../../services/api";
import { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { LogTrackUser } from "../../types/log-track-user/log-track-user";
import Cookies from "js-cookie";

//Dashboard Data will be stored in a table, and updated daily or 4 times daily, or when button is pressed. Last Update Time will be shown
export default function Dashboard() {
  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////
  const [productInfoLast, setProductInfoLast] = useState<{
    id: number;
    brands_count: number;
    categories_count: number;
    suppliers_count: number;
    attributes_count: number;
    attribute_groups_count: number;
    specifications_count: number;
    specifications_group_count: number;
    products_total_count: number;
    products_unique_count: number;
    products_parent_count: number;
    products_child_count: number;
    products_combination_count: number;
    product_reviews_count: number;
    created_at: Date;
  } | null>(null);

  const [errorslogLast, setErrorslogLast] = useState<{
    id: number;
    product_count: number;
    product_image_count: number;
    product_title_count: number;
    product_description_count: number;
    product_seokeywords_count: number;
    product_seourl_count: number;
    product_category_count: number;
    product_measurement_count: number;
    product_attribute_count: number;
    product_specification_count: number;
    created_at: Date;
  } | null>(null);

  const [ordersSummaryLast, setOrdersSummaryLast] = useState<{
    id: number;
    store_id: number;
    total_month_count_orders: number;
    total_count_orders: number;
    total_month_value_orders: number;
    total_value_orders: number;
    created_at_year: number;
    created_at_month: number;
    created_at: Date;
  } | null>(null);

  /* const [ordersLast, setOrdersLast] = useState<{
    id: string;
    store_id: number;
    store: string;
    currency_id: number;
    currency: string;
    customer_id: number;
    customer_email: string;
    customer_name: string;
    order_status_id: number;
    order_status: string;
    cart_id: string;
    payment_method_id: number;
    payment_method: string;
    shipping_method_id: number;
    shipping_method: string;
    subtotal: number;
    discount: number;
    taxes: number;
    shipping_cost: number;
    discount_payment_method: number;
    total: number;
    shipping_address_country_id: number;
    shipping_address_country: string;
    shipping_address_state: string;
    shipping_address_city: string;
    shipping_info_code: string;
    shipping_info_delivery_time: string;
    shipping_info_weight: number;
    shipping_info_height: number;
    shipping_info_width: number;
    shipping_info_length: number;
    shipping_info_tracking_code: string;
    shipping_info_link_tracking: string;
    shipping_info_shipped_date: string;
    when: Date;
    created_at: Date;
  } | null>(null); */
  const [ordersLast, setOrdersLast] = useState([]);

  //const columns = ["Combination Product", "Item Product", "Qty of Item", "Show in Catalog?", "Action", "Delete"];
  //const keys = ["combination_product", "item_product", "qty_in_combination","show_catalog_text", "action", "delete"];
  const fetchDataProductInfo = async () => {
    await api.get(`/dashboard-erp/products-info-last`).then((res) => {
      setProductInfoLast(res.data);
    });
  };

  const fetchDataErrorslog = async () => {
    await api.get(`/dashboard-erp/errorslog-last`).then((res) => {
      setErrorslogLast(res.data);
    });
  };

  const fetchDataOrder = async () => {
    await api.get(`/dashboard-erp/orders`).then((res) => {
      setOrdersSummaryLast(res.data);
    });
  };

  const columns = [
    "ID",
    "Store",
    "Customer",
    "Order Status",
    "Payment Method",
    "Shipping Method",
    "Total",
    "When",
    "Actions",
  ];
  const keys = [
    "id",
    "store",
    "customer_name",
    "order_status",
    "payment_method",
    "shipping_method",
    "total",
    "created_at",
    "action",
  ];

  const fetchLastOrders = async () => {
    await api.get(`/orders?limit=10`).then((res) => {
      setOrdersLast(res.data.items);
    });
  };

  const handleUpdateProductsInfo = async () => {
    let data = {};
    await api
      .post(`dashboard-erp/products-info`, data)
      .then(() => {
        //reset();
        fetchDataProductInfo();
        window.location.reload();
      })
      .catch((error) => {});
  };

  const handleUpdateErrorslog = async () => {
    let data = {};
    await api
      .post(`dashboard-erp/errorslog`, data)
      .then(() => {
        //reset();`
        fetchDataProductInfo();
        window.location.reload();
      })
      .catch((error) => {});
  };

  const handleUpdateOrders = async () => {
    let data = {};
    await api
      .post(`dashboard-erp/orders`, data)
      .then(() => {
        //reset();`
        fetchDataOrder();
        window.location.reload();
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetchDataProductInfo();
    fetchDataErrorslog();
    fetchDataOrder();
    fetchLastOrders();
  }, []);
  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={6}>
          <Paper className="mini__card__container">
            <TableHorizontal columns={[]} data={[]} objectKey={[]} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={6}>
          <Paper className="mini__card__container">
            <TableHorizontal columns={[]} data={[]} objectKey={[]} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={8} lg={6}>
          <Paper className="mini__card__container">
            <>
              <Typography
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom
              >
                Products Info in Numbers
              </Typography>
              Total N. of Products: {productInfoLast?.products_total_count}
              <br></br>
              &emsp; Unique Products: {productInfoLast?.products_unique_count}
              <br></br>
              &emsp; Parent Products: {productInfoLast?.products_parent_count}
              <br></br>
              &emsp; Child Products: {productInfoLast?.products_child_count}
              <br></br>
              &emsp; Combination Products:{" "}
              {productInfoLast?.products_combination_count}
              <br></br>
              Total of Product Reviews: {productInfoLast?.product_reviews_count}
              <br></br>
              Total of Brands: {productInfoLast?.brands_count}
              <br></br>
              Total of Categories: {productInfoLast?.categories_count}
              <br></br>
              Total of Suppliers: {productInfoLast?.suppliers_count}
              <br></br>
              Total of Attributes: {productInfoLast?.attributes_count}
              <br></br>
              Total of Attribute Groups:{" "}
              {productInfoLast?.attribute_groups_count}
              <br></br>
              Total of Specifications: {productInfoLast?.specifications_count}
              <br></br>
              Total of Specification Groups:{" "}
              {productInfoLast?.specifications_group_count}
              <br></br>
              <br></br>
              <Typography variant="subtitle2">
                Last updated on:{" "}
                {moment(productInfoLast?.created_at).format(
                  "DD MMM, YYYY hh:mm:ss"
                )}{" "}
              </Typography>
            </>
          </Paper>
          <Button
            variant="contained"
            href=""
            onClick={handleUpdateProductsInfo}
          >
            Update Product Info in Numbers
          </Button>
        </Grid>
        <Grid item xs={12} md={4} lg={6}>
          <Paper className="mini__card__container">
            <>
              <Typography
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom
              >
                Errors Log in Numbers
              </Typography>
              Total N. of Errors in Products: {errorslogLast?.product_count}
              <br></br>
              &emsp; Image Errors: {errorslogLast?.product_image_count}
              <br></br>
              &emsp; Title Errors: {errorslogLast?.product_title_count}
              <br></br>
              &emsp; Description Errors:{" "}
              {errorslogLast?.product_description_count}
              <br></br>
              &emsp; SEO Keywords Errors:{" "}
              {errorslogLast?.product_seokeywords_count}
              <br></br>
              &emsp; SEO URL Errors: {errorslogLast?.product_seourl_count}
              <br></br>
              &emsp; Category Errors: {errorslogLast?.product_category_count}
              <br></br>
              &emsp; Measurement Errors:{" "}
              {errorslogLast?.product_measurement_count}
              <br></br>
              &emsp; Attribute Errors: {errorslogLast?.product_attribute_count}
              <br></br>
              &emsp; Specification Errors:{" "}
              {errorslogLast?.product_specification_count}
              <br></br>
              <br></br>
              <Typography variant="subtitle2">
                Last updated on:{" "}
                {moment(errorslogLast?.created_at).format(
                  "DD MMM, YYYY hh:mm:ss"
                )}{" "}
              </Typography>
            </>
          </Paper>
          <Button variant="contained" href="" onClick={handleUpdateErrorslog}>
            Update Errorslog in Numbers
          </Button>
        </Grid>
        <Grid item xs={12} md={4} lg={6}>
          <Paper className="mini__card__container">
            <>
              <Typography
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom
              >
                Orders in Numbers
              </Typography>
              Total N. of Orders in Current Month:{" "}
              {ordersSummaryLast?.total_month_count_orders}
              <br></br>
              &emsp; Total N. of Orders: {ordersSummaryLast?.total_count_orders}
              <br></br>
              &emsp; Total Value of Orders in Current Month:{" "}
              {ordersSummaryLast?.total_month_value_orders}
              <br></br>
              &emsp; Total Value of Orders:{" "}
              {ordersSummaryLast?.total_value_orders}
              <br></br>
              <Typography variant="subtitle2">
                Last updated on:{" "}
                {moment(ordersSummaryLast?.created_at).format(
                  "DD MMM, YYYY hh:mm:ss"
                )}{" "}
              </Typography>
            </>
          </Paper>
          <Button variant="contained" href="" onClick={handleUpdateOrders}>
            Update Orders in Numbers
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Fragment>
              <Typography
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom
              >
                Recent Orders
              </Typography>
              <TableHorizontal
                screen={"orders"}
                columns={columns}
                data={ordersLast}
                objectKey={keys}
              />
              <Link
                color="primary"
                href="#"
                onClick={() => {
                  window.open(`/orders`);
                }}
                sx={{ mt: 3 }}
              >
                See more orders
              </Link>
            </Fragment>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
