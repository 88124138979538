import {
  Box,
  Button,
  Container,
  Typography,
  Stack,
  ListItem,
  ListItemText,
  List,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import api from "../../services/api";
import Swal from "sweetalert2";
import { AsyncPaginate } from "react-select-async-paginate";

function NewCashiers() {
  const [circularLoading, setCircularLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);

  const [user, setUser] = useState<{
    id: number;
  } | null>(null);
  const { register, reset, handleSubmit } = useForm();
  const onSubmit = (cashier: any) => {
    setCircularLoading(true);
    if (user) {
      cashier.user_id = user ? user.id : null;
    }

    api
      .post("cashiers", cashier)
      .then((response) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "Cashiers created!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          footer: '<a href="">Why do I have this issue?</a>',
        });
        //isso confere o tamanho do array os erros tem que dar 3, para setRequestErrpr ser true caso o contrario tem que ser false se nao da tela branca
        if (error.response.data.message.length === 3) {
          setRequestError(true);
        } else {
          setRequestError(false);
        }

        setCircularLoading(false);
        setRequestError(false);
        setRequestErrorData(error.response.data.message);
      });
  };
  const [status, setStatus] = React.useState("");
  const handleChangeStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  const defaultAdditional = {
    page: 1,
  };

  const loadOptions = async (search: any, page: any) => {
    const results = await api.get(`/users?page=${page}`);

    const hasMore = results.data.total_pages === results.data.current_page;

    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptions = async (q: any, _prevOptions: any, { page }: any) => {
    const { options, hasMore } = await loadOptions(q, page);

    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  };
  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{ fontSize: "30px", marginBottom: "15px", color: "#556cd6" }}
        >
          New Cashiers
        </Typography>
        {requestError && (
          <List component="nav" aria-label="secondary mailbox folder">
            {requestErrorData.map((error) => (
              <ListItem key={error}>
                <ListItemText
                  sx={{
                    color: "red",
                  }}
                  primary={error}
                />
              </ListItem>
            ))}
          </List>
        )}
        <Box sx={{ mt: 1, minWidth: 3 / 4, width: "100%" }}>
          <AsyncPaginate
            placeholder="Users"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            value={user}
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.name}
            loadOptions={loadPageOptions}
            onChange={setUser}
          />
          <FormControl fullWidth>
            <InputLabel id="status-select" sx={{ marginTop: "30px" }}>
              Status
            </InputLabel>
            <Select
              labelId="status-select-label"
              id="status"
              value={status}
              {...register("status", {})}
              label="Status"
              sx={{ marginTop: "30px" }}
              onChange={handleChangeStatus}
            >
              <MenuItem value={1}>Enabled</MenuItem>
              <MenuItem value={0}>Disabled</MenuItem>
              <MenuItem value={2}>No longer belongs to the team</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box component="form" sx={{ mt: 1 }} onSubmit={handleSubmit(onSubmit)}>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              sx={{ mb: 2, height: "34px", paddingTop: "auto" }}
              size="large"
              endIcon={<SendIcon />}
              disabled={circularLoading}
              type="submit"
            >
              Send
              {circularLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
}
export default NewCashiers;
