/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Button,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";

// import TableHorizontal from "../../../../components/TableHorizontal";
import api from "../../../../services/api";

import { useLocation } from "react-router-dom";
import { LogTrackUser } from "../../../../types/log-track-user/log-track-user";
import { AsyncPaginate } from "react-select-async-paginate";
import getProductTypeName from "../../../../config/getProductTypeName";
import moment from "moment";
import { Link } from "react-router-dom";
import getStoreName from "../../../../config/getStoreName";
import { inputsName } from "../../../../config/languageConst";
import Cookies from "js-cookie";

type PropsWarehouse = {
  id: number;
  name: string;
  city: string;
  postal_code: string;
  address: string;
  address_2: string;
  size: number;
  country_id: number;
  comments: string;
  created_at: Date;
  updated_at: Date;
};

type PropsProductQtyWarehouse = {
  id: number;
  product_id: number;
  warehouse_id: number;
  quantity: number;
  location: string;
  pre_order_sale_enabled: boolean;
  is_discontinued: boolean;
  last_change_username: string;
  warehouse: PropsWarehouse;
  created_at: Date;
  updated_at: Date;
};

type PropsProductWholesalePDVStore = {
  product_id: number;
  store_id: number;
  price: number;
  price_sale: number;
  is_sale: boolean;
  sale_begin: Date;
  sale_end: Date;
  status: number;
  created_at: Date;
  updated_at: Date;
};

type PropsProductWholesaleStore = {
  product_id: number;
  store_id: number;
  price: number;
  price_sale: number;
  is_sale: boolean;
  sale_begin: Date;
  sale_end: Date;
  status: number;
  created_at: Date;
  updated_at: Date;
};

type PropsProductPDVStore = {
  product_id: number;
  store_id: number;
  price: number;
  price_sale: number;
  is_sale: boolean;
  sale_begin: Date;
  sale_end: Date;
  product_condition_id: number;
  status: number;
  created_at: Date;
  updated_at: Date;
};

type PropsProductStore = {
  product_id: number;
  store_id: number;
  price: number;
  price_sale: number;
  is_sale: boolean;
  sale_begin: Date;
  sale_end: Date;
  product_condition_id: number;
  status: number;
  show_catalog: boolean;
  sort_order: number;
  created_at: Date;
  updated_at: Date;
};

type PropsProductDescription = {
  product_id: number;
  language_id: number;
  name: string;
  description: string;
  seo_keyword: string;
  seo_url: string;
};

type PropsProductCategoriesDescription = {
  category_id: number;
  language_id: number;
  name: string;
  meta_keyword: string;
  image: string;
  content_html: string;
  seo_url: string;
  created_at: Date;
  updated_at: Date;
};

type PropsProductCategories = {
  id: number;
  parent_id?: number;
  created_at: Date;
  updated_at: Date;
  descriptions: PropsProductCategoriesDescription[];
};

type PropsProductImages = {
  product_id: number;
  image: string;
  sort_order: number;
  created_at: Date;
  updated_at: Date;
};

type PropsProduct = {
  id: number;
  ysin: string;
  sku: string;
  barcode: string;
  barcode_type: string;
  image: string;
  image2: string;
  product_type: number;
  status: number;
  brand_id: number;
  product_qty_warehouse?: PropsProductQtyWarehouse[];
  product_store?: PropsProductStore[];
  product_pdv_store?: PropsProductPDVStore[];
  product_wholesale_store?: PropsProductWholesaleStore[];
  product_wholesale_pdv_store?: PropsProductWholesalePDVStore[];
  descriptions?: PropsProductDescription[];
  categories?: PropsProductCategories[];
  images?: PropsProductImages[];
  created_at: Date;
  updated_at: Date;
};

const imageBaseURL = process.env.REACT_APP_IMG_CATALOG_URL;

export default function ProductInventoryStoreReport() {
  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////
  const [productInventoryStoreReport, setProductInventoryStoreReport] =
    useState<PropsProduct[]>([]);

  const fetchData = async () => {
    // Avoid too much big data
    if (product_id_beginFilter < 1) setProduct_id_beginFilter(1);
    if (product_id_endFilter < 1) setProduct_id_endFilter(1);
    // No more than 1000 Products ID
    if (Math.abs(product_id_beginFilter - product_id_endFilter) > 1000) {
      setProduct_id_endFilter(product_id_beginFilter + 1000);
    }

    let array_check = [
      inventory,
      prices,
      descriptions,
      categories,
      images,
      brand,
      measurements,
      inventory_sell_attribute,
      attributes,
      specifications,
      reviews,
    ];
    const array_check_count = array_check.filter(Boolean).length;
    if (array_check_count > 5) return;

    await api
      .get(
        `/product-stores/reports/used-unused-products?product_id_begin=${product_id_beginFilter}` +
          `&product_id_end=${product_id_endFilter}&${
            warehouseFilter
              ? "warehouse_id=" + warehouseFilter.id.toString()
              : ""
          }
        ${
          storeFilter ? "store_id=" + storeFilter.id.toString() : ""
        }&language_id=${languageFilter}&inventory=${inventory.toString()}` +
          `&prices=${prices.toString()}&descriptions=${descriptions.toString()}&categories=${categories.toString()}` +
          `&images=${images.toString()}&brand=${brand.toString()}&measurements=${measurements.toString()}` +
          `&inventory_sell_attribute=${inventory_sell_attribute.toString()}&attributes=${attributes.toString()}` +
          `&specifications=${specifications.toString()}&reviews=${reviews.toString()}`
      )
      .then((res) => {
        setProductInventoryStoreReport(res.data);
      });
  };

  const [product_id_beginFilter, setProduct_id_beginFilter] = useState(1);
  const handleChangeProduct_id_beginFilter = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProduct_id_beginFilter(Number(e.target.value));
  };

  const [product_id_endFilter, setProduct_id_endFilter] = useState(1000);
  const handleChangeProduct_id_endFilter = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProduct_id_endFilter(Number(e.target.value));
  };

  const defaultAdditional = {
    page: 1,
  };

  const loadOptionsWarehouses = async (search: any, page: any) => {
    const results = await api.get(
      `/warehouses?page=${page}&limit=0&search=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsWarehouses = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsWarehouses(q, page);
    if (options.length > 0) {
      options.unshift({ id: 0, code: "None" });
    }
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const [warehouseFilter, setWarehouseFilter] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const loadOptionsStores = async (search: any, page: any) => {
    const results = await api.get(`/stores?page=${page}&search=${search}`);
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsStores = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsStores(q, page);
    if (options.length > 0) {
      options.unshift({ id: 0, name: "None" });
    }
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const [storeFilter, setStoreFilter] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const [languageFilter, setLanguageFilter] = useState(0);

  const [languages, setLanguages] = useState<{ id: number; name: string }[]>(
    []
  );
  const fetchLanguages = async () => {
    const response = await api.get(`languages`);
    setLanguages(response.data.items);
    setLanguageFilter(1);
  };

  const [inventory, setInventory] = useState(false);
  const [prices, setPrices] = useState(false);
  const [descriptions, setDescriptions] = useState(false);
  const [categories, setCategories] = useState(false);
  const [images, setImages] = useState(false);
  const [brand, setBrand] = useState(false);
  const [measurements, setMeasurements] = useState(false);
  const [inventory_sell_attribute, setInventory_sell_attribute] =
    useState(false);
  const [attributes, setAttributes] = useState(false);
  const [specifications, setSpecifications] = useState(false);
  const [reviews, setReviews] = useState(false);

  const handleFilterButtonClick = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
    fetchLanguages();
  }, []);

  useEffect(() => {
    console.log("teste");
    fetchData();
  }, [
    inventory,
    prices,
    descriptions,
    categories,
    images,
    brand,
    measurements,
    inventory_sell_attribute,
    attributes,
    specifications,
    reviews,
  ]);

  return (
    <Container maxWidth="xl" sx={{ mt: 2, mb: 4 }}>
      <Typography
        component="h1"
        variant="h5"
        sx={{ fontSize: "32px", marginBottom: "10px" }}
      >
        Product Inventory Store Report
      </Typography>
      <Box
        sx={{
          mt: "8px",
          minWidth: 1,
          marginRight: 1,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        <TextField
          sx={{ minWidth: 1 / 8, width: 1 / 8, marginRight: 1 }}
          margin="normal"
          //fullWidth
          id={"product_id_begin-filter"}
          type="number"
          label="Product ID Begin"
          value={product_id_beginFilter}
          onChange={handleChangeProduct_id_beginFilter}
        />
        <TextField
          sx={{ minWidth: 1 / 8, width: 1 / 8, marginRight: 1 }}
          margin="normal"
          //fullWidth
          id={"product_id_end-filter"}
          type="number"
          label="Product ID End"
          value={product_id_endFilter}
          onChange={handleChangeProduct_id_endFilter}
        />

        <Box
          sx={{
            mt: "8px",
            minWidth: 1 / 4,
            marginRight: 1,
            flexDirection: "column",
          }}
        >
          <InputLabel id="store-filter-label">Select Warehouse</InputLabel>
          <AsyncPaginate
            placeholder="Select Warehouse"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"warehouse_id"}
            id="warehouse"
            name="warehouse"
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.name}
            loadOptions={loadPageOptionsWarehouses}
            value={warehouseFilter}
            onChange={setWarehouseFilter}
          />
        </Box>

        <Box
          sx={{
            mt: "8px",
            minWidth: 1 / 4,
            marginRight: 1,
            flexDirection: "column",
          }}
        >
          <InputLabel id="store-filter-label">Store</InputLabel>
          <AsyncPaginate
            placeholder="Select Store"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"productstore_store"}
            id="productstore_store"
            name="productstore_store"
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.name}
            loadOptions={loadPageOptionsStores}
            value={storeFilter}
            onChange={setStoreFilter}
          />
        </Box>

        <Box
          sx={{
            mt: "8px",
            minWidth: 1 / 6,
            width: 1 / 6,
            marginRight: 1,
            flexDirection: "column",
          }}
        >
          <InputLabel id="language-label">Language</InputLabel>
          <Select
            labelId="language-select-label"
            id="language"
            value={String(languageFilter)}
            label="Language"
            onChange={(e) => setLanguageFilter(Number(e.target.value))}
            sx={{ maxHeight: "2.4em" }}
          >
            <MenuItem value={0}>None</MenuItem>
            {languages.map((language, index) => (
              <MenuItem value={language.id} key={index}>
                {language.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box
        sx={{
          mt: "8px",
          marginRight: 1,
          fontWeight: "bold",
        }}
      >
        No more than 5 checked box simultaneously
      </Box>
      <Box
        sx={{
          mt: "8px",
          minWidth: 1,
          marginRight: 1,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        <FormControlLabel
          label="Inventory?"
          control={
            <Checkbox
              checked={inventory || false}
              onChange={(e) => setInventory(e.target.checked)}
              inputProps={{ "aria-label": "Inventory?" }}
            />
          }
        />

        <FormControlLabel
          label="Prices?"
          control={
            <Checkbox
              checked={prices || false}
              onChange={(e) => setPrices(e.target.checked)}
              inputProps={{ "aria-label": "Prices?" }}
            />
          }
        />

        <FormControlLabel
          label="Descriptions?"
          control={
            <Checkbox
              checked={descriptions || false}
              onChange={(e) => setDescriptions(e.target.checked)}
              inputProps={{ "aria-label": "Descriptions?" }}
            />
          }
        />
        <FormControlLabel
          label="Categories?"
          control={
            <Checkbox
              checked={categories || false}
              onChange={(e) => setCategories(e.target.checked)}
              inputProps={{ "aria-label": "Categories?" }}
            />
          }
        />
        <FormControlLabel
          label="Images?"
          control={
            <Checkbox
              checked={images || false}
              onChange={(e) => setImages(e.target.checked)}
              inputProps={{ "aria-label": "Images?" }}
            />
          }
        />
        <FormControlLabel
          label="Brands?"
          control={
            <Checkbox
              checked={brand || false}
              onChange={(e) => setBrand(e.target.checked)}
              inputProps={{ "aria-label": "Brands?" }}
            />
          }
        />
        <FormControlLabel
          label="Measurements?"
          control={
            <Checkbox
              checked={measurements || false}
              onChange={(e) => setMeasurements(e.target.checked)}
              inputProps={{ "aria-label": "Measurements?" }}
            />
          }
        />

        <FormControlLabel
          label="Inventory Sell Atrributes?"
          control={
            <Checkbox
              checked={inventory_sell_attribute || false}
              onChange={(e) => setInventory_sell_attribute(e.target.checked)}
              inputProps={{ "aria-label": "Inventory Sell Atrributes?" }}
            />
          }
        />

        <FormControlLabel
          label="Attributes?"
          control={
            <Checkbox
              checked={attributes || false}
              onChange={(e) => setAttributes(e.target.checked)}
              inputProps={{ "aria-label": "Atrributes?" }}
            />
          }
        />

        <FormControlLabel
          label="Specifications?"
          control={
            <Checkbox
              checked={specifications || false}
              onChange={(e) => setSpecifications(e.target.checked)}
              inputProps={{ "aria-label": "Specifications?" }}
            />
          }
        />

        <FormControlLabel
          label="Reviews?"
          control={
            <Checkbox
              checked={reviews || false}
              onChange={(e) => setReviews(e.target.checked)}
              inputProps={{ "aria-label": "Reviews?" }}
            />
          }
        />
      </Box>
      <Box
        sx={{
          mt: "8px",
          minWidth: 1,
          marginRight: 1,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        <Button
          variant="contained"
          sx={{ m: 3 }}
          href=""
          onClick={handleFilterButtonClick}
        >
          Filter
        </Button>
      </Box>
      <Box sx={{ alignItems: "start" }}>
        {productInventoryStoreReport.map((productInventoryStore, index) => (
          <div key={index}>
            ID: {productInventoryStore.id} / SKU: {productInventoryStore.sku} /{" "}
            {getProductTypeName(productInventoryStore.product_type)} / Barcode:{" "}
            {productInventoryStore.barcode_type} /{" "}
            {productInventoryStore.barcode} / Last Update:{" "}
            {moment(productInventoryStore.updated_at).format(
              "DD/MM/YYYY hh:mm:ss"
            )}
            /{" "}
            <Link to={`/products/${productInventoryStore.id}`} target="_blank">
              EDIT
            </Link>
            <br></br>
            {inventory && (
              <div>
                <b>INVENTORY:</b>
                {productInventoryStore?.product_qty_warehouse?.length ? (
                  <Box
                    sx={{
                      paddingLeft: "10px",
                    }}
                  >
                    {productInventoryStore.product_qty_warehouse.map(
                      (productQtyWarehouse, index) => (
                        <div key={index}>
                          Warehouse: {productQtyWarehouse.warehouse.name} /
                          Quantity: {productQtyWarehouse.quantity} / Location:{" "}
                          {productQtyWarehouse.location} / Pre Order?:{" "}
                          {productQtyWarehouse.pre_order_sale_enabled.toString()}{" "}
                          / Is Discontinued?:{" "}
                          {productQtyWarehouse.is_discontinued.toString()} /{" "}
                          <Link
                            to={`/product-qty-warehouse/${productQtyWarehouse.id}`}
                            target="_blank"
                          >
                            EDIT
                          </Link>
                        </div>
                      )
                    )}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      color: "red",
                      fontWeight: "bold",
                      paddingLeft: "10px",
                    }}
                  >
                    NO INVENTORY REGISTERED{" / "}
                    <Link to={`/product-qty-warehouse/`} target="_blank">
                      CREATE
                    </Link>
                  </Box>
                )}
              </div>
            )}
            {prices && (
              <div>
                <b>
                  PRICES:{" "}
                  <Link to={`/product-stores/`} target="_blank">
                    EDIT CONSOLIDATE
                  </Link>
                </b>

                {productInventoryStore?.product_store?.length ? (
                  <Box
                    sx={{
                      paddingLeft: "10px",
                    }}
                  >
                    <b>
                      ONLINE STORE:{" "}
                      <Link to={`/product-stores/store`} target="_blank">
                        EDIT
                      </Link>
                    </b>
                    <br></br>
                    {productInventoryStore.product_store.map(
                      (productStore, index) => (
                        <div key={index}>
                          Store: {getStoreName(productStore.store_id)} / Price:{" "}
                          {productStore.price} / Condition ID:{" "}
                          {productStore.product_condition_id} / Status:{" "}
                          {productStore.status} / Show Catalog:{" "}
                          {productStore.show_catalog.toString()} / Sort Order:{" "}
                          {productStore.sort_order}
                          {productStore.is_sale && (
                            <>
                              / Is Sale?: {productStore.is_sale.toString()} /
                              Price Sale: {productStore.price_sale} / Sale
                              Begin:{" "}
                              {moment(productStore.sale_begin).format(
                                "DD/MM/YYYY hh:mm:ss"
                              )}{" "}
                              / Sale End:{" "}
                              {moment(productStore.sale_end).format(
                                "DD/MM/YYYY hh:mm:ss"
                              )}{" "}
                            </>
                          )}
                        </div>
                      )
                    )}
                    <b>
                      PDV STORE:{" "}
                      <Link to={`/product-stores/pdvstore`} target="_blank">
                        EDIT
                      </Link>
                    </b>
                    <br></br>
                    {productInventoryStore?.product_pdv_store?.map(
                      (productStore, index) => (
                        <div key={index}>
                          Store: {getStoreName(productStore.store_id)} / Price:{" "}
                          {productStore.price} / Condition ID:{" "}
                          {productStore.product_condition_id} / Status:{" "}
                          {productStore.status}
                          {productStore.is_sale && (
                            <>
                              / Is Sale?: {productStore.is_sale.toString()} /
                              Price Sale: {productStore.price_sale} / Sale
                              Begin:{" "}
                              {moment(productStore.sale_begin).format(
                                "DD/MM/YYYY hh:mm:ss"
                              )}{" "}
                              / Sale End:{" "}
                              {moment(productStore.sale_end).format(
                                "DD/MM/YYYY hh:mm:ss"
                              )}{" "}
                            </>
                          )}
                        </div>
                      )
                    )}
                    <b>
                      WHOLESALE STORE:{" "}
                      <Link
                        to={`/product-stores/wholesalestore`}
                        target="_blank"
                      >
                        EDIT
                      </Link>
                    </b>
                    <br></br>
                    {productInventoryStore?.product_wholesale_store?.map(
                      (productStore, index) => (
                        <div key={index}>
                          Store: {getStoreName(productStore.store_id)} / Price:{" "}
                          {productStore.price} / Status: {productStore.status}
                          {productStore.is_sale && (
                            <>
                              / Is Sale?: {productStore.is_sale.toString()} /
                              Price Sale: {productStore.price_sale} / Sale
                              Begin:{" "}
                              {moment(productStore.sale_begin).format(
                                "DD/MM/YYYY hh:mm:ss"
                              )}{" "}
                              / Sale End:{" "}
                              {moment(productStore.sale_end).format(
                                "DD/MM/YYYY hh:mm:ss"
                              )}{" "}
                            </>
                          )}
                        </div>
                      )
                    )}
                    <b>
                      WHOLESALE PDV STORE:{" "}
                      <Link
                        to={`/product-stores/wholesalepdvstore`}
                        target="_blank"
                      >
                        EDIT
                      </Link>
                    </b>
                    <br></br>
                    {productInventoryStore?.product_wholesale_pdv_store?.map(
                      (productStore, index) => (
                        <div key={index}>
                          Store: {getStoreName(productStore.store_id)} / Price:{" "}
                          {productStore.price} / Status: {productStore.status}
                          {productStore.is_sale && (
                            <>
                              / Is Sale?: {productStore.is_sale.toString()} /
                              Price Sale: {productStore.price_sale} / Sale
                              Begin:{" "}
                              {moment(productStore.sale_begin).format(
                                "DD/MM/YYYY hh:mm:ss"
                              )}{" "}
                              / Sale End:{" "}
                              {moment(productStore.sale_end).format(
                                "DD/MM/YYYY hh:mm:ss"
                              )}{" "}
                            </>
                          )}
                        </div>
                      )
                    )}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      color: "red",
                      fontWeight: "bold",
                      paddingLeft: "10px",
                    }}
                  >
                    NO PRICES REGISTERED{" / "}
                    <Link to={`/product-stores/`} target="_blank">
                      CREATE
                    </Link>
                  </Box>
                )}
              </div>
            )}
            {descriptions && (
              <div>
                <b>
                  DESCRIPTIONS:{" "}
                  <Link
                    to={`/product/${productInventoryStore.id}`}
                    target="_blank"
                  >
                    EDIT
                  </Link>
                </b>
                {productInventoryStore?.descriptions?.length ? (
                  <Box
                    sx={{
                      paddingLeft: "10px",
                    }}
                  >
                    {productInventoryStore.descriptions.map(
                      (descriptions, index) => (
                        <div key={index}>
                          Language: {inputsName[index].code} / Name:{" "}
                          {descriptions.name}
                          <br></br>
                          Description: {descriptions.description}
                          <br></br>
                          SEO Keyword: {descriptions.seo_keyword}
                          <br></br>
                          SEO URL: {descriptions.seo_url}
                          <br></br>
                          <Link
                            to={`/product/${productInventoryStore.id}`}
                            target="_blank"
                          >
                            EDIT
                          </Link>
                        </div>
                      )
                    )}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      color: "red",
                      fontWeight: "bold",
                      paddingLeft: "10px",
                    }}
                  >
                    NO DESCRIPTIONS{" / "}
                    <Link
                      to={`/product/${productInventoryStore.id}`}
                      target="_blank"
                    >
                      CREATE
                    </Link>
                  </Box>
                )}
              </div>
            )}
            {categories && (
              <div>
                <b>
                  CATEGORIES:{" "}
                  <Link
                    to={`/product/${productInventoryStore.id}`}
                    target="_blank"
                  >
                    EDIT PRODUCT
                  </Link>
                </b>
                {productInventoryStore?.categories?.length ? (
                  <Box
                    sx={{
                      paddingLeft: "10px",
                    }}
                  >
                    {productInventoryStore.categories.map((category, index) => (
                      <div key={index}>
                        ID: {category.id} / Name:{" "}
                        {category.descriptions[0].name} / SEO URL:{" "}
                        {category.descriptions[0].seo_url}
                        {category.parent_id && (
                          <>/ PARENT CATEGORY: {category.parent_id} </>
                        )}{" "}
                        <Link to={`/categories/${category.id}`} target="_blank">
                          CHECK CATEGORY
                        </Link>
                      </div>
                    ))}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      color: "red",
                      fontWeight: "bold",
                      paddingLeft: "10px",
                    }}
                  >
                    NO CATEGORIES LINKED TO THE PRODUCT{" / "}
                    <Link
                      to={`/product/${productInventoryStore.id}`}
                      target="_blank"
                    >
                      LINK CATEGORIES TO THE PRODUCT
                    </Link>
                  </Box>
                )}
              </div>
            )}
            {images && (
              <div>
                <b>
                  IMAGES:{" "}
                  <Link
                    to={`/product/${productInventoryStore.id}`}
                    target="_blank"
                  >
                    EDIT PRODUCT
                  </Link>
                </b>
                {productInventoryStore.image ? (
                  <Box
                    sx={{
                      paddingLeft: "10px",
                    }}
                  >
                    <div>
                      Main Image: {productInventoryStore.image}{" "}
                      <Link
                        to={`${imageBaseURL}${productInventoryStore.image}`}
                        target="_blank"
                      >
                        SEE IMAGE
                      </Link>
                    </div>
                    {productInventoryStore.image2 && (
                      <div>
                        Secondary Image: {productInventoryStore.image2}{" "}
                        <Link
                          to={`${imageBaseURL}${productInventoryStore.image2}`}
                          target="_blank"
                        >
                          SEE IMAGE
                        </Link>
                      </div>
                    )}

                    {productInventoryStore?.images?.map((image, index) => (
                      <div key={index}>
                        Image: {image.image} / Sort Order: {image.sort_order}{" "}
                        <Link
                          to={`${imageBaseURL}${image.image}`}
                          target="_blank"
                        >
                          SEE IMAGE
                        </Link>
                      </div>
                    ))}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      color: "red",
                      fontWeight: "bold",
                      paddingLeft: "10px",
                    }}
                  >
                    NO IMAGES{" / "}
                    <Link
                      to={`/product/${productInventoryStore.id}`}
                      target="_blank"
                    >
                      UPLOAD IMAGES
                    </Link>
                  </Box>
                )}
              </div>
            )}
            <br></br>
            <br></br>
          </div>
        ))}

        {/*         <TableHorizontal
          screen={"errorslogs"}
          columns={columns}
          data={errorslog}
          objectKey={keys}
        /> */}
      </Box>
    </Container>
  );
}
