import { ProductTypeEnum } from "../enums/product-type.enum";

const getProductTypeName = (productType: ProductTypeEnum) => {
  switch (productType) {
    case ProductTypeEnum.UNIQUE_PRODUCT:
      return "UNIQUE PRODUCT";
    case ProductTypeEnum.PARENT_PRODUCT:
      return "PARENT PRODUCT";
    case ProductTypeEnum.CHILD_PRODUCT:
      return "CHILD PRODUCT";
    case ProductTypeEnum.COMBINATION_PRODUCT:
      return "COMBINATION PRODUCT";
    default:
      return null;
  }
};

export default getProductTypeName;
