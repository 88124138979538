import { useEffect, useState } from "react";
import { Container, Pagination, Box } from "@mui/material";
import TableHorizontal from "../TableHorizontal";
import api from "../../services/api";
function ListSellers() {
  const [pageState, setPagepageState] = useState(1);
  const [total_pages, setTotalPages] = useState(0);
  const [sellers, setSellers] = useState([]);
  const columns = ["ID", "Name", "Code", "Phone Number", "Actions", "Delete"];
  const keys = ["id", "name", "code", "phone_number", "action", "delete"];
  const fetchData = async (page = 1) => {
    await api.get(`/sellers?page=${page}&limit=200`).then((res) => {
      setSellers(res.data.items);
      setTotalPages(res.data.total_pages);
    });
  };
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPagepageState(value);
    fetchData(value);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
      <TableHorizontal
        screen={"sellers"}
        columns={columns}
        data={sellers}
        objectKey={keys}
      />
      <Box
        display="flex"
        width={"100%"}
        height={80}
        alignItems="center"
        justifyContent="center"
      >
        <Pagination
          count={total_pages}
          page={pageState}
          onChange={handleChange}
        />
      </Box>
    </Container>
  );
}
export default ListSellers;
