/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { green } from "@mui/material/colors";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import api from "../../../../../services/api";
import { useLocation, useParams } from "react-router-dom";
import { LogTrackUser } from "../../../../../types/log-track-user/log-track-user";
import TableHorizontal from "../../../../../components/TableHorizontal";
import { AsyncPaginate } from "react-select-async-paginate";
import Cookies from "js-cookie";

export default function EditProductSupplier() {
  const { id } = useParams<{ id: string }>();
  const [circularLoading, setCircularLoading] = React.useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestErrorData, setRequestErrorData] = useState([]);

  const [product, setProduct] = useState<{ id: number; sku: string } | null>(
    null
  );

  const columns = [
    "Product",
    "Supplier",
    "Currency",
    "Purchase Date",
    "Quantity Purchase",
    "Unit",
    "Unit Price",
    "Order number",
    "Username",
    "Comments",
  ];
  const keys = [
    "sku",
    "name",
    "currency_id",
    "purchase_date",
    "quantity_purchased",
    "quantity_unit",
    "unit_price_purchased",
    "purchase_order_number",
    "username",
    "comments",
  ];

  const fetchProductSuppliers = async () => {
    await api.get(`product-suppliers/${id}`).then((response) => {
      reset(response.data);
      setProduct({
        id: response.data.product_id,
        sku: response.data.product.sku,
      });
      setQuantityUnit("UN");
      fetchHistory(response.data.product_id);
      /*       reset({
        quantity_purchased: "",
        comments: "",
      }) */
    });
  };

  const [pageState, setPageState] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [productSupplierHistory, setProductSupplierHistory] = useState([]);

  const fetchHistory = async (
    product_id: number,
    page = 1,
    numberItemsPerPage = 500
  ) => {
    await api
      .get(
        `product-suppliers/history?page=${page}&limit=${numberItemsPerPage}&product_id=${product_id}`
      )
      .then((res) => {
        setProductSupplierHistory(res.data.items);
        setTotalPages(res.data.total_pages);
      });
  };
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageState(value);
    fetchHistory(Number(id));
  };

  const [numberItemsPerPage, setNumberItemsPerPage] = useState("500");
  const handleNumberItemsPerPage = (event: SelectChangeEvent) => {
    setNumberItemsPerPage(event.target.value);
    fetchHistory(Number(id), pageState, Number(event.target.value));
  };

  function fetchHistoryClick() {
    fetchHistory(Number(id));
  }

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////

  const onSubmit = (suppliers: any) => {
    setCircularLoading(true);
    let username = Cookies.get("username") ? Cookies.get("username") : "";
    suppliers.username = username?.replace(/["']/g, "");

    suppliers.quantity_purchased = Number(suppliers.quantity_purchased);
    suppliers.product_id = Number(product?.id);
    suppliers.supplier_id = Number(supplier?.id);
    suppliers.currency_id = Number(currency?.id);
    suppliers.quantity_unit = quantityUnit;

    if (textState) {
      suppliers.warehouse_id = Number(suppliers.warehouse_id);
      suppliers.quantity_variation = Number(suppliers.quantity_variation);
      suppliers.location = String(suppliers.location);
    }

    api
      .post(`product-suppliers`, suppliers)
      .then((response) => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "product suppliers created!",
          icon: "success",
          confirmButtonText: "Yes",
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          footer: '<a href="">Why do I have this issue?</a>',
        });
        setCircularLoading(false);
        setRequestError(true); //trocado por false
        setRequestErrorData(error.response.data.message);
      });
  };

  const defaultAdditional = {
    page: 1,
  };

  const [supplier, setSupplier] = useState<{ id: number; name: string } | null>(
    null
  );
  const loadOptionsSupplier = async (search: any, page: any) => {
    const results = await api.get(`/suppliers?page=${page}&name=${search}`);
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsSupplier = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsSupplier(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const [currency, setCurrency] = useState<{ id: number; name: string } | null>(
    null
  );
  const loadOptionsCurrency = async (search: any, page: any) => {
    const results = await api.get(`/currencies?page=${page}&name=${search}`);
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsCurrency = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsCurrency(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const [quantityUnit, setQuantityUnit] = useState("UN");
  /*   const handleChangeQuantityUnit = (event: SelectChangeEvent) => {
    setQuantityUnit(event.target.value);
  }; */

  const [warehouses, setWarehouses] = React.useState<
    { id: number; name: string }[]
  >([]);
  const fetchWarehouses = async () => {
    const response = await api.get("warehouses");
    setWarehouses(response.data.items);
  };

  const [warehouse, setWarehouse] = useState("");
  const handleChangeWarehouse = (event: SelectChangeEvent) => {
    setWarehouse(event.target.value);
  };

  const [textState, setTextState] = useState(true);
  const handleChangeTextState = (event: SelectChangeEvent) => {
    if (textState === true) {
      setTextState(false);
    } else {
      setTextState(true);
    }
  };

  useEffect(() => {
    fetchProductSuppliers();
    fetchWarehouses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Container component="main" maxWidth="lg">
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            marginLeft: "20px",
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            sx={{
              fontSize: "30px",
              marginBottom: "15px",
              color: "#556cd6",
            }}
          >
            Edit Product Supplier {id}
          </Typography>
          {requestError && (
            <List component="nav" aria-label="erros">
              {requestErrorData.map((error, index) => (
                <ListItem key={index}>
                  <ListItemText
                    sx={{
                      color: "red",
                    }}
                    primary={error}
                  />
                </ListItem>
              ))}
            </List>
          )}
          <FormControl
            sx={{ m: 5, minWidth: 3 / 4 }} // equivalent to width: '75%' - usar fracao para %
            size="small"
          >
            <Box sx={{ mt: "8px" }}>
              <p>
                Product: <b>{product?.id}</b> / <b>{product?.sku}</b>
              </p>
              <br></br>
              Quantity Unit: <b>{quantityUnit}</b>
              <br></br>
              <AsyncPaginate
                placeholder="Supplier"
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                additional={defaultAdditional}
                key={"supplier_id"}
                id="supplier"
                name="supplier"
                getOptionValue={(option: any) => option?.id}
                getOptionLabel={(option: any) => option?.name}
                loadOptions={loadPageOptionsSupplier}
                value={supplier}
                onChange={setSupplier}
              />
              <p style={{ color: "red", textAlign: "center" }}>
                {errors?.supplier_id?.toString()}
              </p>
            </Box>
            <Box sx={{ mt: "8px" }}>
              <AsyncPaginate
                placeholder="Currency"
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                additional={defaultAdditional}
                key={"currency_id"}
                id="currency"
                name="currency"
                getOptionValue={(option: any) => option?.id}
                getOptionLabel={(option: any) => option?.name}
                loadOptions={loadPageOptionsCurrency}
                value={currency}
                onChange={setCurrency}
              />
              <p style={{ color: "red", textAlign: "center" }}>
                {errors?.currency_id?.toString()}
              </p>
            </Box>
            <TextField
              margin="normal"
              required
              fullWidth
              id="unit_price_purchased"
              type="number"
              label="Unit Price"
              {...register("unit_price_purchased")}
              autoFocus
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.unit_price_purchased?.toString()}
            </p>
            <TextField
              margin="normal"
              fullWidth
              id="purchase_order_number"
              type="string"
              label="Order Number"
              {...register("purchase_order_number")}
              autoFocus
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.purchase_order_number?.toString()}
            </p>
            <TextField
              id="purchase_date"
              label="Purchase Date"
              type="date"
              //defaultValue="2017-05-24"
              sx={{ minWidth: "25%", mt: "8px" }}
              InputLabelProps={{
                shrink: true,
              }}
              {...register("purchase_date")}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.purchase_date?.toString()}
            </p>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    defaultChecked={false}
                    onChange={handleChangeTextState}
                  />
                }
                label="Add quantity in warehouse"
              />
            </FormGroup>
            <FormControl fullWidth sx={{ marginBottom: "10px" }}>
              <InputLabel id="warehouse-select-label">Warehouse</InputLabel>
              <Select
                sx={{ minWidth: "25%", width: "40%" }}
                labelId="warehouse-select-label"
                id="warehouse"
                value={warehouse}
                {...register("warehouse_id")}
                label="warehouse"
                disabled={textState}
                onChange={handleChangeWarehouse}
              >
                {warehouses.map((warehouse, index) => (
                  <MenuItem key={index} value={warehouse.id}>
                    {warehouse.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              margin="normal"
              fullWidth
              id="quantity_purchased"
              type="number"
              label="Quantity Purchased"
              {...register("quantity_purchased")}
              autoFocus
              disabled={textState}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.quantity_purchased?.toString()}
            </p>
            <TextField
              margin="normal"
              required
              id="location"
              type="string"
              label="Inventory Location in Warehouse"
              disabled={textState}
              {...register("location")}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.location?.toString()}
            </p>
            <TextField
              margin="normal"
              fullWidth
              id="comments"
              type="text"
              label="Comments"
              rows={10}
              multiline
              {...register("comments")}
              autoFocus
              disabled={textState}
            />
            <p style={{ color: "red", textAlign: "center" }}>
              {errors?.comments?.toString()}
            </p>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                mt: 2,
              }}
            >
              <Button
                onClick={() => reset()}
                variant="outlined"
                startIcon={<DeleteIcon />}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                size="large"
                endIcon={<SendIcon />}
                disabled={circularLoading}
                type="submit"
              >
                Send
                {circularLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Button>
            </Stack>

            <FormHelperText
              sx={{ marginTop: 1, marginBottom: -1, marginLeft: 1 }}
            >
              Number of Items per Page
            </FormHelperText>
            <Select
              sx={{ mt: "16px" }}
              labelId="number-items-page-label"
              id="number-items-page"
              value={numberItemsPerPage}
              label="Number of Items per Page"
              onChange={handleNumberItemsPerPage}
            >
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={250}>250</MenuItem>
              <MenuItem value={500}>500</MenuItem>
              <MenuItem value={750}>750</MenuItem>
              <MenuItem value={1000}>1000</MenuItem>
            </Select>
            <Button
              variant="contained"
              href=""
              onClick={fetchHistoryClick}
              sx={{ mt: "8px" }}
            >
              Fetch history
            </Button>
            <TableHorizontal
              screen={"product-suppliers"}
              columns={columns}
              data={productSupplierHistory}
              objectKey={keys}
            />
            <Pagination
              count={totalPages}
              page={pageState}
              onChange={handleChange}
            />
          </FormControl>
        </Box>
      </Container>
    </div>
  );
}
