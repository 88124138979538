/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Container, Pagination, Box, Button, TextField } from "@mui/material";
import TableHorizontal from "../TableHorizontal";
import api from "../../services/api";

function ListSuppliers() {
  const [pageState, setPagepageState] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [suppliers, setSuppliers] = useState([]);
  const columns = [
    "ID",
    "Name",
    "Email",
    "Website",
    "Phone",
    "Responsible",
    "Actions",
    "Delete",
  ];
  const keys = [
    "id",
    "name",
    "email",
    "website",
    "phone_number",
    "responsible_salesperson",
    "action",
    "delete",
  ];
  const fetchData = async (
    page = 1,
    language = 1,
    numberItemsPerPage = 500
  ) => {
    await api
      .get(
        `/suppliers?page=${page}&&limit=${numberItemsPerPage}&language_id=${language}&name=${namefilter}&email=${emailfilter}&phone_number=${phonenumberfilter}&responsible_salesperson=${responsiblefilter}`
      )
      .then((res) => {
        setSuppliers(res.data.items);
        setTotalPages(res.data.total_pages);
      });
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPagepageState(value);
    fetchData(value);
  };

  const [language] = useState("1");

  const [namefilter, setNameFilter] = useState("");
  const handleChangeNamefilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameFilter(e.target.value);
  };

  const [emailfilter, setEmailFilter] = useState("");
  const handleChangeEmailfilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailFilter(e.target.value);
  };

  const [phonenumberfilter, setPhoneNumberFilter] = useState("");
  const handleChangePhoneNumberfilter = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPhoneNumberFilter(e.target.value);
  };

  const [responsiblefilter, setResponsibleFilter] = useState("");
  const handleChangeResponsibleFilter = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setResponsibleFilter(e.target.value);
  };

  const handleFilterButtonClick = () => {
    fetchData(1, Number(language));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container maxWidth="xl" sx={{ mt: 2, mb: 4 }}>
      <TextField
        //sx={{ mt: 1, minWidth: 3/4 }}
        sx={{ minWidth: 1 / 4 }}
        margin="normal"
        //fullWidth
        id={"name"}
        type="text"
        label="Name filter"
        value={namefilter}
        onChange={handleChangeNamefilter}
      />
      <TextField
        //sx={{ mt: 1, minWidth: 3/4 }}
        sx={{ minWidth: 1 / 4, mx: 1  }}
        margin="normal"
        //fullWidth
        id={"email"}
        type="text"
        label="Email Filter"
        value={emailfilter}
        onChange={handleChangeEmailfilter}
      />
      <TextField
        //sx={{ mt: 1, minWidth: 3/4 }}
        margin="normal"
        //fullWidth
        id={"Phone-number"}
        type="text"
        label="Phone Number Filter"
        value={phonenumberfilter}
        onChange={handleChangePhoneNumberfilter}
      />
      <TextField
        //sx={{ mt: 1, minWidth: 3/4 }}
        margin="normal"
        //fullWidth
        id={"responsible-sales"}
        type="text"
        label="Reponsible Filter"
        value={responsiblefilter}
        onChange={handleChangeResponsibleFilter}
      />
      <Button
        variant="contained"
        sx={{ m: 3 }}
        href=""
        onClick={handleFilterButtonClick}
      >
        Filter
      </Button>

      <TableHorizontal
        screen={"suppliers"}
        columns={columns}
        data={suppliers}
        objectKey={keys}
      />
      <Box
        display="flex"
        width={"100%"}
        height={80}
        alignItems="center"
        justifyContent="center"
      >
        <Pagination
          count={totalPages}
          page={pageState}
          onChange={handleChange}
        />
      </Box>
    </Container>
  );
}

export default ListSuppliers;
