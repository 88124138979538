/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Container,
  Pagination,
  Box,
  SelectChangeEvent,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
} from "@mui/material";
import TableHorizontal from "../../TableHorizontal";
import api from "../../../services/api";
function ListAttributeGroups() {
  const [pageState, setPageState] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [customerGroups, setCustomerGroups] = useState([]);
  const columns = ["ID", "Name", "Points Multiplier", "Order T. Multiplier", "Created At", "Actions", "Delete"];
  const keys = ["id", "name", "default_points_multiplier", "default_orders_total_multiplier", "when", "action", "delete"];
  const fetchData = async (
    page = 1,
    language = 1,
    numberItemsPerPage = 500
  ) => {
    await api
      .get(
        `/customer-groups?page=${page}&limit=${numberItemsPerPage}&language_id=${language}&name=${name}&search=${search}`
      )
      .then((res) => {
        setCustomerGroups(res.data.items);
        setTotalPages(res.data.total_pages);
      });
  };
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageState(value);
    fetchData(value, Number(language), Number(numberItemsPerPage));
  };

  const [search, setSearch] = useState("");
  const [name, setName] = useState("");

  const [numberItemsPerPage, setNumberItemsPerPage] = useState("500");
  const handleNumberItemsPerPage = (event: SelectChangeEvent) => {
    setNumberItemsPerPage(event.target.value);
    fetchData(1, Number(language), Number(event.target.value));
  };

  const [language, setLanguage] = useState('0');
  const handleChangeLanguage = (event: SelectChangeEvent) => {
    setLanguage(event.target.value);
    fetchData(1, Number(event.target.value), Number(numberItemsPerPage));
  };

  const [languages, setLanguages] = useState<{ id: number; name: string }[]>(
    []
  );
  const fetchLanguages = async () => {
    const response = await api.get(`languages`);
    setLanguages(response.data.items);
    setLanguage('1');
  };

  const handleSearchClick = () => {
    fetchData(1, Number(language));
  };

  useEffect(() => {
    fetchData();
    fetchLanguages();
  }, []);
  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
      <Box style={{ display: "flex", flexDirection:"row" }}>
        <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginRight: "8px",
          marginLeft: 1,
        }}
        >
      <InputLabel id="number-items-page-label">
        Number of Items per Page
      </InputLabel>
      <Select
        labelId="number-items-page-select-label"
        id="number-items-page"
        value={numberItemsPerPage}
        label="Number of Items per Page"
        onChange={handleNumberItemsPerPage}
      >
        <MenuItem value={100}>100</MenuItem>
        <MenuItem value={250}>250</MenuItem>
        <MenuItem value={500}>500</MenuItem>
        <MenuItem value={750}>750</MenuItem>
        <MenuItem value={1000}>1000</MenuItem>
      </Select>
      </Box>
      <Box
       sx={{
        display: "flex",
        flexDirection: "column",
      }}
      >
      <InputLabel id="language-label">Language</InputLabel>
      <Select
        labelId="language-select-label"
        id="language"
        value={language}
        label="Language"
        onChange={handleChangeLanguage}
      >
        <MenuItem value={0}>None</MenuItem>
        {languages.map((language, index) => (
          <MenuItem key={index} value={language.id}>{language.name}</MenuItem>
        ))}
      </Select>
      </Box>
      <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
      >
        <InputLabel id="name-label">
      Name
      </InputLabel>
      <TextField
        sx={{ minWidth: 1 / 4, marginLeft: "8px" }}
        id={"name-input"}
        type="text"
        label="Name"
        defaultValue={name}
        onBlur={(e) => setName(e.target.value)}
      />
      </Box>
      <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
      >
        <InputLabel id="search-label">
      Search
      </InputLabel>
      <TextField
        sx={{ minWidth: 1 / 4, marginLeft: "8px" }}
        id={"search-input"}
        type="text"
        label="Search"
        defaultValue={search}
        onBlur={(e) => setSearch(e.target.value)}
      />
      </Box>
      <Button
        variant="contained"
        sx={{ m: 3 }}
        href=""
        onClick={handleSearchClick}
      >
        Filter
      </Button>
      </Box>
      <TableHorizontal
        screen={"customer-groups"}
        columns={columns}
        data={customerGroups}
        objectKey={keys}
      />
      <Box
        display="flex"
        width={"100%"}
        height={80}
        alignItems="center"
        justifyContent="center"
      >
        <Pagination
          count={totalPages}
          page={pageState}
          onChange={handleChange}
        />
      </Box>
    </Container>
  );
}
export default ListAttributeGroups;
