/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Container,
  Typography,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  ListItem,
  ListItemText,
  List,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";

import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import Swal from "sweetalert2";
import { useLocation, useParams } from "react-router-dom";
import { LogTrackUser } from "../../../types/log-track-user/log-track-user";
import Cookies from "js-cookie";

//import { AsyncPaginate } from "react-select-async-paginate";

export default function EditUser() {
  const { id } = useParams<{ id: string }>();
  const [circularLoading, setCircularLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);

  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////
  const [requestErrorData, setRequestErrorData] = useState([]);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (cashier: any) => {
    setCircularLoading(true);
    cashier.updated_at = new Date().toISOString();

    api
      .put(`cashiers/${id}`, cashier)
      .then(() => {
        setCircularLoading(false);
        setRequestError(false);
        Swal.fire({
          title: "cashier updated!",
          icon: "success",
          confirmButtonText: "Yes",
        });
        //reset();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "" + error.response.data.message,
          footer: '<a href="">Why do I have this issue?</a>',
        });
        setCircularLoading(false);
        setRequestError(true);
        setRequestErrorData(error.response.data.message);
      });
  };

  const [status, setStatus] = React.useState("");
  const handleChangeStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  const fetchCashier = async () => {
    const response = await api.get(`cashiers/${id}`);
    reset(response.data);
    setStatus(response.data.status);
  };
  useEffect(() => {
    fetchCashier();
  }, []);
  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{ fontSize: "30px", marginBottom: "15px", color: "#556cd6" }}
        >
          Edit Cashier {id}
        </Typography>
        {requestError && (
          <List component="nav" aria-label="secondary mailbox folder">
            {requestErrorData.map((error) => (
              <ListItem key={error}>
                <ListItemText
                  sx={{
                    color: "red",
                  }}
                  primary={error}
                />
              </ListItem>
            ))}
          </List>
        )}
        <Box
          component="form"
          sx={{ mt: 1, minWidth: 3 / 4 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormControl fullWidth>
            <InputLabel id="status-select">Status</InputLabel>
            <Select
              labelId="status-select-label"
              id="status"
              value={status}
              {...register("status", {
                required: "Status is required",
              })}
              label="Status"
              onChange={handleChangeStatus}
            >
              <MenuItem value={1}>Enabled</MenuItem>
              <MenuItem value={0}>Disabled</MenuItem>
              <MenuItem value={2}>No longer belongs to the team</MenuItem>
            </Select>
          </FormControl>
          <p style={{ color: "red", textAlign: "center" }}>
            {errors?.status?.toString()}
          </p>

          <Stack
            direction="row"
            spacing={2}
            sx={{
              mt: 2,
            }}
          >
            <Button href="/cashiers">Back</Button>
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              size="large"
              endIcon={<SendIcon />}
              disabled={circularLoading}
              type="submit"
            >
              Send
              {circularLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>
          </Stack>

          <div style={{ marginTop: "100px" }}></div>
        </Box>
      </Box>
    </Container>
  );
}
