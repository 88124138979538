/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Container,
  Pagination,
  Box,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  TextField,
  Button,
} from "@mui/material";
import TableHorizontal from "../TableHorizontal";
import api from "../../services/api";

export default function ListCarts() {
  const [pageState, setPageState] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [carts, setCarts] = useState([]);
  const columns = [
    "ID",
    "Store",
    "Customer",
    "Customer Email",
    "Last Updated",
    "Actions",
    "Delete",
  ];
  const keys = [
    "id",
    "store",
    "customer_name",
    "customer_email",
    "when",
    "action",
    "delete",
  ];
  const fetchData = async (page = 1, numberItemsPerPage = 500) => {
    await api
      .get(
        `/carts?page=${page}&limit=${numberItemsPerPage}&store_id=${store}&customer=${search}`
      )
      .then((res) => {
        setCarts(res.data.items);
        setTotalPages(res.data.total_pages);
      });
  };
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageState(value);
    fetchData(value, Number(numberItemsPerPage));
  };

  const [numberItemsPerPage, setNumberItemsPerPage] = useState("500");
  const handleNumberItemsPerPage = (event: SelectChangeEvent) => {
    setNumberItemsPerPage(event.target.value);
    fetchData(1, Number(event.target.value));
  };

  const [stores, setStores] = useState<{ id: number; name: string }[]>([]);
  const fetchStores = async () => {
    const response = await api.get("stores");
    setStores(response.data.items);
  };

  const [store, setStore] = useState("");

  const [search, setSearch] = useState("");

  const handleSearchClick = () => {
    fetchData(1);
  };

  useEffect(() => {
    fetchData();
    fetchStores();
  }, []);
  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="number-items-page-label">
            Number of Items per Page
          </InputLabel>
          <Select
            labelId="number-items-page-select-label"
            id="number-items-page"
            value={numberItemsPerPage}
            label="Number of Items per Page"
            onChange={handleNumberItemsPerPage}
          >
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={250}>250</MenuItem>
            <MenuItem value={500}>500</MenuItem>
            <MenuItem value={750}>750</MenuItem>
            <MenuItem value={1000}>1000</MenuItem>
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="store-select-label">Store</InputLabel>
          <Select
            labelId="store-select-label"
            id="store_id"
            value={store}
            label="Store"
            onChange={(e) => setStore(e.target.value)}
          >
            <MenuItem key={0} value={0}>
              None
            </MenuItem>
            {stores.map((store, index) => (
              <MenuItem key={index} value={store.id}>
                {store.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <InputLabel id="search-label">Search</InputLabel>
          <TextField
            sx={{ minWidth: 1 / 4, marginLeft: "8px" }}
            id={"search-input"}
            type="text"
            label="Search"
            defaultValue={search}
            onBlur={(e) => setSearch(e.target.value)}
          />
        </Box>
        <Button
          variant="contained"
          sx={{ m: 3 }}
          href=""
          onClick={handleSearchClick}
        >
          Filter
        </Button>
      </Box>
      <TableHorizontal
        screen={"carts"}
        columns={columns}
        data={carts}
        objectKey={keys}
      />
      <Box
        display="flex"
        width={"100%"}
        height={80}
        alignItems="center"
        justifyContent="center"
      >
        <Pagination
          count={totalPages}
          page={pageState}
          onChange={handleChange}
        />
      </Box>
    </Container>
  );
}
